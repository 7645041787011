import React, { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Swal from 'sweetalert2';
import Map from './Map/map';
import './contactStyles.scss';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';

function Contact() {
  usePageTitle('DRIVESOFT | CONTACT');
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}contact/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formState),
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Message envoyé',
          text: 'Le message a été envoyé avec succès.',
        });
        setFormState({ name: '', email: '', message: '' });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Erreur lors de l\'envoi du message.',
        });
      }
    }
    catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Erreur lors de l\'envoi du message.',
      });
    }
  };

  return (
    <div className="contact">
      <div className="contact__title">
        <h1>CONTACT</h1>
      </div>

      <div className="wrapper-contact">
        <div className="contact__content__form">
          <form onSubmit={handleSubmit}>
            <div className="contact__content__form__group">
              <input
                className="input-contact"
                type="text"
                name="name"
                id="name"
                placeholder="Nom"
                required
                value={formState.name}
                onChange={handleChange}
              />
            </div>
            <div className="contact__content__form__group">
              <input
                className="input-contact"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                value={formState.email}
                onChange={handleChange}
              />
            </div>
            <div className="contact__content__form__group">
              <textarea
                className="input-contact"
                name="message"
                id="message"
                placeholder="Votre message"
                required
                value={formState.message}
                onChange={handleChange}
              />
            </div>
            <div className="button-container">
              <button type="submit" className="button__contact">Envoyer</button>
            </div>
          </form>
        </div>
        <div className="contact__content__info">
          <div className="contact__content__info__address">
            <LocationOnIcon fontSize="large" />
            <h2 className="contact__infotext"> 3 rue Marlène Dietrich, Herblay-sur-Seine</h2>
          </div>
          <div className="contact__content__info__phone">
            <LocalPhoneIcon fontSize="large" />
            <h2 className="contact__infotext"> 06 52 80 77 36</h2>
          </div>
          <div className="contact__content__info__email">
            <EmailIcon fontSize="large" />
            <h2 className="contact__infotext"> contact@drivesoft.fr </h2>
          </div>
        </div>
      </div>
      <div className="contact__map">
        <Map />
      </div>
    </div>
  );
}

export default React.memo(Contact);
