/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
  useMediaQuery,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

function LocauxDataTab({
  locaux,
  handleFilterLocauxChange,
  currentLocauxFacturesPage,
  formatDate,
  calculateFactureCost,
  paginateLocaux,
  currentLocauxPage,
  filteredLocauxFactures,
  facturesPerPage,
  totalLocauxCost,
  locauxCostsByType,
  pastelColors,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Barre de Filtrage */}
      <Box sx={{ marginBottom: 4 }}>
        <Autocomplete
          options={[{ nom: '', id: 0 }, ...locaux]}
          getOptionLabel={(option) => option.nom || 'Tous les locaux'}
          onChange={(event, newValue) => handleFilterLocauxChange(newValue ? newValue.nom : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrer par local"
              variant="outlined"
              fullWidth
            />
          )}
          clearOnEscape
        />
      </Box>

      {/* Tableau des factures */}
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom du local</TableCell>
              <TableCell>Date de la facture</TableCell>
              <TableCell>Type de facture</TableCell>
              <TableCell>Total (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLocauxFacturesPage.map((facture, index) => (
              <TableRow key={index}>
                <TableCell>{facture.localName}</TableCell>
                <TableCell>{formatDate(facture.date)}</TableCell>
                <TableCell>{facture.type}</TableCell>
                <TableCell>
                  {facture.details
                    ? `${calculateFactureCost(facture).toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €`
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
        <Pagination
          count={Math.ceil(filteredLocauxFactures.length / facturesPerPage)}
          page={currentLocauxPage}
          onChange={(event, page) => paginateLocaux(page)}
          color="primary"
        />
      </Box>

      {/* Coût total et graphique */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Coût total des factures
              </Typography>
              <Typography variant="h4" align="center">
                {totalLocauxCost.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Coût par type de facture" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 400 }}>
                <Doughnut
                  data={{
                    labels: Object.keys(locauxCostsByType),
                    datasets: [{
                      data: Object.values(locauxCostsByType),
                      backgroundColor: pastelColors.slice(0, Object.keys(locauxCostsByType).length),
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.label || '';
                            const value = context.parsed || 0;
                            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${label}: ${value} € (${percentage}%)`;
                          }
                        }
                      }
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(LocauxDataTab);
