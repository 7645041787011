/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api'; // Assurez-vous que ce chemin est correct
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function PrescripteurManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES PRESCRIPTEURS');
  const [loading, setLoading] = useState(true);
  const [prescripteurs, setPrescripteurs] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPrescripteur, setSelectedPrescripteur] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    identifiant: '',
    nom: '',
    prenom: '',
    adresse: '',
    code_postal: '',
    ville: '',
    telephone: '',
    specialite: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchPrescripteurs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}prescripteurs`, { headers });
      setPrescripteurs(response.data.prescripteurs);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch prescripteurs', error);
    }
  };

  useEffect(() => {
    fetchPrescripteurs();
  }, []);

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const handleOpen = (prescripteur) => {
    if (prescripteur) {
      setIsEditing(true);
      setSelectedPrescripteur(prescripteur);
      setFormData({ ...prescripteur });
    }
    else {
      setIsEditing(false);
      setFormData({
        code: '',
        identifiant: '',
        nom: '',
        prenom: '',
        adresse: '',
        code_postal: '',
        ville: '',
        telephone: '',
        specialite: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isEditing ? 'put' : 'post';
    const url = isEditing ? `${BASE_URL}prescripteurs/${selectedPrescripteur.id}` : `${BASE_URL}prescripteurs`;

    try {
      await axios({
        method: method,
        url: url,
        data: formData,
        headers: headers // Ajoutez vos en-têtes ici
      });
      fetchPrescripteurs();
      handleClose();
    }
    catch (error) {
      console.error('Failed to submit prescripteur', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}prescripteurs/${id}`, { headers });
      fetchPrescripteurs();
    }
    catch (error) {
      console.error('Failed to delete prescripteur', error);
    }
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <IconButton color="primary" onClick={() => handleOpen(null)}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{isEditing ? 'Modifier le prescripteur' : 'Créer un nouveau prescripteur'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField fullWidth margin="dense" name="code" label="Code" value={formData.code} onChange={(e) => setFormData({ ...formData, code: e.target.value })} />
            <TextField fullWidth margin="dense" name="identifiant" label="Identifiant" value={formData.identifiant} onChange={(e) => setFormData({ ...formData, identifiant: e.target.value })} />
            <TextField fullWidth margin="dense" name="nom" label="Nom" value={formData.nom} onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
            <TextField fullWidth margin="dense" name="prenom" label="Prénom" value={formData.prenom} onChange={(e) => setFormData({ ...formData, prenom: e.target.value })} />
            <TextField fullWidth margin="dense" name="adresse" label="Adresse" value={formData.adresse} onChange={(e) => setFormData({ ...formData, adresse: e.target.value })} />
            <TextField fullWidth margin="dense" name="code_postal" label="Code Postal" value={formData.code_postal} onChange={(e) => setFormData({ ...formData, code_postal: e.target.value })} />
            <TextField fullWidth margin="dense" name="ville" label="Ville" value={formData.ville} onChange={(e) => setFormData({ ...formData, ville: e.target.value })} />
            <TextField fullWidth margin="dense" name="telephone" label="Téléphone" value={formData.telephone} onChange={(e) => setFormData({ ...formData, telephone: e.target.value })} />
            <TextField fullWidth margin="dense" name="specialite" label="Spécialité" value={formData.specialite} onChange={(e) => setFormData({ ...formData, specialite: e.target.value })} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Code</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Identifiant</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Nom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Prénom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Adresse</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Code Postal</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Ville</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Téléphone</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Spécialité</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prescripteurs.map((prescripteur) => (
              <TableRow key={prescripteur.id}>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.code}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.identifiant}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.nom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.prenom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.adresse}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.code_postal}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.ville}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.telephone}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{prescripteur.specialite}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton onClick={() => handleOpen(prescripteur)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(prescripteur.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(PrescripteurManager);
