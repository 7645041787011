/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { QrReader } from 'react-qr-reader';
import { BASE_URL } from '../../utils/api';

function QrTest({
  setModalOpen, setActionType, handleQuantityButtonClick, userToken
}) {
  const isMobileDevice = () => window.innerWidth <= 800;
  const [isScanning, setIsScanning] = React.useState(true);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  async function fetchArticleByQRCode(uuid) {
    try {
      const response = await axios.get(`${BASE_URL}articles/qr/${uuid}`, { headers });
      return response.data;
    }
    catch (err) {
      return null;
    }
  }

  const handleScan = async (data) => {
    if (data && isMobileDevice()) {
      setIsScanning(false);
      const article = await fetchArticleByQRCode(data);
      if (!article) {
        // Si aucun article n'est trouvé ou s'il y a une erreur, affichez une notification à l'utilisateur.
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Aucun article correspondant au QR code scanné n\'a été trouvé.',
        });
        return;
      }

      const result = await Swal.fire({
        title: 'Que souhaitez-vous faire?',
        text: "Choisissez l'action après le scan",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ajouter',
        cancelButtonText: 'Soustraire'
      });

      if (result.isConfirmed) {
        handleQuantityButtonClick(article.article_id, 'add');
        setActionType('add');
      }
      else if (result.isDismissed) {
        handleQuantityButtonClick(article.article_id, 'subtract');
        setActionType('subtract');
      }

      setModalOpen(true, () => {
        setIsScanning(true); // 3. Redémarrez le scanner après avoir fermé la modale
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  return (
    <div>
      {isScanning && (
      <QrReader
        delay={300}
        onError={handleError}
        onResult={handleScan}
        constraints={{ facingMode: 'environment' }}
        style={{ width: '100%' }}
      />
      )}
    </div>
  );
}

export default React.memo(QrTest);
