/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { FaEyeSlash } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';

function SignUpForm() {
  usePageTitle('DRIVESOFT | INSCRIPTION');
  const [username, setUsername] = useState(''); // Nouvel état pour le nom d'utilisateur
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState(''); // Erreur pour le nom d'utilisateur
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePassword = (pwd) => {
    if (!pwd) {
      return 'Le mot de passe ne peut pas être vide.';
    }
    if (pwd.length < 8) {
      return 'Le mot de passe doit contenir au moins 8 caractères.';
    }
    if (!/[A-Z]/.test(pwd)) {
      return 'Le mot de passe doit contenir au moins une majuscule.';
    }
    if (!/\d/.test(pwd)) {
      return 'Le mot de passe doit contenir au moins un chiffre.';
    }
    return '';
  };

  const handlePasswordChange = (event) => {
    const newPass = event.target.value;
    setPassword(newPass);
    const validationMessage = validatePassword(newPass);
    setIsPasswordValid(!validationMessage);
    if (validationMessage) {
      setPasswordError(validationMessage);
    }
    else {
      setPasswordError('');
      setConfirmPasswordError(newPass === confirmPassword ? '' : 'Les mots de passe ne correspondent pas.');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPass = event.target.value;
    setConfirmPassword(newConfirmPass);
    setConfirmPasswordError(password === newConfirmPass ? '' : 'Les mots de passe ne correspondent pas.');
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${BASE_URL}signup`, { email, password, username }); // Inclure le nom d'utilisateur
      swal.fire({
        title: 'Utilisateur ajouté',
        text: "L'utilisateur a bien été ajouté.",
        icon: 'success',
        confirmButtonText: 'OK',
        position: 'top-end',
      });
      setTimeout(() => {
        window.location = '/admin';
      }, 1500);
    }
    catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        if (error.response.data.error === 'Email is already in use') {
          setEmailError("L'adresse e-mail est déjà utilisée.");
        }
        else if (error.response.data.error === 'Username is already in use') {
          setUsernameError("Le nom d'utilisateur est déjà utilisé.");
        }
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const isFormValid = () => username && email && password && confirmPassword && isPasswordValid && password === confirmPassword;

  return (
    <div className="login-container">
      {isLoading && <div className="loader"><AmbysoftLoader /> </div>}
      <div className="stats-panel" />
      <div className="form-container">
        <h1>Ajouter un utilisateur</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="labelForm">Nom d'utilisateur</label>
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Nom d'utilisateur"
          />
          <p className="error-message">{usernameError}</p>

          <label className="labelForm">Email</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
          />
          <p className="error-message">{emailError}</p>

          <label className="labelForm">Mot de passe</label>
          <div className="password-container">
            <input
              className={`password-input ${isPasswordValid ? '' : 'error'}`}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Mot de passe"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="password-toggle"
            >
              <FaEyeSlash />
            </button>
          </div>
          <p className="error-message">{passwordError}</p>

          <label className="labelForm">Confirmer le mot de passe</label>
          <div className="password-container">
            <input
              className={`password-input ${isPasswordValid ? '' : 'error'}`}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirmer le mot de passe"
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="password-toggle"
            >
              <FaEyeSlash />
            </button>
          </div>
          <p className="error-message">{confirmPasswordError}</p>

          <button className="buttonLogin" type="submit" disabled={!isFormValid()}>Ajout d'un utilisateur</button>
        </form>
      </div>
    </div>
  );
}

export default React.memo(SignUpForm);
