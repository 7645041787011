/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
import React from 'react';
import {
  Grid, Card, CardContent, Typography, Icon
} from '@mui/material';
import {
  Assignment, CalendarToday, People, AccessTime, DirectionsCar, Business, Factory, Storage, MedicalServices, Build, Apartment
} from '@mui/icons-material'; // Example icons
import usePageTitle from '../hooks/usePageTitle';

function ActivityManagement({ theme }) {
  usePageTitle('DRIVESOFT | GESTION D\'ACTIVITÉ');

  const activities = [
    { title: 'Gestion des tâches', icon: <Assignment />, link: '/activityManagement/taskManagement' },
    { title: 'Gestion des plannings', icon: <CalendarToday />, link: '/activityManagement/planningManagement' },
    { title: 'Gestion des équipages', icon: <People />, link: '/activityManagement/crews' },
    { title: 'Pointage des heures', icon: <AccessTime />, link: '/activityManagement/timeClock' },
    { title: 'Gestion du personnel', icon: <People />, link: '/activityManagement/staffManagement' },
    { title: 'Gestion du parc automobile', icon: <DirectionsCar />, link: '/activityManagement/vehiculeManagement' },
    { title: 'Gestion des établissements', icon: <Apartment />, link: '/activityManagement/etablissementsManagement' },
    { title: 'Gestion des sociétés', icon: <Business />, link: '/activityManagement/companiesManagement' },
    { title: 'Gestion des séries', icon: <Factory />, link: '/activityManagement/seriesManagement' },
    { title: 'Gestion des stocks', icon: <Storage />, link: '/activityManagement/stockManagement' },
    { title: 'Gestion du matériel médical', icon: <MedicalServices />, link: '/activityManagement/medicalStockManagement' },
    { title: 'Gestion des procédures qualités', icon: <Build />, link: '/activityManagement/processManagement' },
    { title: 'Gestion des locaux', icon: <Apartment />, link: '/activityManagement/locauxManagement' },
    { title: 'Calendrier', icon: <Assignment />, link: '/activityManagement/calendar' },
  ];

  return (
    <div style={{ padding: '2rem', backgroundColor: theme === 'dark' ? '#000000' : '#fff', color: theme === 'dark' ? '#fff' : '#1a2b42' }}>
      <Typography variant="h4" gutterBottom align="center">Gestion d'activité</Typography>
      <Grid container spacing={3} justifyContent="center">
        {activities.map((activity, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => window.location.href = activity.link}
              sx={{
                cursor: 'pointer',
                ':hover': { transform: 'scale(1.05)' },
                transition: 'transform 0.3s',
                backgroundColor: theme === 'dark' ? '#242424' : '#fff',
                color: theme === 'dark' ? '#fff' : '#1a2b42',
                boxShadow: theme === 'dark' ? '0px 0px 5px 2px white' : 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px',
              }}
            >
              <CardContent style={{ textAlign: 'center' }}>
                <Icon style={{ fontSize: 50 }}>{activity.icon}</Icon>
                <Typography variant="h6" component="div">{activity.title}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default React.memo(ActivityManagement);
