/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { BASE_URL } from '../../../../utils/api';

function AddServiceModal({ etablissement, handleAddService, userToken }) {
  const [uh, setUh] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}services`, {
        uh,
        name,
        etablissement_id: etablissement.id,
        phone
      }, { headers });

      const newService = response.data.service;
      handleAddService(newService);
      setUh('');
      setName('');
      setPhone('');
    }
    catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="addServiceModal"
    >
      <h2 className="addModalTitle">Ajouter un service</h2>
      <TextField
        type="text"
        sx={{ width: '30%' }}
        name="uh"
        label="UH"
        InputLabelProps={{
          shrink: true,
        }}
        id="uh"
        value={uh}
        onChange={(e) => setUh(e.target.value)}
        required
      />
      <TextField
        type="text"
        sx={{ width: '30%' }}
        name="name"
        label="Libellé"
        InputLabelProps={{
          shrink: true,
        }}
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <TextField
        type="text"
        sx={{ width: '30%' }}
        name="phone"
        label="Téléphone"
        InputLabelProps={{
          shrink: true,
        }}
        id="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <Button
        type="submit"
        sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
        variant="contained"
        onClick={handleFormSubmit}
      >
        Ajouter
      </Button>
    </Box>
  );
}

export default React.memo(AddServiceModal);
