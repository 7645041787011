import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

function BackButton() {
  const navigate = useNavigate();
  const useStyles = makeStyles({
    backButton: {
      position: 'fixed',
      top: 0,
      left: 70,
    },
  });

  const classes = useStyles();

  return (
    <Tooltip title="Retour">
      <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="retour" className={classes.backButton}>
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
}

export default React.memo(BackButton);
