/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
  useMediaQuery,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

function CompanyDataTab({
  companies,
  handleFilterCompanyChange,
  currentCompanyFacturesPage,
  formatDate,
  paginateCompany,
  currentCompanyPage,
  filteredCompanyFactures,
  facturesPerPage,
  totalCompanyCost,
  companyCostsByType,
  pastelColors,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Barre de Filtrage */}
      <Box sx={{ marginBottom: 4 }}>
        <Autocomplete
          options={[{ code: '', id: 0, companyname: 'Toutes les sociétés' }, ...companies]}
          getOptionLabel={(option) => option.code || 'Toutes les sociétés'}
          onChange={(event, newValue) => handleFilterCompanyChange(newValue ? newValue.code : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrer par société"
              variant="outlined"
              fullWidth
            />
          )}
          clearOnEscape
        />
      </Box>

      {/* Tableau des factures */}
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom de la société</TableCell>
              <TableCell>Date de la facture</TableCell>
              <TableCell>Type de facture</TableCell>
              <TableCell>Total (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentCompanyFacturesPage.map((facture, index) => (
              <TableRow key={index}>
                <TableCell>{facture.companyName}</TableCell>
                <TableCell>{formatDate(facture.date)}</TableCell>
                <TableCell>{facture.type}</TableCell>
                <TableCell>
                  {facture.cost > 0
                    ? `${facture.cost.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €`
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
        <Pagination
          count={Math.ceil(filteredCompanyFactures.length / facturesPerPage)}
          page={currentCompanyPage}
          onChange={(event, page) => paginateCompany(page)}
          color="primary"
        />
      </Box>

      {/* Coût total et graphique */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Coût total des factures
              </Typography>
              <Typography variant="h4" align="center">
                {totalCompanyCost.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Coût par type de facture" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 400 }}>
                <Doughnut
                  data={{
                    labels: Object.keys(companyCostsByType),
                    datasets: [{
                      data: Object.values(companyCostsByType),
                      backgroundColor: pastelColors.slice(0, Object.keys(companyCostsByType).length),
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.label || '';
                            const value = context.parsed || 0;
                            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${label}: ${value} € (${percentage}%)`;
                          }
                        }
                      }
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(CompanyDataTab);
