/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './menuStyles.scss';
import {
  LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  QueryStats as QueryStatsIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Apps as AppsIcon,
  Euro as EuroIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  DataUsage as DataUsageIcon,
  Help as HelpIcon,
  Contacts as ContactsIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { handleLogOut } from '../../utils/authServices';
import logoLight from '../../images/icon.png';
import logoDark from '../../images/icon.png';

function Menu({ theme, switchTheme }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('userRole');
    if (token) {
      setIsLoggedIn(true);
      setUserRole(role);
    }
  }, []);

  const renderLink = (to, text, Icon) => (
    <Tooltip title={text} placement="right">
      <NavLink
        to={to}
        alt="navigation buttons"
        className="menu__link icon-only"
        activeClassName="active"
      >
        <Icon />
      </NavLink>
    </Tooltip>
  );

  return (
    <div className="menu collapsed">
      <div className="menu__top">
        <div className="menu__logo">
          <NavLink to="/">
            <img src={theme === 'dark' ? logoDark : logoLight} className="menu__logo__img" alt="logo" />
          </NavLink>
        </div>
      </div>

      <ul className="menu__links">
        {isLoggedIn ? (
          <>
            {userRole !== 'Personnel' && renderLink('/regulation', 'Régulation', EmojiTransportationIcon)}
            {userRole !== 'Personnel' && renderLink('/activityManagement', "Gestion d'activité", AppsIcon)}
            {userRole !== 'Personnel' && renderLink('/facturation', 'Facturation', EuroIcon)}
            {userRole !== 'Personnel' && renderLink('/dashboard', 'Tableau de bord', QueryStatsIcon)}
            {userRole !== 'Personnel' && renderLink('/managementData', 'Données exploitation', DataUsageIcon)}
            {renderLink('/porte-document', 'Porte-documents', ContactsIcon)}
            {renderLink('/documentation', 'Documentation', HelpIcon)}
            {userRole === 'Administrateur' && renderLink('/admin', 'Admin', AdminPanelSettingsIcon)}
            <Tooltip title="Déconnexion" placement="right">
              <div className="menu__link icon-only" onClick={handleLogOut}>
                <LogoutIcon />
              </div>
            </Tooltip>
          </>
        ) : (
          <>
            {renderLink('/about', 'Solution', InfoIcon)}
            {renderLink('/contact', 'Contact', ContactsIcon)}
            {renderLink('/login', 'Connexion', LoginIcon)}
          </>
        )}
      </ul>

      <div className="menu__bottom">
        <div className="brightButton">
          <IconButton type="button" alt="Bouton pour changer le thème" onClick={switchTheme}>
            {theme === 'light' ? (
              <Brightness4Icon style={{ color: 'black' }} />
            ) : (
              <Brightness7Icon style={{ color: '#feba00' }} />
            )}
          </IconButton>
        </div>
        <div className="menu__socials">
          <a alt="Florent__Linkedin" href="#" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon fontSize="small" />
          </a>
          <a alt="Florent__Instagram" href="#" target="_blank" rel="noopener noreferrer">
            <InstagramIcon fontSize="small" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Menu);
