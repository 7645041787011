/* eslint-disable max-len */
import React from 'react';
import './termsStyles.scss';

function Terms() {
  return (
    <div className="terms-container">
      <h1>Conditions générales d'utilisation de DRIVESOFT</h1>
      <p>Dernière mise à jour : 12/03/2024</p>

      <h2>1. Acceptation des conditions</h2>
      <p>
        En accédant et en utilisant l'application DRIVESOFT, vous acceptez d'être lié par les présentes Conditions Générales d'Utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre application.
      </p>

      <h2>2. Description du service</h2>
      <p>
        DRIVESOFT fournit une solution logicielle pour la gestion des sociétés de transport sanitaire, incluant des fonctionnalités telles que la régulation, la facturation, la gestion du temps, l'inventaire, le tableau de bord, etc.
      </p>

      <h2>3. Accès à l'application</h2>
      <p>
        Nous nous efforçons de maintenir l'application accessible 24/7, mais nous ne saurions être tenus responsables en cas d'indisponibilité de celle-ci, quelle qu'en soit la cause.
      </p>

      <h2>4. Utilisation autorisée</h2>
      <p>
        Vous vous engagez à utiliser l'application conformément à sa destination et à ne pas tenter de détourner son fonctionnement pour des usages non autorisés ou illégaux.
      </p>

      <h2>5. Propriété intellectuelle</h2>
      <p>
        Tous les contenus présents sur l'application (textes, images, logos, etc.) sont la propriété exclusive de DRIVESOFT. Toute reproduction ou représentation, totale ou partielle, est interdite sans notre accord préalable.
      </p>

      <h2>6. Données personnelles</h2>
      <p>
        Nous collectons et traitons vos données personnelles conformément à notre Politique de Confidentialité. En utilisant notre application, vous consentez à ce traitement.
      </p>

      <h2>7. Limitation de responsabilité</h2>
      <p>
        DRIVESOFT ne saurait être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'impossibilité d'utiliser l'application.
      </p>

      <h2>8. Modifications des conditions</h2>
      <p>
        Nous nous réservons le droit de modifier les présentes conditions à tout moment. Les modifications entreront en vigueur dès leur publication sur cette page. Il vous appartient de consulter régulièrement les conditions pour vous tenir informé des changements éventuels.
      </p>

      <h2>9. Droit applicable et juridiction</h2>
      <p>
        Les présentes conditions sont soumises au droit français. Tout litige relatif à leur interprétation ou leur exécution sera de la compétence exclusive des tribunaux français.
      </p>

      <h2>10. Contact</h2>
      <p>
        Pour toute question concernant ces Conditions Générales d'Utilisation, vous pouvez nous contacter :
      </p>
      <ul>
        <li>Par email : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a></li>
        <li>Par téléphone : (+33)6 52 80 77 36</li>
      </ul>
    </div>
  );
}

export default React.memo(Terms);
