import { useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function usePdfToImageConverter() {
  const convertPdfToImage = useCallback(async (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target.result);

      try {
        // Chargement du document PDF
        const pdfDoc = await pdfjsLib.getDocument({ data: typedArray }).promise;

        // Rendre la première page du PDF
        const page = await pdfDoc.getPage(1);
        const viewport = page.getViewport({ scale: 2.0 });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };

        await page.render(renderContext).promise;

        // Convertir le canvas en Blob
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.95); // Ajustez le type MIME et la qualité selon besoin
      }
      catch (error) {
        reject(error);
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsArrayBuffer(file);
  }), []);

  return convertPdfToImage;
}

export default usePdfToImageConverter;
