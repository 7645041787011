/* eslint-disable react/prop-types */
// StockResourcesData.jsx
import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

function StockResourcesData({
  totalRetire,
  breakdownByReasonWithPrices,
  vehiclesLabels,
  vehiclesPrices,
  reasonsLabels,
  reasonsPrices,
  pastelColors,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Total HT des articles sortis */}
      <Grid container spacing={3} sx={{ marginBottom: 4 }} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Total des articles sortis
              </Typography>
              <Typography variant="h5" color="textPrimary">
                {totalRetire.toFixed(2)} €HT
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Breakdown by reason */}
      <Grid container spacing={3} sx={{ marginBottom: 4 }}>
        {Object.entries(breakdownByReasonWithPrices).map(([reason, data]) => (
          <Grid item xs={12} md={6} key={reason}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  Total des articles sortis pour :
                </Typography>
                <Typography variant="h6" color="textPrimary">
                  {reason}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {data.quantite} articles retirés pour un total de {data.prix.toFixed(2)} €HT
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Graphiques */}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Réapprovisionnements par véhicule en €" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 300 }}>
                <Doughnut
                  data={{
                    labels: vehiclesLabels,
                    datasets: [
                      {
                        data: vehiclesPrices,
                        backgroundColor: pastelColors,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Retraits par raison en €" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 300 }}>
                <Doughnut
                  data={{
                    labels: reasonsLabels,
                    datasets: [
                      {
                        data: reasonsPrices,
                        backgroundColor: pastelColors,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(StockResourcesData);
