/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
// utils/calculateInvoice.js
const calculateInvoice = (tarifApplicable, transportDetails) => {
  if (!tarifApplicable || !transportDetails) {
    console.error('Informations manquantes pour le calcul de la facture');
    return {};
  }

  const {
    selectedTransportType,
    distance,
    waitingTime,
    roundTrip,
    departureTime,
    arrivalTime,
    dayOfWeek,
    aeroport,
    premature,
    tpmr,
    numberOfPeople,
    cancelled,
    urgent,
    tarifTaxi,
  } = transportDetails;

  let totalMinutes = 0;

  if (waitingTime) {
    const [hours, minutes, seconds] = waitingTime.split(':').map(Number);
    totalMinutes = hours * 60 + minutes + (seconds >= 30 ? 1 : 0); // Arrondir à la minute la plus proche
  }
  else {
    totalMinutes = 0;
  }

  let estimate = 0;
  let totalWithTVA = 0;
  let distanceCharge = 0;

  const tarifbaseKmTaxiApplicable = tarifTaxi;

  const isTaxi = tarifApplicable.categorie === 'TAXI';
  const isTaxiAm = tarifApplicable.categorie === 'TAXI-AM';
  const isTaxiAmRem = tarifApplicable.categorie === 'TAXI-AMREM';
  const isParaHC = tarifApplicable.categorie === 'PARAHC' || tarifApplicable.categorie === 'PARAC';
  const isParacOrParahc = isParaHC;
  const isUPH = tarifApplicable.categorie === 'UPH';
  const isAgglomerationOrDepartemental = tarifApplicable.categorie === 'Agglomération' || tarifApplicable.categorie === 'Départemental';

  const baseCharge = parseFloat(tarifApplicable.base);

  if (isTaxi || isTaxiAm || isTaxiAmRem) {
    distanceCharge = distance * parseFloat(tarifbaseKmTaxiApplicable);
  }
  else if (isAgglomerationOrDepartemental) {
    distanceCharge = distance > 3 ? (distance - 3) * parseFloat(tarifApplicable.basekm) : 0;
  }
  else if (isUPH) {
    distanceCharge = distance > 20 ? (distance - 20) * parseFloat(tarifApplicable.basekm) : 0;
  }
  else {
    distanceCharge = distance * parseFloat(tarifApplicable.basekm);
  }

  estimate = baseCharge + distanceCharge;

  // Ajout des majorations et des autres charges
  const tvaRate = selectedTransportType === 2 ? parseFloat(tarifApplicable.tva) : 0;

  if ((isTaxi || isTaxiAm || isTaxiAmRem) && distance > 0 && distance <= 8) {
    estimate = 15;
    totalWithTVA = estimate * (1 + parseFloat(tarifApplicable.tva) / 100);
    return { estimate: estimate.toFixed(2), totalWithTVA: totalWithTVA.toFixed(2) };
  }

  let valorisationTrajetCourtCharge = 0;
  const conditions = tarifApplicable.tarifscourttrajet.map((trajet) => ({
    min: trajet.condition.includes('>') ? parseFloat(trajet.condition.split('>')[1].split('≤')[0]) : 0,
    max: trajet.condition.includes('≤') ? parseFloat(trajet.condition.split('≤')[1]) : Infinity,
    tarif: parseFloat(trajet.tarif)
  }));

  conditions.sort((a, b) => a.min - b.min);

  for (const condition of conditions) {
    if (distance > condition.min && distance <= condition.max) {
      valorisationTrajetCourtCharge = condition.tarif;
      break;
    }
  }

  // Calcul de la majoration par tranche de 25 km au-delà de 100 km pour certaines catégories
  let majorationTranche25km = 0;
  if (isParacOrParahc && distance > 100) {
    const valeurMajoration25km = parseFloat(tarifApplicable.majorations.find((majoration) => majoration.nom === 'Tranche 25km')?.valeur || 0);
    const tranches = Math.floor((distance - 100) / 25);
    majorationTranche25km = tranches * valeurMajoration25km;
  }

  estimate += majorationTranche25km + valorisationTrajetCourtCharge;

  let waitingCost = 0;
  if (isTaxi || isTaxiAm || isTaxiAmRem) {
    const hourlyRate = parseFloat(tarifApplicable.majorations.find((m) => m.nom === 'Heure d\'attente')?.valeur || 0);
    waitingCost = (totalMinutes / 60) * hourlyRate; // Coût basé sur la fraction d'heure
  }
  else {
    const ratePer15Min = parseFloat(tarifApplicable.majorations.find((m) => m.nom === 'Attente 15 min')?.valeur || 0);
    const periods = Math.ceil(totalMinutes / 15); // Nombre de périodes de 15 minutes entamées
    waitingCost = periods * ratePer15Min; // Coût pour chaque période de 15 minutes entamée
  }

  // Appliquer les majorations spécifiques basées sur les conditions (nuit, férié, etc.)
  let nightRate = 0; let holidayRate = 0; let aeroportMajoration = 0; let prematureMajoration = 0; let tpmrMajoration = 0; let cancelledBill = 0; let
    urgentMajoration = 0;

  const isNight = (departureTime > '20:00' || departureTime < '08:00')
    || (arrivalTime !== '00:00:00' && (arrivalTime > '20:00' || arrivalTime < '08:00'));
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // 0 pour dimanche, 6 pour samedi

  tarifApplicable.majorations.forEach((majoration) => {
    switch (majoration.nom) {
      case 'Nuit':
        if (isNight) nightRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
        break;
      case 'Dim/férié':
        if (isWeekend) holidayRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
        break;
      case 'Aéroport/Gare':
        if (aeroport) aeroportMajoration = parseFloat(majoration.valeur);
        break;
      case 'Prématuré':
        if (premature) prematureMajoration = parseFloat(majoration.valeur);
        break;
      case 'TPMR':
        if (tpmr) tpmrMajoration = parseFloat(majoration.valeur);
        break;
      case 'Annuler facturé':
        if (cancelled) cancelledBill = parseFloat(majoration.valeur);
        break;
      case 'Urgent':
        if (urgent) urgentMajoration = parseFloat(majoration.valeur);
        break;
      case 'Nuit/Férié/Week-end':
        if (isParaHC && (isNight || isWeekend)) {
          valorisationTrajetCourtCharge *= (1 + parseFloat(majoration.valeur) / 100);
        }
        break;
      default:
        break;
    }
  });
  estimate += nightRate + holidayRate + aeroportMajoration + prematureMajoration + tpmrMajoration + cancelledBill + urgentMajoration + waitingCost;

  // Appliquer la remise basée sur le nombre de personnes avant de calculer la TVA
  let remisePercent = 0;
  const numPeople = Number(numberOfPeople); // Convertir en nombre si nécessaire
  if (numPeople === 2) {
    remisePercent = parseFloat(tarifApplicable.reductiondeuxpersonnes);
  }
  else if (numPeople === 3) {
    remisePercent = parseFloat(tarifApplicable.reductiontroispersonnes);
  }

  let remiseSimultane = 0;
  if (remisePercent > 0) {
    remiseSimultane = estimate * (remisePercent / 100);
    estimate -= remiseSimultane;
  }

  // Appliquer la TVA si applicable
  if (tvaRate > 0) {
    totalWithTVA = estimate * (1 + tvaRate / 100);
  }
  else {
    totalWithTVA = estimate;
  }

  // Vérifier si l'option aller-retour est cochée et ajuster l'estimation
  if (roundTrip) {
    estimate *= 2;
    totalWithTVA *= 2;
  }

  // Gérer le cas où la course est annulée
  if (cancelled) {
    estimate = cancelledBill;
    totalWithTVA = estimate; // Ajoutez la TVA si nécessaire pour les courses annulées aussi
  }

  return {
    totalWithTVA: totalWithTVA.toFixed(2),
    distanceCharge: distanceCharge.toFixed(2),
    nightRate: nightRate.toFixed(2),
    holidayRate: holidayRate.toFixed(2),
    waitingCost: waitingCost.toFixed(2),
    valorisationTrajetCourtCharge: valorisationTrajetCourtCharge.toFixed(2),
    remiseSimultane: remiseSimultane.toFixed(2),
    aeroportMajoration: aeroportMajoration.toFixed(2),
    prematureMajoration: prematureMajoration.toFixed(2),
    tpmrMajoration: tpmrMajoration.toFixed(2),
    cancelledBill: cancelledBill.toFixed(2),
    urgentMajoration: urgentMajoration.toFixed(2),
    majorationTranche25km: majorationTranche25km.toFixed(2)
  };
};

export default calculateInvoice;
