/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useRef
} from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AiFillAlert } from 'react-icons/ai';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import EastIcon from '@mui/icons-material/East';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Tooltip, IconButton
} from '@mui/material';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import UpdateTransportModal from './updateTransportModal/updateTransportModal';
import AddTransportModal from './AddTransportModal/AddTransportModal';
import usePageTitle from '../hooks/usePageTitle';
import './regulationStyles.scss';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';
import DevisRapide from './DevisRapide/devisRapide';
import { BASE_URL } from '../../utils/api';
import { sendNotificationToVehiculeSMS } from '../../utils/notifications';

const regulation = ({ theme, userRole, userToken }) => {
  usePageTitle('DRIVESOFT | RÉGULATION');
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Ensure month is two digits
  const day = today.getDate().toString().padStart(2, '0'); // Ensure day is two digits
  const date = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedTypes, setSelectedTypes] = useState(['TOTAL']);
  const [transports, setTransports] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [crews, setCrews] = useState([]);
  const [filteredTransports, setFilteredTransports] = useState([]);
  const [currentTransport, setCurrentTransport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showEstimateModal, setShowEstimateModal] = useState(false);
  const [currentDayCa, setCurrentDayCa] = useState(0);
  const [currentDayAmb, setCurrentDayAmb] = useState(0);
  const [currentDayVsl, setCurrentDayVsl] = useState(0);
  const [currentDayPara, setCurrentDayPara] = useState(0);
  const [currentDaySamu, setCurrentDaySamu] = useState(0);
  const [currentDayTaxi, setCurrentDayTaxi] = useState(0);
  const [expandedHours, setExpandedHours] = useState(Array.from({ length: 24 }, (_, i) => i));
  const [showDetails, setShowDetails] = useState({
    ca: false,
    transportType: false,
  });

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentTime = moment().format('HH:mm:ss');
  const UpdateModalRef = useRef(null);
  const modalRef = useRef(null);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  const fetchTransports = async () => {
    try {
      const res = await axios.get(`${BASE_URL}transports`, { headers });
      setTransports(res.data.transports);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}crews`, { headers })
      .then((res) => {
        setCrews(res.data.crews);
      });
  }, [selectedDate]);

  useEffect(() => { // Get all vehicles
    axios
      .get(`${BASE_URL}vehicules`, { headers })
      .then((res) => {
        setVehicles(res.data.vehicule);
      });
  }, []);

  useEffect(() => {
    fetchTransports();
  }, []);

  const handleClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowModal(false);
    }
    if (UpdateModalRef.current && !UpdateModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
  };

  const handleHourToggle = (hour) => {
    if (expandedHours.includes(hour)) {
      setExpandedHours((prevHours) => prevHours.filter((h) => h !== hour));
    }
    else {
      setExpandedHours((prevHours) => [...prevHours, hour]);
    }
  };

  // Handle double-clicking on a transport to open the update modal
  const handleDoubleClick = (transport) => {
    if (isAuthorizedRole()) {
      setCurrentTransport(transport);
      setShowUpdateModal(true);
    }
    else {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour modifier un transport',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleAddTransportClick = () => {
    if (isAuthorizedRole()) {
      setShowModal(true);
    }
    else {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour ajouter un transport',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDevisClick = () => {
    if (isAuthorizedRole()) {
      setShowEstimateModal(true);
    }
    else {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour établir un devis',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleAddTransport = (transport) => {
    setTransports([...transports, transport]);
    setShowModal(false);
    fetchTransports();
    Swal.fire({
      title: 'Transport ajouté !',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const handleDeleteTransport = async (id, linked_transport_id) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour supprimer un transport',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    try {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce transport ?',
        text: 'Cette action est irréversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler',
      });

      if (result.isConfirmed) {
        if (linked_transport_id) {
          const isDeleteReturn = await Swal.fire({
            title: 'Voulez-vous également supprimer le transport retour associé ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer',
            cancelButtonText: 'Non, dissocier',
          });

          if (isDeleteReturn.isConfirmed) {
            await axios.delete(`${BASE_URL}transports/${linked_transport_id}`, { headers });
            fetchTransports();
          }
        }

        await axios.delete(`${BASE_URL}transports/${id}`, { headers });
        setTransports(transports.filter((transport) => transport.id !== id));
        fetchTransports();

        Swal.fire(
          'Supprimé !',
          'Le transport a bien été supprimé.',
          'success',
        );
      }
    }
    catch (error) {
      console.error('Error deleting transport:', error);
    }
  };

  // Handle updating the transport
  const handleUpdateTransport = async (updatedTransport) => {
    // Show a confirmation before updating the transport
    const result = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir mettre à jour ce transport ?',
      text: 'Les changements seront définitifs.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, mettre à jour',
      cancelButtonText: 'Annuler',
    });
    // If the user confirms the update, proceed to update the transport
    if (result.value) {
      // Find the index of the transport that needs to be updated
      const index = transports.findIndex((transport) => transport.id === currentTransport.id);
      // Create a new array with the updated transport
      const newTransports = [...transports.slice(0, index), updatedTransport, ...transports.slice(index + 1)];
      // Update the state with the new array
      setTransports(newTransports);
      setShowUpdateModal(false);
      fetchTransports();
      Swal.fire({
        title: 'Transport mis à jour !',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }
  };

  const updateTransportStatus = async (id, status) => {
    try {
      const response = await axios.put(`${BASE_URL}transports/${id}/status`, { status }, { headers });
      if (response.status === 200) {
        fetchTransports();
      }
      else {
        console.error('Failed to update transport status');
      }
    }
    catch (error) {
      console.error('Error updating transport status:', error);
    }
  };

  const sendTransportNotification = async (vehiculeCode, transport) => {
    try {
      await axios.post(`${BASE_URL}transports/sendTransportNotification`, { vehiculeCode, transport }, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      Swal.fire('Succès', 'Notification envoyée avec succès', 'success');
    }
    catch (error) {
      console.error('Error sending transport notification:', error);
      Swal.fire('Erreur', 'Erreur lors de l\'envoi de la notification', 'error');
    }
  };

  const handleSendNotification = async (vehiculeCode, transport) => {
    try {
      await sendTransportNotification(vehiculeCode, transport);

      // Mise à jour du statut de la mission à "Envoyé"
      await updateTransportStatus(transport.id, 'Envoyé');

      Swal.fire('Succès', 'Notification envoyée et statut mis à jour', 'success');
    }
    catch (error) {
      console.error('Error sending transport notification:', error);
      Swal.fire('Erreur', 'Erreur lors de l\'envoi de la notification', 'error');
    }
  };

  useEffect(() => {
    if (!selectedDate) {
      setFilteredTransports(transports);
      return;
    }

    setFilteredTransports(transports.filter((transport) => {
      const transportDate = new Date(transport.date);
      const selectedDateObject = moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      return moment(transportDate, 'YYYY-MM-DD').format('YYYY-MM-DD') === selectedDateObject;
    }));
  }, [transports, selectedDate]); // Cet effet s'exécutera chaque fois que 'transports' ou 'selectedDate' change

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []); // Cet effet ne s'exécutera qu'une fois, au montage du composant

  const sortedTransports = filteredTransports.sort((a, b) => {
    // Nouvelle logique pour mettre les transports avec le statut "Terminé" en haut
    if (a.status === 'Terminé' && b.status !== 'Terminé') {
      return -1;
    }
    if (a.status !== 'Terminé' && b.status === 'Terminé') {
      return 1;
    }

    // Votre logique de tri existante
    if (a.date === b.date) {
      if (a.departure_hour && b.departure_hour) {
        return a.departure_hour.localeCompare(b.departure_hour);
      }
      return 0;
    }
    return new Date(a.date) - new Date(b.date);
  });

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSendSMS = async (vehiculeCode, fdate, transport) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        title: 'Vous n\'avez pas les droits pour envoyer un SMS',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    try {
      if (!transport.id) {
        throw new Error('Transport ID is missing');
      }

      await sendNotificationToVehiculeSMS(vehiculeCode, fdate);

      // Update transport status to "Envoyé"
      await updateTransportStatus(transport.id, 'Envoyé');

      Swal.fire('Succès', 'SMS envoyé et statut mis à jour', 'success');
    }
    catch (error) {
      console.error('Error sending SMS:', error);
      Swal.fire('Erreur', 'Erreur lors de l\'envoi du SMS', 'error');
    }
  };

  const filteredTransport = sortedTransports.filter((mission) => (mission.last_name?.toLowerCase() || '').includes(search.toLowerCase())
  || (mission.departure_label?.toLowerCase() || '').includes(search.toLowerCase())
  || (mission.arrival_label?.toLowerCase() || '').includes(search.toLowerCase())
  || (mission.arrival_address?.toLowerCase() || '').includes(search.toLowerCase()));

  const filterType = filteredTransport.filter(
    (transport) => (selectedTypes.includes(transport.tarification_type) || selectedTypes.includes('TOTAL'))
      && (transport.vehicule === selectedVehicle || selectedVehicle === '')
  );

  const currentCrewVehicleIds = crews
    .filter((crew) => moment(crew.date, 'DD-MM-YYYY').isSame(moment(selectedDate, 'DD-MM-YYYY'), 'day'))
    .map((crew) => crew.vehicule_id);

  const currentCrewVehicleCodes = vehicles
    .filter((vehicle) => currentCrewVehicleIds.includes(vehicle.id))
    .map((vehicle) => vehicle.code);

  const timeSlots = [];
  for (let hour = 0; hour <= 23; hour += 1) { // Modifiez ici pour commencer à 0 et finir à 23
    const start = `${hour}:00`;
    const end = `${hour}:59`;
    const transportsInThisHour = filterType.filter((transport) => {
      if (!transport.departure_hour) {
        return false;
      }
      const transportHour = parseInt(transport.departure_hour.split(':')[0], 10);
      return transportHour === hour;
    });

    const ambCount = transportsInThisHour.filter((transport) => transport.tarification_type === 'Ambulance').length;
    const vslCount = transportsInThisHour.filter((transport) => transport.tarification_type === 'Vsl').length;
    const paraCount = transportsInThisHour.filter((transport) => transport.tarification_type === 'Paramédical').length;
    const samuCount = transportsInThisHour.filter((transport) => transport.tarification_type === 'Samu').length;
    const taxiCount = transportsInThisHour.filter((transport) => transport.tarification_type === 'Taxi').length;
    timeSlots.push({
      start,
      end,
      ambCount,
      transports: transportsInThisHour,
      vslCount,
      paraCount,
      samuCount,
      taxiCount,
    });
  }

  useEffect(() => {
    let totalCa = 0;
    let totalAmb = 0;
    let totalVsl = 0;
    let totalPara = 0;
    let totalSamu = 0;
    let totalTaxi = 0;

    filteredTransports.forEach((transport) => {
      if (transport.status !== 'Terminé' && transport.status !== 'Annuler facturé') return; // Skip if status is not "Terminé" or "Annuler facturé"

      const price = parseFloat(transport.price || '0');
      totalCa += price;

      switch (transport.tarification_type) {
        case 'Ambulance':
          totalAmb += price;
          break;
        case 'Vsl':
          totalVsl += price;
          break;
        case 'Paramédical':
          totalPara += price;
          break;
        case 'Samu':
          totalSamu += price;
          break;
        case 'Taxi':
          totalTaxi += price;
          break;
        default:
          break;
      }
    });

    setCurrentDayCa(totalCa);
    setCurrentDayAmb(totalAmb);
    setCurrentDayVsl(totalVsl);
    setCurrentDayPara(totalPara);
    setCurrentDaySamu(totalSamu);
    setCurrentDayTaxi(totalTaxi);
  }, [filteredTransports]);

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        // Fermer la modal si l'utilisateur confirme
        setModalState(false);
      }
    });
  };

  const customStylesUpdate = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  const customStylesAdd = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  if (isLoading) return <div><AmbysoftLoader /></div>;

  return (
    <div className="regulation">
      {userRole === 'invité' ? (
        <p className="bad_role_message">Vous êtes sur un compte "invité", veuillez demander les permissions à l'administrateur.</p>
      ) : (
        <>
          <div className="regulation__header">
            <div className="regulation__header__date">
              <div className="date_regulation__arrow__left" onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'days').format('YYYY-MM-DD'))}>
                <ArrowBackIcon />
              </div>
              <input
                type="date"
                className="date_regulation__input"
                value={moment(selectedDate).format('YYYY-MM-DD')}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
              <div className="date_regulation__arrow__right" onClick={() => setSelectedDate(moment(selectedDate).add(1, 'days').format('YYYY-MM-DD'))}>
                <ArrowForwardIcon />
              </div>
            </div>
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                width: '10%',
                '@media (max-width: 600px)': {
                  display: 'none', // Cacher sur les écrans de moins de 600px de large
                },
              }}
            >
              <InputLabel
                id="vehicle-filter-label"
                sx={{
                  fontSize: '0.7rem',
                  zIndex: '0',
                  color: theme === 'dark' ? '#fff' : '#000',
                  '&.Mui-focused': {
                    color: theme === 'dark' ? '#ccc' : '#666',
                  }
                }}
                shrink
              >
                Filtrer par véhicule
              </InputLabel>
              <Select
                labelId="vehicle-filter-label"
                value={selectedVehicle}
                onChange={(e) => setSelectedVehicle(e.target.value)}
                label="Filtre par véhicule"
                sx={{
                  fontSize: '0.7rem',
                  backgroundColor: theme === 'dark' ? '#333' : '#fff',
                  color: theme === 'dark' ? '#fff' : '#000',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme === 'dark' ? '#777' : '#ccc',
                  }
                }}
              >
                <MenuItem value="">
                  <em>Tous les véhicules</em>
                </MenuItem>
                {currentCrewVehicleCodes.map((vehicleCode) => (
                  <MenuItem key={vehicleCode} value={vehicleCode}>
                    {vehicleCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              placeholder="Recherche"
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{
                fontSize: '0.7rem',
                color: theme === 'dark' ? '#fff' : '#000', // Changer la couleur de texte selon le thème
                backgroundColor: theme === 'dark' ? '#333' : '#fff', // Changer la couleur de fond selon le thème
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme === 'dark' ? '#777' : '#ccc', // Changer la couleur de la bordure
                  }
                },
                '@media (max-width: 600px)': {
                  display: 'none', // Cacher sur les écrans de moins de 600px de large
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <div className="regulation__header__filter">
              <button type="button" className="AddTransport" onClick={handleDevisClick}>Devis</button>
            </div>
            <div className="regulation__header__button">
              <button type="button" className="AddTransport" onClick={handleAddTransportClick}>
                Ajouter une mission
              </button>
            </div>
            <div className="regulation__header__datetime">
              <div className="dayAndTime">
                {`${moment(selectedDate, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')} - ${moment().format('HH:mm')}`}
              </div>
            </div>
          </div>
          {showEstimateModal && (
          <Modal
            isOpen={showEstimateModal}
            style={customStylesUpdate}
            onRequestClose={() => confirmCloseModal(setShowEstimateModal)}
          >
            <DevisRapide userToken={userToken} />
          </Modal>
          )}
          {showModal && (
          <Modal
            isOpen={showModal}
            ariaHideApp={false}
            onRequestClose={() => confirmCloseModal(setShowModal)}
            style={customStylesAdd}
            ref={(el) => {
              modalRef.current = el && el.contentRef;
            }}
          >
            <AddTransportModal userToken={userToken} theme={theme} onAddTransport={handleAddTransport} />
          </Modal>
          )}
          <div className="transportTable">
            <table width="100%" className="table">
              <thead>
                <tr>
                  <th className="table__header table__header--hide-on-mobile">Retard</th>
                  <th className="table__header table__header--hide-on-mobile">Status</th>
                  <th className="table__header">Heure de départ</th>
                  <th className="table__header">Nom</th>
                  <th className="table__header">Lieu de départ</th>
                  <th className="table__header">Lieu d'arrivée</th>
                  <th className="table__header table__header--hide-on-mobile">Motif</th>
                  <th className="table__header table__header--hide-on-mobile">Heure du rendez-vous</th>
                  <th className="table__header">Véhicule/Chauffeur</th>
                  <th className="table__header">Type</th>
                  <th className="table__header table__header--hide-on-mobile">Actions</th>
                </tr>
              </thead>
              <tbody className="transportBody">
                {timeSlots.map((slot) => (
                  slot.transports.length > 0 && (
                  <React.Fragment key={slot.start}>
                    <tr className="luxurious-style" onClick={() => handleHourToggle(parseInt(slot.start, 10))}>
                      <td colSpan="11">
                        <p>{expandedHours.includes(parseInt(slot.start, 10)) ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}{slot.start}</p>
                      </td>
                    </tr>
                    {expandedHours.includes(parseInt(slot.start, 10)) && slot.transports.map((transport) => (
                      <tr
                        key={transport.id}
                        style={{
                          backgroundColor: theme === 'dark'
                            ? (transport.status === 'En cours'
                              ? '#696571'
                              : transport.status === 'Terminé'
                                ? '#2e006c'
                                : transport.status === 'Annulé'
                                  ? '#B27D8B'
                                  : transport.status === 'Envoyé'
                                    ? 'grey'
                                    : 'none')
                            : (transport.status === 'En cours'
                              ? '#F6E38E'
                              : transport.status === 'Terminé'
                                ? '#DFB6F6'
                                : transport.status === 'Annulé' || transport.status === 'Annuler facturé'
                                  ? '#F6958E'
                                  : transport.status === 'Envoyé'
                                    ? 'grey'
                                    : 'none'),
                          textDecoration: transport.status === 'Annulé' || transport.status === 'Annuler facturé' ? 'line-through' : 'none',
                          // hover background gris pour toutes les status
                        }}
                        onClick={() => {
                          // Exécuter handleDoubleClick uniquement si l'appareil n'est pas mobile
                          if (!isMobile()) {
                            handleDoubleClick(transport);
                          }
                        }}
                        className="table-row"
                      >
                        <td className={`table__cell table__cell--hide-on-mobile ${(() => {
                          if (transport.status === 'Annulé' || transport.status === 'Terminé') {
                            return 'useless-delay';
                          }
                          const delay = moment(currentTime, 'HH:mm').diff(moment(transport.departure_hour, 'HH:mm'), 'minutes');
                          if (delay < -1) return 'advance';
                          if (delay < 0) return 'ontime';
                          if (delay >= 0 && delay < 15) return 'late-15';
                          if (delay >= 15 && delay < 30) return 'late-30';
                          if (delay >= 30 && delay < 60) return 'late-60';
                          if (delay >= 60 && delay < 180) return 'late-90';
                          if (delay >= 180) return 'late-180';
                          return '';
                        })()}`}
                        >
                          {moment(currentTime, 'HH:mm').diff(moment(transport.departure_hour, 'HH:mm'), 'minutes')} minutes
                        </td>
                        <td className="table__cell table__cell--hide-on-mobile">
                          {transport.status}
                        </td>
                        <td className="table__cell">
                          {transport.departure_hour}
                        </td>
                        <td className="table__cell">
                          {(transport.last_name)}
                        </td>
                        <td className="table__cell">
                          {transport.departure_label || transport.departure_address || 'N/A'}
                        </td>
                        <td className="table__cell">
                          {transport.arrival_label || transport.arrival_address || 'N/A'}
                        </td>
                        <td className="table__cell table__cell--hide-on-mobile">
                          {transport.reason === 'URGENCE' ? (
                            <div className="icon-container">
                              <div className="icon">
                                URGENCE
                                <AiFillAlert color="red" />
                              </div>
                            </div>
                          ) : transport.reason === 'CONSULTATION' ? (
                            <div className="icon-container">
                              CONSULTATION
                              <div className="icon">
                                <SyncAltIcon fontSize="small" />
                              </div>
                            </div>
                          ) : transport.reason === 'TRANSFERT' ? (
                            <div className="icon-container">
                              TRANSFERT
                              <div className="icon">
                                <EastIcon fontSize="small" />
                              </div>
                            </div>
                          ) : (
                            transport.reason
                          )}
                        </td>
                        <td className="table__cell table__cell--hide-on-mobile">
                          {transport.appointment_hour}
                        </td>
                        <td className="table__cell vehicule-driver">
                          {transport.vehicule || transport.driver1 || transport.driver2
                            ? `${transport.vehicule} - ${transport.driver1}${transport.driver2 ? ` / ${transport.driver2}` : ''}`
                            : 'Non attribué'}
                        </td>

                        <td className={`table__cell type ${transport.tarification_type}`}>
                          {transport.tarification_type}
                        </td>
                        <td className="table__cell deleteb table__cell--hide-on-mobile">
                          <div>
                            <Tooltip title="Envoyer un SMS au véhicule" arrow>
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSendSMS(transport.vehicule, transport.date, transport);
                                }}
                              >
                                <SendToMobileIcon fontSize="small" />
                              </IconButton>

                            </Tooltip>
                            <Tooltip title="Envoyer les détails du transport" arrow>
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSendNotification(transport.vehicule, transport);
                                }}
                              >
                                <SendIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer la mission" arrow>
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteTransport(transport.id, transport.linked_transport_id);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                  )
                ))}
              </tbody>
            </table>
          </div>
          <div className="date__regulation table__cell--hide-on-mobile">
            <div className="transport_ca_container">
              <button
                className="regulationHide-button"
                type="button"
                onClick={() => {
                  setShowDetails((prev) => ({ ...prev, ca: !prev.ca }));
                }}
              >
                {showDetails.ca ? 'Fermer' : "Chiffre d'affaire du jour"}
              </button>

              <div className={`transport_ca ${showDetails.ca ? '' : 'hidden'}`}>
                <div className="transport_detail TOTAL">Total: { (currentDayCa).toFixed(0) }€</div>
                <div className="transport_detail Ambulance">AMB: { (currentDayAmb).toFixed(0) }€</div>
                <div className="transport_detail Vsl">VSL: { (currentDayVsl).toFixed(0) }€</div>
                <div className="transport_detail Paramédical">PARA: { (currentDayPara).toFixed(0) }€</div>
                <div className="transport_detail Samu">SAMU: { (currentDaySamu).toFixed(0) }€</div>
                <div className="transport_detail Taxi">TAXI: { (currentDayTaxi).toFixed(0) }€</div>
              </div>
            </div>
            <div className="transport_number_container">
              <button
                className="regulationHide-button"
                type="button"
                onClick={() => {
                  setShowDetails((prev) => ({ ...prev, transportType: !prev.transportType }));
                }}
              >
                {showDetails.transportType ? 'Fermer' : 'Filtre par type de mission'}
              </button>

              <div className={`transport_number ${showDetails.transportType ? '' : 'hidden'}`}>
                <p
                  className={`transport_detail TOTAL${selectedTypes.includes('TOTAL') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes([]);
                    }
                    else {
                      setSelectedTypes(['TOTAL']);
                    }
                  }}
                >
                  Total: {filteredTransports.length}
                </p>
                <p
                  className={`transport_detail Ambulance${selectedTypes.includes('Ambulance') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes(['Ambulance']);
                    }
                    else {
                      setSelectedTypes(selectedTypes.includes('Ambulance') ? selectedTypes.filter((type) => type !== 'Ambulance') : [...selectedTypes, 'Ambulance']);
                    }
                  }}
                >
                  AMB: {filteredTransports.filter((transport) => transport.tarification_type === 'Ambulance').length}
                </p>
                <p
                  className={`transport_detail Vsl${selectedTypes.includes('Vsl') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes(['Vsl']);
                    }
                    else {
                      setSelectedTypes(selectedTypes.includes('Vsl') ? selectedTypes.filter((type) => type !== 'Vsl') : [...selectedTypes, 'Vsl']);
                    }
                  }}
                >
                  VSL: {filteredTransports.filter((transport) => transport.tarification_type === 'Vsl').length}
                </p>
                <p
                  className={`transport_detail Paramédical${selectedTypes.includes('Paramédical') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes(['Paramédical']);
                    }
                    else {
                      setSelectedTypes(selectedTypes.includes('Paramédical') ? selectedTypes.filter((type) => type !== 'Paramédical') : [...selectedTypes, 'Paramédical']);
                    }
                  }}
                >
                  PARA: {filteredTransports.filter((transport) => transport.tarification_type === 'Paramédical').length}
                </p>
                <p
                  className={`transport_detail Samu${selectedTypes.includes('Samu') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes(['Samu']);
                    }
                    else {
                      setSelectedTypes(selectedTypes.includes('Samu') ? selectedTypes.filter((type) => type !== 'Samu') : [...selectedTypes, 'Samu']);
                    }
                  }}
                >
                  SAMU: {filteredTransports.filter((transport) => transport.tarification_type === 'Samu').length}
                </p>
                <p
                  className={`transport_detail Taxi${selectedTypes.includes('Taxi') ? ' active' : ''}`}
                  onClick={() => {
                    if (selectedTypes.includes('TOTAL')) {
                      setSelectedTypes(['Taxi']);
                    }
                    else {
                      setSelectedTypes(selectedTypes.includes('Taxi') ? selectedTypes.filter((type) => type !== 'Taxi') : [...selectedTypes, 'Taxi']);
                    }
                  }}
                >
                  TAXI: {filteredTransports.filter((transport) => transport.tarification_type === 'Taxi').length}
                </p>
              </div>
            </div>
          </div>
          <Modal
            isOpen={showUpdateModal}
            onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
            style={customStylesUpdate}
            ref={(el) => {
              UpdateModalRef.current = el && el.contentRef;
            }}
          >
            <UpdateTransportModal
              onUpdateTransport={handleUpdateTransport}
              currentTransport={currentTransport}
              userToken={userToken}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default React.memo(regulation);
