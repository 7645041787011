/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, Button, FormControl, InputLabel, MenuItem, Select
} from '@mui/material';
import { BASE_URL } from '../../../../utils/api';
import fetchAddresses from '../../../../utils/fetchAddresses';

function UpdateEtablissementModal({ onUpdateEtablissement, etablissement, userToken }) {
  const [etablissementForm, setEtablissementForm] = useState({
    code: '',
    name: '',
    address: '',
    zipcode: '',
    contact: '',
    phone: '',
    day_contract: '',
    company: '',
    attachment_fund: '',
    night_contract: '',
    day_tarif_amb: '',
    day_tarif_vsl: '',
    night_tarif_amb: '',
    night_tarif_vsl: '',
  });
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tarifs, setTarifs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!etablissementForm.code) {
      newErrors.code = 'Le code est requis';
    }
    if (!etablissementForm.name) {
      newErrors.name = 'Le nom est requis';
    }
    // Add validation rules for other fields

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    setEtablissementForm({
      code: etablissement.code,
      name: etablissement.name,
      address: etablissement.address,
      zipcode: etablissement.zipcode,
      contact: etablissement.contact,
      phone: etablissement.phone,
      day_contract: etablissement.day_contract,
      company: etablissement.company,
      attachment_fund: etablissement.attachment_fund,
      night_contract: etablissement.night_contract,
      day_tarif_amb: etablissement.day_tarif_amb,
      day_tarif_vsl: etablissement.day_tarif_vsl,
      night_tarif_amb: etablissement.night_tarif_amb,
      night_tarif_vsl: etablissement.night_tarif_vsl,
    });
  }, [etablissement]);

  const handleChange = (event) => {
    setEtablissementForm((prevEtablissementForm) => ({
      ...prevEtablissementForm,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      code: etablissementForm.code,
      name: etablissementForm.name,
      address: etablissementForm.address,
      zipcode: etablissementForm.zipcode,
      contact: etablissementForm.contact,
      phone: etablissementForm.phone,
      day_contract: etablissementForm.day_contract,
      company: etablissementForm.company,
      attachment_fund: etablissementForm.attachment_fund,
      night_contract: etablissementForm.night_contract,
      day_tarif_amb: etablissementForm.day_tarif_amb,
      day_tarif_vsl: etablissementForm.day_tarif_vsl,
      night_tarif_amb: etablissementForm.night_tarif_amb,
      night_tarif_vsl: etablissementForm.night_tarif_vsl,
    };
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(`${BASE_URL}etablissements/${etablissement.id}`, data, { headers });
      onUpdateEtablissement(data);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchTarifs = async () => {
    try {
      const res = await axios.get(`${BASE_URL}tarifs`, { headers });
      setTarifs(res.data.tarifs);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchTarifs();
  }, []);

  const handleAddressChange = async (event) => {
    const query = event.target.value;
    setEtablissementForm((prevEtablissementForm) => ({
      ...prevEtablissementForm,
      address: query,
    }));

    if (query.length > 2) {
      const data = await fetchAddresses(query, { limit: 5 });
      if (data && data.features) {
        setAddressSuggestions(data.features);
      }
    }
    else {
      setAddressSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestionLabel) => {
    const selectedSuggestion = addressSuggestions.find((item) => item.properties.label === suggestionLabel);
    if (selectedSuggestion) {
      setEtablissementForm((prevEtablissementForm) => ({
        ...prevEtablissementForm,
        address: selectedSuggestion.properties.name || selectedSuggestion.properties.label,
        zipcode: `${selectedSuggestion.properties.postcode || ''} ${selectedSuggestion.properties.city || ''}`,
      }));
    }
    setAddressSuggestions([]);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="updateEtablissementModal"
    >
      <h2 className="addModalTitle">Modifier l'établissement</h2>
      <div className="firstLine">
        <TextField
          type="text"
          sx={{ width: '50%', paddingRight: '10px' }}
          name="code"
          label="Code"
          InputLabelProps={{
            shrink: true,
          }}
          id="code"
          value={etablissementForm.code}
          onChange={handleChange}
        />
        {errors.code && <p className="error">{errors.code}</p>}
        <TextField
          type="text"
          sx={{ width: '50%', paddingRight: '10px' }}
          name="name"
          label="Nom"
          InputLabelProps={{
            shrink: true,
          }}
          id="name"
          value={etablissementForm.name}
          onChange={handleChange}
        />
      </div>
      <div className="secondLine">
        <TextField
          type="text"
          sx={{ width: '50%', paddingRight: '10px' }}
          name="contact"
          label="Contact"
          InputLabelProps={{
            shrink: true,
          }}
          id="contact"
          value={etablissementForm.contact}
          onChange={handleChange}
        />
        {errors.contact && <span className="error">{errors.contact}</span>}
        <TextField
          type="text"
          sx={{ width: '50%', paddingRight: '10px' }}
          name="phone"
          label="Téléphone"
          InputLabelProps={{
            shrink: true,
          }}
          id="phone"
          value={etablissementForm.phone}
          onChange={handleChange}
        />
        {errors.phone && <span className="error">{errors.phone}</span>}
      </div>
      <div className="thirdLine">
        <TextField
          type="text"
          sx={{ width: '100%', paddingRight: '10px' }}
          name="address"
          label="Adresse"
          InputLabelProps={{
            shrink: true,
          }}
          id="address"
          value={etablissementForm.address}
          onChange={handleAddressChange}
        />
        {errors.address && <span className="error">{errors.address}</span>}
        {addressSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {addressSuggestions.map((suggestion, index) => (
              <li
                key={index}
                className="suggestions-list-item"
                onClick={() => handleSuggestionClick(suggestion.properties.label)}
              >
                {suggestion.properties.label}
              </li>
            ))}
          </ul>
        )}
        <TextField
          type="text"
          sx={{ width: '50%', margin: '10px' }}
          name="zipcode"
          label="Code postal"
          InputLabelProps={{
            shrink: true,
          }}
          id="zipcode"
          value={etablissementForm.zipcode}
          onChange={handleChange}
        />
        {errors.zipcode && <span className="error">{errors.zipcode}</span>}
      </div>
      <div className="fourthLine">
        <FormControl sx={{ width: '50%', margin: '10px' }}>
          <InputLabel id="company">Société</InputLabel>
          <Select
            required
            name="company"
            label="Société"
            id="company"
            value={etablissementForm.company}
            onChange={handleChange}
          >
            {errors.company && <p className="error">{errors.company}</p>}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '50%', margin: '10px' }}>
          <InputLabel id="attachment_fund">Tarif attribué</InputLabel>
          <Select
            required
            name="attachment_fund"
            label="Tarif attribué"
            id="attachmentFund"
            value={etablissementForm.attachment_fund}
            onChange={handleChange}
          >
            {errors.attachment_fund && <p className="error">{errors.attachment_fund}</p>}
            {tarifs.map((tarif) => (
              <MenuItem key={tarif.id} value={tarif.nom}>
                {tarif.nom}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="divButton">
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }} variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? 'Loading...' : 'Modifier l\'établissement'}
        </Button>
      </div>
    </Box>
  );
}

export default React.memo(UpdateEtablissementModal);
