/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BASE_URL } from '../../../../utils/api';

function UpdateServiceModal({
  onUpdateService, service, handleModalClose, userToken
}) {
  const [serviceForm, setServiceForm] = useState({
    uh: '',
    name: '',
    phone: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!serviceForm.uh) {
      newErrors.uh = 'Le champ UH est requis';
    }
    if (!serviceForm.name) {
      newErrors.name = 'Le nom du service est requis';
    }
    if (!serviceForm.etablissement_id) {
      newErrors.etablissement_id = 'Le champ établissement est requis';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    setServiceForm({
      uh: service.uh,
      name: service.name,
      phone: service.phone,
      etablissement_id: service.etablissement_id,
    });
  }, [service]);

  const handleChange = (event) => {
    setServiceForm((prevServiceForm) => ({
      ...prevServiceForm,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      uh: serviceForm.uh,
      name: serviceForm.name,
      phone: serviceForm.phone,
      etablissement_id: serviceForm.etablissement_id,
    };
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(`${BASE_URL}services/${service.id}`, data, { headers });
      onUpdateService(data);
      setIsLoading(false);
      handleModalClose();
    }
    catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="addServiceModal"
      onMouseDown={handleModalClick}
    >
      <h2 className="addModalTitle">Modifier le service</h2>
      <TextField
        type="text"
        sx={{ width: '40%' }}
        name="uh"
        label="UH"
        InputLabelProps={{
          shrink: true,
        }}
        id="uh"
        value={serviceForm.uh}
        onChange={handleChange}
      />
      {errors.uh && <p className="error">{errors.uh}</p>}
      <TextField
        type="text"
        sx={{ width: '40%' }}
        name="name"
        label="Nom"
        InputLabelProps={{
          shrink: true,
        }}
        id="name"
        value={serviceForm.name}
        onChange={handleChange}
      />
      {errors.etablissement_id && <p className="error">{errors.etablissement_id}</p>}
      <TextField
        type="text"
        sx={{ width: '40%' }}
        name="phone"
        label="Téléphone"
        InputLabelProps={{
          shrink: true,
        }}
        id="phone"
        value={serviceForm.phone}
        onChange={handleChange}
      />
      <Button
        onClick={handleSubmit}
        type="submit"
        sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }}
        variant="contained"
        disabled={isLoading}
      >
        Mettre à jour
      </Button>
    </Box>
  );
}

export default React.memo(UpdateServiceModal);
