/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box, TextField, InputLabel, MenuItem, FormControl, Select, Button
} from '@mui/material';
import moment from 'moment';
import { BASE_URL } from '../../../../utils/api';
import fetchAddresses from '../../../../utils/fetchAddresses';

function AddStaffModal({ onAddStaff, userToken }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [staffForm, setStaffForm] = useState({
    name: '',
    last_name: '',
    code: '',
    phoneNumber: '',
    email: '',
    adress: '',
    city: '',
    zipCode: '',
    company: '',
    graduation: '',
    socialNumber: '',
    idCard: '' || null,
    drivingLicense: '',
    medicalVisit: '',
    drivingLicenseVisit: '',
    afgsu: '',
    rib: '' || null,
    vaccinationLicense: '' || null,
    entryDate: '',
    exitDate: null,
    responsability: '',
    date_de_naissance: '',
    sexe: '',
  });

  const [open, setOpen] = useState(false);
  const addressSuggestionsRef = useRef(null);

  const validateForm = () => {
    const newErrors = {};
    if (!staffForm.name) {
      newErrors.name = 'Le nom est requis';
    }
    if (!staffForm.last_name) {
      newErrors.last_name = 'Le prénom est requis';
    }
    if (!staffForm.code) {
      newErrors.code = 'Le code est requis';
    }
    if (!staffForm.phoneNumber) {
      newErrors.phoneNumber = 'Le numéro de téléphone est requis';
    }
    if (!staffForm.email) {
      newErrors.email = 'L\'email est requis';
    }
    if (!staffForm.adress) {
      newErrors.adress = 'L\'adresse est requise';
    }
    if (!staffForm.city) {
      newErrors.city = 'La ville est requise';
    }
    if (!staffForm.zipCode) {
      newErrors.zipCode = 'Le code postal est requis';
    }
    if (!staffForm.company) {
      newErrors.company = 'La société est requise';
    }
    if (!staffForm.graduation) {
      newErrors.graduation = 'Le poste est requis';
    }
    if (!staffForm.medicalVisit) {
      newErrors.medicalVisit = 'La visite médicale est requise';
    }
    if (!staffForm.drivingLicenseVisit) {
      newErrors.drivingLicenseVisit = 'La visite du permis de conduire est requise';
    }
    if (!staffForm.afgsu) {
      newErrors.afgsu = 'L\'afgsu est requis';
    }
    if (!staffForm.entryDate) {
      newErrors.entryDate = 'La date d\'entrée est requise';
    }
    if (!staffForm.date_de_naissance) {
      newErrors.date_de_naissance = 'La date de naissance est requise';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchVehicles = async () => {
    try {
      const res = await axios.get(`${BASE_URL}vehicules`, { headers });
      const filteredAndSortedVehicles = res.data.vehicule
        .filter((vehicle) => !vehicle.exitdate) // Exclut les véhicules avec une exit_date
        .sort((a, b) => a.code.localeCompare(b.code, undefined, { numeric: true })); // Trie par code du plus petit au plus grand
      setVehicles(filteredAndSortedVehicles);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchVehicles();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Si le clic est en dehors de la zone des suggestions, fermer cette zone.
      if (addressSuggestionsRef.current && !addressSuggestionsRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // Attacher l'écouteur d'événement au document
    document.addEventListener('mousedown', handleClickOutside);

    // Nettoyer l'écouteur d'événement du document lors du démontage
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addressSuggestionsRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: staffForm.name,
      last_name: staffForm.last_name,
      code: staffForm.code,
      phoneNumber: staffForm.phoneNumber,
      email: staffForm.email,
      adress: staffForm.adress,
      city: staffForm.city,
      zipCode: staffForm.zipCode,
      company: staffForm.company,
      graduation: staffForm.graduation,
      socialNumber: staffForm.socialNumber,
      idCard: staffForm.idCard,
      drivingLicense: staffForm.drivingLicense,
      medicalVisit: moment(staffForm.medicalVisit).format('YYYY-MM-DD'),
      drivingLicenseVisit: moment(staffForm.drivingLicenseVisit).format('YYYY-MM-DD'),
      afgsu: moment(staffForm.afgsu).format('YYYY-MM-DD'),
      rib: staffForm.rib,
      vaccinationLicense: staffForm.vaccinationLicense,
      entryDate: moment(staffForm.entryDate).format('YYYY-MM-DD'),
      exitDate: staffForm.exitDate ? moment(staffForm.exitDate).format('YYYY-MM-DD') : null,
      responsability: staffForm.responsability,
      date_de_naissance: moment(staffForm.date_de_naissance).format('YYYY-MM-DD'),
      sexe: staffForm.sexe,
    };
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}staff`, data, { headers });
      onAddStaff(response.data);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleFormChange = (event) => {
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm, [event.target.name]: event.target.value,
    }));
  };

  const handleAddressChange = async (event) => {
    const query = event.target.value;
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      adress: query,
    }));

    if (query.length > 2) {
      setOpen(true); // Ouvrir les suggestions

      const suggestions = await fetchAddresses(query, { limit: 5 });
      setAddressSuggestions(suggestions.features || []);
    }
    else {
      setAddressSuggestions([]);
      setOpen(false); // Fermer les suggestions
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      adress: suggestion.properties.name,
      city: suggestion.properties.city,
      zipCode: suggestion.properties.postcode,
    }));
    setAddressSuggestions([]);
    setOpen(false);
  };

  const handleSocialNumberChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, ''); // Remove all non-numeric characters

    // Format the number with the required spaces
    const formattedValue = value
      .slice(0, 13)
      .replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})/, '$1 $2 $3 $4 $5 $6');

    // Add the clef if there are 15 digits (the last two digits are the clef)
    const clef = value.slice(13, 15);
    const finalValue = clef ? `${formattedValue} clef : ${clef}` : formattedValue;

    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      socialNumber: finalValue,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, ''); // Supprime tous les caractères non numériques

    // Appliquer le formatage avec des espaces tous les deux chiffres
    const formattedValue = value
      .split('')
      .reduce(
        (acc, digit, index) => ((index > 0 && index % 2 === 0) ? `${acc} ${digit}` : acc + digit),
        ''
      );

    setStaffForm((prevStaffForm) => ({
      ...prevStaffForm,
      phoneNumber: formattedValue,
    }));
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <h2 className="addModalTitle">Ajouter un nouveau membre du personnel</h2>
      <form className="addTransportModal">
        <div className="firstLine">
          <TextField
            type="text"
            sx={{ width: '30%' }}
            name="code"
            label="Code"
            InputLabelProps={{
              shrink: true,
            }}
            id="code"
            value={staffForm.code}
            onChange={handleFormChange}
          />
          {errors.code && <span className="error">{errors.code}</span>}
          <TextField
            type="text"
            sx={{ width: '30%' }}
            name="name"
            label="Nom"
            InputLabelProps={{
              shrink: true,
            }}
            id="nom"
            value={staffForm.name}
            onChange={handleFormChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
          <TextField
            type="text"
            sx={{ width: '30%' }}
            name="last_name"
            label="Prénom"
            InputLabelProps={{
              shrink: true,
            }}
            id="prenom"
            value={staffForm.last_name}
            onChange={handleFormChange}
          />
          {errors.last_name && <span className="error">{errors.last_name}</span>}
          <TextField
            type="date"
            sx={{ width: '30%' }}
            name="date_de_naissance"
            label="Date de naissance"
            InputLabelProps={{
              shrink: true,
            }}
            id="dateNaissance"
            value={staffForm.date_de_naissance}
            onChange={handleFormChange}
          />
          {errors.date_de_naissance && <span className="error">{errors.date_de_naissance}</span>}
        </div>
        <FormControl sx={{ width: '20%', padding: '10px' }}>
          <InputLabel id="graduation">Diplôme</InputLabel>
          <Select
            required
            name="graduation"
            id="graduation"
            value={staffForm.graduation}
            onChange={handleFormChange}
          >
            {errors.graduation && <span className="error">{errors.graduation}</span>}
            <MenuItem value="DEA">DEA</MenuItem>
            <MenuItem value="CCA">CCA</MenuItem>
            <MenuItem value="IDE">IDE</MenuItem>
            <MenuItem value="AA">AA</MenuItem>
            <MenuItem value="CA">CA</MenuItem>
            <MenuItem value="ADMINISTRATIF">ADMINISTRATIF</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '20%' }}>
          <InputLabel id="company">Société</InputLabel>
          <Select
            required
            label="Société"
            name="company"
            id="company"
            value={staffForm.company}
            onChange={handleFormChange}
          >
            {errors.company && <span className="error">{errors.company}</span>}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '20%', padding: '10px', margin: '20px' }}>
          <InputLabel id="sexe">Sexe</InputLabel>
          <Select
            required
            name="sexe"
            id="sexe"
            value={staffForm.sexe}
            onChange={handleFormChange}
          >
            {errors.sexe && <span className="error">{errors.sexe}</span>}
            <MenuItem value="Homme">Homme</MenuItem>
            <MenuItem value="Femme">Femme</MenuItem>
            <MenuItem value="Autre">Autre</MenuItem>
          </Select>
        </FormControl>
        <div className="secondLine">
          <FormControl sx={{ width: '30%', padding: '10px' }}>
            <InputLabel id="responsability">Responsabilité de véhicule</InputLabel>
            <Select
              name="responsability"
              id="responsability"
              value={staffForm.responsability}
              onChange={handleFormChange}
            >
              {errors.responsability && <span className="error">{errors.responsability}</span>}
              {vehicles.map((vehicle) => (
                <MenuItem key={vehicle.id} value={vehicle.code}>
                  {vehicle.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="text"
            sx={{ width: '40%' }}
            name="phoneNumber"
            label="Numéro de téléphone"
            InputLabelProps={{
              shrink: true,
            }}
            id="numeroTelephone"
            value={staffForm.phoneNumber}
            onChange={handlePhoneNumberChange}
          />

          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
          <TextField
            type="text"
            sx={{ width: '30%' }}
            name="email"
            label="Email"
            InputLabelProps={{
              shrink: true,
            }}
            id="email"
            value={staffForm.email}
            onChange={handleFormChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="thirdLine">
          <TextField
            type="text"
            sx={{ width: '80%', marginBottom: '1rem' }}
            name="adress"
            label="Adresse"
            InputLabelProps={{
              shrink: true,
            }}
            id="adresse"
            value={staffForm.adress}
            onChange={handleAddressChange}
          />
          {open && addressSuggestions.length > 0 && (
          <ul className="suggestions-list" ref={addressSuggestionsRef}>
            {addressSuggestions.map((suggestion, index) => (
              <li key={index} className="suggestions-list-item" onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.properties.label}
              </li>
            ))}
          </ul>
          )}
          <TextField
            type="text"
            sx={{ width: '80%', marginBottom: '1rem' }}
            name="city"
            label="Ville"
            InputLabelProps={{
              shrink: true,
            }}
            id="ville"
            value={staffForm.city}
            onChange={handleFormChange}
          />
          {errors.city && <span className="error">{errors.city}</span>}
          <TextField
            type="text"
            sx={{ width: '80%', marginBottom: '1rem' }}
            name="zipCode"
            label="Code postal"
            InputLabelProps={{
              shrink: true,
            }}
            id="codePostal"
            value={staffForm.zipCode}
            onChange={handleFormChange}
          />
          {errors.zipCode && <span className="error">{errors.zipCode}</span>}
        </div>
        <div className="fourthLine" style={{ paddingBottom: '1rem' }}>
          <TextField
            type="text"
            sx={{ width: '40%' }}
            name="socialNumber"
            label="N° Sécurité sociale"
            InputLabelProps={{
              shrink: true,
            }}
            id="numeroSecuriteSociale"
            value={staffForm.socialNumber}
            onChange={handleSocialNumberChange}
          />
          <TextField
            type="text"
            sx={{ width: '40%' }}
            name="drivingLicense"
            label="N° Permis de conduire"
            InputLabelProps={{
              shrink: true,
            }}
            id="drivingLicense"
            value={staffForm.drivingLicense}
            onChange={handleFormChange}
          />
        </div>
        <div className="fifthLine">
          <TextField
            type="date"
            sx={{ width: '20%' }}
            name="medicalVisit"
            label="Visite médicale"
            InputLabelProps={{
              shrink: true,
            }}
            id="visiteMedicale"
            value={staffForm.medicalVisit}
            onChange={handleFormChange}
          />
          {errors.medicalVisit && <span className="error">{errors.medicalVisit}</span>}
          <TextField
            type="date"
            sx={{ width: '20%' }}
            name="drivingLicenseVisit"
            label="Visite permis de conduire"
            InputLabelProps={{
              shrink: true,
            }}
            id="drivingLicenseVisit"
            value={staffForm.drivingLicenseVisit}
            onChange={handleFormChange}
          />
          {errors.drivingLicenseVisit && <span className="error">{errors.drivingLicenseVisit}</span>}
          <TextField
            type="date"
            sx={{ width: '20%' }}
            name="afgsu"
            label="Visite Afgsu"
            InputLabelProps={{
              shrink: true,
            }}
            id="afgsu"
            value={staffForm.afgsu}
            onChange={handleFormChange}
          />
          {errors.afgsu && <span className="error">{errors.afgsu}</span>}
        </div>
        <div className="sixLine">
          <TextField
            type="date"
            sx={{ width: '20%' }}
            name="entryDate"
            label="Date d'entrée"
            InputLabelProps={{
              shrink: true,
            }}
            id="dateEntree"
            value={staffForm.entryDate}
            onChange={handleFormChange}
          />
          {errors.entryDate && <span className="error">{errors.entryDate}</span>}
        </div>
        <div className="sevenLine">
          <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }} variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? 'Loading...' : 'Ajouter un personnel'}
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default React.memo(AddStaffModal);
