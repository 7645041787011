/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  TextField, MenuItem, Button, Card, CardContent, Typography, Box, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function CompanyFactureForm({ onSaveFacture }) {
  const [factureType, setFactureType] = useState('');
  const [details, setDetails] = useState({});
  const [customFields, setCustomFields] = useState([]); // Champs personnalisés pour "Chiffre d'affaires"
  const [newFieldLabel, setNewFieldLabel] = useState(''); // Intitulé du nouveau champ
  const [newFieldValue, setNewFieldValue] = useState(''); // Valeur du nouveau champ
  const [commentaires, setCommentaires] = useState('');
  const [factureDate, setFactureDate] = useState(new Date().toISOString().slice(0, 7)); // Format 'YYYY-MM'

  const categories = {
    "Chiffre d'affaires": ['Ambulance', 'Paramedical', 'Samu tableau A', 'Samu tableau B', 'Vsl', 'Taxi'],
    Informatique: ['Régulation', 'Comptabilité', 'Facturation', 'Paies', 'Licences 365', 'Internet', 'Téléphonie', 'Serveur', 'Télésurveillance'],
    Assurances: ['Assurance RC', 'Assurance infirmières'],
    Banques: ['Crédits', 'Frais bancaires', 'Frais de gestion', 'BPI', 'Investissements'],
    Impôts: ['TVA', 'Crédit d’impôts', 'Impôts sur société', 'Taxes sur salaires'],
    Intervenants: ['Directeur opérationnel', 'Comptabilité', 'Commissaire aux comptes', 'Avocats', 'Expert-comptable', 'Franchise', 'Technicien informatique', 'Normes']
  };

  const handleInputChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const handleAddCustomField = () => {
    if (newFieldLabel && newFieldValue) {
      setDetails({ ...details, [newFieldLabel]: newFieldValue });
      setCustomFields([...customFields, { label: newFieldLabel, value: newFieldValue }]);
      setNewFieldLabel('');
      setNewFieldValue('');
    }
  };

  const handleSaveFacture = () => {
    const newFacture = {
      date: `${factureDate}-01`, // Use only month and year, adding day 01 to avoid full date formatting issues
      type: factureType,
      details,
      commentaires
    };
    onSaveFacture(newFacture); // Fonction pour enregistrer la facture
  };

  return (
    <div>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h6">Détails de la facture</Typography>
          <TextField
            label="Mois et année de la facture"
            type="month"
            value={factureDate}
            onChange={(e) => setFactureDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            select
            label="Catégorie de facture"
            value={factureType}
            onChange={(e) => setFactureType(e.target.value)}
            fullWidth
            margin="normal"
            required
          >
            {Object.keys(categories).map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>

          {/* Affiche dynamiquement les champs en fonction du type de facture sélectionné */}
          {factureType && categories[factureType].map((field) => (
            <TextField
              key={field}
              label={field}
              value={details[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              fullWidth
              margin="normal"
            />
          ))}
        </CardContent>
      </Card>

      {factureType === "Chiffre d'affaires" && (
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography
              sx={{
                marginBottom: 4, color: 'grey', margin: 'auto'
              }}
              variant="h8"
            >Ajout de champs personnalisés
            </Typography>
            {customFields.map((field, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                  label={field.label}
                  value={field.value}
                  onChange={(e) => handleInputChange(field.label, e.target.value)}
                  fullWidth
                />
              </Box>
            ))}

            <Box sx={{
              display: 'flex', gap: 2, alignItems: 'center', marginTop: 2
            }}
            >
              <TextField
                label="Intitulé du champ"
                value={newFieldLabel}
                onChange={(e) => setNewFieldLabel(e.target.value)}
                fullWidth
              />
              <TextField
                label="Valeur"
                value={newFieldValue}
                onChange={(e) => setNewFieldValue(e.target.value)}
                fullWidth
              />
              <IconButton color="primary" onClick={handleAddCustomField}>
                <AddIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      )}

      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <TextField
            label="Commentaires"
            value={commentaires}
            onChange={(e) => setCommentaires(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </CardContent>
      </Card>

      <Button
        onClick={handleSaveFacture}
        sx={{ backgroundColor: '#505154' }}
        variant="contained"
        fullWidth
      >
        Enregistrer la facture
      </Button>
    </div>
  );
}

export default React.memo(CompanyFactureForm);
