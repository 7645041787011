/* eslint-disable react/prop-types */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormControlLabel
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function MutuelleManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES MUTUELLES');
  const [loading, setLoading] = useState(true);
  const [mutuelles, setMutuelles] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMutuelle, setSelectedMutuelle] = useState(null);
  const [formData, setFormData] = useState({
    code: '',
    nom: '',
    numero_contrat: '',
    type_contrat: '',
    cmu: false,
    ame: false,
    risque: '',
    exo: '',
    taux: '',
    adresse: '',
    complement: '',
    code_postal: '',
    ville: '',
    telephone: '',
    email: '',
    numero_compte: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchMutuelles = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}mutuelles`, { headers });
      setMutuelles(response.data.mutuelles);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch mutuelles', error);
    }
  };

  useEffect(() => {
    fetchMutuelles();
  }, []);

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const handleOpen = (mutuelle) => {
    if (mutuelle) {
      setIsEditing(true);
      setSelectedMutuelle(mutuelle);
      setFormData({ ...mutuelle });
    }
    else {
      setIsEditing(false);
      setFormData({
        code: '',
        nom: '',
        numero_contrat: '',
        type_contrat: '',
        cmu: false,
        ame: false,
        risque: '',
        exo: '',
        taux: '',
        adresse: '',
        complement: '',
        code_postal: '',
        ville: '',
        telephone: '',
        email: '',
        numero_compte: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isEditing ? 'put' : 'post';
    const url = isEditing ? `${BASE_URL}mutuelles/${selectedMutuelle.id}` : `${BASE_URL}mutuelles`;

    try {
      await axios({
        method: method,
        url: url,
        data: formData,
        headers: headers // Ajoutez vos en-têtes ici
      });
      fetchMutuelles();
      handleClose();
    }
    catch (error) {
      console.error('Failed to submit mutuelle', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}mutuelles/${id}`, { headers });
      fetchMutuelles();
    }
    catch (error) {
      console.error('Failed to delete mutuelle', error);
    }
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <IconButton color="primary" onClick={() => handleOpen(null)}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{isEditing ? 'Modifier la mutuelle' : 'Créer une nouvelle mutuelle'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField fullWidth margin="dense" label="Code" value={formData.code} onChange={(e) => setFormData({ ...formData, code: e.target.value })} />
            <TextField fullWidth margin="dense" label="Nom" value={formData.nom} onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
            <TextField fullWidth margin="dense" label="Numéro de contrat" value={formData.numero_contrat} onChange={(e) => setFormData({ ...formData, numero_contrat: e.target.value })} />
            <TextField fullWidth margin="dense" label="Type de contrat" value={formData.type_contrat} onChange={(e) => setFormData({ ...formData, type_contrat: e.target.value })} />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={formData.cmu}
                  onChange={(e) => setFormData({ ...formData, cmu: e.target.checked })}
                  name="cmu"
                  color="primary"
                />
  )}
              label="CMU"
            />

            <FormControlLabel
              control={(
                <Checkbox
                  checked={formData.ame}
                  onChange={(e) => setFormData({ ...formData, ame: e.target.checked })}
                  name="ame"
                  color="primary"
                />
  )}
              label="AME"
            />
            <TextField fullWidth margin="dense" label="Risque" value={formData.risque} onChange={(e) => setFormData({ ...formData, risque: e.target.value })} />
            <TextField fullWidth margin="dense" label="Exo" value={formData.exo} onChange={(e) => setFormData({ ...formData, exo: e.target.value })} />
            <TextField fullWidth margin="dense" label="Taux" value={formData.taux} onChange={(e) => setFormData({ ...formData, taux: e.target.value })} />
            <TextField fullWidth margin="dense" label="Adresse" value={formData.adresse} onChange={(e) => setFormData({ ...formData, adresse: e.target.value })} />
            <TextField fullWidth margin="dense" label="Complément" value={formData.complement} onChange={(e) => setFormData({ ...formData, complement: e.target.value })} />
            <TextField fullWidth margin="dense" label="Code postal" value={formData.code_postal} onChange={(e) => setFormData({ ...formData, code_postal: e.target.value })} />
            <TextField fullWidth margin="dense" label="Ville" value={formData.ville} onChange={(e) => setFormData({ ...formData, ville: e.target.value })} />
            <TextField fullWidth margin="dense" label="Téléphone" value={formData.telephone} onChange={(e) => setFormData({ ...formData, telephone: e.target.value })} />
            <TextField fullWidth margin="dense" label="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            <TextField fullWidth margin="dense" label="Numéro de compte" value={formData.numero_compte} onChange={(e) => setFormData({ ...formData, numero_compte: e.target.value })} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Code</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Nom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Numéro de contrat</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Type de contrat</TableCell>
              <TableCell style={{ textAlign: 'center' }}>CMU</TableCell>
              <TableCell style={{ textAlign: 'center' }}>AME</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Risque</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Exo</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Taux</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Adresse</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Complément</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Code postal</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Ville</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Téléphone</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Email</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Numéro de compte</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mutuelles.map((mutuelle) => (
              <TableRow key={mutuelle.id}>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.code}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.nom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.numero_contrat}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.type_contrat}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {mutuelle.cmu ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {mutuelle.ame ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.risque}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.exo}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.taux}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.adresse}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.complement}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.code_postal}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.ville}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.telephone}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.email}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{mutuelle.numero_compte}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton color="primary" onClick={() => handleOpen(mutuelle)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(mutuelle.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(MutuelleManager);
