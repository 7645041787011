/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { TextField, MenuItem, Button } from '@mui/material';

function StaffFactureForm({ onSaveFacture }) {
  const [factureType, setFactureType] = useState('');
  const [details, setDetails] = useState({});
  const [commentaires, setCommentaires] = useState('');
  const [factureDate, setFactureDate] = useState(new Date().toISOString().slice(0, 7)); // Format 'YYYY-MM'

  const handleInputChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const handleSaveFacture = () => {
    const newFacture = {
      date: `${factureDate}-01`, // Use only month and year, adding day 01 to avoid full date formatting issues
      type: factureType,
      details,
      commentaires,
    };
    onSaveFacture(newFacture); // Function to save the facture
  };

  return (
    <div>
      <TextField
        label="Mois et année de la facture"
        type="month"
        value={factureDate}
        onChange={(e) => setFactureDate(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        select
        label="Type de facture"
        value={factureType}
        onChange={(e) => setFactureType(e.target.value)}
        fullWidth
        margin="normal"
      >
        <MenuItem value="Salaire">Salaire</MenuItem>
        <MenuItem value="Frais de formation">Frais de formation</MenuItem>
        <MenuItem value="Frais commité d'entreprise">Frais commité d'entreprise</MenuItem>
        <MenuItem value="Frais coffre fort digital">Frais de coffre fort digital</MenuItem>
        <MenuItem value="Rupture conventionnelle">Rupture conventionnelle</MenuItem>
        <MenuItem value="Indemnité de licenciement">Indemnité de licenciement</MenuItem>
        <MenuItem value="ART 700">ART 700</MenuItem>
        <MenuItem value="Dommage et intérêt">Dommage et intérêt</MenuItem>
      </TextField>

      {factureType === 'Salaire' && (
        <>
          <TextField
            label="Salaire brut"
            value={details.salaire_brut || ''}
            onChange={(e) => handleInputChange('salaire_brut', e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Cotisations patronales"
            value={details.cotisations_patronales || ''}
            onChange={(e) => handleInputChange('cotisations_patronales', e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Indemnités de repas"
            value={details.indemnites_repas || ''}
            onChange={(e) => handleInputChange('indemnites_repas', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Indemnités de repas 2"
            value={details.indemnites_repas_2 || ''}
            onChange={(e) => handleInputChange('indemnites_repas_2', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prime de salissure"
            value={details.prime_salissure || ''}
            onChange={(e) => handleInputChange('prime_salissure', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Transport"
            value={details.transport || ''}
            onChange={(e) => handleInputChange('transport', e.target.value)}
            fullWidth
            margin="normal"
          />
        </>
      )}

      {factureType === 'Rupture conventionnelle' && (
        <TextField
          label="Coût"
          value={details.cout || ''}
          onChange={(e) => handleInputChange('cout', e.target.value)}
          fullWidth
          margin="normal"
        />
      )}

      {factureType === 'Indemnité de licenciement' && (
        <TextField
          label="Coût"
          value={details.cout || ''}
          onChange={(e) => handleInputChange('cout', e.target.value)}
          fullWidth
          margin="normal"
        />
      )}

      {factureType === 'ART 700' && (
        <TextField
          label="Coût"
          value={details.cout || ''}
          onChange={(e) => handleInputChange('cout', e.target.value)}
          fullWidth
          margin="normal"
        />
      )}

      {factureType === 'Dommage et intérêt' && (
        <TextField
          label="Coût"
          value={details.cout || ''}
          onChange={(e) => handleInputChange('cout', e.target.value)}
          fullWidth
          margin="normal"
        />
      )}

      {factureType === 'Frais de formation' && (
      <TextField
        label="Coût"
        value={details.cout || ''}
        onChange={(e) => handleInputChange('cout', e.target.value)}
        fullWidth
        margin="normal"
      />
      )}

      {factureType === 'Frais commité d\'entreprise' && (
      <TextField
        label="Coût"
        value={details.cout || ''}
        onChange={(e) => handleInputChange('cout', e.target.value)}
        fullWidth
        margin="normal"
      />
      )}

      {factureType === 'Frais coffre fort digital' && (
      <TextField
        label="Coût"
        value={details.cout || ''}
        onChange={(e) => handleInputChange('cout', e.target.value)}
        fullWidth
        margin="normal"
      />
      )}

      <TextField
        label="Commentaires"
        value={commentaires}
        onChange={(e) => setCommentaires(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />

      <Button
        onClick={handleSaveFacture}
        sx={{ margin: 'auto', backgroundColor: '#505154' }}
        variant="contained"
        fullWidth
      >
        Enregistrer la facture
      </Button>
    </div>
  );
}

export default React.memo(StaffFactureForm);
