/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import axios from 'axios';
import './logInStyles.scss';
import swal from 'sweetalert2';
import { FaEyeSlash } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';

function LoginForm() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMfaStep, setIsMfaStep] = useState(false);
  const [tempToken, setTempToken] = useState('');
  const [, setUserId] = useState('');

  usePageTitle('DRIVESOFT | CONNEXION');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    return regex.test(pwd);
  };

  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsPasswordValid(validatePassword(event.target.value));
  };

  const handleMfaCodeChange = (event) => {
    setMfaCode(event.target.value);
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}login`, { login, password });

      // Si le code MFA est envoyé
      if (response.data.tempToken) {
        setTempToken(response.data.tempToken);
        setUserId(response.data.userId);
        setIsMfaStep(true);
      }

      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      swal.fire({
        title: 'Erreur',
        text: error.response?.data?.error || 'Erreur lors de la connexion',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
        position: 'middle',
      });
    }
  };

  const handleMfaSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}login/mfa`, { tempToken, mfaCode });

      // Stocker les détails de l'utilisateur dans le local storage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userRole', response.data.userRole);
      localStorage.setItem('userEmail', response.data.userEmail);
      localStorage.setItem('userId', response.data.userId);

      setIsLoading(false);

      // Rediriger l'utilisateur vers la page d'accueil
      window.location = '/';
    }
    catch (error) {
      setIsLoading(false);
      swal.fire({
        title: 'Erreur',
        text: error.response?.data?.error || 'Code MFA invalide',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
        position: 'middle',
      });
    }
  };

  return (
    <div className="login-container">
      {isLoading && <div className="loader"><AmbysoftLoader /> </div>}

      <div className="stats-panel" />
      <div className="form-container">
        <h1>Bienvenue sur Drivesoft</h1>
        {!isMfaStep ? (
          <form className="login-form" onSubmit={handleLoginSubmit}>
            <input
              type="text"
              value={login}
              onChange={handleLoginChange}
              placeholder="Nom d'utilisateur ou Email"
              autoFocus
            />
            <div className="password-container">
              <input
                className={`password-input ${isPasswordValid ? '' : 'error'}`}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Mot de passe"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="password-toggle"
              >
                <FaEyeSlash />
              </button>
            </div>
            {!isPasswordValid && (
              <div className="error-message">Le mot de passe doit contenir au moins 8 caractères dont une majuscule et un chiffre</div>
            )}
            <div className="remember-forgot">
              <label>
                <input type="checkbox" /> Restez connecté
              </label>
              <a href="/forgot-password">Mot de passe oublié ?</a>
            </div>
            <button className="buttonLogin" type="submit">Connexion</button>
            <div className="social-login">
              {/* Social icons go here */}
            </div>
          </form>
        ) : (
          <form className="mfa-form" onSubmit={handleMfaSubmit}>
            <h2>Vérification MFA</h2>
            <p>Un code de vérification a été envoyé à votre adresse email.</p>
            <input
              type="text"
              value={mfaCode}
              onChange={handleMfaCodeChange}
              placeholder="Entrez le code MFA"
              autoFocus
            />
            <button className="buttonLogin" type="submit">Vérifier</button>
            <div className="social-login">
              {/* Social icons go here */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default React.memo(LoginForm);
