/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
// ProceduresResponses.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  Slider,
} from '@mui/material';
import {
  Book as ChapterIcon,
  Info as InfoIcon,
  TextFields as TextIcon,
  Filter1 as NumberIcon,
  Event as DateIcon,
  DateRange as DateRangeIcon,
  AccessTime as DateTimeIcon,
  AttachFile as FileIcon,
  Notes as MultilineIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckboxIcon,
  ToggleOn as ToggleOnIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  ImageSearch as ImageSearchIcon,
} from '@mui/icons-material';
import { BASE_URL } from '../../../../../utils/api';

function ProceduresResponses({ procedureId, userToken, responseId = null }) {
  const [procedures, setProcedures] = useState([]);
  const [responses, setResponses] = useState([]);
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}procedures/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
      setOpenImageDialog(true);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadImage = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}procedures/get-signed-url`, { key }, { headers });
      downloadImage(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    const fetchProceduresAndResponses = async () => {
      try {
        const proceduresRes = await axios.get(`${BASE_URL}procedures`, { headers });

        // Si on veut une seule réponse, on change l'URL pour filtrer par réponse
        const responsesRes = responseId
          ? await axios.get(`${BASE_URL}procedure-responses/response/${responseId}`, { headers })
          : await axios.get(`${BASE_URL}procedure-responses?procedureId=${procedureId}`, { headers });

        // Vérifiez si `responsesRes.data` est un tableau ou un objet
        const responsesData = Array.isArray(responsesRes.data) ? responsesRes.data : [responsesRes.data];

        const uniqueVehicleIds = [...new Set(responsesData.map((response) => response.vehicule_id))];
        setVehicleOptions(uniqueVehicleIds);

        setProcedures(proceduresRes.data);
        setResponses(responsesData);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchProceduresAndResponses();
  }, [procedureId, responseId]);

  const filteredResponses = responses
    .filter((response) => (selectedVehicleId ? response.vehicule_id === Number(selectedVehicleId) : true))
    .filter((response) => {
      if (selectedDate) {
        const responseDate = new Date(response.submitted_at).toDateString();
        const selectedDateString = new Date(selectedDate).toDateString();
        return responseDate === selectedDateString;
      }
      return true;
    });

  const getProcedureById = (id) => procedures.find((proc) => proc.id === id);

  const displayResponse = (response, fieldType) => {
    switch (fieldType) {
      case 'chapter':
      case 'info':
        return (
          <Typography variant="subtitle1" align="center">
            {response}
          </Typography>
        );
      case 'text':
      case 'multiline':
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
      case 'number':
        return (
          <Typography variant="body1" align="center">
            {Number(response)}
          </Typography>
        );
      case 'date':
      case 'datelimite':
        return (
          <Typography variant="body1" align="center">
            {new Date(response).toLocaleDateString()}
          </Typography>
        );
      case 'datetime':
        return (
          <Typography variant="body1" align="center">
            {new Date(response).toLocaleString()}
          </Typography>
        );
      case 'file':
        let images = response;

        if (!images) {
          return <Typography>Aucune image disponible</Typography>;
        }

        if (Array.isArray(images)) {
          // images est déjà un tableau d'images
        }
        else if (typeof images === 'object' && images.key) {
          // images est un objet image unique, on le met dans un tableau
          images = [images];
        }
        else {
          // images n'est ni un tableau ni un objet image, on l'affiche tel quel
          return (
            <Typography variant="body1" align="center">
              {images}
            </Typography>
          );
        }

        // Maintenant, images est un tableau d'images
        return (
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid item>
                    <Tooltip title="Visualiser le document">
                      <IconButton onClick={() => fetchSignedUrlForViewing(image.key)}>
                        <ImageSearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Télécharger le document">
                      <IconButton onClick={() => fetchSignedUrlForDownloading(image.key)}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        );

      case 'radio':
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
      case 'checkbox':
        return (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {response.map((item) => (
              <li key={item}>
                <Typography variant="body1" align="center">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        );
      case 'satisfaction':
        return (
          <Rating
            name="read-only"
            value={Number(response)}
            readOnly
            max={5}
          />
        );

      case 'jauge':
        return (
          <div style={{ width: '100%', padding: '0 10px' }}>
            <Typography variant="body2" align="center">{`${response}%`}</Typography>
            <Slider
              value={Number(response)}
              readOnly
              disabled
              marks
              min={0}
              max={100}
            />
          </div>
        );
      case 'toggle':
        return (
          <Typography
            variant="body1"
            align="center"
            color={response === 'OK' ? 'success.main' : 'error.main'}
          >
            {response === 'OK' ? (
              <>
                OK <CheckIcon fontSize="small" />
              </>
            ) : (
              <>
                KO <CloseIcon fontSize="small" />
              </>
            )}
          </Typography>
        );
      default:
        return (
          <Typography variant="body1" align="center">
            {response}
          </Typography>
        );
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      {!responseId && ( // Afficher les filtres seulement si responseId est nul
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="vehicle-select-label">Véhicule</InputLabel>
              <Select
                labelId="vehicle-select-label"
                value={selectedVehicleId}
                label="Véhicule"
                onChange={(e) => setSelectedVehicleId(e.target.value)}
              >
                <MenuItem value="">
                  <em>Tous les véhicules</em>
                </MenuItem>
                {vehicleOptions.map((vehiculeId) => (
                  <MenuItem key={vehiculeId} value={vehiculeId}>
                    Véhicule {vehiculeId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="date"
              label="Date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}

      {filteredResponses.map((response) => {
        const procedure = getProcedureById(response.procedure_id);
        const dateObj = new Date(response.submitted_at);
        const options = {
          day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'
        };
        const formattedDate = dateObj.toLocaleDateString('fr-FR', options);

        return (
          <Card key={response.response_id} sx={{ marginBottom: 3 }}>
            <CardHeader
              title={procedure.name}
              subheader={`Soumis par l'utilisateur ${response.user_id} le ${formattedDate} pour le véhicule ${response.vehicule_id}`}
            />
            <CardContent>
              {procedure.fields.map((field) => (
                <div key={field.id} style={{ marginBottom: '1rem' }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                        {field.type === 'chapter' && <ChapterIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'info' && <InfoIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'text' && <TextIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'number' && <NumberIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'date' && <DateIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'datelimite' && <DateRangeIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'datetime' && <DateTimeIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'file' && <FileIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'multiline' && <MultilineIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'radio' && <RadioButtonCheckedIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'checkbox' && <CheckboxIcon sx={{ marginRight: 1 }} />}
                        {field.type === 'toggle' && <ToggleOnIcon sx={{ marginRight: 1 }} />}
                        {field.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {displayResponse(response.response_data[field.id], field.type)}
                    </Grid>
                  </Grid>
                </div>
              ))}
            </CardContent>
          </Card>
        );
      })}

      {/* Dialog pour afficher l'image */}
      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          {viewImageUrl && (
            <img src={viewImageUrl} alt="Document" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default React.memo(ProceduresResponses);
