/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
import React from 'react';
import {
  Typography, Grid, Card, CardContent, Icon
} from '@mui/material';
import {
  Receipt, MonetizationOn, Payment, Warning, People, PriceChange, AccountBalance, HealthAndSafety, Person, Report, Gavel
} from '@mui/icons-material';
import usePageTitle from '../hooks/usePageTitle';

function Facturation({ theme }) {
  usePageTitle('DRIVESOFT | FACTURATION');
  const userRole = localStorage.getItem('userRole');

  const facturationItems = [
    { title: 'Gestion des factures - EN DEVELOPPEMENT', icon: <Receipt />, link: '/facturationManagement/facturesManagement' },
    { title: 'Gestion des impayés - EN DEVELOPPEMENT', icon: <MonetizationOn />, link: '/facturationManagement/impayesManagement' },
    { title: 'Gestion des réglements - EN DEVELOPPEMENT', icon: <Payment />, link: '/facturationManagement/reglementsManagement' },
    { title: 'Gestion des relances - EN DEVELOPPEMENT', icon: <Warning />, link: '/facturationManagement/relancesManagement' },
    { title: 'Gestion des assurés', icon: <People />, link: '/facturationManagement/assuresManagement' },
    { title: 'Gestion des tarifs', icon: <PriceChange />, link: '/facturationManagement/tarifsManagement' },
    { title: 'Gestion des caisses', icon: <AccountBalance />, link: '/facturationManagement/caissesManagement' },
    { title: 'Gestion des mutuelles', icon: <HealthAndSafety />, link: '/facturationManagement/mutuellesManagement' },
    { title: 'Gestion des préscripteurs', icon: <Person />, link: '/facturationManagement/prescripteursManagement' },
    { title: 'Gestion des codes risques / exonération', icon: <Report />, link: '/facturationManagement/risquesExonerationManagement' },
    { title: 'Gestions des marchés', icon: <Gavel />, link: '/facturationManagement/marchesManagement' },
  ];

  return (
    <div style={{ padding: '2rem', backgroundColor: theme === 'dark' ? '#000000' : '#fff', color: theme === 'dark' ? '#fff' : '#1a2b42' }}>
      {userRole === 'invité' ? (
        <Typography variant="h6" align="center" style={{ color: theme === 'dark' ? '#ff4040' : '#e74c3c' }}>
          Vous êtes sur un compte "invité", veuillez demander les permissions à l'administrateur.
        </Typography>
      ) : (
        <>
          <Typography variant="h4" gutterBottom align="center">Facturation</Typography>
          <Grid container spacing={3} justifyContent="center">
            {facturationItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() => window.location.href = item.link}
                  sx={{
                    cursor: 'pointer',
                    ':hover': { transform: 'scale(1.05)' },
                    transition: 'transform 0.3s',
                    backgroundColor: theme === 'dark' ? '#242424' : '#fff',
                    color: theme === 'dark' ? '#fff' : '#1a2b42',
                    boxShadow: theme === 'dark' ? '0px 0px 5px 2px white' : 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px',
                  }}
                >
                  <CardContent style={{ textAlign: 'center' }}>
                    <Icon style={{ fontSize: 50 }}>{item.icon}</Icon>
                    <Typography variant="h6" component="div">{item.title}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

export default React.memo(Facturation);
