/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function CaisseManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES CAISSES');
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [caisses, setCaisses] = useState([]);
  const [selectedCaisse, setSelectedCaisse] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    code_regime: '',
    caisse_gestionnaire: '',
    centre_gestionnaire: '',
    libelle: '',
    regime: '',
    organisme_destinataire: '',
    code_centre_informatique: '',
    adresse_1: '',
    adresse_2: '',
    commune: '',
    code_postal: '',
    cedex: '',
    telephone: '',
    fax: '',
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchCaisses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}caisses`, { headers });
      setCaisses(response.data.caisses);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch caisses', error);
    }
  };

  useEffect(() => {
    fetchCaisses();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickOpen = (caisse) => {
    if (caisse) {
      setIsEditing(true);
      setSelectedCaisse(caisse);
      setFormData({ ...caisse });
    }
    else {
      setIsEditing(false);
      setFormData({
        code_regime: '',
        caisse_gestionnaire: '',
        centre_gestionnaire: '',
        libelle: '',
        regime: '',
        organisme_destinataire: '',
        code_centre_informatique: '',
        adresse_1: '',
        adresse_2: '',
        commune: '',
        code_postal: '',
        cedex: '',
        telephone: '',
        fax: '',
      });
    }
    setOpen(true);
  };

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.code_regime.trim()) errors.code_regime = 'Code Régime est requis';
    if (!formData.caisse_gestionnaire.trim()) errors.caisse_gestionnaire = 'Caisse Gestionnaire est requis';
    if (!formData.centre_gestionnaire.trim()) errors.centre_gestionnaire = 'Centre Gestionnaire est requis';
    if (!formData.libelle.trim()) errors.libelle = 'Libellé est requis';
    if (!formData.regime.trim()) errors.regime = 'Régime est requis';
    if (!formData.organisme_destinataire.trim()) errors.organisme_destinataire = 'Organisme Destinataire est requis';
    if (!formData.code_centre_informatique.trim()) errors.code_centre_informatique = 'Code Centre Informatique est requis';
    if (!formData.adresse_1.trim()) errors.adresse_1 = 'Adresse 1 est requise';
    if (!formData.commune.trim()) errors.commune = 'Commune est requise';
    if (!formData.code_postal.trim()) errors.code_postal = 'Code Postal est requis';
    if (!formData.cedex.trim()) errors.cedex = 'Cedex est requis';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    const isValid = validateForm(); // Valide et met à jour l'état des erreurs

    if (isValid) {
      try {
        const method = selectedCaisse ? 'put' : 'post';
        const url = selectedCaisse ? `${BASE_URL}caisses/${selectedCaisse.id}` : `${BASE_URL}caisses`;

        await axios({
          method: method,
          url: url,
          data: formData,
          headers: headers // Ajoutez vos en-têtes ici
        });
        fetchCaisses();
        setOpen(false);
        setFormErrors({}); // Réinitialiser les erreurs après une soumission réussie
      }
      catch (error) {
        console.error('Failed to submit caisse', error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}caisses/${id}`, { headers });
      fetchCaisses();
    }
    catch (error) {
      console.error('Failed to delete caisse', error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const uploadFormData = new FormData();
    uploadFormData.append('file', file);

    try {
      await axios.post(`${BASE_URL}caisses/upload`, uploadFormData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchCaisses();
    }
    catch (error) {
      console.error('Failed to upload file', error);
    }
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <Tooltip title="Ajouter une caisse" placement="right">
        <IconButton color="black" onClick={() => handleClickOpen(null)}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>{isEditing ? 'Modifier la caisse' : 'Créer une nouvelle caisse'}</DialogTitle>
        <DialogContent>
          <form>
            <TextField required fullWidth margin="dense" name="code_regime" label="Code Régime" value={formData.code_regime} onChange={handleFormChange} error={!!formErrors.code_regime} helperText={formErrors.code_regime} />
            <TextField required fullWidth margin="dense" name="caisse_gestionnaire" label="Caisse Gestionnaire" value={formData.caisse_gestionnaire} onChange={handleFormChange} error={!!formErrors.caisse_gestionnaire} helperText={formErrors.caisse_gestionnaire} />
            <TextField required fullWidth margin="dense" name="centre_gestionnaire" label="Centre Gestionnaire" value={formData.centre_gestionnaire} onChange={handleFormChange} error={!!formErrors.centre_gestionnaire} helperText={formErrors.centre_gestionnaire} />
            <TextField required fullWidth margin="dense" name="libelle" label="Libellé" value={formData.libelle} onChange={handleFormChange} error={!!formErrors.libelle} helperText={formErrors.libelle} />
            <TextField required fullWidth margin="dense" name="regime" label="Régime" value={formData.regime} onChange={handleFormChange} error={!!formErrors.regime} helperText={formErrors.regime} />
            <TextField required fullWidth margin="dense" name="organisme_destinataire" label="Organisme Destinataire" value={formData.organisme_destinataire} onChange={handleFormChange} error={!!formErrors.organisme_destinataire} helperText={formErrors.organisme_destinataire} />
            <TextField required fullWidth margin="dense" name="code_centre_informatique" label="Code Centre Informatique" value={formData.code_centre_informatique} onChange={handleFormChange} error={!!formErrors.code_centre_informatique} helperText={formErrors.code_centre_informatique} />
            <TextField required fullWidth margin="dense" name="adresse_1" label="Adresse 1" value={formData.adresse_1} onChange={handleFormChange} error={!!formErrors.adresse_1} helperText={formErrors.adresse_1} />
            <TextField fullWidth margin="dense" name="adresse_2" label="Adresse 2" value={formData.adresse_2} onChange={handleFormChange} error={!!formErrors.adresse_2} helperText={formErrors.adresse_2} />
            <TextField required fullWidth margin="dense" name="commune" label="Commune" value={formData.commune} onChange={handleFormChange} error={!!formErrors.commune} helperText={formErrors.commune} />
            <TextField required fullWidth margin="dense" name="code_postal" label="Code Postal" value={formData.code_postal} onChange={handleFormChange} error={!!formErrors.code_postal} helperText={formErrors.code_postal} />
            <TextField required fullWidth margin="dense" name="cedex" label="Cedex" value={formData.cedex} onChange={handleFormChange} error={!!formErrors.cedex} helperText={formErrors.cedex} />
            <TextField fullWidth margin="dense" name="telephone" label="Téléphone" value={formData.telephone} onChange={handleFormChange} error={!!formErrors.telephone} helperText={formErrors.telephone} />
            <TextField fullWidth margin="dense" name="fax" label="Fax" value={formData.fax} onChange={handleFormChange} error={!!formErrors.fax} helperText={formErrors.fax} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <label htmlFor="upload-file">
        <input id="upload-file" type="file" style={{ display: 'none' }} accept=".xlsx" onChange={handleFileChange} />
        <Tooltip title="Importer un fichier" placement="right">
          <IconButton color="black" component="span">
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
      </label>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Code Régime</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Caisse Gestionnaire</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Centre Gestionnaire</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Libellé</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Régime</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Organisme Destinataire</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Code Centre Informatique</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Adresse 1</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Adresse 2</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Commune</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Code Postal</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Cedex</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Téléphone</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Fax</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caisses.map((caisse) => (
              <TableRow key={caisse.id}>
                <TableCell style={{ textAlign: 'center' }}>{caisse.code_regime}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.caisse_gestionnaire}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.centre_gestionnaire}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.libelle}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.regime}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.organisme_destinataire}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.code_centre_informatique}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.adresse_1}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.adresse_2}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.commune}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.code_postal}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.cedex}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.telephone}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{caisse.fax}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Tooltip title="Modifier" placement="top">
                    <IconButton onClick={() => handleClickOpen(caisse)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer" placement="top">
                    <IconButton onClick={() => handleDelete(caisse.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(CaisseManager);
