import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from './api';

const userToken = localStorage.getItem('token');

const headers = {
  Authorization: `Bearer ${userToken}`,
};

export const sendNotificationEmail = async (staffId, date) => {
  try {
    await axios.post(`${BASE_URL}timeClock/notifyStaffEmail`, {
      staff_id: staffId,
      date: date,
    }, { headers });
    Swal.fire('Notification envoyée', 'Notification par e-mail envoyée.', 'success');
  }
  catch (error) {
    console.error('Échec de l\'envoi de la notification par e-mail. Erreur :', error);
    Swal.fire('Erreur', 'Échec de l\'envoi de la notification par e-mail. Veuillez réessayer.', 'error');
  }
};

export const sendNotificationSMS = async (staffId, date) => {
  try {
    await axios.post(`${BASE_URL}timeClock/notifyStaffSMS`, {
      staff_id: staffId,
      date: date,
    }, { headers });
    Swal.fire('Notification envoyée', 'Notification par SMS envoyée !', 'success');
  }
  catch (error) {
    console.error(`Échec de l'envoi de la notification par SMS au personnel ${staffId}. Erreur :`, error);
    Swal.fire('Erreur', 'Échec de l\'envoi de la notification par SMS. Veuillez réessayer.', 'error');
  }
};

export const sendNotificationToVehiculeSMS = async (vehiculeCode, date) => {
  try {
    await axios.post(`${BASE_URL}transports/sendTransportSMSNotification`, {
      code: vehiculeCode,
      date: date,
    }, { headers });
    Swal.fire('Notification envoyée', `Notification par SMS envoyée au véhicule ${vehiculeCode} !`, 'success');
  }
  catch (error) {
    console.error(`Échec de l'envoi de la notification par SMS au véhicule ${vehiculeCode}. Erreur :`, error);
    Swal.fire('Erreur', 'Échec de l\'envoi de la notification par SMS. Veuillez réessayer.', 'error');
  }
};
