/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './categoriesManagementModalStyles.scss';

function CategoryManagementModal({
  categories, onCategoryUpdate, onCategoryDelete
}) {
  const [editingCategory, setEditingCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleEditClick = (category) => {
    setEditingCategory(category.category_id);
    setNewCategoryName(category.category_name);
  };

  const handleUpdateCategory = (category) => {
    onCategoryUpdate({ ...category, category_name: newCategoryName });
    setEditingCategory(null);
  };

  return (
    <div className="category-list">
      {categories.map((category) => (
        <div key={category.category_id} className="category-item">
          {editingCategory === category.category_id ? (
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdateCategory(category);
                }
              }}
            />
          ) : (
            <span className="category-name">{category.category_name}</span>
          )}
          <div>
            <Tooltip title="Modifier le label" arrow>
              <IconButton onClick={() => handleEditClick(category)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Supprimer" arrow>
              <IconButton onClick={() => onCategoryDelete(category)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(CategoryManagementModal);
