/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// ProcedureResponsesModal.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { BASE_URL } from '../../../../../utils/api';
import ProceduresResponses from './proceduresResponses';

function ProcedureResponsesModal({
  procedureId, isOpen, onRequestClose, userToken, responseId = null
}) {
  const [responses, setResponses] = useState([]);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const response = responseId
          ? await axios.get(`${BASE_URL}procedure-responses/response/${responseId}`, { headers })
          : await axios.get(`${BASE_URL}procedure-responses?procedureId=${procedureId}`, { headers });
        setResponses(responseId ? [response.data] : response.data);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des réponses', error);
      }
    };

    if (isOpen) {
      fetchResponses();
    }
  }, [isOpen, procedureId, responseId]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Résultats de la procédure
        <Button
          aria-label="close"
          onClick={onRequestClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <ProceduresResponses
          userToken={userToken}
          procedureId={procedureId}
          responses={responses}
          responseId={responseId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ProcedureResponsesModal);
