/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  Button, FormControlLabel, Checkbox, TextField
} from '@mui/material';

function AddSeriesModal({
  theme, isOpen, onClose, onConfirm
}) {
  const [seriesData, setSeriesData] = useState({
    series_end_date: '',
    round_trip_series: false,
    return_departure_hour: '',
    days: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      if (['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].includes(name)) {
        // Si le nom est l'un des jours de la semaine, mettez à jour l'objet days
        setSeriesData((prev) => ({
          ...prev,
          days: {
            ...prev.days,
            [name]: !prev.days[name]
          }
        }));
      }
      else {
        // Pour les autres propriétés de premier niveau
        setSeriesData((prev) => ({ ...prev, [name]: !prev[name] }));
      }
    }
    else {
      setSeriesData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleConfirm = () => {
    const selectedDays = [];
    for (const [day, isChecked] of Object.entries(seriesData.days)) {
      if (isChecked) {
        selectedDays.push(day.charAt(0).toUpperCase() + day.slice(1)); // Convert "monday" to "Monday"
      }
    }
    const updatedSeriesData = {
      ...seriesData,
      series_days: selectedDays.join(',') // Convert array to comma-separated string
    };
    onConfirm(updatedSeriesData);
    onClose();
  };
  const customStylesAdd = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      position: 'fixed',
      top: '50%',
      left: '55%',
      width: '80%',
      height: '45%',
      display: 'flex',
      margin: 'auto',
      flexDirection: 'column',
      transform: 'translate(-50%, -50%)',
      padding: '0 2rem',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll',
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
      overflowY: 'hidden',
    },
  };

  return (
    <Modal isOpen={isOpen} style={customStylesAdd} onRequestClose={onClose} contentLabel="Série Modal">
      <h3 className="addModalTitle">Détails de la série</h3>

      <div className="firstLine">
        <FormControlLabel
          sx={{ marginRight: '1rem' }}
          control={<Checkbox name="monday" onChange={handleChange} />}
          label="Lundi"
        />
        <FormControlLabel
          control={<Checkbox name="tuesday" onChange={handleChange} />}
          label="Mardi"
        />
        <FormControlLabel
          control={<Checkbox name="wednesday" onChange={handleChange} />}
          label="Mercredi"
        />
        <FormControlLabel
          control={<Checkbox name="thursday" onChange={handleChange} />}
          label="Jeudi"
        />
        <FormControlLabel
          control={<Checkbox name="friday" onChange={handleChange} />}
          label="Vendredi"
        />
        <FormControlLabel
          control={<Checkbox name="saturday" onChange={handleChange} />}
          label="Samedi"
        />
        <FormControlLabel
          control={<Checkbox name="sunday" onChange={handleChange} />}
          label="Dimanche"
        />
      </div>
      <div className="thirdLine">
        <TextField
          type="date"
          name="series_end_date"
          label="Date de fin de série"
          InputLabelProps={{
            shrink: true,
          }}
          value={seriesData.series_end_date}
          onChange={handleChange}
        />
        <FormControlLabel
          control={<Checkbox name="round_trip_series" onChange={handleChange} />}
          label="Retour"
        />
      </div>
      {seriesData.round_trip_series && (
        <TextField
          type="time"
          sx={{ width: '30%', margin: 'auto' }}
          name="return_departure_hour"
          label="Heure de retour"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
            list: 'time-list',
            pattern: '[0-9]{2}:[0-9]{2}',
          }}
          value={seriesData.return_departure_hour}
          onChange={handleChange}
        />
      )}
      <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" onClick={handleConfirm}>Confirmer</Button>
    </Modal>
  );
}

export default React.memo(AddSeriesModal);
