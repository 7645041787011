/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
// UpdateProcedures.jsx
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Paper,
  ListItemText,
} from '@mui/material';
import {
  Book as ChapterIcon,
  Info as InfoIcon,
  TextFields as TextIcon,
  Filter1 as NumberIcon,
  Event as DateIcon,
  DateRange as DateRangeIcon,
  AccessTime as DateTimeIcon,
  AttachFile as FileIcon,
  Notes as MultilineIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckBox as CheckboxIcon,
  ToggleOn as ToggleOnIcon,
  Delete as DeleteIcon,
  Star as StarIcon,
  LinearScale as LinearScaleIcon,
} from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BASE_URL } from '../../../../utils/api';
import UserProcedureForm from '../userProcedures/userProcedures';

function UpdateProcedures({ onUpdateProcedures, currentProcedures, userToken }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [nextFieldId, setNextFieldId] = useState(1);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [updatedField, setUpdatedField] = useState({});
  const [selectedField, setSelectedField] = useState(null);
  const [showFillModal, setShowFillModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newFieldOptions, setNewFieldOptions] = useState([]);
  const [updatedFieldOptions, setUpdatedFieldOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [procedureData, setProcedureData] = useState({
    name: '',
    commentaire: '',
    optional: false,
    frequency: 'Ponctuelle',
    fields: [],
  });
  const [newField, setNewField] = useState({
    name: '',
    type: '',
    optional: false,
    information: '',
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (currentProcedures) {
      setProcedureData(currentProcedures);
      const maxFieldId = Math.max(0, ...currentProcedures.fields.map((f) => f.id));
      setNextFieldId(maxFieldId + 1);

      // Initialiser les types sélectionnés
      const procedureTypeIds = currentProcedures.types.map((type) => type.id);
      setSelectedTypes(procedureTypeIds);

      // Initialiser les rôles sélectionnés
      const procedureRoleIds = currentProcedures.roles.map((role) => role.id);
      setSelectedRoles(procedureRoleIds);
    }
  }, [currentProcedures]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(procedureData.fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProcedureData({ ...procedureData, fields: items });
  };

  const openModal = () => {
    setNewFieldOptions([]);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, fermer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalIsOpen(false);
      }
    });
  };

  const openUpdateModal = (field) => {
    setSelectedField(field);
    setUpdatedField({ ...field });
    setUpdatedFieldOptions(field.options || []);
    setUpdateModalIsOpen(true);
  };

  const closeUpdateModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, fermer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        setUpdateModalIsOpen(false);
      }
    });
  };

  const handleProcedureChange = (e) => {
    const { name, value } = e.target;
    setProcedureData({ ...procedureData, [name]: value });
  };

  const handleAddField = async () => {
    if (!newField.name.trim() || !newField.type) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Le nom et le type du champ sont requis.',
      });
      return;
    }
    if (
      (newField.type === 'radio' || newField.type === 'checkbox')
      && newFieldOptions.length < 2
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Au moins deux options sont requises pour les champs de type choix unique ou choix multiple.',
      });
      return;
    }
    if (
      (newField.type === 'radio' || newField.type === 'checkbox')
      && newFieldOptions.some((option) => !option.trim())
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Toutes les options doivent avoir une valeur.',
      });
      return;
    }
    if (newField.type === 'chapter' || newField.type === 'info') {
      newField.optional = true;
    }
    const result = await Swal.fire({
      title: 'Ajouter ce champ?',
      text: 'Voulez-vous vraiment ajouter ce nouveau champ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ajouter',
      cancelButtonText: 'Annuler',
    });

    if (result.isConfirmed) {
      const newFieldWithId = { ...newField, id: nextFieldId };

      if (newField.type === 'radio' || newField.type === 'checkbox') {
        newFieldWithId.options = newFieldOptions;
      }

      setProcedureData({
        ...procedureData,
        fields: [...procedureData.fields, newFieldWithId],
      });
      setNewField({
        name: '',
        type: '',
        optional: false,
        information: '',
      });
      setNewFieldOptions([]);
      setNextFieldId(nextFieldId + 1);
      setModalIsOpen(false);

      Swal.fire({
        icon: 'success',
        title: 'Champ ajouté avec succès!',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    else {
      setModalIsOpen(false);
    }
  };

  useEffect(() => {
    const fetchTypesAndRoles = async () => {
      try {
        // Récupérer les types
        const typesResponse = await axios.get(`${BASE_URL}procedures/procedure/types`, { headers });
        setTypes(typesResponse.data);

        // Récupérer les rôles
        const rolesResponse = await axios.get(`${BASE_URL}roles`, { headers });
        setRoles(rolesResponse.data);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des types et des rôles', error);
      }
    };

    fetchTypesAndRoles();
  }, []);

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...newFieldOptions];
    updatedOptions[index] = value;
    setNewFieldOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setNewFieldOptions([...newFieldOptions, '']);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...newFieldOptions];
    updatedOptions.splice(index, 1);
    setNewFieldOptions(updatedOptions);
  };

  const handleUpdatedOptionChange = (index, value) => {
    const updatedOptions = [...updatedFieldOptions];
    updatedOptions[index] = value;
    setUpdatedFieldOptions(updatedOptions);
  };

  const handleUpdatedAddOption = () => {
    setUpdatedFieldOptions([...updatedFieldOptions, '']);
  };

  const handleUpdatedDeleteOption = (index) => {
    const updatedOptions = [...updatedFieldOptions];
    updatedOptions.splice(index, 1);
    setUpdatedFieldOptions(updatedOptions);
  };

  const handleUpdateField = async (fieldId, updatedFields) => {
    if (!updatedFields.name.trim() || !updatedFields.type) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Le nom et le type du champ sont requis.',
      });
      return;
    }
    if (
      (updatedFields.type === 'radio' || updatedFields.type === 'checkbox')
      && updatedFieldOptions.length < 2
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Au moins deux options sont requises pour les champs de type choix unique ou choix multiple.',
      });
      return;
    }
    if (
      (updatedFields.type === 'radio' || updatedFields.type === 'checkbox')
      && updatedFieldOptions.some((option) => !option.trim())
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Toutes les options mises à jour doivent avoir une valeur.',
      });
      return;
    }
    if (updatedFields.type === 'chapter' || updatedFields.type === 'info') {
      updatedFields.optional = true;
    }
    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Voulez-vous enregistrer les modifications apportées à ce champ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, enregistrer!',
      cancelButtonText: 'Annuler',
    });

    if (result.isConfirmed) {
      const newFields = procedureData.fields.map((field) => (field.id === fieldId
        ? { ...field, ...updatedFields, options: updatedFieldOptions }
        : field));

      setProcedureData({ ...procedureData, fields: newFields });
      setUpdatedFieldOptions(updatedFields.options || []);
      setUpdateModalIsOpen(false);

      Swal.fire('Enregistré!', 'Le champ a été mis à jour.', 'success');
    }
    else {
      setUpdateModalIsOpen(false);
    }
  };

  const handleDeleteField = async (fieldId) => {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas revenir en arrière!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimez-le!',
      cancelButtonText: 'Annuler',
    });

    if (result.isConfirmed) {
      const newFields = procedureData.fields.filter((field) => field.id !== fieldId);
      setProcedureData({ ...procedureData, fields: newFields });

      Swal.fire('Supprimé!', 'Votre champ a été supprimé.', 'success');
    }
  };

  const validateProcedure = () => {
    let isValid = true;
    const newErrors = {};

    if (!procedureData.name.trim()) {
      newErrors.name = 'Le nom de la procédure est requis.';
      isValid = false;
    }

    if (selectedTypes.length === 0) {
      newErrors.types = 'Au moins un type doit être sélectionné.';
      isValid = false;
    }

    if (selectedRoles.length === 0) {
      newErrors.roles = 'Au moins un rôle doit être sélectionné.';
      isValid = false;
    }

    procedureData.fields.forEach((field, index) => {
      if (!field.name.trim()) {
        newErrors[`field_${index}`] = 'Le libellé du champ est requis.';
        isValid = false;
      }

      if (
        (field.type === 'radio' || field.type === 'checkbox')
        && (!field.options || field.options.length === 0)
      ) {
        newErrors[`field_${index}`] = 'Au moins une option est requise.';
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateProcedure()) {
      setIsLoading(false);
      return;
    }
    try {
      const dataToSend = {
        ...procedureData,
        types: selectedTypes,
        roles: selectedRoles,
      };
      await axios.put(`${BASE_URL}procedures/${procedureData.id}`, dataToSend, {
        headers,
      });
      onUpdateProcedures(dataToSend);
    }
    catch (error) {
      console.error('Erreur lors de la modification de la procédure', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  function getFieldStyle(fieldType) {
    switch (fieldType) {
      case 'chapter':
        return { backgroundColor: 'grey', color: 'white', fontWeight: 'bold' };
      case 'info':
        return { backgroundColor: '#2196f3', color: 'white' };
      default:
        return {};
    }
  }

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Modifier la procédure</Typography>
        </Grid>
      </Grid>

      <TextField
        label="Nom de la procédure"
        name="name"
        value={procedureData.name}
        onChange={handleProcedureChange}
        placeholder="Nom de la procédure"
        fullWidth
        margin="normal"
        error={Boolean(errors.name)}
        helperText={errors.name}
      />

      <form onSubmit={handleSubmit}>
        <TextField
          label="Description"
          name="commentaire"
          value={procedureData.commentaire}
          onChange={handleProcedureChange}
          fullWidth
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Fréquence</InputLabel>
          <Select
            name="frequency"
            value={procedureData.frequency}
            onChange={handleProcedureChange}
            label="Fréquence"
          >
            <MenuItem value="Ponctuelle">Ponctuelle</MenuItem>
            <MenuItem value="Quotidienne">Quotidienne</MenuItem>
            <MenuItem value="Hebdomadaire">Hebdomadaire</MenuItem>
            <MenuItem value="Bihebdomadaire">Bihebdomadaire</MenuItem>
            <MenuItem value="Mensuelle">Mensuelle</MenuItem>
            <MenuItem value="Annuelle">Annuelle</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" error={Boolean(errors.types)}>
          <InputLabel>Types</InputLabel>
          <Select
            multiple
            value={selectedTypes}
            onChange={(e) => setSelectedTypes(e.target.value)}
            label="Types"
            renderValue={(selected) => selected
              .map((typeId) => {
                const type = types.find((t) => t.id === typeId);
                return type ? type.name : '';
              })
              .join(', ')}
          >
            {types.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                <Checkbox checked={selectedTypes.indexOf(type.id) > -1} />
                <ListItemText primary={type.name} />
              </MenuItem>
            ))}
          </Select>
          {errors.types && <Typography color="error">{errors.types}</Typography>}
        </FormControl>

        <FormControl fullWidth margin="normal" error={Boolean(errors.roles)}>
          <InputLabel>Rôles</InputLabel>
          <Select
            multiple
            value={selectedRoles}
            onChange={(e) => setSelectedRoles(e.target.value)}
            label="Rôles"
            renderValue={(selected) => selected
              .map((roleId) => {
                const role = roles.find((r) => r.id === roleId);
                return role ? role.name : '';
              })
              .join(', ')}
          >
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                <Checkbox checked={selectedRoles.indexOf(role.id) > -1} />
                <ListItemText primary={role.name} />
              </MenuItem>
            ))}
          </Select>
          {errors.roles && <Typography color="error">{errors.roles}</Typography>}
        </FormControl>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableFields">
            {(provided) => (
              <div
                className="fields-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {procedureData.fields.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        elevation={snapshot.isDragging ? 8 : 2}
                        style={{
                          padding: '10px',
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          ...getFieldStyle(field.type),
                          ...provided.draggableProps.style,
                        }}
                        onClick={() => openUpdateModal(field)}
                      >
                        <span style={{ marginRight: '10px' }}>
                          {field.type === 'chapter' && <ChapterIcon />}
                          {field.type === 'info' && <InfoIcon />}
                          {field.type === 'text' && <TextIcon />}
                          {field.type === 'number' && <NumberIcon />}
                          {field.type === 'date' && <DateIcon />}
                          {field.type === 'datelimite' && <DateRangeIcon />}
                          {field.type === 'datetime' && <DateTimeIcon />}
                          {field.type === 'file' && <FileIcon />}
                          {field.type === 'multiline' && <MultilineIcon />}
                          {field.type === 'radio' && <RadioButtonCheckedIcon />}
                          {field.type === 'checkbox' && <CheckboxIcon />}
                          {field.type === 'toggle' && <ToggleOnIcon />}
                          {field.type === 'satisfaction' && <StarIcon />}
                          {field.type === 'jauge' && <LinearScaleIcon />}
                        </span>
                        <Typography variant="body1" style={{ flexGrow: 1 }}>
                          {field.name}
                        </Typography>
                        <Tooltip title="Supprimer le champ" placement="top">
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteField(field.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid item>
          <Button fullWidth variant="contained" color="primary" onClick={openModal}>
            + Ajouter un champ
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '20px' }}
          >
            {isLoading ? 'Enregistrement...' : 'Modifier la procédure'}
          </Button>
        </Grid>
      </form>

      {/* Dialog pour ajouter un champ */}
      <Dialog open={modalIsOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle>Ajouter un champ</DialogTitle>
        <DialogContent>
          <TextField
            label="Libellé du champ"
            name="name"
            value={newField.name}
            onChange={(e) => setNewField({ ...newField, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Type de champ</InputLabel>
            <Select
              name="type"
              value={newField.type}
              onChange={(e) => setNewField({ ...newField, type: e.target.value })}
              label="Type de champ"
            >
              <MenuItem value="">Sélectionnez un type</MenuItem>
              <MenuItem value="chapter">Tête de chapitre</MenuItem>
              <MenuItem value="info">Informations</MenuItem>
              <MenuItem value="text">Texte</MenuItem>
              <MenuItem value="toggle">OK/KO</MenuItem>
              <MenuItem value="number">Nombre</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="datelimite">Date limite</MenuItem>
              <MenuItem value="datetime">Date et heure</MenuItem>
              <MenuItem value="file">Photo/fichier</MenuItem>
              <MenuItem value="multiline">Texte multi-lignes</MenuItem>
              <MenuItem value="radio">Choix unique</MenuItem>
              <MenuItem value="checkbox">Choix multiple</MenuItem>
              <MenuItem value="satisfaction">Satisfaction</MenuItem>
              <MenuItem value="jauge">Jauge</MenuItem>
            </Select>
          </FormControl>

          {(newField.type === 'radio' || newField.type === 'checkbox') && (
            <div>
              {newFieldOptions.map((option, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={`Option ${index + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddOption}
                style={{ marginTop: '10px' }}
              >
                Ajouter une option
              </Button>
            </div>
          )}

          {newField.type !== 'chapter' && newField.type !== 'info' && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={newField.optional}
                  onChange={() => setNewField({ ...newField, optional: !newField.optional })}
                />
              )}
              label="Optionnel"
              style={{ marginTop: '10px' }}
            />
          )}

          <TextField
            label="Information"
            name="information"
            value={newField.information}
            onChange={(e) => setNewField({ ...newField, information: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Annuler</Button>
          <Button onClick={handleAddField} variant="contained" color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour modifier un champ */}
      <Dialog open={updateModalIsOpen} onClose={closeUpdateModal} maxWidth="sm" fullWidth>
        <DialogTitle>Modifier un champ</DialogTitle>
        <DialogContent>
          <TextField
            label="Libellé du champ"
            name="name"
            value={updatedField.name}
            onChange={(e) => setUpdatedField({ ...updatedField, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Type de champ</InputLabel>
            <Select
              name="type"
              value={updatedField.type}
              label="Type de champ"
              onChange={(e) => setUpdatedField({ ...updatedField, type: e.target.value })}
            >
              <MenuItem value="">Sélectionnez un type</MenuItem>
              <MenuItem value="chapter">Tête de chapitre</MenuItem>
              <MenuItem value="info">Informations</MenuItem>
              <MenuItem value="text">Texte</MenuItem>
              <MenuItem value="toggle">OK/KO</MenuItem>
              <MenuItem value="number">Nombre</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="datelimite">Date limite</MenuItem>
              <MenuItem value="datetime">Date et heure</MenuItem>
              <MenuItem value="file">Photo/fichier</MenuItem>
              <MenuItem value="multiline">Texte multi-lignes</MenuItem>
              <MenuItem value="radio">Choix unique</MenuItem>
              <MenuItem value="checkbox">Choix multiple</MenuItem>
              <MenuItem value="satisfaction">Satisfaction</MenuItem>
              <MenuItem value="jauge">Jauge</MenuItem>
            </Select>
          </FormControl>

          {(updatedField.type === 'radio' || updatedField.type === 'checkbox') && (
            <div>
              {updatedFieldOptions.map((option, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={`Option ${index + 1}`}
                    value={option}
                    onChange={(e) => handleUpdatedOptionChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleUpdatedDeleteOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUpdatedAddOption}
                style={{ marginTop: '10px' }}
              >
                Ajouter une option
              </Button>
            </div>
          )}

          {updatedField.type !== 'chapter' && updatedField.type !== 'info' && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={updatedField.optional}
                  onChange={() => setUpdatedField({
                    ...updatedField,
                    optional: !updatedField.optional,
                  })}
                />
              )}
              label="Optionnel"
              style={{ marginTop: '10px' }}
            />
          )}

          <TextField
            label="Information"
            name="information"
            value={updatedField.information}
            onChange={(e) => setUpdatedField({ ...updatedField, information: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUpdateModal}>Annuler</Button>
          <Button
            onClick={() => handleUpdateField(selectedField.id, updatedField)}
            variant="contained"
            color="primary"
          >
            Modifier
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal pour l'aperçu de la procédure */}
      <Dialog
        open={showFillModal}
        onClose={() => setShowFillModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Aperçu de la procédure</DialogTitle>
        <DialogContent>
          {procedureData && <UserProcedureForm procedureData={procedureData} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFillModal(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default React.memo(UpdateProcedures);
