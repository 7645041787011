// DocumentsImages.jsx
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Importer l'icône de PDF
import { BASE_URL } from '../../utils/api';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';

const urlCache = {};

function DocumentsImages({ imageKey, selectedImageUrl, userToken }) {
  const [imgSrc, setImgSrc] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  function isPdfFile(key) {
    return key && key.toLowerCase().endsWith('.pdf');
  }

  const isPdf = isPdfFile(imageKey);

  const fetchSignedUrl = async () => {
    if (urlCache[imageKey]) {
      setImgSrc(urlCache[imageKey]);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}vehicules/get-signed-url`, { key: imageKey }, { headers });
      urlCache[imageKey] = response.data.url; // Mise en cache de l'URL
      setImgSrc(response.data.url);
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l\'URL signée :', error);
    }
  };

  useEffect(() => {
    if (selectedImageUrl) {
      setImgSrc(selectedImageUrl);
    }
    else if (imageKey) {
      fetchSignedUrl();
    }
  }, [imageKey, selectedImageUrl]);

  const handleImageLoad = () => {
    setIsUploading(false);
  };

  return (
    <div>
      {isUploading && <AmbysoftLoader />}
      {isPdf ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        >
          <PictureAsPdfIcon style={{ fontSize: 80, color: '#d32f2f' }} />
        </div>
      ) : (
        <Avatar
          onLoad={handleImageLoad}
          alt="documentImg"
          variant="square"
          style={{
            width: '200px',
            height: 'auto',
            margin: 'auto',
            cursor: 'pointer',
          }}
          src={imgSrc}
        />
      )}
    </div>
  );
}

export default React.memo(DocumentsImages);
