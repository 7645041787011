/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, Card, CardContent, Typography, Grid, List, ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api'; // Assurez-vous que ce chemin est correct
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function AssureManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES ASSURÉS');
  const [loading, setLoading] = useState(true);
  const [assures, setAssures] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssure, setSelectedAssure] = useState(null);
  const [beneData, setBeneData] = useState([]);
  const [beneModalOpen, setBeneModalOpen] = useState(false);
  const [selectedBene, setSelectedBene] = useState(null);
  const [caisses, setCaisses] = useState([]);
  const [mutuelles, setMutuelles] = useState([]);
  const [selectedCaisseDetails, setSelectedCaisseDetails] = useState({});
  const [selectedMutuelleDetails, setSelectedMutuelleDetails] = useState({});
  const [exoneration, setExoneration] = useState([]);
  const [formData, setFormData] = useState({
    civilite: '',
    nom: '',
    nom_usage: '',
    prenom: '',
    date_naissance: '',
    adresse: '',
    code_postal: '',
    ville: '',
    batiment: '',
    etage: '',
    porte: '',
    telephone: '',
    numero_securite_sociale: '',
    email: '',
    type: '',
    taux: '',
    exoneration: '',
    date_fin_droits: '',
    nature_assurance: '',
    commentaire: '',
    caisse_id: '',
    mutuelle_id: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  }

  function isoDateToShortDate(isoDate) {
    return isoDate ? isoDate.split('T')[0] : '';
  }

  function formatPhoneNumber(value) {
    // Enlever tous les caractères non-numériques
    const numbers = value.replace(/[^\d]/g, '');

    // Découper et formater le numéro
    return numbers.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  }

  function formatSocialSecurityNumber(value) {
    // Enlever tous les caractères non-numériques
    const numbers = value.replace(/[^\d]/g, '');

    // Vérifier que le numéro a exactement 15 chiffres avant de formater
    if (numbers.length === 15) {
      // Découper et formater le numéro
      return numbers.replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})/, '$1 $2 $3 $4 $5 $6 clé: $7');
    }

    // Retourner les nombres non formatés si la longueur n'est pas 15
    return numbers;
  }

  const fetchAssures = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}assures`, { headers });
      setAssures(response.data.assures);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch assures', error);
    }
  };

  const updateSelectedCaisseDetails = (caisseId, caissesData) => {
    const selectedCaisse = caissesData.find((caisse) => caisse.id === caisseId);
    setSelectedCaisseDetails(selectedCaisse || {});
  };
  const fetchCaisses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}caisses`, { headers });
      setCaisses(response.data.caisses);
      // Mettre à jour les détails de la caisse lors du chargement des caisses
      updateSelectedCaisseDetails(formData.caisse_id, response.data.caisses);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch caisses', error);
    }
  };

  const updateSelectedMutuelleDetails = (mutuelleId, mutuellesData) => {
    const selectedMutuelle = mutuellesData.find((mutuelle) => mutuelle.id === mutuelleId);
    setSelectedMutuelleDetails(selectedMutuelle || {});
  };

  const fetchMutuelles = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}mutuelles`, { headers });
      setMutuelles(response.data.mutuelles);
      // Mettre à jour les détails de la mutuelle lors du chargement des mutuelles
      updateSelectedMutuelleDetails(formData.mutuelle_id, response.data.mutuelles);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch mutuelles', error);
    }
  };

  const fetchExoneration = async () => {
    try {
      const response = await axios.get(`${BASE_URL}risques-exoneration`, { headers });
      setExoneration(response.data.entries);
    }
    catch (error) {
      console.error('Failed to fetch exoneration', error);
    }
  };

  const fetchBeneficiaires = async () => {
    const response = await axios.get(`${BASE_URL}beneficiaires/${selectedAssure.id}`, { headers });
    setBeneData(response.data.beneficiaires);
    setBeneModalOpen(true);
  };

  useEffect(() => {
    fetchAssures();
    fetchCaisses();
    fetchMutuelles();
    fetchExoneration();
  }, []);

  useEffect(() => {
    updateSelectedCaisseDetails(formData.caisse_id, caisses);
  }, [formData.caisse_id, caisses]);

  useEffect(() => {
    updateSelectedMutuelleDetails(formData.mutuelle_id, mutuelles);
  }, [formData.mutuelle_id, mutuelles]);

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const handleCaisseChange = async (event) => {
    const caisseId = event.target.value;
    setFormData({ ...formData, caisse_id: caisseId });
    const selectedCaisse = caisses.find((caisse) => caisse.id === caisseId);
    setSelectedCaisseDetails(selectedCaisse || {});
  };

  const handleMutuelleChange = async (event) => {
    const mutuelleId = event.target.value;
    setFormData({ ...formData, mutuelle_id: mutuelleId });
    const selectedMutuelle = mutuelles.find((mutuelle) => mutuelle.id === mutuelleId);
    setSelectedMutuelleDetails(selectedMutuelle || {});
  };

  const openBeneModal = () => {
    fetchBeneficiaires(selectedAssure);
    setBeneModalOpen(true);
    setSelectedBene(null); // Réinitialiser le bénéficiaire sélectionné pour le formulaire
  };

  const closeBeneModal = () => {
    setBeneModalOpen(false);
  };

  const handleBeneChange = (event) => {
    const { name, value } = event.target;
    setSelectedBene((prev) => ({ ...prev, [name]: value }));
  };

  const saveBene = async () => {
    // Déterminer si c'est une création ou une mise à jour
    const isUpdate = selectedAssure && selectedBene.id;
    const url = isUpdate ? `${BASE_URL}beneficiaires/${selectedAssure.id}` : `${BASE_URL}beneficiaires/${selectedAssure.id}`;

    try {
      const response = await axios({
        method: isUpdate ? 'put' : 'post',
        url: url,
        data: {
          ...selectedBene,
          assure_id: selectedAssure.id // Assurez-vous que l'ID de l'assuré est toujours envoyé dans le corps de la requête pour une création
        },
        headers: headers // Ajoutez vos en-têtes ici
      });
      // Mettre à jour les données localement
      setBeneData((prevData) => (isUpdate ? prevData.map((b) => (b.id === response.data.id ? response.data : b)) : [...prevData, response.data]));
      setSelectedBene(null);
      setBeneModalOpen(false); // Fermer le modal après l'enregistrement
    }
    catch (error) {
      console.error('Failed to save beneficiaire', error);
    }
  };

  const addBene = () => {
    setSelectedBene({
      nom: '', prenom: '', date_naissance: '', lien: '', rang: ''
    });
  };

  const deleteBene = async (id) => {
    try {
      await axios.delete(`${BASE_URL}beneficiaires/${id}`, { headers });
      setBeneData((prevData) => prevData.filter((b) => b.id !== id));
    }
    catch (error) {
      console.error('Failed to delete beneficiaire', error);
    }
  };

  const handleOpen = (assure) => {
    if (assure) {
      setIsEditing(true);
      setSelectedAssure(assure);
      setFormData({
        ...assure,
        date_naissance: isoDateToShortDate(assure.date_naissance),
        date_fin_droits: isoDateToShortDate(assure.date_fin_droits)
      });
    }
    else {
      setIsEditing(false);
      setFormData({
        civilite: '',
        nom: '',
        nom_usage: '',
        prenom: '',
        date_naissance: '',
        adresse: '',
        code_postal: '',
        ville: '',
        batiment: '',
        etage: '',
        porte: '',
        telephone: '',
        numero_securite_sociale: '',
        email: '',
        type: '',
        taux: '',
        exoneration: '',
        date_fin_droits: '',
        nature_assurance: '',
        commentaire: '',
        caisse_id: '',
        mutuelle_id: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isEditing ? 'put' : 'post';
    const url = isEditing ? `${BASE_URL}assures/${selectedAssure.id}` : `${BASE_URL}assures`;

    try {
      await axios({
        method: method,
        url: url,
        data: formData,
        headers: headers // Ajoutez vos en-têtes ici
      });
      fetchAssures();
      handleClose();
    }
    catch (error) {
      console.error('Failed to submit assure', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}assures/${id}`, { headers });
      fetchAssures();
    }
    catch (error) {
      console.error('Failed to delete assure', error);
    }
  };

  const handleEditBene = (bene) => {
    setSelectedBene({
      ...bene,
      date_naissance: isoDateToShortDate(bene.date_naissance)
    });
  };

  const handleTelephoneChange = (e) => {
    // Formater le numéro de téléphone lors de la saisie
    const formattedPhone = formatPhoneNumber(e.target.value);

    setFormData({ ...formData, telephone: formattedPhone });
  };

  const handleSSChange = (e) => {
    // Formater le numéro de téléphone lors de la saisie
    const formattedSSN = formatSocialSecurityNumber(e.target.value);

    setFormData({ ...formData, numero_securite_sociale: formattedSSN });
  };

  const handleExonerationChange = (event) => {
    const selectedExonerationId = event.target.value;
    // Trouver l'exonération correspondante dans le tableau
    const selectedExoneration = exoneration.find((exo) => exo.id === selectedExonerationId);

    // Mettre à jour l'état avec l'ID de l'exonération, le taux correspondant, et le code de risque (nature d'assurance)
    setFormData({
      ...formData,
      exoneration: selectedExonerationId,
      taux: selectedExoneration ? selectedExoneration.taux : '',
      nature_assurance: selectedExoneration ? selectedExoneration.code_risque : ''
    });
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <IconButton color="black" onClick={() => handleOpen(null)}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ margin: 'auto' }}>{isEditing ? 'Modifier l\'assuré' : 'Créer un nouvel assuré'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Informations du Patient</Typography>
                    <FormControl fullWidth margin="dense">
                      <InputLabel id="civility-label">Civilité</InputLabel>
                      <Select
                        labelId="civility-label"
                        value={formData.civilite}
                        onChange={(e) => setFormData({ ...formData, civilite: e.target.value })}
                        label="Civilité"
                      >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mme">Mme</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField fullWidth margin="dense" name="nom" label="Nom" value={formData.nom} onChange={(e) => setFormData({ ...formData, nom: e.target.value })} />
                    <TextField fullWidth margin="dense" name="nom_usage" label="Nom d'usage" value={formData.nom_usage} onChange={(e) => setFormData({ ...formData, nom_usage: e.target.value })} />
                    <TextField fullWidth margin="dense" name="prenom" label="Prénom" value={formData.prenom} onChange={(e) => setFormData({ ...formData, prenom: e.target.value })} />
                    <TextField
                      fullWidth
                      margin="dense"
                      type="date"
                      name="date_naissance"
                      label="Date de naissance"
                      value={formData.date_naissance}
                      onChange={(e) => setFormData({ ...formData, date_naissance: e.target.value })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField fullWidth margin="dense" name="numero_securite_sociale" label="Numéro de sécurité sociale" value={formData.numero_securite_sociale} onChange={handleSSChange} />
                    <TextField fullWidth margin="dense" name="adresse" label="Adresse" value={formData.adresse} onChange={(e) => setFormData({ ...formData, adresse: e.target.value })} />
                    <TextField fullWidth margin="dense" name="code_postal" label="Code postal" value={formData.code_postal} onChange={(e) => setFormData({ ...formData, code_postal: e.target.value })} />
                    <TextField fullWidth margin="dense" name="ville" label="Ville" value={formData.ville} onChange={(e) => setFormData({ ...formData, ville: e.target.value })} />
                    <TextField fullWidth margin="dense" name="batiment" label="Bâtiment" value={formData.batiment} onChange={(e) => setFormData({ ...formData, batiment: e.target.value })} />
                    <TextField fullWidth margin="dense" name="etage" label="Étage" value={formData.etage} onChange={(e) => setFormData({ ...formData, etage: e.target.value })} />
                    <TextField fullWidth margin="dense" name="porte" label="Porte" value={formData.porte} onChange={(e) => setFormData({ ...formData, porte: e.target.value })} />
                    <TextField fullWidth margin="dense" name="telephone" label="Téléphone" value={formData.telephone} onChange={handleTelephoneChange} />
                    <TextField fullWidth margin="dense" name="email" label="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                    <TextField fullWidth margin="dense" name="commentaire" label="Commentaire" value={formData.commentaire} onChange={(e) => setFormData({ ...formData, commentaire: e.target.value })} />
                  </CardContent>
                </Card>
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Détails de l'Assurance</Typography>
                  <TextField fullWidth margin="dense" name="type" label="Type d'assuré" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} />
                  <TextField
                    select
                    label="Exonération"
                    value={formData.exoneration}
                    onChange={handleExonerationChange}
                    fullWidth
                    margin="dense"
                  >
                    {exoneration.map((exo) => (
                      <MenuItem key={exo.id} value={exo.id}>
                        {exo.commentaire}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="taux"
                    label="Taux"
                    value={formData.taux}
                    onChange={(e) => setFormData({ ...formData, taux: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    name="nature_assurance"
                    label="Nature de l'assurance"
                    value={formData.nature_assurance}
                    onChange={(e) => setFormData({ ...formData, nature_assurance: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    type="date"
                    name="date_fin_droits"
                    label="Date de fin des droits"
                    value={formData.date_fin_droits}
                    onChange={(e) => setFormData({ ...formData, date_fin_droits: e.target.value })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ my: 2 }}>
                <CardContent>
                  <Typography variant="h6" color="text.primary">
                    Détails de la Caisse
                  </Typography>
                  <TextField select label="Caisse" value={formData.caisse_id} onChange={handleCaisseChange} fullWidth margin="dense">
                    {caisses.map((caisse) => (
                      <MenuItem key={caisse.id} value={caisse.id}>
                        {caisse.libelle}
                      </MenuItem>
                    ))}
                  </TextField>
                  {selectedCaisseDetails && (
                    <div>
                      <Typography>Libellé: {selectedCaisseDetails.libelle}</Typography>
                      <Typography>Adresse: {selectedCaisseDetails.adresse_1}</Typography>
                      <Typography>Complément d'adresse: {selectedCaisseDetails.adresse_2}</Typography>
                      <Typography>Code postal: {selectedCaisseDetails.code_postal}</Typography>
                      <Typography>Ville: {selectedCaisseDetails.commune}</Typography>
                      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <Typography variant="body2"><strong>Numéro de caisse: </strong>{selectedCaisseDetails.code_regime}</Typography>
                        <Typography variant="body2">{selectedCaisseDetails.caisse_gestionnaire}</Typography>
                        <Typography variant="body2">{selectedCaisseDetails.centre_gestionnaire}</Typography>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ my: 2 }}>
                <CardContent>
                  <Typography variant="h6" color="text.primary">
                    Détails de la Mutuelle
                  </Typography>
                  <TextField select label="Mutuelle" value={formData.mutuelle_id} onChange={handleMutuelleChange} fullWidth margin="dense">
                    {mutuelles.map((mutuelle) => (
                      <MenuItem key={mutuelle.id} value={mutuelle.id}>
                        {mutuelle.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                  {selectedMutuelleDetails && (
                    <div style={{ marginTop: '10px' }}>
                      <Typography> Nom: {selectedMutuelleDetails.nom} </Typography>
                      <Typography> Numéro de contrat: {selectedMutuelleDetails.numero_contrat} </Typography>
                    </div>
                  )}
                </CardContent>
              </Card>
              <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => openBeneModal(formData.id)} sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained">Bénéficiaires</Button>
              </Grid>

            </Grid>
          </Grid>
        </DialogContent>
        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Dialog open={beneModalOpen} onClose={closeBeneModal} maxWidth="md" fullWidth>
            <DialogTitle>Bénéficiaires</DialogTitle>
            <DialogContent>
              <List>
                {beneData.map((bene) => (
                  <ListItem key={bene.id} divider>
                    <ListItemText
                      primary={`${bene.nom} ${bene.prenom}`}
                      secondary={`Date de naissance: ${formatDate(bene.date_naissance)} - Lien: ${bene.lien} - Rang: ${bene.rang}`}
                    />
                    <ListItemSecondaryAction>
                      <Button sx={{ margin: 'auto', backgroundColor: '#505154', marginRight: '3px' }} variant="contained" onClick={() => handleEditBene(bene)}>
                        Modifier
                      </Button>
                      <Button sx={{ margin: 'auto', backgroundColor: '#505154' }} variant="contained" onClick={() => deleteBene(bene.id)}>
                        Supprimer
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Button sx={{ margin: 'auto', backgroundColor: '#505154' }} variant="contained" onClick={addBene} style={{ marginTop: 20 }}>
                Ajouter Bénéficiaire
              </Button>
              {selectedBene && (
              <form style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="nom"
                      label="Nom"
                      value={selectedBene.nom}
                      onChange={handleBeneChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="prenom"
                      label="Prénom"
                      value={selectedBene.prenom}
                      onChange={handleBeneChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="date_naissance"
                      label="Date de Naissance"
                      type="date"
                      value={selectedBene.date_naissance}
                      onChange={handleBeneChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="lien"
                      label="Lien"
                      value={selectedBene.lien}
                      onChange={handleBeneChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="rang"
                      label="Rang"
                      type="number"
                      value={selectedBene.rang}
                      onChange={handleBeneChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  sx={{ margin: 'auto', backgroundColor: '#505154' }}
                  variant="contained"
                  onClick={saveBene}
                  type="button"
                  style={{ marginTop: 20 }}
                >
                  Sauvegarder
                </Button>
              </form>
              )}
            </DialogContent>
          </Dialog>
        </Grid>
        <DialogActions sx={{ margin: 'auto' }}>
          <Button onClick={handleSubmit} sx={{ margin: 'auto', backgroundColor: '#505154', width: '100%' }} variant="contained">Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Civilité</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Nom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Nom d'usage</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Prénom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Date de naissance</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Téléphone</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Email</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Date de fin des droits</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Commentaire</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assures.map((assure) => (
              <TableRow key={assure.id}>
                <TableCell style={{ textAlign: 'center' }}>{assure.civilite}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.nom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.nom_usage}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.prenom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{formatDate(assure.date_naissance)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.telephone}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.email}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{formatDate(assure.date_fin_droits)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{assure.commentaire}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton onClick={() => handleOpen(assure)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(assure.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(AssureManager);
