import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';

function ForgotPassword() {
  usePageTitle('DRIVESOFT | MOT DE PASSE OUBLIÉ');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Activer le loader

    try {
      const response = await axios.post(`${BASE_URL}password-reset/request-reset`, { email });

      if (response.status === 200) {
        toast.success('Vous allez recevoir un email pour réinitialiser votre mot de passe.');
      }
      else {
        toast.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    }
    catch (error) {
      console.error('Erreur lors de la demande de réinitialisation:', error);
      if (error.response && error.response.status === 404 && error.response.data.error === 'Email not found') {
        toast.error("L'adresse e-mail n'est pas associée à un compte.");
      }
      else {
        toast.error('Une erreur est survenue lors de la demande de réinitialisation du mot de passe.');
      }
    }
    finally {
      setIsLoading(false); // Désactiver le loader
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="login-container">
      {isLoading && <div><AmbysoftLoader /> </div>}

      <div className="stats-panel" />
      <div className="form-container">
        <h1>Récupération de mot de passe</h1>
        <form className="login-form" onSubmit={handleSubmit}>

          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            placeholder="email"
          />
          <button className="buttonLogin" type="submit">Demander la réinitialisation du mot de passe</button>
        </form>
      </div>
    </div>
  );
}

export default React.memo(ForgotPassword);
