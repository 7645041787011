/* eslint-disable no-shadow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  format, addDays, startOfWeek, addWeeks, getISOWeek
} from 'date-fns';
import { TextField, InputAdornment } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fr } from 'date-fns/locale';
import './annualPlanningStyles.scss';
import SearchIcon from '@mui/icons-material/Search';
import usePageTitle from '../../hooks/usePageTitle';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';

function Planning({ userEmail, userRole, userToken }) {
  usePageTitle('DRIVESOFT | PLANNING ANNUEL');
  const [staff, setStaff] = useState([]);
  const [showExitedStaff, setShowExitedStaff] = useState(false);
  const [search, setSearch] = useState('');
  const [planning, setPlanning] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedGraduation, setSelectedGraduation] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 2 }, (_, i) => currentYear + i);
  const statusOptions = ['R', 'CP', 'T', 'AM', 'AT', 'RCR', 'F', 'SS', 'ABS', 'EC', 'PAR'];
  const statusLabels = {
    R: 'Repos (R)',
    CP: 'Congés payés (CP)',
    T: 'Travail (T)',
    AM: 'Arrêt maladie (AM)',
    AT: 'Accident de travail (AT)',
    RCR: 'Repos compensateur (RCR)',
    F: 'Férié (F)',
    SS: 'Sans solde (SS)',
    ABS: 'Absence (ABS)',
    EC: 'Ecole (EC)',
    PAR: 'Congés parentaux (PAR)',
  };

  const categories = {
    DEA_CCA: ['DEA', 'CCA'],
    AA_CHAUFFEUR: ['AA', 'CHAUFFEUR'],
    IDE: ['IDE'],
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const [selectedWeek, setSelectedWeek] = useState(getISOWeek(new Date()) - 1);
  const isFrenchHoliday = (date) => {
    const year = date.getFullYear();
    const holidays = [
      new Date(year, 0, 1),
      new Date(year, 4, 1),
      new Date(year, 4, 8),
      new Date(year, 6, 14),
      new Date(year, 7, 15),
      new Date(year, 10, 1),
      new Date(year, 10, 11),
      new Date(year, 11, 25),
    ];

    return holidays.some((holiday) => date.getTime() === holiday.getTime());
  };

  const getFirstMondayOfYear = (year) => {
    const date = new Date(year, 0, 1); // January 1st of the given year
    const firstMonday = startOfWeek(date, { weekStartsOn: 1 });

    if (firstMonday.getFullYear() < year) {
      return addWeeks(firstMonday, 1); // Move to the next week
    }

    return firstMonday;
  };

  const startDate = getFirstMondayOfYear(selectedYear);

  const fetchStaff = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      const sortedStaff = response.data.staff.sort((a, b) => a.order_index - b.order_index);
      setStaff(sortedStaff);
    }
    catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newFilteredStaff = staff
      .filter((staffMember) => userRole === 'Administrateur' || userRole === 'Régulation' || userRole === 'Ressources humaines' || !userEmail || staffMember.email === userEmail)
      .filter((staffMember) => !selectedCompany || staffMember.company === selectedCompany)
      .filter((staffMember) => !selectedGraduation || staffMember.graduation === selectedGraduation)
      .filter((staffMember) => {
        if (showExitedStaff) {
          return staffMember.exitdate && new Date(staffMember.exitdate) <= new Date();
        }
        return !staffMember.exitdate || new Date(staffMember.exitdate) > new Date();
      })
      .filter((staffMember) => {
        const name = staffMember.name ? staffMember.name.toLowerCase() : '';
        const firstName = staffMember.last_name ? staffMember.last_name.toLowerCase() : '';
        return name.includes(search.toLowerCase()) || firstName.includes(search.toLowerCase());
      });

    setFilteredStaff(newFilteredStaff);
  }, [staff, userRole, userEmail, selectedCompany, selectedGraduation, showExitedStaff, search]);

  const fetchPlanning = async () => {
    try {
      const response = await axios.get(`${BASE_URL}planning`, { headers });
      setPlanning(response.data.plannings);
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching planning:', error);
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleMouseDown = (staffId, dateIndex) => {
    setIsSelecting(true);
    setSelectedCells([{ staffId, dateIndex }]);
  };

  const handleMouseEnter = (staffId, dateIndex) => {
    if (isSelecting) {
      // Vérifiez si la cellule est déjà dans la sélection
      if (!selectedCells.some((cell) => cell.staffId === staffId && cell.dateIndex === dateIndex)) {
        setSelectedCells((prevCells) => [...prevCells, { staffId, dateIndex }]);
      }
    }
  };

  const handleCellClick = async (staffId, date, planningState) => {
    if (userRole !== 'Administrateur' && userRole !== 'Régulation' && userRole !== 'Direction' && userRole !== 'Responsable exploitation') {
      return planningState; // retourne l'état actuel sans changement
    }
    const formattedDate = format(addDays(startDate, date + (selectedWeek * 7)), "yyyy-MM-dd'T00:00:00.000Z");
    const existingPlanning = planningState.find((p) => p.staff_id === staffId && p.date === formattedDate);

    try {
      const newStatus = selectedStatus;
      if (existingPlanning) {
        await axios.put(`${BASE_URL}planning/${existingPlanning.id}`, {
          date: formattedDate,
          status: newStatus,
        }, { headers });
        return planningState.map((p) => (p.id === existingPlanning.id ? { ...p, status: newStatus } : p));
      }
      const response = await axios.post(`${BASE_URL}planning`, {
        staff_id: staffId,
        date: formattedDate,
        status: newStatus,
      }, { headers });
      return [...planningState, {
        id: response.data.id,
        staff_id: staffId,
        date: formattedDate,
        status: newStatus,
      }];
    }
    catch (error) {
      console.error('Error updating planning:', error);
      return planningState; // retourne l'état actuel en cas d'erreur
    }
  };

  const handleMouseUp = async () => {
    setIsSelecting(false);

    let newPlanningState = [...planning];

    for (const { staffId, dateIndex } of selectedCells) {
      newPlanningState = await handleCellClick(staffId, dateIndex, newPlanningState);
    }

    setPlanning(newPlanningState);
    setSelectedCells([]);
  };

  const renderDaysAndWeeks = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = addDays(startDate, i + (selectedWeek * 7));
      const weekNumber = format(date, 'I', { locale: fr });
      const day = format(date, 'EEEE', { locale: fr });
      const formattedDate = format(date, 'd MMMM', { locale: fr });
      days.push(
        <th className="annualPlanning_th" key={i}>
          SEMAINE {weekNumber}
          <br />
          {day}
          <br />
          {formattedDate}
        </th>
      );
    }
    return days;
  };

  const getDefaultStatusForDate = (date) => {
    const isSaturday = format(date, 'EEEE') === 'Saturday';
    const isSunday = format(date, 'EEEE') === 'Sunday';
    if (isSaturday || isSunday) {
      return 'R';
    }
    if (isFrenchHoliday(date)) {
      return 'F';
    }
    return '';
  };

  useEffect(() => {
    fetchStaff();
    fetchPlanning();
    fetchCompanies();
  }, [selectedYear]);

  useEffect(() => {
  }, [planning]);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const calculateDailyTotals = () => {
    const dailyTotals = Array(7).fill(null).map(() => ({
      DEA_CCA: 0,
      AA_CHAUFFEUR: 0,
      IDE: 0,
    }));

    filteredStaff.forEach((staffMember) => {
      for (let i = 0; i < 7; i++) {
        const date = addDays(startDate, i + (selectedWeek * 7));
        const formattedDate = format(date, "yyyy-MM-dd'T00:00:00.000Z");
        const existingPlanning = planning.find((p) => p.staff_id === staffMember.id && p.date === formattedDate);
        if (existingPlanning && existingPlanning.status === 'T') {
          if (categories.DEA_CCA.includes(staffMember.graduation)) {
            dailyTotals[i].DEA_CCA++;
          }
          if (categories.AA_CHAUFFEUR.includes(staffMember.graduation)) {
            dailyTotals[i].AA_CHAUFFEUR++;
          }
          if (categories.IDE.includes(staffMember.graduation)) {
            dailyTotals[i].IDE++;
          }
        }
      }
    });

    return dailyTotals;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // Si l'élément est déposé en dehors de la liste, ne faites rien
    if (!destination) {
      return;
    }

    // Si l'élément est déposé à la même place, ne faites rien
    if (
      source.droppableId === destination.droppableId
      && source.index === destination.index
    ) {
      return;
    }

    // Création d'une nouvelle liste reflétant l'ordre après glissement
    const reorderedFilteredStaff = Array.from(filteredStaff);
    const [removed] = reorderedFilteredStaff.splice(source.index, 1);
    reorderedFilteredStaff.splice(destination.index, 0, removed);

    setFilteredStaff(reorderedFilteredStaff);
    try {
      const response = await axios.put(`${BASE_URL}staff/order`, { order: reorderedFilteredStaff.map((staff) => staff.id) }, { headers });
      console.log(response.data);
    }
    catch (error) {
      console.error('Error saving new staff order:', error);
    }
  };

  const dailyTotals = calculateDailyTotals(categories);

  if (loading) {
    return <AmbysoftLoader />;
  }

  if (userRole === 'utilisateur') {
    return (
      <div className="annualPlanning">
        <div className="search-bar">
          <select value={selectedYear} onChange={handleYearChange}>
            {yearOptions.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <select value={selectedWeek} onChange={(e) => setSelectedWeek(Number(e.target.value))}>
            {Array.from({ length: 52 }, (_, index) => {
              const weekNumber = index + 1;
              return (
                <option key={weekNumber} value={weekNumber - 1}>
                  Semaine {weekNumber}
                </option>
              );
            })}
          </select>
        </div>
        <table className="annualPlanning__table">
          <thead className="annualPlanning__thead">
            <tr>
              <th className="annualPlanning__filtersValues ">{selectedCompany} <br />{selectedGraduation}</th>
              { renderDaysAndWeeks() }
            </tr>
          </thead>
          <tbody>
            {filteredStaff.map((staffMember) => (
              <tr className="annualPlanning__tr" key={staffMember.id}>
                <td className="annualPlanning__td annualPlanning__td--sticky">{staffMember.name} {staffMember.last_name}</td>
                {Array.from({ length: 7 }, (_, index) => {
                  const date = addDays(startDate, index + (selectedWeek * 7));
                  const formattedDate = format(date, "yyyy-MM-dd'T00:00:00.000Z");
                  const existingPlanning = planning.find((p) => p.staff_id === staffMember.id && p.date === formattedDate);
                  const dayStatus = existingPlanning ? existingPlanning.status : getDefaultStatusForDate(date);
                  return (
                    <td
                      className={`annualPlanning__td annualPlanning__td--${dayStatus}`}
                      key={index}
                      onMouseDown={() => handleMouseDown(staffMember.id, index)}
                      onMouseEnter={() => handleMouseEnter(staffMember.id, index)}
                      onMouseUp={handleMouseUp}
                    >
                      {dayStatus}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <div className="annualPlanning">
      <div className="search-bar">
        <TextField
          placeholder="Recherche par nom ou prénom"
          onChange={handleChange}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <button type="button" onClick={() => setShowExitedStaff((prev) => !prev)}>
          {showExitedStaff ? 'Voir l\'effectif actuel' : 'Voir l\'effectif parti'}
        </button>
        <select value={selectedYear} onChange={handleYearChange}>
          {yearOptions.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <select value={selectedWeek} onChange={(e) => setSelectedWeek(Number(e.target.value))}>
          {Array.from({ length: 52 }, (_, index) => {
            const weekNumber = index + 1;
            const isCurrentWeek = weekNumber === getISOWeek(new Date());
            const label = isCurrentWeek ? `Semaine ${weekNumber} (Actuelle)` : `Semaine ${weekNumber}`;
            return (
              <option key={weekNumber} value={weekNumber - 1}>
                {label}
              </option>
            );
          })}
        </select>
        <select onChange={(event) => setSelectedCompany(event.target.value)}>
          <option value="">Toutes les sociétés</option>
          {companies.map((company) => (
            <option key={company.id} value={company.code}>
              {company.code}
            </option>
          ))}
        </select>
        <select value={selectedGraduation} onChange={(e) => setSelectedGraduation(e.target.value)}>
          <option value="">Diplôme</option>
          <option value="DEA">DEA</option>
          <option value="CCA">CCA</option>
          <option value="IDE">IDE</option>
          <option value="AA">AA</option>
          <option value="CA">CHAUFFEUR</option>
          <option value="ADMINISTRATIF">ADMINISTRATIF</option>
        </select>
      </div>
      <div className="annualPlanning__statusButtons">
        {statusOptions.map((status) => (
          <button
            type="button"
            key={status}
            className={`annualPlanning__statusButton annualPlanning__statusButton--${status} ${selectedStatus === status ? 'selected' : ''}`}
            onClick={() => setSelectedStatus(status)}
          >
            {statusLabels[status]}
          </button>
        ))}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableStaff">
          {(provided) => (
            <table className="annualPlanning__table">
              <thead className="annualPlanning__thead">
                <tr>
                  <th className="annualPlanning__filtersValues">{selectedCompany} <br />{selectedGraduation}</th>
                  { renderDaysAndWeeks() }
                </tr>
              </thead>
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {filteredStaff.map((staffMember, index) => (
                  <Draggable key={staffMember.id} draggableId={String(staffMember.id)} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`annualPlanning__tr ${snapshot.isDragging ? 'dragging' : ''}`}
                      >
                        <td
                          className="annualPlanning__td annualPlanning__td--sticky annualPlanning__td--name"
                          {...provided.dragHandleProps}
                        >
                          {staffMember.name} {staffMember.last_name}
                        </td>
                        {Array.from({ length: 7 }, (_, index) => {
                          const date = addDays(startDate, index + (selectedWeek * 7));
                          const formattedDate = format(date, "yyyy-MM-dd'T00:00:00.000Z");
                          const existingPlanning = planning.find((p) => p.staff_id === staffMember.id && p.date === formattedDate);
                          const dayStatus = existingPlanning ? existingPlanning.status : getDefaultStatusForDate(date);
                          const isSelected = selectedCells.some((cell) => cell.staffId === staffMember.id && cell.dateIndex === index);
                          const selectionClass = isSelected ? 'annualPlanning__td--selected' : '';

                          return (
                            <td
                              className={`annualPlanning__td annualPlanning__td--${dayStatus} ${selectionClass}`}
                              key={index}
                              onMouseDown={() => handleMouseDown(staffMember.id, index)}
                              onMouseEnter={() => isSelecting && handleMouseEnter(staffMember.id, index)}
                              onMouseUp={handleMouseUp}
                            >
                              {dayStatus}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <tr>
                  <td className="annualPlanning__td annualPlanning__td--sticky planningCounter">Nombre de DEA</td>
                  {dailyTotals.map((total, index) => (
                    <td className="annualPlanning__td planningCounter" key={index}>{total.DEA_CCA}</td>
                  ))}
                </tr>
                <tr>
                  <td className="annualPlanning__td annualPlanning__td--sticky planningCounter">Nombre d'AA</td>
                  {dailyTotals.map((total, index) => (
                    <td className="annualPlanning__td planningCounter" key={index}>{total.AA_CHAUFFEUR}</td>
                  ))}
                </tr>
                <tr>
                  <td className="annualPlanning__td annualPlanning__td--sticky planningCounter">Nombre d'IDE</td>
                  {dailyTotals.map((total, index) => (
                    <td className="annualPlanning__td planningCounter" key={index}>{total.IDE}</td>
                  ))}
                </tr>

              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default React.memo(Planning);
