/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, MenuItem, Button, FormControlLabel, Checkbox, InputLabel, Select,
} from '@mui/material';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Holidays from 'date-holidays';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BASE_URL } from '../../../utils/api';
import Logo from '../../../images/logoJussieu.png';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
// import zonesData from '../../../utils/zones.json';
import priseEnChargeData from '../../../utils/priseEnCharge.json';
import agglomerationData from '../../../utils/agglomeration.json';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById('googleMapsScript');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDo6Q-gVHGzcy0VtTb-jo-Ojx56amB2Oi4&libraries=places';
    script.id = 'googleMapsScript';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }
  else if (callback) {
    callback();
  }
};

function Devis({ userToken }) {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTransportType, setSelectedTransportType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [logoDataURL, setLogoDataURL] = useState('');
  // Plus tard, lors de l'application des majorations
  const [tarifs, setTarifs] = useState([]);
  const [typesDeTarif, setTypesDeTarif] = useState([]);
  const [forfait, setForfait] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [address, setAddress] = useState('');
  const [recipientPostalCode, setRecipientPostalCode] = useState('');
  const [recipientCities, setRecipientCities] = useState([]);
  const [selectedRecipientCity, setSelectedRecipientCity] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [selectedDestinationCity, setSelectedDestinationCity] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [destinationPostalCode, setDestinationPostalCode] = useState('');
  const [destinationCities, setDestinationCities] = useState([]);
  const [distanceKm, setDistanceKm] = useState(0);
  const [isCalculateButtonDisabled, setIsCalculateButtonDisabled] = useState(true);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchTarifs = async () => {
    try {
      const res = await axios.get(`${BASE_URL}tarifs`, { headers });
      setTarifs(res.data.tarifs);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchTypeDeTarif = async () => {
    try {
      const res = await axios.get(`${BASE_URL}types-de-tarif`, { headers });
      setTypesDeTarif(res.data.typesDeTarif);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setCities(response.data);
    }
    catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  useEffect(() => {
    loadGoogleMapsScript(() => {
      console.log('Google Maps API script loaded.');
    });
  }, []);

  const calculateDistance = () => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [`${postalCode} ${selectedCity} ${address}`],
        destinations: [`${destinationPostalCode} ${selectedDestinationCity} ${destinationAddress}`],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          const distance = response.rows[0].elements[0].distance.value / 1000; // Convertir en km
          setDistanceKm(distance);
        }
        else {
          console.error('Erreur lors du calcul de la distance:', status);
        }
      }
    );
  };

  const handlePostalCodeChange = (e) => {
    const codePostal = e.target.value;
    setPostalCode(codePostal);
    setSelectedCity(''); // Reset selected city when postal code changes
    fetchCities(codePostal);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setIsCalculateButtonDisabled(!(e.target.value && destinationAddress));
  };

  const fetchRecipientCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setRecipientCities(response.data);
    }
    catch (error) {
      console.error('Error fetching recipient cities:', error);
    }
  };

  const fetchDestinationCities = async (codePostal) => {
    try {
      const response = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);
      setDestinationCities(response.data);
    }
    catch (error) {
      console.error('Error fetching recipient cities:', error);
    }
  };

  useEffect(() => {
    const checkEligibilityForSpecialForfaits = () => {
      // Extraire les deux premiers chiffres du code postal pour le département
      const departmentCode = postalCode.substring(0, 2);

      // Vérification pour le forfait de prise en charge
      for (const department in priseEnChargeData.PriseEnCharge) {
        const eligibleDepartmentsOrCities = priseEnChargeData.PriseEnCharge[department];

        // Vérifier si le département correspond ou si la ville est listée directement
        if (eligibleDepartmentsOrCities.includes(parseInt(departmentCode, 10)) || eligibleDepartmentsOrCities.includes(selectedCity)) {
          return 'PEC';
        }
      }

      // Vérification pour le forfait agglomération
      for (const department in agglomerationData.ForfaitAgglomeration) {
        for (const agglomeration in agglomerationData.ForfaitAgglomeration[department]) {
          const agglomerationCities = agglomerationData.ForfaitAgglomeration[department][agglomeration];
          if (Array.isArray(agglomerationCities) && agglomerationCities.includes(selectedCity)) {
            return 'Agglomération';
          }
        }
      }

      // Si la ville n'est éligible à aucun des deux forfaits spéciaux
      return 'Départemental';
    };

    const applyDefaultForfait = (category) => category; // Remove block statement and move returned value immediately after the `=>

    const updateForfait = () => {
      const selectedTarifs = tarifs.filter((tarif) => tarif.typedetarifid === parseInt(selectedTransportType, 10));
      let forfaitApplique = '';

      if (selectedTarifs.length > 0) {
        const categories = selectedTarifs.map((tarif) => tarif.categorie);
        // Supposons que tous les tarifs filtrés ont la même catégorie pour simplifier
        const category = categories[0];

        if (['Agglomération', 'Départemental', 'PEC'].includes(category)) {
          forfaitApplique = checkEligibilityForSpecialForfaits(category);
        }
        else {
          forfaitApplique = applyDefaultForfait(category); // Passez la catégorie à la fonction
        }
      }

      setForfait(forfaitApplique);
    };

    updateForfait();
  }, [selectedCity, postalCode, selectedTransportType, tarifs]);

  const handleRecipientPostalCodeChange = (e) => {
    const codePostal = e.target.value;
    setRecipientPostalCode(codePostal);
    setSelectedRecipientCity(''); // Reset selected recipient city when postal code changes
    fetchRecipientCities(codePostal);
  };

  const handleRecipientCityChange = (e) => {
    setSelectedRecipientCity(e.target.value);
  };

  const handleRecipientAddressChange = (e) => {
    setRecipientAddress(e.target.value);
  };

  const handleDestinationCityChange = (e) => {
    setSelectedDestinationCity(e.target.value);
  };

  const handleDestinationAddressChange = (e) => {
    setDestinationAddress(e.target.value);
    setIsCalculateButtonDisabled(!(address && e.target.value));
  };

  const handleDestinationPostalCodeChange = (e) => {
    const codePostal = e.target.value;
    setDestinationPostalCode(codePostal);
    setSelectedDestinationCity('');
    fetchDestinationCities(codePostal);
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchTarifs();
    fetchTypeDeTarif();
  }, []);

  const filteredCategories = tarifs
    .filter((tarif) => tarif.typedetarifid === parseInt(selectedTransportType, 10))
    .map((tarif) => tarif.categorie)
  // Supprimer les doublons
    .filter((value, index, self) => self.indexOf(value) === index);

  const categoryToTarifNameMapping = filteredCategories.reduce((acc, category) => {
    const tarifName = tarifs.find((tarif) => tarif.categorie === category)?.nom;
    if (tarifName) {
      acc[category] = tarifName; // Assigne le nom du tarif à la catégorie
    }
    return acc;
  }, {});

  // Mettre à jour l'état sélectionné lorsque le type de transport change
  useEffect(() => {
    setSelectedCategory('');
  }, [selectedTransportType]);

  const handleExpediteurChange = (e, value) => {
    setSelectedCompany(value);
  };

  const handleTransportTypeChange = (e) => {
    setSelectedTransportType(e.target.value);
  };

  const getBase64Image = (imgUrl, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', imgUrl);
    xhr.responseType = 'blob';
    xhr.send();
  };

  useEffect(() => {
    getBase64Image(Logo, (base64Img) => {
      setLogoDataURL(base64Img);
    });
  }, []);

  const handleEstimate = (e) => {
    e.preventDefault();

    const transportTypeId = parseInt(selectedTransportType, 10); // S'assurer que c'est un nombre

    // Trouver l'objet dans typesDeTarif correspondant à l'ID
    const foundType = typesDeTarif.find((type) => type.id === transportTypeId);

    // Utiliser le champ 'nom' de l'objet trouvé pour afficher le nom du type dans le devis
    const typeName = foundType ? foundType.nom : 'Type non spécifié';

    // Récupérer les données du formulaire
    const formData = new FormData(e.target);
    const estimateNumber = Math.floor(Math.random() * 1000000); // Génère un numéro de devis aléatoire
    const estimateDate = new Date().toLocaleDateString(); // Récupère la date du jour
    function isHolidayOrWeekend(departureDateTime) {
      const hd = new Holidays('FR');
      hd.init('FR');
      const holidays = hd.getHolidays(departureDateTime.getFullYear());
      const dateStr = departureDateTime.toISOString().split('T')[0];
      const isHoliday = holidays.some((holiday) => holiday.date === dateStr);

      const isSunday = departureDateTime.getDay() === 0;
      // Samedi après 12h jusqu'à minuit
      const isSaturdayAfterNoon = departureDateTime.getDay() === 6 && departureDateTime.getHours() >= 12;

      return isHoliday || isSunday || isSaturdayAfterNoon;
    }

    // Utilisation de la fonction modifiée
    const transportDate = new Date(formData.get('date'));
    const departureTime = formData.get('departureTime'); // "HH:MM"
    const [hours, minutes] = departureTime.split(':').map(Number);
    const departureDateTime = new Date(transportDate);
    departureDateTime.setHours(hours, minutes, 0, 0);

    const isSundayOrHoliday = isHolidayOrWeekend(transportDate, departureTime);

    const formattedDate = transportDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
    const distance = parseFloat(formData.get('distance'));
    const waitingTime = parseFloat(formData.get('waitingTime')); // Notez le "let" ici au lieu de "const"
    const roundTrip = formData.get('roundTrip') !== null;
    const arrivalTime = formData.get('arrivalTime');
    const tolls = parseFloat(formData.get('tolls')); // Récupérer les péages
    const nonReimbursableSupplement = parseFloat(formData.get('nonReimbursableSupplement')); // Récupérer le supplément non remboursable
    const companyName = formData.get('companyName');
    const companyAddress = formData.get('companyAddress');
    const companyPhone = formData.get('companyPhone');
    const companyEmail = formData.get('companyEmail');
    const companySiret = formData.get('companySiret');
    const companyIdentificationNumber = formData.get('companyIdentification');
    const companyVATNumber = formData.get('companyTva');
    const companyIBAN = formData.get('companyIban');
    const companyBIC = formData.get('companyBic');
    const companyAPE = formData.get('companyApe');
    const companyBoitePostale = formData.get('companyPostal');
    const recipientName = formData.get('recipientName');
    const recipientAddresss = formData.get('recipientAddress');
    const recipientPhone = formData.get('recipientPhone');
    const recipientEmail = formData.get('recipientEmail');
    const comment = formData.get('comment');
    const issuingCompanyDetails = `${companyName}\n${companyAddress}\n${companyBoitePostale}\n${companyPhone}\n${companyEmail}\nSIRET: ${companySiret}\nCode APE: ${companyAPE}\nN° d'identification: ${companyIdentificationNumber}\nN° de TVA intra: ${companyVATNumber}\nIBAN: ${companyIBAN}\nBIC: ${companyBIC}`;
    const recipientDetails = `${recipientName}\n${recipientAddresss}\n${recipientPhone}\n${recipientEmail}`;

    // Arrondir le temps d'attente au quart d'heure le plus proche

    const tarifApplicable = tarifs.find((tarif) => tarif.typedetarifid === selectedTransportType
        && (selectedCategory ? tarif.categorie === selectedCategory : tarif.categorie.toLowerCase() === forfait.toLowerCase()));

    if (!tarifApplicable) {
      console.error('Aucun tarif applicable trouvé pour le type de transport et la catégorie sélectionnés');
      return;
    }

    const isParaHC = tarifApplicable.categorie === 'PARAHC' || tarifApplicable.categorie === 'PARAC'; // Vérifiez si le tarif est pour PARAHC
    const isParacOrParahc = isParaHC;
    const isUPH = tarifApplicable.categorie === 'UPH';
    const isAgglomerationOrDepartemental = tarifApplicable.categorie === 'Agglomération' || tarifApplicable.categorie === 'Départemental';

    const baseCharge = parseFloat(tarifApplicable.base);
    let distanceCharge;
    if (isAgglomerationOrDepartemental) {
      // Pour "Agglomération" et "Départemental", facturer à partir du 3e km
      distanceCharge = distance > 3 ? (distance - 3) * parseFloat(tarifApplicable.basekm) : 0;
    }
    else if (isUPH) {
      // Pour "UPH", ne facturer que la distance au-delà de 20 km
      distanceCharge = distance > 20 ? (distance - 20) * parseFloat(tarifApplicable.basekm) : 0;
    }
    else {
      // Calcul standard pour les autres catégories
      distanceCharge = distance * parseFloat(tarifApplicable.basekm);
    }

    let kilometresFactures;
    if (isAgglomerationOrDepartemental) {
      kilometresFactures = distance > 3 ? distance - 3 : 0;
    }
    else if (isUPH) {
      kilometresFactures = distance > 20 ? distance - 20 : 0;
    }
    else {
      kilometresFactures = distance; // Pour les autres catégories, tous les kilomètres sont facturés
    }

    const tvaRate = selectedTransportType === 2 ? parseFloat(tarifApplicable.tva) : 0; // Supposons que l'ID 2 correspond à VSL

    let valorisationTrajetCourtCharge = 0;
    const conditions = tarifApplicable.tarifscourttrajet.map((trajet) => ({
      min: trajet.condition.includes('>') ? parseFloat(trajet.condition.split('>')[1].split('≤')[0]) : 0,
      max: trajet.condition.includes('≤') ? parseFloat(trajet.condition.split('≤')[1]) : Infinity,
      tarif: parseFloat(trajet.tarif)
    }));

    // Triez les conditions par ordre croissant de 'min' pour s'assurer que la logique s'applique correctement
    conditions.sort((a, b) => a.min - b.min);

    for (const condition of conditions) {
      if (distance > condition.min && distance <= condition.max) {
        valorisationTrajetCourtCharge = condition.tarif;
        break; // Arrêtez la boucle une fois la condition correspondante trouvée
      }
    }

    // Calculer les tarifs spécifiques à la mise à disposition
    let miseADispositionRate = 0;
    if (selectedTransportType === 'Mise à disposition') {
      miseADispositionRate = parseFloat(formData.get('tarif')) || 0;
    }

    // Après avoir récupéré les données du formulaire et défini les bases (baseCharge, distanceCharge, etc.)
    let majorationTranche25km = 0;
    const isNight = departureTime > '20:00' || departureTime < '08:00' || arrivalTime > '20:00' || arrivalTime < '08:00';
    const numberOfPeople = parseInt(formData.get('numberOfPeople') || '1', 10);
    const tpmr = formData.get('tpmr') === 'on';
    const aeroport = formData.get('aeroport') === 'on';
    const premature = formData.get('premature') === 'on';

    // Initialisation des variables pour chaque majoration
    let nightRate = 0; let holidayRate = 0; let aeroportMajoration = 0; let prematureMajoration = 0; let tpmrMajoration = 0;
    const waitingMajoration = tarifApplicable.majorations.find((majoration) => majoration.nom === 'Attente 15 min');
    const waitingRate = waitingMajoration ? parseFloat(waitingMajoration.valeur) : 0;

    // Parcourir les majorations disponibles pour le tarif applicable
    tarifApplicable.majorations.forEach((majoration) => {
      switch (majoration.nom) {
        case 'Nuit':
          if (isNight) nightRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
          break;
        case 'Dim/férié':
          if (isSundayOrHoliday) holidayRate = (baseCharge + distanceCharge + valorisationTrajetCourtCharge) * (parseFloat(majoration.valeur) / 100);
          break;
        case 'Aéroport/Gare':
          if (aeroport) aeroportMajoration = parseFloat(majoration.valeur);
          break;
        case 'Prématuré':
          if (premature) prematureMajoration = parseFloat(majoration.valeur);
          break;
        case 'TPMR':
          if (tpmr) tpmrMajoration = parseFloat(majoration.valeur);
          break;
        case 'Nuit/Férié/Week-end':
          if (isParaHC && (isNight || isSundayOrHoliday)) {
            // Appliquer cette majoration spécifiquement à la valorisation trajet court pour PARAHC
            valorisationTrajetCourtCharge *= (1 + parseFloat(majoration.valeur) / 100);
          }
          break;
        default:
          break;
      }
    });

    if (isParacOrParahc && distance > 100) {
      // Trouver la valeur de la majoration par tranche de 25 km
      const valeurMajoration25km = parseFloat(tarifApplicable.majorations.find((majoration) => majoration.nom === 'Tranche 25km')?.valeur || 0);

      // Calculer combien de tranches de 25 km au-delà de 100 km
      const tranches = Math.floor((distance - 100) / 25);

      // Calculer la majoration pour les distances au-delà de 100 km
      majorationTranche25km = tranches * valeurMajoration25km;
    }

    let remise = 0;
    if (numberOfPeople === 2) {
      remise = parseFloat(tarifApplicable.reductiondeuxpersonnes);
    }
    else if (numberOfPeople === 3) {
      remise = parseFloat(tarifApplicable.reductiontroispersonnes);
    }

    // Calcul du total des majorations pour l'attente
    const waitingRateTotal = waitingTime * waitingRate;

    // Inclure les lignes des majorations dans le tableau des détails
    const majorationsTableLines = [];

    if (nightRate > 0) {
      majorationsTableLines.push(['Tarif de nuit', `${nightRate.toFixed(2)} €`, '']);
    }
    if (holidayRate > 0) {
      majorationsTableLines.push(['Tarif dimanche/jour férié', `${holidayRate.toFixed(2)} €`, '']);
    }
    if (aeroport && aeroportMajoration > 0) {
      majorationsTableLines.push(['Tarif Aéroport', `${aeroportMajoration.toFixed(2)} €`, '']);
    }
    if (premature && prematureMajoration > 0) {
      majorationsTableLines.push(['Tarif Prématuré', `${prematureMajoration.toFixed(2)} €`, '']);
    }
    if (tpmr && tpmrMajoration > 0) {
      majorationsTableLines.push(['Majoration TPMR', `${tpmrMajoration.toFixed(2)} €`, '']);
    }
    if (waitingRateTotal > 0) {
      majorationsTableLines.push(['Tarif d\'attente', `${waitingRateTotal.toFixed(2)} €`, '']);
    }

    // Ajouter tous les tarifs pour obtenir l'estimation totale, incluant les majorations calculées
    let estimate = baseCharge + distanceCharge + waitingRateTotal + tolls + nonReimbursableSupplement + miseADispositionRate + valorisationTrajetCourtCharge + majorationTranche25km + nightRate + holidayRate + aeroportMajoration + prematureMajoration + tpmrMajoration;
    // Ajoutez ici le calcul des majorations

    // Appliquez la remise basée sur le nombre de personnes avant de calculer le total TTC
    let remisePercent = 0;
    if (numberOfPeople === 2) {
      remisePercent = parseFloat(tarifApplicable.reductiondeuxpersonnes);
    }
    else if (numberOfPeople === 3) {
      remisePercent = parseFloat(tarifApplicable.reductiontroispersonnes);
    }

    if (remisePercent > 0) {
      const remiseAmount = estimate * (remisePercent / 100);
      estimate -= remiseAmount;
      // Ajoutez la ligne de remise dans detailsTable si applicable
    }

    // Calculez le total TTC si le type de transport est VSL
    let totalWithTVA = estimate;
    if (tvaRate > 0) {
      const tvaAmount = estimate * (tvaRate / 100);
      totalWithTVA += tvaAmount;
      // Ajoutez ici la ligne de TVA dans detailsTable
    }
    // Vérifier si l'option aller-retour est cochée
    let roundTripRate = 0;
    // Vérifier si l'option aller-retour est cochée
    if (roundTrip) {
      // Ajouter seulement une fois les frais de base et de distance pour le retour, sans les multiplier par 2
      roundTripRate = baseCharge + distanceCharge; // Ceci est déjà le coût total pour un trajet, pas besoin de multiplier par 2
      // Ajouter le tarif de retour à l'estimation totale
      estimate += roundTripRate; // Ceci ajoute le coût de retour, équivalent au coût de l'aller, à l'estimation totale
    }
    const validityDate = new Date();
    validityDate.setMonth(validityDate.getMonth() + 1);

    // Créer le tableau des détails en fonction du mode de transport
    // Initialisation du tableau des détails avec les lignes qui s'afficheront toujours
    const detailsTableHeaders = selectedTransportType === 2 // Si le type sélectionné est VSL
      ? [{ text: 'Détails', fillColor: '#cccccc' }, { text: 'Tarif HT', fillColor: '#cccccc' }, { text: `Tarif TTC (TVA ${tvaRate}%)`, fillColor: '#cccccc' }]
      : [{ text: 'Détails', fillColor: '#cccccc' }, { text: 'Tarif HT ', fillColor: '#cccccc' }, { text: 'Tarif TTC', fillColor: '#cccccc' }];

    let detailsTable = [detailsTableHeaders];

    if (majorationTranche25km > 0) {
      detailsTable.push(['Majoration tranche 25 km', `${majorationTranche25km.toFixed(2)} €`, '']);
    }

    // Ajout conditionnel des lignes du tableau
    if (baseCharge > 0) {
      detailsTable.push(selectedTransportType === 2
        ? ['Prise en charge', `${baseCharge.toFixed(2)} €`, `${(baseCharge * (1 + tvaRate / 100)).toFixed(2)} €`]
        : ['Prise en charge', `${baseCharge.toFixed(2)} €`, '']);
    }

    if (distanceCharge > 0) {
      detailsTable.push(selectedTransportType === 2
        ? [
          'Tarif kilométrique',
          `${kilometresFactures} km à ${tarifApplicable.basekm} €/km : ${distanceCharge.toFixed(2)} €`, // Affiche le nombre de km facturés
          `${(distanceCharge * (1 + tvaRate / 100)).toFixed(2)} €`
        ]
        : [
          'Tarif kilométrique',
          `${kilometresFactures} km à ${tarifApplicable.basekm} €/km : ${distanceCharge.toFixed(2)} €`, // Affiche également pour les autres types si nécessaire
          ''
        ]);
    }

    // Conditionnellement ajouter d'autres lignes basées sur leur valeur
    const label = (tarifApplicable.categorie === 'PARAC' || tarifApplicable.categorie === 'PARAHC') ? 'Tranche kilométrique' : 'Valorisation court trajet';

    if (valorisationTrajetCourtCharge > 0) {
      detailsTable.push(selectedTransportType === 2
        ? [label, `${valorisationTrajetCourtCharge.toFixed(2)} €`, `${(valorisationTrajetCourtCharge * (1 + tvaRate / 100)).toFixed(2)} €`]
        : [label, `${valorisationTrajetCourtCharge.toFixed(2)} €`, '']);
    }
    if (tolls > 0) {
      detailsTable.push(selectedTransportType === 2
        ? ['Péages', `${tolls.toFixed(2)} €`, `${(tolls * (1 + tvaRate / 100)).toFixed(2)} €`]
        : ['Péages', `${tolls.toFixed(2)} €`, '']);
    }
    if (nonReimbursableSupplement > 0) {
      detailsTable.push(selectedTransportType === 2
        ? ['Supplément non remboursable', `${nonReimbursableSupplement.toFixed(2)} €`, `${(nonReimbursableSupplement * (1 + tvaRate / 100)).toFixed(2)} €`]
        : ['Supplément non remboursable', `${nonReimbursableSupplement.toFixed(2)} €`, '']);
    }
    if (roundTripRate > 0) {
      detailsTable.push(selectedTransportType === 2
        ? ['Tarif retour', `${roundTripRate.toFixed(2)} €`, `${(roundTripRate * (1 + tvaRate / 100)).toFixed(2)} €`]
        : ['Tarif retour', `${roundTripRate.toFixed(2)} €`, '']);
    }
    if (miseADispositionRate > 0) {
      detailsTable.push(selectedTransportType === 2
        ? ['Tarification mise à disposition', `${miseADispositionRate.toFixed(2)} €`, `${(miseADispositionRate * (1 + tvaRate / 100)).toFixed(2)} €`]
        : ['Tarification mise à disposition', `${miseADispositionRate.toFixed(2)} €`, '']);
    }

    if (remise > 0) {
      detailsTable.push(['Remise pour nombre de personnes', `-${remise.toFixed(2)} %`, '']);
    }

    // Ajouter les lignes de majoration si elles sont applicables
    majorationsTableLines.forEach((line) => {
      detailsTable.push(line);
    });

    // Ajouter la TVA et le total TTC si le type de transport est VSL
    if (selectedTransportType === 2) {
      detailsTable.push(['', '', '']);
      detailsTable.push(['Total HT', `${estimate.toFixed(2)} €`, '']);
      detailsTable.push(['TVA', '', `${(estimate * tvaRate / 100).toFixed(2)} €`]);
      detailsTable.push([{ text: 'Total TTC', fillColor: '#ffff00' }, '', { text: `${totalWithTVA.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }]);
    }
    else {
      detailsTable.push([{ text: 'Total', fillColor: '#ffff00' }, { text: `${estimate.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }, '']);
    }

    // Ajouter le texte de note à la fin du tableau des détails
    detailsTable.push([
      {
        colSpan: 3,
        italics: true,
        fontSize: 10,
        text: 'Veuillez noter que ce devis est basé sur les informations fournies et peut être sujet à des ajustements en fonction des conditions réelles du transport.',
      },
      {}, {}
    ]);

    // Ajouter les lignes spécifiques à la mise à disposition si c'est le mode de transport sélectionné
    if (selectedTransportType === 'Mise à disposition') {
      detailsTable = [
        [{ text: 'Détails', fillColor: '#cccccc' }, { text: 'TVA', fillColor: '#cccccc' }, { text: 'Tarif TTC', fillColor: '#cccccc' }],
        ['Tarification mise à disposition', '', `${miseADispositionRate.toFixed(2)} €`],
        [{ text: 'Total', fillColor: '#ffff00' }, '', { text: `${miseADispositionRate.toFixed(2)} €`, bold: true, fillColor: '#ffff00' }],
        [
          {
            colSpan: 3,
            italics: true,
            fontSize: 10,
            text: 'Veuillez noter que ce devis est basé sur les informations fournies et peut être sujet à des ajustements en fonction des conditions réelles du transport. Le paiment de celui ci devra être effectué 24h avant la prestation.',
          },
          {},
          {},
        ],
      ];
    }
    // Créer le PDF
    const docDefinition = {
      content: [
        {
          columns: [
            {
              image: logoDataURL,
              width: 200,
              alignment: 'top'
            },
            [
              {
                text: `Date du devis: ${estimateDate}`,
                alignment: 'right'
              },
              {
                text: `Numéro de devis: ${estimateNumber}`,
                alignment: 'right'
              },
              {
                text: `Date de validité: ${validityDate.toLocaleDateString()}`,
                alignment: 'right'
              }
            ]
          ]
        },
        {
          columns: [
            { text: issuingCompanyDetails, style: 'subheader' },
            { text: recipientDetails, style: 'subheader', alignment: 'right' }
          ]
        },
        { text: `Object: Demande de tarification en ${typeName}`, margin: [0, 20, 0, 0] },
        { text: '', margin: [0, 10, 0, 0] },
        { text: 'Madame, Monsieur,', margin: [0, 0, 0, 0] },
        { text: `Nous avons le plaisir de vous adresser notre devis pour votre demande de tarification en ${typeName}, le ${formattedDate} avec une heure de début prévue pour ${departureTime} et une heure de fin prévue pour ${arrivalTime}`, margin: [0, 0, 0, 0] },
        { text: '', margin: [0, 10, 0, 0] },
        {
          style: 'tableExample',
          table: {
            body: detailsTable,
          },
        },
        { text: `Information: ${comment || ''}`, margin: [0, 10, 0, 0] },
        { text: '', margin: [0, 10, 0, 0] },
        { text: 'Nous restons à votre disposition pour toute information complémentaire.', margin: [0, 0, 0, 0] },
        { text: '', margin: [0, 10, 0, 0] },
        { text: '\nDate et Signature du client précédée de la mention "Bon pour accord"', style: 'signature' },
      ],
      styles: {
        header: {
          fontSize: 10,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          fontSize: 10,
          margin: [0, 5, 0, 15]
        },
        signature: {
          fontSize: 10,
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          fontSize: 10,
          fillColor: '#cccccc',
        },
        tableTotal: {
          fontSize: 10,
          fillColor: '#ffff00',
        },
      },
      defaultStyle: {
        columnGap: 20,
      },
    };

    pdfMake.createPdf(docDefinition).download(`Devis du ${estimateDate}, ${recipientName}.pdf`);
  };

  if (loading) {
    return <AmbysoftLoader />;
  }
  return (
    <div className="modal">
      <h2 className="addModalTitle">Devis</h2>
      <form onSubmit={handleEstimate}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <Autocomplete
                options={companies}
                getOptionLabel={(option) => option.companyname}
                value={selectedCompany}
                onChange={handleExpediteurChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nom de la société"
                    type="text"
                    name="companyName"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Adresse"
                type="text"
                name="companyAddress"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.address || ''}
                value={selectedCompany?.address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Boite postale"
                type="text"
                name="companyPostal"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.boitepostale || ''}
                value={selectedCompany?.boitepostale}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Numéro de téléphone"
                type="text"
                name="companyPhone"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.phone || ''}
                value={selectedCompany?.phone}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Email"
                type="email"
                name="companyEmail"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.email || ''}
                value={selectedCompany?.email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="SIRET"
                type="text"
                name="companySiret"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.siret || ''}
                value={selectedCompany?.siret}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Code APE"
                type="text"
                name="companyApe"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.codeape || ''}
                value={selectedCompany?.codeape}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Numéro d'identification"
                type="text"
                name="companyIdentification"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={selectedCompany?.numeroidentification || ''}
                value={selectedCompany?.numeroidentification}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Numéro de TVA intra"
              type="text"
              sx={{ width: '100%' }}
              name="companyTva"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={selectedCompany?.numerotva || ''}
              value={selectedCompany?.numerotva}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="IBAN"
              sx={{ width: '100%' }}
              type="text"
              name="companyIban"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={selectedCompany?.iban || ''}
              value={selectedCompany?.iban}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="BIC"
              type="text"
              sx={{ width: '100%' }}
              name="companyBic"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={selectedCompany?.bic || ''}
              value={selectedCompany?.bic}
            />
          </Grid>

          <Grid item xs={12}>
            <h3 className="modal-subtitle">Destinataire</h3>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Nom / Prénom / Raison sociale"
                type="text"
                name="recipientName"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Code Postal Destinataire"
                type="text"
                name="recipientPostalCode"
                value={recipientPostalCode}
                onChange={handleRecipientPostalCodeChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                select
                label="Ville Destinataire"
                name="recipientCity"
                value={selectedRecipientCity}
                onChange={handleRecipientCityChange}
              >
                {recipientCities.map((city) => (
                  <MenuItem key={city.code} value={city.nom}>
                    {city.nom}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Adresse Destinataire"
                type="text"
                name="recipientAddress"
                value={recipientAddress}
                onChange={handleRecipientAddressChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Numéro de téléphone"
                type="text"
                name="recipientPhone"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <TextField
                label="Email"
                type="email"
                name="recipientEmail"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <h3 className="modal-subtitle">Détail de la mission</h3>
          <Grid item xs={14}>
            <FormControl margin="normal" fullWidth>
              <TextField
                select
                label="Mode de transport"
                name="type"
                value={selectedTransportType}
                onChange={handleTransportTypeChange}
              >
                {typesDeTarif.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.nom}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {filteredCategories.length > 0 && (
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="category-label">Tarification</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                value={selectedCategory}
                label="Catégorie"
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {Object.entries(categoryToTarifNameMapping).map(([category, tarifName]) => (
                  <MenuItem key={category} value={category}>
                    {tarifName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          )}

          {selectedTransportType === 'Mise à disposition' ? (
            <>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Heure de départ:"
                    type="time"
                    name="departureTime"
                    defaultValue="00:00"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Heure d'arrivée"
                    type="time"
                    name="arrivalTime"
                    defaultValue="00:00"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <Grid item xs={6}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Tarification mise à disposition"
                        type="number"
                        name="tarif"
                        required
                      />
                    </FormControl>
                  </Grid>
                </FormControl>
              </Grid>
            </>
          ) : null}

          {selectedTransportType !== 'Mise à disposition' ? (
            <>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Date de la mission"
                    type="date"
                    name="date"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Heure de départ:"
                    type="time"
                    name="departureTime"
                    defaultValue="00:00"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Heure d'arrivée"
                    type="time"
                    name="arrivalTime"
                    defaultValue="00:00"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    label="Code Postal prise en charge"
                    type="text"
                    name="postalCode"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    select
                    label="Ville prise en charge"
                    name="city"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.code} value={city.nom}>
                        {city.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    label="Adresse de prise en charge"
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleAddressChange}
                  />
                </FormControl>
              </Grid>
              <br />
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    label="Code Postal destination"
                    type="text"
                    name="destinationPostalCode"
                    value={destinationPostalCode}
                    onChange={handleDestinationPostalCodeChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    select
                    label="Ville de destination"
                    name="destinationCity"
                    value={selectedDestinationCity}
                    onChange={handleDestinationCityChange}
                  >
                    {destinationCities.map((city) => (
                      <MenuItem key={city.code} value={city.nom}>
                        {city.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    label="Adresse de destination"
                    type="text"
                    name="destinationAddress"
                    value={destinationAddress}
                    onChange={handleDestinationAddressChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }}
                  onClick={calculateDistance}
                  disabled={isCalculateButtonDisabled}
                >
                  Calculer la distance
                </Button>
              </Grid>

              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Distance (en Km)"
                    value={distanceKm}
                    onChange={(e) => setDistanceKm(e.target.value)}
                    type="number"
                    name="distance"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Quarts d'heure d'attente"
                    defaultValue="0"
                    type="number"
                    name="waitingTime"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue="0"
                    label="Péages"
                    type="number"
                    name="tolls"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue="0"
                    label="Supplément non remboursable"
                    type="number"
                    name="nonReimbursableSupplement"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=""
                    label="Commentaire"
                    type="text"
                    name="comment"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={<Checkbox name="roundTrip" />}
                  label="Retour"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel control={<Checkbox name="aeroport" />} label="Aéroport/Gare" />
              </Grid>
              {
              selectedTransportType === 1 && (
                <Grid item xs={2}>
                  <FormControlLabel
                    control={<Checkbox name="premature" />}
                    label="Prématuré"
                  />
                </Grid>
              )
            }

              {
            (selectedTransportType === 2 || selectedTransportType === 4) && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="number-of-people-label">Nombre de personnes</InputLabel>
                    <Select
                      labelId="number-of-people-label"
                      id="number-of-people-select"
                      name="numberOfPeople"
                      label="Nombre de personnes"
                      defaultValue=""
                    >
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={<Checkbox name="tpmr" />}
                    label="TPMR"
                  />
                </Grid>

              </>
            )
          }

            </>
          ) : null}
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }} variant="contained" color="primary" type="submit">
              Générer un devis
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default React.memo(Devis);
