import './homePageStyles.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import usePageTitle from '../hooks/usePageTitle';

function HomePage() {
  usePageTitle('DRIVESOFT | ACCUEIL');

  return (
    <div className="homePage">
      <div className="contentWrapper">
        <Link to="/about">
          <div className="buttonWrapper">
            <Button variant="contained" color="primary" className="learnMoreButton">
              Découvrir la solution
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
