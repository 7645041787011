/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import {
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Collapse
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { BASE_URL } from '../../../utils/api';
import './tasksManagementStyles.scss';

function Task({
  task, index, onDelete, onArchive, onUpdatePriority, onUpdateRole, userToken, userRole
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState(task.role_name);
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}roles`, { headers });
        setRoles(response.data);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des rôles', error);
      }
    };

    fetchRoles();
  }, []);

  const handleRoleChange = async (event) => {
    const newRoleName = event.target.value;
    try {
      await axios.put(`${BASE_URL}tasks/${task.id}/role`, { role_name: newRoleName }, { headers });
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Le rôle de la tâche a été mis à jour.',
      });
      setCurrentRole(newRoleName);
      onUpdateRole(task.id, newRoleName);
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour du rôle de la tâche', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue lors de la mise à jour du rôle de la tâche.',
      });
    }
  };

  const handleDelete = async () => {
    if (!['Direction', 'Administrateur'].includes(userRole)) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à supprimer les tâches.',
      });
      return;
    }
    try {
      await axios.delete(`${BASE_URL}tasks/${task.id}`, { headers });
      onDelete(task.id);
    }
    catch (error) {
      console.error('Erreur lors de la suppression de la tâche', error);
    }
  };

  const handleArchive = async () => {
    if (!['Direction', 'Administrateur'].includes(userRole)) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à archiver les tâches.',
      });
      return;
    }
    try {
      await axios.put(`${BASE_URL}tasks/${task.id}`, { status: 'archivé' }, { headers });
      onArchive(task.id);
    }
    catch (error) {
      console.error('Erreur lors de l\'archivage de la tâche', error);
    }
  };

  const handlePriorityChange = async (newPriority) => {
    try {
      await axios.put(`${BASE_URL}tasks/${task.id}`, { ...task, priority: newPriority }, { headers });
      onUpdatePriority(task.id, newPriority);
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la priorité', error);
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const cardClass = `task-card ${task.priority}`;
  const createdTimeAgo = formatDistanceToNow(new Date(task.created_at), { locale: fr });
  const updatedTimeAgo = formatDistanceToNow(new Date(task.updated_at), { locale: fr });

  return (
    <Draggable draggableId={task.id.toString()} index={index}>
      {(provided) => (
        <Card
          className={cardClass}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ mb: 2 }}
        >
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="div" gutterBottom>
                {task.field_name}
              </Typography>
              <IconButton onClick={handleToggleExpand} aria-label="expand">
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {task.comment}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Véhicule: {task.vehicule_code || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Créée par: {task.author_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task.status === 'à faire' ? `À faire depuis ${createdTimeAgo}` : `En cours depuis ${updatedTimeAgo}`}
              </Typography>
              {['Direction', 'Administrateur', 'Responsable exploitation'].includes(userRole) && (
              <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }}>
                <InputLabel id={`role-select-label-${task.id}`}>Rôle</InputLabel>
                <Select
                  labelId={`role-select-label-${task.id}`}
                  id={`role-select-${task.id}`}
                  value={currentRole}
                  onChange={handleRoleChange}
                  label="Rôle"
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}
              <div className="actions">
                <Button
                  variant={task.priority === 'forecast' ? 'contained' : 'outlined'}
                  color="info"
                  onClick={() => handlePriorityChange('forecast')}
                  sx={{ mr: 1 }}
                >
                  À prévoir
                </Button>
                <Button
                  variant={task.priority === 'low' ? 'contained' : 'outlined'}
                  color="success"
                  onClick={() => handlePriorityChange('low')}
                  sx={{ mr: 1 }}
                >
                  Basse
                </Button>
                <Button
                  variant={task.priority === 'medium' ? 'contained' : 'outlined'}
                  color="warning"
                  onClick={() => handlePriorityChange('medium')}
                  sx={{ mr: 1 }}
                >
                  Moyenne
                </Button>
                <Button
                  variant={task.priority === 'high' ? 'contained' : 'outlined'}
                  color="error"
                  onClick={() => handlePriorityChange('high')}
                  sx={{ mr: 1 }}
                >
                  Haute
                </Button>
                {task.status !== 'archivé' && (
                <Tooltip title="Archiver la tâche" placement="top">
                  <IconButton aria-label="archive" onClick={handleArchive}>
                    <ArchiveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                <Tooltip title="Supprimer la tâche" placement="top">
                  <IconButton
                    aria-label="delete"
                    onClick={handleDelete}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </Collapse>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
}

function Column({
  columnId, tasks, title, onDelete, onArchive, onUpdatePriority, onUpdateRole, isAuthorizedRole, userToken, userRole
}) {
  const priorityOrder = {
    high: 1,
    medium: 2,
    low: 3,
    forecast: 4, // À prévoir
  };

  const sortedTasks = tasks.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
  return (
    <Droppable droppableId={columnId}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="column"
        >
          <h2>{title}</h2>
          {sortedTasks.map((task, index) => (
            <Task
              key={task.id}
              task={task}
              index={index}
              onDelete={onDelete}
              onArchive={onArchive}
              onUpdatePriority={onUpdatePriority}
              onUpdateRole={onUpdateRole}
              isAuthorizedRole={isAuthorizedRole}
              userToken={userToken}
              userRole={userRole}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function IntegratedTaskPlanner({ userRole, userToken }) {
  const [columns, setColumns] = useState({
    todo: { title: 'À faire', taskIds: [] },
    inProgress: { title: 'En cours', taskIds: [] },
    done: { title: 'Terminé', taskIds: [] }
  });

  const [selectedVehicle, setSelectedVehicle] = useState('all');
  const [selectedRole, setSelectedRole] = useState('all');
  const [showTaskCreationModal, setShowTaskCreationModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [roles, setRoles] = useState([]);
  const [newTaskData, setNewTaskData] = useState({
    field_name: '',
    author_name: '',
    vehicule_code: '',
    status: 'à faire',
    role_name: userRole,
    procedure_id: 0,
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}roles`, { headers });
        setRoles(response.data);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des rôles', error);
      }
    };

    fetchRoles();
  }, []);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const handleOpenTaskCreationModal = () => {
    setNewTaskData({
      field_name: '',
      author_name: '',
      vehicule_code: '',
      status: 'à faire',
      role_name: userRole, // Assigner automatiquement le userRole de l'utilisateur
      procedure_id: 0,
    });

    setShowTaskCreationModal(true);
  };

  const handleCloseTaskCreationModal = () => setShowTaskCreationModal(false);
  const handleNewTaskDataChange = (e) => {
    const { name, value } = e.target;
    setNewTaskData({ ...newTaskData, [name]: value });
  };

  const toggleArchivedTasks = () => {
    setShowArchived(!showArchived);
  };

  useEffect(() => {
    const fetchVehicules = async () => {
      try {
        const response = await axios.get(`${BASE_URL}vehicules`, { headers });

        // Filtrer les véhicules sans exitdate
        const filteredVehicules = response.data.vehicule.filter((vehicule) => !vehicule.exitdate);

        // Trier les véhicules par code en ordre croissant (du plus petit au plus grand)
        const sortedVehicules = filteredVehicules.sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true }));

        setVehicules(sortedVehicules);
      }
      catch (error) {
        console.error('Erreur lors de la récupération des véhicules', error);
      }
    };

    fetchVehicules();
  }, []);

  const filterTasksByVehicle = (tasks, vehicle) => (vehicle === 'all' ? tasks : tasks.filter((task) => task.vehicule_code === vehicle));
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      if (response.data.tasks) {
        // Filtrage par rôle de l'utilisateur
        const filteredByUserRole = isAuthorizedRole()
          ? response.data.tasks // Les rôles autorisés voient toutes les tâches
          : response.data.tasks.filter((task) => task.role_name === userRole);

        // Filtrage par rôle sélectionné dans le filtre (si vous voulez conserver cette fonctionnalité)
        const filteredBySelectedRole = selectedRole === 'all'
          ? filteredByUserRole
          : filteredByUserRole.filter((task) => task.role_name === selectedRole);

        // Filtrage par véhicule
        const filteredByVehicle = selectedVehicle === 'all'
          ? filteredBySelectedRole
          : filteredBySelectedRole.filter((task) => task.vehicule_code === selectedVehicle);

        // Filtrage par recherche
        const filteredBySearch = searchQuery.trim() === ''
          ? filteredByVehicle
          : filteredByVehicle.filter((task) => task.field_name.toLowerCase().includes(searchQuery.toLowerCase()));

        // Organisation des tâches par colonne
        const newColumns = {
          todo: { title: 'À faire', taskIds: [] },
          inProgress: { title: 'En cours', taskIds: [] },
          done: { title: 'Terminé', taskIds: [] },
          archived: { title: 'Archivé', taskIds: [] }
        };

        filteredBySearch.forEach((task) => {
          if (task.status === 'à faire') {
            newColumns.todo.taskIds.push(task);
          }
          else if (task.status === 'en cours') {
            newColumns.inProgress.taskIds.push(task);
          }
          else if (task.status === 'terminé') {
            newColumns.done.taskIds.push(task);
          }
          else if (task.status === 'archivé') {
            newColumns.archived.taskIds.push(task);
          }
        });

        // Extraction des véhicules uniques à partir des tâches
        const uniqueVehicles = Array.from(new Set(
          response.data.tasks
            .filter((task) => task.status !== 'archivé') // Exclure les tâches archivées
            .map((task) => task.vehicule_code)
        )).filter(Boolean);

        // Trier les uniqueVehicles
        uniqueVehicles.sort((a, b) => {
          const aNum = parseInt(a, 10);
          const bNum = parseInt(b, 10);

          if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
            return aNum - bNum;
          } if (!Number.isNaN(aNum)) {
            return -1; // a est numérique, b ne l'est pas
          } if (!Number.isNaN(bNum)) {
            return 1; // b est numérique, a ne l'est pas
          }
          return a.localeCompare(b);
        });

        setVehicles(uniqueVehicles);

        // Application du filtre de véhicule aux colonnes
        const filteredColumns = {
          todo: { title: 'À faire', taskIds: filterTasksByVehicle(newColumns.todo.taskIds, selectedVehicle) },
          inProgress: { title: 'En cours', taskIds: filterTasksByVehicle(newColumns.inProgress.taskIds, selectedVehicle) },
          done: { title: 'Terminé', taskIds: filterTasksByVehicle(newColumns.done.taskIds, selectedVehicle) },
          archived: { title: 'Archivé', taskIds: filterTasksByVehicle(newColumns.archived.taskIds, selectedVehicle) }
        };

        setColumns(filteredColumns);
      }
      else {
        console.error('Format de données inattendu', response.data);
      }
    }
    catch (error) {
      console.error('Erreur lors de la récupération des tâches', error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTasks();
    }, 300); // Délai de 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, selectedVehicle, selectedRole, showArchived]);

  // eslint-disable-next-line no-unused-vars
  const updateTaskRole = (taskId, newRole) => {
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      Object.keys(newColumns).forEach((columnId) => {
        newColumns[columnId].taskIds = newColumns[columnId].taskIds.filter((task) => task.id !== taskId);
      });
      return newColumns;
    });
    fetchTasks();
  };

  const handleAddNewTask = async (status) => {
    try {
      const taskData = {
        ...newTaskData,
        status,
        role_name: userRole,
      };

      await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      handleCloseTaskCreationModal();
      fetchTasks();
    }
    catch (error) {
      console.error('Erreur lors de la création de la tâche', error);
    }
  };

  const handleArchiveTask = async (taskId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à modifié les tâches.',
      });
      return;
    }
    try {
      await axios.put(`${BASE_URL}tasks/${taskId}`, { status: 'archivé' }, { headers });
      fetchTasks();
    }
    catch (error) {
      console.error('Erreur lors de l\'archivage de la tâche', error);
    }
  };

  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleDeleteTask = (taskId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous n\'êtes pas autorisé à supprimer les tâches.',
      });
      return;
    }
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };

      Object.keys(newColumns).forEach((columnId) => {
        newColumns[columnId].taskIds = newColumns[columnId].taskIds.filter((task) => task.id !== taskId);
      });

      return newColumns;
    });
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const task = columns[source.droppableId].taskIds[source.index];

    const newColumns = { ...columns };

    newColumns[source.droppableId].taskIds.splice(source.index, 1);
    newColumns[destination.droppableId].taskIds.splice(destination.index, 0, task);

    const newStatus = destination.droppableId === 'todo'
      ? 'à faire'
      : destination.droppableId === 'inProgress'
        ? 'en cours'
        : destination.droppableId === 'done'
          ? 'terminé'
          : 'archivé';

    setColumns(newColumns);

    try {
      await axios.put(`${BASE_URL}tasks/${task.id}`, {
        ...task,
        status: newStatus
      }, { headers });
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche', error);
    }
  };

  const handleUpdatePriority = (taskId, newPriority) => {
    setColumns((prevColumns) => {
      const newColumns = { ...prevColumns };
      Object.keys(newColumns).forEach((columnId) => {
        newColumns[columnId].taskIds = newColumns[columnId].taskIds.map((task) => {
          if (task.id === taskId) {
            return { ...task, priority: newPriority };
          }
          return task;
        });
      });
      return newColumns;
    });
  };

  const renderColumns = () => {
    const columnIds = showArchived ? ['archived'] : ['todo', 'inProgress', 'done'];
    return columnIds.map((columnId) => {
      const column = columns[columnId];
      return (
        <Column
          key={columnId}
          columnId={columnId}
          title={column.title}
          tasks={column.taskIds}
          onDelete={handleDeleteTask}
          onArchive={handleArchiveTask}
          onUpdatePriority={handleUpdatePriority}
          onUpdateRole={updateTaskRole}
          isAuthorizedRole={isAuthorizedRole()}
          userToken={userToken}
          userRole={userRole}
        />
      );
    });
  };

  return (
    <>
      <div className="taskManagement">
        <div className="search-bar">
          <TextField
            label="Rechercher une tâche"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mr: 2, width: '300px' }}
          />
          <select value={selectedVehicle} onChange={handleVehicleChange}>
            <option value="all">Toutes les entités</option>
            {vehicles.map((code) => (
              <option key={code} value={code}>{code}</option>
            ))}
          </select>

          {['Direction', 'Administrateur', 'Responsable d\'exploitation'].includes(userRole) && (
            <select value={selectedRole} onChange={handleRoleChange}>
              <option value="all">Tous les rôles</option>
              {roles.map((role) => (
                <option key={role.id} value={role.name}>{role.name}</option>
              ))}
            </select>
          )}
          <Button variant="outlined" onClick={toggleArchivedTasks}>
            {showArchived ? 'Masquer' : 'Voir'} les tâches archivées
          </Button>
        </div>

        <Button variant="contained" style={{ backgroundColor: '#505154 ' }} onClick={handleOpenTaskCreationModal}>
          Ajouter une tâche
        </Button>
      </div>

      <Dialog open={showTaskCreationModal} onClose={handleCloseTaskCreationModal}>
        <DialogTitle>Ajouter une tâche</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            name="field_name"
            label="Nom de la tâche"
            variant="outlined"
            value={newTaskData.field_name}
            onChange={handleNewTaskDataChange}
            required
            sx={{ mb: 2, mt: 2 }}
          />
          <TextField
            fullWidth
            name="comment"
            label="Commentaire"
            variant="outlined"
            value={newTaskData.comment}
            onChange={handleNewTaskDataChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="author_name"
            label="Nom de l'auteur"
            variant="outlined"
            value={newTaskData.author_name}
            onChange={handleNewTaskDataChange}
          />
          <div className="form-control">
            <label htmlFor="vehicule-code-select">Code du véhicule</label>
            <select
              id="vehicule-code-select"
              name="vehicule_code"
              value={newTaskData.vehicule_code}
              onChange={handleNewTaskDataChange}
            >
              <option value="">Sélectionnez un véhicule</option>
              {vehicules.map((vehicule) => (
                <option key={vehicule.id} value={vehicule.code}>
                  {vehicule.code}
                </option>
              ))}
            </select>
          </div>
          {userRole === 'Administrateur' && (
            <div className="form-control">
              <label htmlFor="role-select">Attribuer au rôle</label>
              <select
                id="role-select"
                name="role_name"
                value={newTaskData.role_name}
                onChange={handleNewTaskDataChange}
              >
                <option value="">Sélectionnez un rôle</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ backgroundColor: '#505154 ', margin: 'auto' }} onClick={() => handleAddNewTask('à faire')}>
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="task-planner">
          {renderColumns()}
        </div>
      </DragDropContext>
    </>
  );
}

export default React.memo(IntegratedTaskPlanner);
