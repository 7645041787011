/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  TablePagination, // Importé pour la pagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { format } from 'date-fns';
import { BASE_URL } from '../../../../utils/api';
import usePageTitle from '../../../hooks/usePageTitle';
import AmbysoftLoader from '../../../AmbysoftLoader/AmbysoftLoader';
import BackButton from '../../../hooks/backButton';

function HistoriqueTable({ theme, userToken }) {
  usePageTitle('DRIVESOFT | HISTORIQUE');
  const [historique, setHistorique] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // États pour la pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  console.log(historique);

  const fetchHistorique = async () => {
    try {
      const response = await axios.get(`${BASE_URL}historique`, { headers });
      setHistorique(response.data.records);
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error fetching historique:', error);
      setIsLoading(false);
    }
  };

  const deleteHistorique = async (id) => {
    try {
      await axios.delete(`${BASE_URL}historique/${id}`, { headers });
      fetchHistorique();
    }
    catch (error) {
      console.error('Error deleting historique:', error);
    }
  };

  const formatDate = (date) => {
    const formatedDate = format(new Date(date), 'dd/MM/yyyy');
    return formatedDate;
  };

  useEffect(() => {
    fetchHistorique();
  }, []);

  const themeColor = theme === 'dark' ? '#feba00' : '#2C2C2C';
  const themeBackgroundColor = theme === 'dark' ? '#2C2C2C' : '#fff';

  // Fonction pour gérer le changement de page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Fonction pour gérer le changement de nombre de lignes par page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <TableContainer component={Paper} style={{ color: themeColor, backgroundColor: themeBackgroundColor }}>
      <BackButton />
      <h2 style={{ textAlign: 'center' }}>Historique</h2>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow style={{ color: '#feba00' }}>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Date
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Action
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Quantité
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Article
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Créateur
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Destinataire
            </TableCell>

            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Véhicule Destinataire
            </TableCell>

            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Raison
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Commentaire
            </TableCell>
            <TableCell style={{
              textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
            }}
            >Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historique
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((record) => (
              <TableRow key={record.historique_id}>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{formatDate(record.date)}
                </TableCell>
                <TableCell style={{ textAlign: 'center', alignItems: 'center' }}>
                  {record.type === 'add' ? (
                    <Tooltip title="Ajout quantité" arrow>
                      <IconButton>
                        <AddIcon style={{ color: 'green' }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Suppression quantité" arrow>
                      <IconButton>
                        <RemoveIcon style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{record.quantite}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{record.article_name}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{record.user_email}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >
                  {record.destinataire || ''}
                </TableCell>

                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >
                  {record.vehicule_destinataire || ''}
                </TableCell>

                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{record.raison}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >{record.commentaire}
                </TableCell>
                <TableCell style={{
                  textAlign: 'center', alignItems: 'center', color: themeColor, backgroundColor: themeBackgroundColor
                }}
                >
                  <Tooltip title="Supprimer" arrow>
                    <IconButton onClick={() => deleteHistorique(record.historique_id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* Ajout du composant TablePagination avec labels en français */}
      <TablePagination
        component="div"
        count={historique.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]} // Options pour le nombre de lignes par page
        labelRowsPerPage="Lignes par page"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`}
        backIconButtonText="Page précédente"
        nextIconButtonText="Page suivante"
      />
    </TableContainer>
  );
}

export default React.memo(HistoriqueTable);
