/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api'; // Assurez-vous que ce chemin est correct
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function RisquesExonerationManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES RISQUES / EXONÉRATIONS');
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [formData, setFormData] = useState({
    code_risque: '',
    code_exoneration: '',
    taux: '',
    commentaire: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}risques-exoneration`, { headers });
      setEntries(response.data.entries);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch entries', error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleOpen = (entry) => {
    if (entry) {
      setIsEditing(true);
      setSelectedEntry(entry);
      setFormData({ ...entry });
    }
    else {
      setIsEditing(false);
      setFormData({
        code_risque: '',
        code_exoneration: '',
        taux: '',
        commentaire: ''
      });
    }
    setOpen(true);
  };

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isEditing ? 'put' : 'post';
    const url = isEditing ? `${BASE_URL}risques-exoneration/${selectedEntry.id}` : `${BASE_URL}risques-exoneration`;

    try {
      await axios({
        method: method,
        url: url,
        data: formData,
        headers: headers // Ajoutez vos en-têtes ici
      });
      fetchEntries();
    }
    catch (error) {
      console.error('Failed to submit entry', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}risques-exoneration/${id}`, { headers });
      fetchEntries();
    }
    catch (error) {
      console.error('Failed to delete entry', error);
    }
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <IconButton color="primary" onClick={() => handleOpen(null)}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{isEditing ? 'Modifier l\'entrée' : 'Créer une nouvelle entrée'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              label="Code risque"
              value={formData.code_risque}
              onChange={(event) => setFormData({ ...formData, code_risque: event.target.value })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Code exonération"
              value={formData.code_exoneration}
              onChange={(event) => setFormData({ ...formData, code_exoneration: event.target.value })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Taux"
              value={formData.taux}
              onChange={(event) => setFormData({ ...formData, taux: event.target.value })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Commentaire"
              value={formData.commentaire}
              onChange={(event) => setFormData({ ...formData, commentaire: event.target.value })}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Code risque</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Code exonération</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Taux</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Commentaire</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell style={{ textAlign: 'center' }}>{entry.code_risque}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{entry.code_exoneration}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{entry.taux}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{entry.commentaire}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton color="primary" onClick={() => handleOpen(entry)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(entry.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(RisquesExonerationManager);
