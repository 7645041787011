/* eslint-disable react/prop-types */
import React from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
import {
  Box, Typography, List, ListItem, ListItemText
} from '@mui/material';
// import { BASE_URL } from '../../../../utils/api';

function AddTypeTarifModal({ typesDeTarif }) {
  // const [nom, setNom] = useState('');

  // const headers = {
  //   Authorization: `Bearer ${userToken}`,
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios.post(`${BASE_URL}types-de-tarif`, { nom }, { headers });
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Type de tarif ajouté',
  //       text: 'Le nouveau type de tarif a été créé avec succès.'
  //     });
  //     setNom(''); // Réinitialiser le champ de saisie
  //   }
  //   catch (error) {
  //     console.error('Erreur lors de l’ajout du type de tarif:', error);
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Erreur',
  //       text: 'Une erreur s’est produite lors de l’ajout du type de tarif.'
  //     });
  //   }
  // };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Types de Tarifs
      </Typography>
      <List dense>
        {typesDeTarif.map((type) => (
          <ListItem key={type.id}>
            <ListItemText primary={type.nom} />
          </ListItem>
        ))}
      </List>

      {/* <Typography variant="h6" gutterBottom component="div">
        Ajouter un Type de Tarif
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          id="nomTypeTarif"
          label="Nom du Type de Tarif"
          type="text"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Ajouter
        </Button>
      </form> */}
    </Box>
  );
}

export default React.memo(AddTypeTarifModal);
