/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, InputLabel, MenuItem, Select, Button, FormControl, IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { BASE_URL } from '../../../../../utils/api';
import ArticleImage from '../articleImage/articleImage';
import usePdfToImageConverter from '../../../../hooks/usePdfToImageConverter';

function UpdateArticle({ currentArticle, onArticleUpdated, userToken }) {
  const [categories, setCategories] = useState([]);
  const convertPdfToImage = usePdfToImageConverter();
  const [selectedCategory, setSelectedCategory] = useState(currentArticle.category_name);
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [formData, setFormData] = useState({
    picture: currentArticle.picture,
    category_name: currentArticle.category_id,
    name: currentArticle.name,
    quantity: currentArticle.quantity,
    min_quantity: currentArticle.min_quantity,
    qr_code: currentArticle.qr_code,
    conditionnement: currentArticle.conditionnement,
    prix_unite: currentArticle.prix_unite,
    prix_total: currentArticle.prix_total,
    date_limite: currentArticle.date_limite,
    fournisseur: currentArticle.fournisseur,
    url_fournisseur: currentArticle.url_fournisseur
  });

  useEffect(() => {
    setFormData({
      ...currentArticle,
      date_limite: currentArticle.date_limite ? currentArticle.date_limite.split('T')[0] : ''
    });
    setSelectedCategory(currentArticle.category_name);
  }, [currentArticle]);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    // Fonction pour charger les catégories depuis l'API
    async function fetchCategories() {
      try {
        const response = await axios.get(`${BASE_URL}categories`, { headers });
        setCategories(response.data.categories);
      }
      catch (error) {
        console.error('Error fetching categories:', error);
      }
    }

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    if (selectedValue === 'new') {
      setShowNewCategoryInput(true);
      setNewCategoryName('');
    }
    else {
      setShowNewCategoryInput(false);
      setFormData({ ...formData, category_name: selectedValue });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    if (name === 'quantity' || name === 'prix_unite') {
      const quantity = name === 'quantity' ? parseFloat(value) : parseFloat(formData.quantity);
      const prixUnite = name === 'prix_unite' ? parseFloat(value) : parseFloat(formData.prix_unite);

      const prixTotal = quantity * prixUnite;
      if (!Number.isNaN(prixTotal)) {
        updatedFormData.prix_total = prixTotal;
      }
    }

    if (name === 'date_limite') {
      updatedFormData.date_limite = value ? new Date(value).toISOString() : '';
    }

    setFormData(updatedFormData);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Contrôlez la taille du fichier (exemple pour 2MB maximum)
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    // Contrôlez le type de fichier (exemple pour les images PNG et JPEG)
    const validFileTypes = ['image/jpeg', 'image/png', 'image/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF image.');
      return;
    }

    try {
      // Convertir le PDF en image si c'est un PDF
      let fileToUpload = file;
      if (file.type === 'application/pdf') {
        const imageBlob = await convertPdfToImage(file);
        fileToUpload = new File([imageBlob], `${file.name.split('.').slice(0, -1).join('.')}.jpg`, { type: 'image/jpeg' });
      }
      const response = await axios.post(`${BASE_URL}articles/presigned`, { name: fileToUpload.name, type: fileToUpload.type }, { headers });
      const { url, fields } = response.data;

      // Step 2: Use the presigned URL to upload the file directly to S3
      const uploadData = new FormData(); // Renamed from formData to uploadData
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value); // Using the renamed variable here
      });
      uploadData.append('file', fileToUpload); // And here
      await axios.post(url, uploadData, { // And here as well
        headers: {
          'Content-Type': file.type
        }
      });

      // Set the picture field to the URL of the uploaded file
      const uploadedFileUrl = `${fields.key}`;
      setFormData({ ...formData, picture: uploadedFileUrl });
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const articlesData = {
        ...formData,
        category_name: showNewCategoryInput ? newCategoryName : formData.category_name,
        date_limite: formData.date_limite || null // Mettre la date limite à null si elle est vide
      };
      await axios.put(`${BASE_URL}articles/${currentArticle.article_id}`, articlesData, { headers });
      onArticleUpdated(articlesData);
    }
    catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const handleClearDate = () => {
    setFormData({ ...formData, date_limite: '' });
  };

  useEffect(
    () => {
    },
    [currentArticle]
  );

  return (
    <form noValidate autoComplete="off">
      <div className="thirdLine">
        <Box mb={2}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <ArticleImage key={formData.picture} imageKey={formData.picture} width={200} height={200} />
          </label>
        </Box>
      </div>
      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel id="category_name">Catégorie</InputLabel>
          <Select
            labelId="category_name"
            id="category_name"
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Catégorie"
            fullWidth
          >
            {categories.map((category) => (
              <MenuItem key={category.category_id} value={category.category_name}>
                {category.category_name}
              </MenuItem>
            ))}
            <MenuItem value="new">Ajouter une nouvelle catégorie</MenuItem>
          </Select>
          {showNewCategoryInput && (
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: '0.5rem' }}
            fullWidth
            id="new_category_name"
            name="new_category_name"
            label="Nouvelle catégorie"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          )}
        </FormControl>
      </Box>

      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="name"
          name="name"
          label="Nom"
          value={formData.name}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="quantity"
          name="quantity"
          label="Quantité"
          value={formData.quantity}
          onChange={handleInputChange}
          disabled
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="min_quantity"
          name="min_quantity"
          label="Quantité minimale"
          value={formData.min_quantity}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem', display: 'none' }}
          fullWidth
          id="qr_code"
          name="qr_code"
          label="Code QR"
          value={formData.qr_code}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="conditionnement"
          name="conditionnement"
          label="Conditionnement"
          value={formData.conditionnement}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_unite"
          name="prix_unite"
          label="Prix unitaire"
          value={formData.prix_unite}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="prix_total"
          name="prix_total"
          label="Prix Total"
          value={formData.prix_total}
          onChange={handleInputChange}
          disabled
        />
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          type="date"
          id="date_limite"
          name="date_limite"
          label="Date limite"
          value={formData.date_limite ? formData.date_limite.split('T')[0] : ''}
          onChange={handleInputChange}
        />
        <IconButton onClick={handleClearDate} aria-label="clear date">
          <ClearIcon />
        </IconButton>
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="fournisseur"
          name="fournisseur"
          label="Fournisseur"
          value={formData.fournisseur}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '0.5rem' }}
          fullWidth
          id="url_fournisseur"
          name="url_fournisseur"
          label="URL fournisseur"
          value={formData.url_fournisseur}
          onChange={handleInputChange}
        />
        <div className="sixLine">
          <Button type="submit" sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }} variant="contained" onClick={handleFormSubmit}>
            Mettre à jour
          </Button>
        </div>
      </Box>
    </form>
  );
}

export default React.memo(UpdateArticle);
