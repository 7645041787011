/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEyeSlash } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';
import AmbysoftLoader from '../AmbysoftLoader/AmbysoftLoader';

function ResetPassword() {
  usePageTitle('DRIVESOFT | RÉINITIALISATION DU MOT DE PASSE');
  const [newPassword, setNewPassword] = useState('');
  const { token } = useParams();
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    return regex.test(pwd);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
    setIsPasswordValid(validatePassword(event.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Activer le loader

    try {
      await axios.post(`${BASE_URL}password-reset/reset-password`, { token, newPassword });
      toast.success('Votre mot de passe a bien été modifié, vous allez être redirigé vers la page de connexion.');
      setTimeout(() => {
        window.location = '/logIn';
      }, 1500);
    }
    catch (error) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', error);
      toast.error('Une erreur est survenue lors de la réinitialisation du mot de passe.');
    }
    finally {
      setIsLoading(false); // Désactiver le loader
    }
  };

  return (
    <div className="login-container">
      {isLoading && <div><AmbysoftLoader /> </div>}
      <div className="stats-panel" />
      <div className="form-container">
        <h1>Changement du mot de passe</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="password-container">

            <input
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handlePasswordChange}
              required
              placeholder="Nouveau mot de passe"
              autoFocus
              className={`password-input ${isPasswordValid ? '' : 'error'}`}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="password-toggle"
            >
              <FaEyeSlash />
            </button>
          </div>
          {!isPasswordValid && (
            <div className="error-message">Le mot de passe doit contenir au moins 8 caractères dont une majuscule et un chiffre</div>
          )}
          <button className="buttonLogin" type="submit">Changer le mot de passe</button>
        </form>
      </div>
    </div>
  );
}

export default React.memo(ResetPassword);
