/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { BASE_URL } from '../../../utils/api';
import './userGridStyles.scss';

function userGrid(props) {
  const [totals, setTotals] = useState({});
  const [totalsMissions, setTotalsMissions] = useState({});
  const [workedHours, setWorkedHours] = useState({});
  const [selectedCompany, setSelectedCompany] = useState('ASJ');
  const [words, setWords] = useState([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('Chiffre d\'affaire par heure');
  const [itemsToDisplay, setItemsToDisplay] = useState(10);
  const [transports, setTransports] = useState([]);
  const [staff, setStaff] = useState([]);
  const [timeClock, setTimeClock] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
  const objectif = 130;
  const dateRange = props.dateRange;
  const userToken = props.userToken;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchTransports = async () => {
    try {
      const res = await axios.get(`${BASE_URL}transports`, { headers });
      setTransports(res.data.transports);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchStaff = async () => {
    try {
      const res = await axios.get(`${BASE_URL}staff`, { headers });
      setStaff(res.data.staff);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchTimeClock = async () => {
    try {
      const res = await axios.get(`${BASE_URL}timeclock`, { headers });
      setTimeClock(res.data.timeClockEntries);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTransports();
    fetchStaff();
    fetchTimeClock();
  }, []);

  const filteredData = transports.filter((obj) => {
    const date = new Date(obj.date);
    return date >= dateRange.startDate && date <= dateRange.endDate;
  });
  const selectedCompanyRef = useRef(selectedCompany);

  const fetchWords = () => {
    let chauffeurs = staff;
    if (selectedCompany) {
      chauffeurs = staff.filter((chauffeur) => chauffeur.company === selectedCompany);
    }
    chauffeurs.forEach((chauffeur) => {
      chauffeur['Chiffre d\'affaire par heure'] = (totals[chauffeur.name] / workedHours[chauffeur.name]).toFixed(2);
    });
    chauffeurs.sort((a, b) => ((a['Chiffre d\'affaire par heure'] > b['Chiffre d\'affaire par heure']) ? 1 : -1));
    return chauffeurs;
  };

  const calculateData = (newWords) => {
    const chauffeurTotals = {};
    const chauffeurTotalsMissions = {};
    const chauffeurWorkedHours = {};

    newWords.forEach((chauffeur) => {
      // Filtrer les données FacturesGlobales par le nom du chauffeur actuel et vérifier le statut
      const chauffeurResults = filteredData.filter((obj) => (obj.driver1 === `${chauffeur.name} ${chauffeur.last_name}`
         || obj.driver2 === `${chauffeur.name} ${chauffeur.last_name}`)
        && (obj.status === 'Terminé' || obj.status === 'Annuler facturé'));

      // Filtrer les données de pointage par l'ID du chauffeur et vérifier la plage de dates
      const chauffeurHoursResults = timeClock.filter((obj) => obj.staff_id === chauffeur.id);
      let hours = 0;

      const chauffeurHoursFiltered = chauffeurHoursResults.filter((obj) => {
        const clockInTime = new Date(`${obj.date.split('T')[0]}T${obj.clock_in_time}`);
        const clockOutTime = new Date(`${obj.date.split('T')[0]}T${obj.clock_out_time}`);
        return clockInTime >= dateRange.startDate && clockOutTime <= dateRange.endDate;
      });

      chauffeurHoursFiltered.forEach((pointeuse) => {
        const startDateString = pointeuse.date.split('T')[0];
        const startDateTimeString = `${startDateString}T${pointeuse.clock_in_time}`;
        const endDateTimeString = `${startDateString}T${pointeuse.clock_out_time}`;

        const start = new Date(startDateTimeString);
        const end = new Date(endDateTimeString);

        const diff = end - start; // Différence en millisecondes
        hours += diff / 3600000; // Conversion en heures
      });

      chauffeurWorkedHours[chauffeur?.name] = hours.toFixed(0);

      // Calculer le total pour le chauffeur actuel
      const missions = chauffeurResults.length;
      const total = chauffeurResults
        .map((obj) => parseFloat(obj.price || 0))
        .reduce((a, b) => a + b, 0)
        .toFixed(2);

      // Ajouter le total aux objets chauffeurTotals et chauffeurTotalsMissions
      chauffeurTotals[chauffeur?.name] = total;
      chauffeurTotalsMissions[chauffeur?.name] = missions;
    });

    return { chauffeurTotals, chauffeurTotalsMissions, chauffeurWorkedHours };
  };

  useEffect(() => {
    const newWords = fetchWords();
    selectedCompanyRef.current = selectedCompany;
    setWords(newWords);

    const { chauffeurTotals, chauffeurTotalsMissions, chauffeurWorkedHours } = calculateData(newWords);
    setTotals(chauffeurTotals);
    setTotalsMissions(chauffeurTotalsMissions);
    setWorkedHours(chauffeurWorkedHours);
  }, [selectedCompany, dateRange]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredWords = words.filter((word) => word.name.toLowerCase().includes(search.toLowerCase())
  || word.last_name.toLowerCase().includes(search.toLowerCase()));

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Sort the list of chauffeurs based on the selected option
  const sortedWords = filteredWords
    .filter((chauffeur) => {
      // If a company is selected, only return chauffeurs that belong to that company
      if (selectedCompany) {
        return chauffeur.company === selectedCompany;
      }
      // Otherwise, return all chauffeurs
      return true;
    })
    .sort((a, b) => {
      // Sort the chauffeurs using the sortBy state variable
      if (sortBy === 'Chiffre d\'affaire par heure') {
        return (totals[b.name] / workedHours[b.name] || 0) - (totals[a.name] / workedHours[a.name] || 0);
      } if (sortBy === 'Heures travaillées') {
        return workedHours[b.name] - workedHours[a.name];
      } if (sortBy === 'Missions par heure') {
        return (totalsMissions[b.name] / workedHours[b.name] || 0) - (totalsMissions[a.name] / workedHours[a.name] || 0);
      } if (sortBy === 'Missions') {
        return totalsMissions[b.name] - totalsMissions[a.name];
      } if (sortBy === 'Chiffre d\'affaire') {
        return totals[b.name] - totals[a.name];
      }
    });

  if (isLoading) {
    return <div className="loading">Chargement...</div>;
  }
  return (
    <>
      {isDesktopOrLaptop && (
      <div className="userGrid">
        <div className="search-wrapper">
          <form className="articles-search">
            <input type="text" className="articles-input" placeholder="Rechercher un employé" onChange={handleChange} />
          </form>
        </div>
        <div className="search-bar">
          <div>
            <select value={sortBy} onChange={handleSortChange}>
              <option className="option" value="Chiffre d'affaire par heure">Chiffre d'affaire /h</option>
              <option className="option" value="Missions par heure">Missions /h</option>
              <option className="option" value="Chiffre d'affaire">Chiffre d'affaire</option>
              <option className="option" value="Missions">Missions</option>
              <option className="option" value="Heures travaillées">Heure travaillés</option>
            </select>
            <select onChange={(event) => setSelectedCompany(event.target.value)}>
              <option value="ASJ">ASJ</option>
              <option value="APAP">APAP</option>
              <option value="A2L">A2L</option>
              <option value="SNAS">SNAS</option>
              <option value="SNAM">SNAM</option>
            </select>
          </div>
        </div>
        <div className="table-container">
          <div className="table-wrapper">
            <table className="fl-table userGrid">
              <thead>
                <tr>
                  <th>name</th>
                  <th>Prénom</th>
                  <th>Type</th>
                  <th>Chiffre d'affaire</th>
                  <th>Heures</th>
                  <th>Missions</th>
                  <th>Chiffre d'affaire par heure</th>
                  <th>Missions par heure</th>
                  <th>Objectif</th>
                </tr>
              </thead>

              {sortedWords.slice(0, itemsToDisplay).map((word) => {
                if (workedHours[word.name] > 0) {
                  return (
                    <tbody key={word.id} className="userGrid-body">
                      <tr>
                        <td>{word.name}</td>
                        <td>{word.last_name}</td>
                        <td>{word.graduation}</td>
                        <td>{totals[word?.name]}€</td>
                        <td>{workedHours[word.name]}h</td>
                        <td>{totalsMissions[word.name]}</td>
                        <td>{(Math.round(totals[word.name] / workedHours[word.name]))}€</td>
                        <td>{(totalsMissions[word.name] / workedHours[word.name]).toFixed(2)}</td>
                        <td>{totals[word.name] / workedHours[word.name] > objectif ? <DoneIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}</td>
                      </tr>
                    </tbody>
                  );
                }
                return null;
              })}
            </table>
            <div className="button-wrapper">
              {itemsToDisplay < sortedWords.length
            && <button className="more-results-btn" type="button" onClick={() => setItemsToDisplay(itemsToDisplay + 10)}>Plus de résultats</button>}
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default React.memo(userGrid);
