/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Avatar from '@mui/material/Avatar';
import { BASE_URL } from '../../../../utils/api';
import AmbysoftLoader from '../../../AmbysoftLoader/AmbysoftLoader';

const urlCache = {};

function VehiculeImage({ imageKey, selectedImageUrl, userToken }) {
  const [imgSrc, setImgSrc] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  function isPdfUrl(url) {
    try {
      const urlPath = (new URL(url)).pathname;
      return urlPath.endsWith('.pdf');
    }
    catch (error) {
      console.error('Erreur lors de la création de l\'objet URL:', error);
      return false;
    }
  }

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const isPdf = imgSrc && isPdfUrl(imgSrc);

  const fetchSignedUrl = async () => {
    if (urlCache[imageKey]) {
      setImgSrc(urlCache[imageKey]);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}vehicules/get-signed-url`, { key: imageKey }, { headers });
      urlCache[imageKey] = response.data.url; // Cache the URL
      setImgSrc(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (selectedImageUrl) {
      setImgSrc(selectedImageUrl);
    }
    else if (imageKey) {
      fetchSignedUrl();
    }
  }, [imageKey, selectedImageUrl]);

  const handleImageLoad = () => {
    setIsUploading(false);
  };

  return (
    <div>
      {isUploading && <AmbysoftLoader />}
      {isPdf ? (
        <Viewer fileUrl={imgSrc} />
      ) : (
        <Avatar
          onLoad={handleImageLoad}
          alt="vehiculeImg"
          variant="square"
          style={{
            cursor: 'pointer', width: 70, height: 70, margin: '0 50px'
          }}
          src={imgSrc}
        />
      )}
    </div>
  );
}

export default React.memo(VehiculeImage);
