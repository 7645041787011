/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import {
  Card, CardContent, CardActions, Typography, IconButton, Tooltip, Collapse,
  Select, MenuItem, FormControl, InputLabel, Grid, Badge, Chip, CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  format, isWithinInterval, parseISO, addDays,
} from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fr } from 'date-fns/locale';
import { BASE_URL } from '../../../utils/api';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import ProcedureResponsesModal from './userProcedures/proceduresResponses/proceduresResponsesModal';

function HistoriqueProcedures({
  userToken, userEmail, userRole,
}) {
  usePageTitle('DRIVESOFT | HISTORIQUE DES PROCÉDURES');
  const [historique, setHistorique] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [userCompany, setUserCompany] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [expandedProcedureId, setExpandedProcedureId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResponseId, setSelectedResponseId] = useState(null);
  const [nonRespondingVehicles, setNonRespondingVehicles] = useState({});
  const [loadingNonResponding, setLoadingNonResponding] = useState({});

  // États pour les filtres
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedVehicule, setSelectedVehicule] = useState('');

  // État pour la date sélectionnée
  const [selectedDate, setSelectedDate] = useState(new Date());

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy', { locale: fr });
  const formatDateFull = (date) => format(new Date(date), 'EEEE dd MMMM yyyy', { locale: fr });

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const filteredHistorique = useMemo(() => historique.filter((record) => {
    const vehicule = vehicules.find((v) => String(v.code) === String(record.vehicule_id));

    // Si le véhicule n'est pas trouvé, exclure l'enregistrement
    if (!vehicule) {
      return false;
    }

    const vehiculeMatch = selectedVehicule ? String(record.vehicule_id) === selectedVehicule : true;
    const companyMatch = selectedCompany ? vehicule.societe === selectedCompany : true;
    const submittedDate = parseISO(record.submitted_at);
    const dateMatch = format(submittedDate, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd');
    return vehiculeMatch && companyMatch && dateMatch;
  }), [historique, selectedCompany, selectedVehicule, selectedDate, vehicules]);

  const handleViewDetails = (responseId) => {
    setSelectedResponseId(responseId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedResponseId(null);
  };

  const handleCompanyFilterChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleVehiculeFilterChange = (event) => {
    setSelectedVehicule(event.target.value);
  };

  const handleDateChange = (direction) => {
    setSelectedDate((prevDate) => addDays(prevDate, direction === 'next' ? 1 : -1));
  };

  const fetchUserCompany = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}staff`, { headers });
      const userInfo = data.staff.find(
        (staff) => staff.email.trim().toLowerCase() === userEmail.trim().toLowerCase(),
      );
      if (userInfo) {
        setUserCompany(userInfo.company);
      }
    }
    catch (error) {
      console.error('Error fetching user company:', error);
    }
  };

  const fetchVehicules = async () => {
    try {
      const [vehiculesResponse, responsablesResponse] = await Promise.all([
        axios.get(`${BASE_URL}vehicules`, { headers }),
        axios.get(`${BASE_URL}staff`, { headers }),
      ]);

      const responsablesMap = new Map(
        responsablesResponse.data.staff.map((responsable) => [responsable.id, responsable]),
      );

      const vehiculesWithResponsables = vehiculesResponse.data.vehicule
        .filter((vehicule) => !vehicule.exit_date)
        .map((vehicule) => {
          const responsable = responsablesMap.get(vehicule.responsable_id);
          return { ...vehicule, responsable };
        })
        .sort((a, b) => a.code - b.code);

      let userVehicules = [];
      if (isAuthorizedRole()) {
        userVehicules = vehiculesWithResponsables;
      }
      else if (userRole === 'Responsable opérationnel') {
        // Si la société est 'ASJ', inclure 'APAP' également
        if (userCompany === 'ASJ') {
          userVehicules = vehiculesWithResponsables.filter(
            (vehicule) => ['ASJ', 'APAP'].includes(vehicule.societe),
          );
        }
        else {
          userVehicules = vehiculesWithResponsables.filter(
            (vehicule) => vehicule.societe === userCompany,
          );
        }
      }
      else {
        userVehicules = vehiculesWithResponsables.filter((vehicule) => {
          if (!vehicule.responsable || !vehicule.responsable.email) {
            return false;
          }
          const isResponsible = vehicule.responsable.email.trim().toLowerCase() === userEmail.trim().toLowerCase();
          return isResponsible;
        });
      }
      setVehicules(userVehicules);
    }
    catch (error) {
      console.error('Error fetching vehicles or responsables:', error);
    }
  };

  const fetchHistorique = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}procedure-responses`, { headers });
      setHistorique(data);
    }
    catch (error) {
      console.error('Error fetching procedure responses:', error);
    }
  };

  const fetchProcedures = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}procedures`, { headers });
      setProcedures(data);
    }
    catch (error) {
      console.error('Error fetching procedures:', error);
    }
  };

  const findVehiclesNotResponded = async (procedure) => {
    try {
      // Récupérer les réponses à la procédure
      const responses = await axios.get(
        `${BASE_URL}procedure-responses?procedureId=${procedure.id}`,
        { headers },
      );

      // Récupérer les équipes (crews)
      const crewsResponse = await axios.get(`${BASE_URL}crews`, { headers });
      const { crews } = crewsResponse.data;

      // Date sélectionnée
      const selectedDateStart = new Date(format(selectedDate, 'yyyy-MM-dd'));
      const selectedDateEnd = new Date(format(selectedDate, 'yyyy-MM-dd'));
      selectedDateEnd.setHours(23, 59, 59, 999);

      if (procedure.frequency === 'Quotidienne') {
        // Pour les procédures quotidiennes, on utilise la logique existante
        const validResponses = responses.data.filter((response) => {
          const responseDate = new Date(response.submitted_at);

          const isWithinDateRange = isWithinInterval(responseDate, {
            start: selectedDateStart,
            end: selectedDateEnd,
          });

          return isWithinDateRange;
        });

        const respondedVehicleIds = validResponses.map((response) => response.vehicule_id.toString());

        const vehiclesNotResponded = vehicules.filter((vehicule) => {
          const isResponded = respondedVehicleIds.includes(vehicule.code.toString());

          // Vérifier si le véhicule devait travailler (en utilisant la table crews)
          const crewForVehicle = crews.find(
            (crew) => crew.vehicule_id === vehicule.id
              && isWithinInterval(new Date(crew.date), {
                start: selectedDateStart,
                end: selectedDateEnd,
              }),
          );

          // Si pas d'équipe prévue pour ce véhicule, on le retire
          if (!crewForVehicle) {
            return false;
          }

          // Vérifier si le type de procédure correspond au crew_type ou au type du véhicule
          const procedureTypeMatch = procedure.types.some(
            (procType) => procType.name === crewForVehicle.crew_type || procType.name === vehicule.type,
          );

          return !isResponded && procedureTypeMatch;
        });

        return vehiclesNotResponded.map((v) => v.code);
      } if (['Quotidienne', 'Hebdomadaire', 'Bihebdomadaire', 'Mensuelle', 'Annuelle'].includes(procedure.frequency)) {
        const currentDate = new Date(selectedDate);
        currentDate.setHours(23, 59, 59, 999);

        // Calculer la période acceptable en fonction de la fréquence
        let periodInDays;
        switch (procedure.frequency) {
          case 'Quotidienne':
            periodInDays = 1;
            break;
          case 'Hebdomadaire':
            periodInDays = 7;
            break;
          case 'Bihebdomadaire':
            periodInDays = 14;
            break;
          case 'Mensuelle':
            periodInDays = 30;
            break;
          case 'Annuelle':
            periodInDays = 365;
            break;
          default:
            periodInDays = 0;
        }

        const vehiclesToCheck = vehicules.filter((vehicule) => {
          // Vérifier si le véhicule devait travailler (selon crews)
          const crewForVehicle = crews.find(
            (crew) => crew.vehicule_id === vehicule.id
              && isWithinInterval(new Date(crew.date), {
                start: selectedDateStart,
                end: selectedDateEnd,
              }),
          );

          // Si pas d'équipe prévue pour ce véhicule, on le retire
          if (!crewForVehicle) {
            return false;
          }

          // Vérifier si le type de procédure correspond au crew_type ou au type du véhicule
          const procedureTypeMatch = procedure.types.some(
            (procType) => procType.name === crewForVehicle.crew_type || procType.name === vehicule.type,
          );

          return procedureTypeMatch;
        });

        const vehiclesNotResponded = [];
        vehiclesToCheck.forEach((vehicule) => {
          // Trouver les réponses du véhicule pour cette procédure
          const vehicleResponses = responses.data.filter(
            (response) => response.vehicule_id === vehicule.code.toString(),
          );

          if (vehicleResponses.length === 0) {
            // Si aucune réponse, il est considéré comme non répondant
            vehiclesNotResponded.push(vehicule.code);
          }
          else {
            // Trouver la dernière date de réponse
            const lastResponseDate = new Date(
              Math.max(...vehicleResponses.map((r) => new Date(r.submitted_at))),
            );

            // Calculer la différence en jours entre la date sélectionnée et la dernière réponse
            const diffInDays = (currentDate - lastResponseDate) / (1000 * 60 * 60 * 24);

            if (diffInDays >= periodInDays) {
              // Véhicule non répondant
              vehiclesNotResponded.push(vehicule.code);
            }
          }
        });

        return vehiclesNotResponded;
      }
      // Pour les autres fréquences, on peut adapter la logique si nécessaire
      return [];
    }
    catch (error) {
      console.error('Erreur lors de la récupération des réponses', error);
      return [];
    }
  };
  const fetchNonRespondingVehicles = async (procedureId) => {
    setLoadingNonResponding((prev) => ({ ...prev, [procedureId]: true }));
    try {
      const procedure = procedures.find((proc) => proc.id === procedureId);
      if (!procedure) return;

      const vehiclesNotResponded = await findVehiclesNotResponded(procedure);
      setNonRespondingVehicles((prev) => ({
        ...prev,
        [procedureId]: vehiclesNotResponded,
      }));
    }
    catch (error) {
      console.error('Erreur lors de la récupération des véhicules non répondants', error);
    }
    setLoadingNonResponding((prev) => ({ ...prev, [procedureId]: false }));
  };

  const handleExpandClick = (procedureId) => {
    setExpandedProcedureId((prev) => (prev === procedureId ? null : procedureId));
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchUserCompany();
      await fetchVehicules();
      await fetchHistorique();
      await fetchProcedures();
      setIsLoading(false);
    };
    loadData();
  }, [userCompany]);

  // Recharger les véhicules non répondants lorsqu'on change de date
  useEffect(() => {
    procedures.forEach((procedure) => {
      fetchNonRespondingVehicles(procedure.id);
    });
  }, [selectedDate, procedures]);

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  // Filtrer les procédures pour exclure celles avec fréquence 'Ponctuelle'
  const filteredProcedures = procedures.filter(
    (procedure) => procedure.frequency !== 'Ponctuelle',
  );

  return (
    <div style={{ padding: '20px' }}>
      {/* Affichage de la date sélectionnée avec les boutons de navigation */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        style={{ marginBottom: '20px' }}
      >
        <Grid item>
          <IconButton onClick={() => handleDateChange('previous')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h5">{formatDateFull(selectedDate)}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleDateChange('next')}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Filtres par société et véhicule */}
      <Grid container spacing={2} marginBottom={2}>
        {isAuthorizedRole() && (
          <Grid item xs={12} sm={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="company-select-label">Société</InputLabel>
              <Select
                labelId="company-select-label"
                value={selectedCompany}
                onChange={handleCompanyFilterChange}
                label="Société"
              >
                <MenuItem value="">
                  <em>Toutes les sociétés</em>
                </MenuItem>
                {Array.from(new Set(vehicules.map((vehicule) => vehicule.societe))).map(
                  (societe) => (
                    <MenuItem key={societe} value={societe}>
                      {societe}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="vehicule-select-label">Véhicule</InputLabel>
            <Select
              labelId="vehicule-select-label"
              value={selectedVehicule}
              onChange={handleVehiculeFilterChange}
              label="Véhicule"
            >
              <MenuItem value="">
                <em>Tous les véhicules</em>
              </MenuItem>
              {vehicules.map((vehicule) => (
                <MenuItem key={vehicule.code} value={vehicule.code}>
                  {vehicule.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {filteredProcedures.map((procedure) => {
        // Filtrer les réponses pour cette procédure
        const procedureResponses = filteredHistorique.filter(
          (record) => record.procedure_id === procedure.id,
        );

        // Vérifier s'il y a des véhicules non répondants
        const hasNonRespondingVehicles = nonRespondingVehicles[procedure.id]?.length > 0;
        const nonRespondingCount = nonRespondingVehicles[procedure.id]?.length || 0;

        // Si la procédure n'a pas de réponses et pas de véhicules non répondants, ne pas l'afficher
        if (procedureResponses.length === 0 && !hasNonRespondingVehicles) {
          return null;
        }

        return (
          <Card key={procedure.id} style={{ marginBottom: '20px' }}>
            <CardContent>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">{procedure.name}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      loadingNonResponding[procedure.id]
                        ? 'Chargement...'
                        : `${nonRespondingCount} véhicules non répondants`
                    }
                    arrow
                  >
                    <Badge
                      badgeContent={
                        loadingNonResponding[procedure.id] ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : (
                          nonRespondingCount
                        )
                      }
                      color="error"
                      invisible={!hasNonRespondingVehicles}
                    >
                      <IconButton onClick={() => handleExpandClick(procedure.id)}>
                        <ExpandMoreIcon
                          style={{
                            transform:
                              expandedProcedureId === procedure.id
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                        />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>

            <Collapse
              in={expandedProcedureId === procedure.id}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                {hasNonRespondingVehicles && (
                  <div style={{ marginBottom: '20px' }}>
                    <Typography variant="subtitle1">Véhicules non répondants :</Typography>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '8px',
                    }}
                    >
                      {nonRespondingVehicles[procedure.id].map((vehiculeCode) => (
                        <Chip
                          key={vehiculeCode}
                          label={`Véhicule ${vehiculeCode}`}
                          color="secondary"
                        />
                      ))}
                    </div>
                  </div>
                )}

                {procedureResponses.map((record) => (
                  <Card key={record.response_id} style={{ marginBottom: '10px' }}>
                    <CardContent>
                      <Typography color="textSecondary">
                        Date : {formatDate(record.submitted_at)}
                      </Typography>
                      <Typography color="textSecondary">
                        Véhicule : {record.vehicule_id}
                      </Typography>
                      <Typography color="textSecondary">
                        Utilisateur : {record.user_id}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Tooltip title="Voir les détails" arrow>
                        <IconButton onClick={() => handleViewDetails(record.response_id)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </Card>
                ))}
              </CardContent>
            </Collapse>
          </Card>
        );
      })}

      {selectedResponseId && (
        <ProcedureResponsesModal
          responseId={selectedResponseId}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          userToken={userToken}
        />
      )}
    </div>
  );
}

export default React.memo(HistoriqueProcedures);
