/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';
import CompanyFactureForm from './companyFactureForm';

function CompanyManagement({ userToken, userRole }) {
  usePageTitle('DRIVESOFT | GESTION DES SOCIÉTÉS');
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showFactureModal, setShowFactureModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [formData, setFormData] = useState({
    companyname: '',
    address: '',
    phone: '',
    email: '',
    siret: '',
    codeape: '',
    numeroidentification: '',
    numerotva: '',
    iban: '',
    bic: '',
    code: '',
    boitepostale: ''
  });
  const [factures, setFactures] = useState([]);

  const headers = { Authorization: `Bearer ${userToken}` };

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(response.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des sociétés:', error);
    }
  };

  const groupFacturesByMonth = (factures) => factures.reduce((acc, facture) => {
    const date = new Date(facture.date);
    const monthYear = date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(facture);
    return acc;
  }, {});

  const fetchFactures = async (companyId) => {
    try {
      const response = await axios.get(`${BASE_URL}companies/${companyId}/factures`, { headers });

      // Tri par date décroissante
      const sortedFactures = response.data.factures.sort((a, b) => new Date(b.date) - new Date(a.date));

      setFactures(sortedFactures);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  };

  const facturesGroupedByMonth = groupFacturesByMonth(factures);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleAddClick = () => {
    setShowAddModal(true);
    setFormData({
      companyname: '',
      address: '',
      phone: '',
      email: '',
      siret: '',
      codeape: '',
      numeroidentification: '',
      numerotva: '',
      iban: '',
      bic: '',
      code: '',
      boitepostale: ''
    });
  };

  const handleEditClick = (company) => {
    setCurrentCompany(company);
    setFormData(company);
    setShowUpdateModal(true);
  };

  const handleAddFactureClick = (company) => {
    setCurrentCompany(company);
    fetchFactures(company.id);
    setShowFactureModal(true);
  };

  const handleDeleteCompany = (companyId) => {
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette société ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}companies/${companyId}`, { headers });
          setCompanies(companies.filter((company) => company.id !== companyId));
          Swal.fire('Supprimée!', 'La société a été supprimée avec succès.', 'success');
        }
        catch (error) {
          console.error('Erreur lors de la suppression de la société:', error);
        }
      }
    });
  };

  const handleSubmitAdd = async () => {
    try {
      const response = await axios.post(`${BASE_URL}companies`, formData, { headers });
      setCompanies([...companies, response.data]);
      setShowAddModal(false);
      fetchCompanies();
      Swal.fire('Succès', 'La société a été ajoutée avec succès.', 'success');
    }
    catch (error) {
      console.error('Erreur lors de l\'ajout de la société:', error);
    }
  };

  const handleSubmitUpdate = async () => {
    try {
      await axios.put(`${BASE_URL}companies/${currentCompany.id}`, formData, { headers });
      const updatedCompanies = companies.map((company) => (company.id === currentCompany.id ? formData : company));
      setCompanies(updatedCompanies);
      setShowUpdateModal(false);
      fetchCompanies();
      Swal.fire('Succès', 'La société a été mise à jour avec succès.', 'success');
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour de la société:', error);
    }
  };

  const handleSaveFacture = async (newFacture) => {
    try {
      await axios.post(`${BASE_URL}companies/${currentCompany.id}/factures`, newFacture, { headers });
      setShowFactureModal(false);
      fetchFactures(currentCompany.id);
      Swal.fire('Succès', 'La facture a été ajoutée avec succès.', 'success');
    }
    catch (error) {
      console.error('Erreur lors de l\'ajout de la facture:', error);
    }
  };

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}companies/${currentCompany.id}/factures`, {
          headers: { Authorization: `Bearer ${userToken}` },
          data: { factureId }, // Pass the factureId here
        });

        // Re-fetch the factures after deletion
        fetchFactures(currentCompany.id);

        Swal.fire({
          title: 'Supprimée!',
          text: 'La facture a été supprimée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la facture :', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de la facture.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      { isAuthorizedRole() && (
      <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={handleAddClick}>
        Ajouter une société
      </Button>
      )}

      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Code', 'Nom de la société', 'Adresse', 'Téléphone', 'Email', 'Siret', 'Actions'].map((header) => (
                <TableCell key={header} align="center">{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell align="center">{company.code}</TableCell>
                <TableCell align="center">{company.companyname}</TableCell>
                <TableCell align="center">{company.address}</TableCell>
                <TableCell align="center">{company.phone}</TableCell>
                <TableCell align="center">{company.email}</TableCell>
                <TableCell align="center">{company.siret}</TableCell>
                <TableCell align="center">
                  { isAuthorizedRole() && (
                    <>
                      <Tooltip title="Modifier la société">
                        <IconButton onClick={() => handleEditClick(company)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ajouter/Consulter une facture">
                        <IconButton onClick={() => handleAddFactureClick(company)}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer la société">
                        <IconButton onClick={() => handleDeleteCompany(company.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modale pour ajouter une société */}
      <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="md">
        <DialogTitle>Ajouter une société</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: '10px' }}>
            {[
              { name: 'companyname', label: 'Nom de la société' },
              { name: 'address', label: 'Adresse' },
              { name: 'phone', label: 'Téléphone' },
              { name: 'email', label: 'Email' },
              { name: 'siret', label: 'Siret' },
              { name: 'codeape', label: 'Code APE' },
              { name: 'numeroidentification', label: 'Numéro d\'identification' },
              { name: 'numerotva', label: 'Numéro de TVA' },
              { name: 'iban', label: 'IBAN' },
              { name: 'bic', label: 'BIC' },
              { name: 'code', label: 'Code' },
              { name: 'boitepostale', label: 'Boîte postale' },
            ].map((field) => (
              <Grid item xs={12} sm={6} key={field.name}>
                <TextField
                  name={field.name}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddModal(false)} color="secondary">Annuler</Button>
          <Button onClick={handleSubmitAdd} color="primary">Enregistrer</Button>
        </DialogActions>
      </Dialog>

      {/* Modale pour mettre à jour une société */}
      <Dialog open={showUpdateModal} onClose={() => setShowUpdateModal(false)} fullWidth maxWidth="md">
        <DialogTitle>Modifier la société</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: '10px' }}>
            {[
              { name: 'companyname', label: 'Nom de la société' },
              { name: 'address', label: 'Adresse' },
              { name: 'phone', label: 'Téléphone' },
              { name: 'email', label: 'Email' },
              { name: 'siret', label: 'Siret' },
              { name: 'codeape', label: 'Code APE' },
              { name: 'numeroidentification', label: 'Numéro d\'identification' },
              { name: 'numerotva', label: 'Numéro de TVA' },
              { name: 'iban', label: 'IBAN' },
              { name: 'bic', label: 'BIC' },
              { name: 'code', label: 'Code' },
              { name: 'boitepostale', label: 'Boîte postale' },
            ].map((field) => (
              <Grid item xs={12} sm={6} key={field.name}>
                <TextField
                  name={field.name}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUpdateModal(false)} color="secondary">Annuler</Button>
          <Button onClick={handleSubmitUpdate} color="primary">Enregistrer</Button>
        </DialogActions>
      </Dialog>

      {/* Modale pour ajouter une facture */}
      <Dialog open={showFactureModal} onClose={() => setShowFactureModal(false)} fullWidth maxWidth="md">
        <DialogTitle>Ajouter une charge pour {currentCompany?.companyname}</DialogTitle>
        <DialogContent>
          <CompanyFactureForm onSaveFacture={handleSaveFacture} /> {/* Intégration du formulaire StaffFactureForm */}
          <hr />

          {/* Affichage des factures */}
          <h3 className="addModalTitle">Charges existantes</h3>
          <div>
            {Object.keys(facturesGroupedByMonth).map((monthYear) => (
              <Accordion key={monthYear}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{monthYear}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Détails</TableCell>
                          <TableCell>Commentaires</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {facturesGroupedByMonth[monthYear].map((facture) => (
                          <TableRow key={facture.id}>
                            <TableCell>
                              {new Date(facture.date).toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </TableCell>
                            <TableCell>{facture.type}</TableCell>
                            <TableCell>
                              {Object.keys(facture.details).map((key) => (
                                facture.details[key] && (
                                <div key={key}>
                                  <strong>{key}:</strong> {facture.details[key]}
                                </div>
                                )
                              ))}
                            </TableCell>
                            <TableCell>{facture.commentaires}</TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFactureModal(false)} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(CompanyManagement);
