/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Card,
  CardContent,
  Collapse,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// Composant pour afficher une fonctionnalité
function FeatureComponent({ title, description }) {
  const renderMarkdown = (content) => {
    const rawMarkup = marked(content);
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanMarkup };
  };

  return (
    <Card sx={{
      maxWidth: '95%', margin: 'auto', mt: 4, boxShadow: 3, borderRadius: 2
    }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box
          className="markdown-body"
          dangerouslySetInnerHTML={renderMarkdown(description)}
          sx={{
            '& img': { maxWidth: '100%', height: 'auto' },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              marginTop: '24px',
              marginBottom: '16px',
              fontWeight: 600,
              lineHeight: 1.25,
              fontSize: '1rem', // Réduire la taille de la police des titres
            },
            '& p': { marginTop: 0, marginBottom: '16px', fontSize: '0.875rem' }, // Réduire la taille de la police des paragraphes
            '& ul, & ol': { paddingLeft: '2em', marginBottom: '16px', fontSize: '0.875rem' }, // Réduire la taille de la police des listes
            '& code': {
              padding: '0.2em 0.4em',
              margin: 0,
              fontSize: '0.875rem',
              borderRadius: '3px',
            },
            '& pre': {
              padding: '16px',
              overflow: 'auto',
              fontSize: '0.875rem',
              lineHeight: 1.45,
              borderRadius: '3px',
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

const managementFeatures = [
  {
    title: 'Gestion des tâches',
    description: `

    Le composant Gestion des tâches de DRIVESOFT permet de gérer les tâches de vos employés. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales :

### Affichage des tâches :

- **Colonnes de tâches** : Les tâches sont affichées dans différentes colonnes selon leur statut : À faire, En cours, Terminé, et Archivé.
- **Détails des tâches** : Chaque tâche affiche des informations telles que le nom de la tâche, le commentaire, le véhicule associé, le créateur de la tâche, et le temps écoulé depuis sa création ou sa dernière mise à jour.

### Filtrage et recherche :

- **Recherche par véhicule** : Utilisez le menu déroulant pour filtrer les tâches par véhicule.
- **Recherche par rôle** : Les utilisateurs ayant les rôles Direction, Administrateur ou Responsable d'exploitation peuvent filtrer les tâches par rôle.

### Gestion des tâches :

- **Création de tâches** : Certaines tâches seront créées automatiquement depuis d'autres composants de l'application, comme la gestion de procédure, les échéances de contrôle technique, entretiens des véhicules ou des locaux...
- **Création de tâches** : Cliquez sur "Ajouter une tâche" pour ouvrir une fenêtre modale permettant de créer une nouvelle tâche. Remplissez les détails de la tâche, sélectionnez le véhicule et le rôle (si vous êtes Administrateur), puis cliquez sur "Ajouter".
- **Modification de la priorité** : Les tâches peuvent être marquées comme "À prévoir", "Basse", "Moyenne", ou "Haute" en cliquant sur les boutons correspondants.
- **Archiver les tâches** : Cliquez sur l'icône d'archive pour déplacer une tâche dans la colonne Archivé.
- **Suppression de tâches** : Cliquez sur l'icône de suppression pour retirer définitivement une tâche.
- **Changer le rôle** : Les Administrateurs peuvent attribuer ou changer le rôle associé à une tâche.

### Interaction et navigation :

- **Glisser-déposer** : Réorganisez les tâches en les faisant glisser et en les déposant dans les colonnes appropriées pour mettre à jour leur statut.
- **Afficher les tâches archivées** : Utilisez le bouton pour afficher ou masquer les tâches archivées.
- **Sélection de véhicule et de rôle** : Sélectionnez un véhicule ou un rôle pour filtrer les tâches correspondantes.
`,
  },
  {
    title: 'Gestion des plannings',
    description: `

    Le composant Gestion des planningsde DRIVESOFT permet de gérer les plannings de vos employés. Voici une présentation de ses principales fonctionnalités :

## Affichage du planning

Le composant affiche un planning hebdomadaire pour l'ensemble du personnel. Chaque ligne représente un employé, et chaque colonne représente un jour de la semaine.

### Fonctionnalités principales :

- **Visualisation des statuts** : Chaque cellule du planning indique le statut de l'employé pour ce jour-là, comme Travail (T), Repos (R), Congé Payé (CP), etc.
- **Navigation par semaine** : Vous pouvez naviguer entre les semaines à l'aide du sélecteur de semaines. Sélectionnez une année et une semaine pour afficher le planning correspondant.
- **Filtrage et recherche** :
  - **Recherche par nom** : Utilisez la barre de recherche pour trouver un employé par son nom ou son prénom.
  - **Filtrage par société et diplôme** : Filtrez les employés par société et par diplôme pour une visualisation plus ciblée.
  - **Afficher les employés partis** : Vous pouvez choisir d'afficher ou de masquer les employés qui ont quitté l'entreprise.

## Mise à jour du planning

### Sélection de cellules :

- **Sélection du statut** : En haut du composant Planning, vous pouvez sélectionner le statut que vous souhaitez (par exemple, Travail, Repos, Congés Payés, etc.).
- **Sélection de cellule** : Cliquez sur une ou plusieurs cellules, faites glisser pour sélectionner plusieurs cellules dans le planning.
- **Mise à jour des statuts** : Quand vous relâchez une cellule, le composant mettra à jour les statuts de toutes les cellules sélectionnées.

### Glisser-déposer :

- **Réorganisation des employés** : Réorganisez les employés par glisser-déposer pour refléter l'ordre souhaité dans le planning.

## Totaux Quotidiens :

Le planning affiche le nombre total d'employés de différents diplômes (DEA, CCA, IDE, etc.) travaillant chaque jour.
`,
  },
  {
    title: 'Gestion des équipages',
    description: `

    Le composant Gestion des équipages de DRIVESOFT permet de gérer les équipages de vos employés. Voici une présentation de ses principales fonctionnalités :

## Affichage des équipages :

Le composant affiche les équipages pour une date spécifique.
Les équipages sont regroupés par société et type d'équipage.

## Navigation par date :

Vous pouvez naviguer entre les dates à l'aide des flèches gauche et droite.
Il est également possible de sélectionner une date spécifique via un sélecteur de date.

## Ajout d'un nouvel équipage :

Un bouton "Ajouter un équipage" permet d'ouvrir une fenêtre modale pour créer un nouvel équipage.
Dans cette fenêtre, vous pouvez sélectionner la date, le type d'équipage, le véhicule et les membres du personnel.

## Modification d'un équipage existant :

En cliquant sur une carte d'équipage, une fenêtre modale s'ouvre pour modifier les détails de l'équipage.
Vous pouvez changer la date, le véhicule, le type d'équipage et les membres du personnel.

## Suppression d'un équipage :

Chaque carte d'équipage comporte un bouton "Supprimer" pour retirer l'équipage de la liste.

## Gestion des types d'équipages :

Le composant gère différents types d'équipages (AMB, PARA, SAMU, VSL, TAXI, BARIA, PSY).
Certains types d'équipages (comme VSL et TAXI) ne nécessitent qu'un seul chauffeur, tandis que les autres en requièrent deux.

## Vérifications et alertes :

Le système vérifie la disponibilité des véhicules et du personnel pour éviter les conflits.
Des alertes sont affichées si un véhicule ou un membre du personnel est déjà assigné à la date sélectionnée.

## Contrôle d'accès :

Certaines fonctionnalités (comme l'ajout, la modification ou la suppression d'équipages) sont réservées aux utilisateurs ayant des rôles spécifiques.

## Affichage adapté :

Les équipages sont affichés sous forme de cartes, regroupés par société et type d'équipage pour une meilleure lisibilité.
`,
  },
  {
    title: 'Pointage des heures',
    description: `

    Le composant Pointage des heures de DRIVESOFT permet de gérer les heures de travail de vos employés. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales :

### Affichage des équipages et des heures de travail :

- **Équipages et véhicules** : Le composant affiche les équipages assignés à différents véhicules pour une date spécifique.
- **Détails des entrées de pointeuse** : Pour chaque membre d'équipage, les heures de début, de fin, les pauses, et les heures travaillées sont affichées.

### Filtrage et recherche :

- **Recherche** : Utilisez la barre de recherche pour filtrer les équipages par nom, prénom ou code du véhicule.
- **Navigation par date** : Vous pouvez naviguer entre les dates à l'aide des flèches ou en sélectionnant une date spécifique via un sélecteur de date.

### Gestion des entrées de pointeuse :

- **Validation des entrées** : Validez les entrées de pointeuse pour un membre d'équipage en cliquant sur le bouton "Validé".
- **Refus des entrées** : Refusez une entrée en fournissant une raison dans le champ "Raison du refus" et en cliquant sur "Refusé".
- **Modification des heures** : Modifiez les heures de début, de fin, les pauses repas et les autres pauses directement dans les champs correspondants.
- **Suppression des entrées** : Supprimez une entrée de pointeuse en cliquant sur l'icône de suppression.
- **Notifications** : Envoyez des notifications par email ou SMS aux membres d'équipage ou à tous les équipages.

### Exportation des données :

- **Exportation Excel** : Exportez les données de pointeuse au format Excel pour une période sélectionnée.
- **Exportation PDF** : Exportez une feuille de route hebdomadaire en PDF pour une semaine sélectionnée.
`,
  },
  {
    title: 'Gestion du personnel',
    description: `

    Le composant Gestion du personnel de DRIVESOFT permet de gérer les employés de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage du personnel :

- **Liste du personnel** : Affiche une liste complète des employés avec leur nom, prénom, société, diplôme, et les dates de leurs visites médicales, visites du permis, et AFGSU.
- **Informations détail** : Pour chaque employé, affiche les détails comme l'entreprise, le diplôme, et les dates importantes.

#### Filtrage et recherche :

- **Recherche par nom** : Utilisez la barre de recherche pour filtrer les employés par nom ou prénom.
- **Filtrage par société** : Utilisez le menu déroulant pour filtrer les employés par société.
- **Affichage des sorties** : Basculez l'affichage pour inclure ou exclure les employés ayant quitté l'entreprise.

#### Gestion des employés :

- **Ajouter un employé** : Cliquez sur "Ajouter une personne" pour ouvrir une fenêtre modale permettant d'ajouter un nouvel employé.
- **Modifier un employé** : Cliquez sur une ligne de la liste pour ouvrir une fenêtre modale permettant de modifier les informations d'un employé existant (réservé aux utilisateurs autorisés).
- **Exporter les données** : Exportez la liste des employés au format Excel pour une utilisation externe.

#### Statistiques :

- **Total des employés** : Affiche le nombre total d'employés actuels.
- **Répartition par diplôme** : Affiche la répartition des employés par diplôme.
- **Répartition par société** : Affiche la répartition des employés par société.
`,
  },
  {
    title: 'Gestion des véhicules',
    description: `

    Le composant Gestion des véhicules de DRIVESOFT permet de gérer les véhicules de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage des véhicules :

- **Colonnes de véhicules** : Les véhicules sont affichés avec leurs informations telles que le code, l'immatriculation, le modèle, le type, le kilométrage, la date du contrôle technique, les observations et le responsable.
- **Détails des véhicules** : Pour chaque véhicule, les détails supplémentaires comme les dates de contre-visite et les informations sur le responsable sont affichés.

#### Filtrage et recherche :

- **Filtrer par société** : Utilisez le menu déroulant pour filtrer les véhicules par société.
- **Afficher les véhicules sortis** : Basculez l'affichage pour inclure ou exclure les véhicules qui ont quitté la flotte.

#### Gestion des véhicules :

- **Ajouter un véhicule** : Cliquez sur "Ajouter un véhicule" pour ouvrir une fenêtre modale permettant d'ajouter un nouveau véhicule. Remplissez les détails du véhicule et cliquez sur "Ajouter".
- **Modifier un véhicule** : Cliquez sur une ligne du tableau pour ouvrir une fenêtre modale permettant de modifier les informations d'un véhicule existant (réservé aux utilisateurs autorisés).
- **Exporter les données** : Cliquez sur "Exporter en Excel" pour exporter la liste des véhicules au format Excel pour une utilisation externe.

#### Statistiques et alertes :

- **Alertes de contrôle technique** : Le système vérifie les dates de contrôle technique et de contre-visite des véhicules et affiche des alertes si des dates d'expiration sont proches ou dépassées.
- **Création automatique de tâches** : Des tâches sont automatiquement créées pour les contrôles techniques et les contre-visites approchant leur date d'expiration.

#### Gestion des factures :

- **Ajouter et modifier des factures** : Cliquez sur "Ajouter une facture" pour ouvrir une fenêtre modale permettant d'ajouter une nouvelle facture. Remplissez les détails de la facture et cliquez sur "Ajouter". Vous pouvez également modifier une facture existante en cliquant sur une ligne du tableau de factures.
- **Filtrage et tri des factures** : Utilisez les filtres pour trier les factures par type, sous-type et pour afficher uniquement les factures générant des alertes.
- **Création d'alertes et de tâches** : Le système génère automatiquement des alertes et des tâches pour les entretiens à réaliser en fonction des factures saisies.

#### Interaction et navigation :

- **Impression de QR Codes** : Utilisez l'icône QR Code pour imprimer les QR Codes des véhicules.
- **Visualisation et téléchargement de documents** : Visualisez et téléchargez des documents tels que la carte grise, l'assurance et les contrats de location directement depuis l'application.
- **Exportation des demandes d'intervention** : Exportez les demandes d'intervention en format PDF, incluant les détails des entretiens à réaliser et les QR Codes des véhicules.
`,
  },
  {
    title: 'Gestion des établissements',
    description: `
### Fonctionnalités principales :

#### Affichage des établissements :

- **Liste des établissements** : Affiche une liste complète des établissements avec leur code, nom, adresse, code postal, contact, et téléphone.
- **Détails des établissements** : Pour chaque établissement, les services associés sont affichés.

#### Filtrage et recherche :

- **Liste des services** : Cliquez sur "Liste des services" pour afficher ou masquer les services associés à un établissement.

#### Gestion des établissements :

- **Ajouter un établissement** : Cliquez sur "Ajouter un établissement" pour ouvrir une fenêtre modale permettant d'ajouter un nouvel établissement. Remplissez les détails de l'établissement et cliquez sur "Ajouter".
- **Modifier un établissement** : Cliquez sur une ligne du tableau pour ouvrir une fenêtre modale permettant de modifier les informations d'un établissement existant (réservé aux utilisateurs autorisés).
- **Supprimer un établissement** : Cliquez sur l'icône de suppression pour retirer définitivement un établissement de la liste.

#### Gestion des services :

- **Ajouter un service** : Cliquez sur l'icône "Ajouter un service" pour ouvrir une fenêtre modale permettant d'ajouter un nouveau service à un établissement. Remplissez les détails du service et cliquez sur "Ajouter".
- **Modifier un service** : Cliquez sur une ligne de service pour ouvrir une fenêtre modale permettant de modifier les informations d'un service existant (réservé aux utilisateurs autorisés).
- **Supprimer un service** : Cliquez sur l'icône de suppression pour retirer définitivement un service de la liste.

### Interaction et navigation :

- **Afficher les services associés** : Cliquez sur "Liste des services" pour afficher ou masquer les services associés à un établissement.
- **Ajouter un service** : Cliquez sur l'icône "Ajouter un service" pour ouvrir la fenêtre modale d'ajout de service.
- **Supprimer un établissement ou un service** : Cliquez sur l'icône de suppression pour retirer définitivement un établissement ou un service de la liste.
`,
  },
  {
    title: 'Gestion des sociétés',
    description: `
### Fonctionnalités principales :

#### Affichage des sociétés :

- **Liste des sociétés** : Affiche une liste complète des sociétés avec leur code, nom, adresse, téléphone, email et SIRET.

#### Filtrage et recherche :

- **Recherche par nom** : Utilisez la barre de recherche pour trouver une société par son nom ou son code.

#### Gestion des sociétés :

- **Ajouter une société** : Cliquez sur "Ajouter une société" pour ouvrir une fenêtre modale permettant d'ajouter une nouvelle société. Remplissez les détails de la société et cliquez sur "Ajouter".
- **Modifier une société** : Cliquez sur une ligne du tableau pour ouvrir une fenêtre modale permettant de modifier les informations d'une société existante (réservé aux utilisateurs autorisés).
- **Supprimer une société** : Cliquez sur l'icône de suppression pour retirer définitivement une société de la liste.

### Interaction et navigation :

- **Ajouter une société** : Cliquez sur "Ajouter une société" pour ouvrir la fenêtre modale d'ajout de société.
- **Modifier une société** : Cliquez sur une ligne de la liste pour ouvrir la fenêtre modale de modification de société.
- **Supprimer une société** : Cliquez sur l'icône de suppression pour retirer définitivement une société de la liste.
`,
  },
  {
    title: 'Gestion des transports en série',
    description: `
### Fonctionnalités principales :

#### Affichage des séries :

- **Liste des séries** : Affiche une liste complète des séries de transports avec les informations suivantes :
  - Nom
  - Date de début de série
  - Jours de la série
  - Date de fin de la série
  - Type de tarification
  - Raison
  - Transports restants

#### Filtrage et recherche :

- **Recherche par nom** : Utilisez la barre de recherche pour trouver une série par le nom du patient.
- **Recherche par type de tarification** : Utilisez le menu déroulant pour filtrer les séries par type de tarification.
- **Recherche par raison** : Utilisez le menu déroulant pour filtrer les séries par raison de transport.

#### Gestion des séries :

- **Ajouter une série** : Non inclus dans le composant actuel.
- **Modifier une série** : Cliquez sur l'icône de modification pour ouvrir une fenêtre modale permettant de modifier les informations d'une série existante.
- **Supprimer une série** : Cliquez sur l'icône de suppression pour retirer définitivement une série de la liste.

### Interaction et navigation :

- **Filtrage et recherche** : Utilisez les champs de recherche et les menus déroulants pour affiner la liste des séries affichées.
- **Modifier une série** : Cliquez sur l'icône de modification (EditIcon) pour ouvrir la fenêtre modale de modification de série.
- **Supprimer une série** : Cliquez sur l'icône de suppression (DeleteIcon) pour retirer définitivement une série de la liste.
`
  },
  {
    title: 'Gestion des stocks',
    description: `

    Le composant Gestion des stocks de DRIVESOFT permet de gérer les stocks de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage des articles :

- **Liste des articles** : Affiche une liste complète des articles en stock avec les informations suivantes :
  - Catégorie
  - Nom
  - Quantité
  - Quantité minimale
  - Conditionnement
  - Prix unitaire
  - Prix total
  - Date limite
  - Fournisseur

#### Filtrage et recherche :

- **Recherche par nom** : Utilisez la barre de recherche pour trouver un article par son nom.
- **Filtrage par catégorie** : Utilisez le menu déroulant pour filtrer les articles par catégorie.
- **Filtrage par fournisseur** : Utilisez le menu déroulant pour filtrer les articles par fournisseur.
- **Affichage des articles en rupture de stock** : Utilisez le bouton pour afficher uniquement les articles dont la quantité est égale ou inférieure à la quantité minimale.

#### Gestion des articles :

- **Ajouter un article** : Cliquez sur le bouton "Ajouter un article" pour ouvrir une fenêtre modale permettant d'ajouter un nouvel article.
- **Modifier un article** : Cliquez sur un article pour ouvrir une fenêtre modale permettant de modifier les informations de l'article.
- **Supprimer un article** : Cliquez sur l'icône de suppression pour retirer définitivement un article de la liste.
- **Ajouter ou soustraire une quantité** : Cliquez sur les icônes d'ajout ou de soustraction pour ouvrir une fenêtre modale permettant de modifier la quantité d'un article.
- **Impression de QR code** : Cliquez sur l'icône d'impression pour imprimer le QR code d'un article.

#### Gestion des catégories :

- **Ajouter une catégorie** : Cliquez sur l'icône de gestion des catégories pour ouvrir une fenêtre modale permettant d'ajouter une nouvelle catégorie.
- **Modifier une catégorie** : Utilisez la fenêtre modale de gestion des catégories pour modifier les informations d'une catégorie existante.
- **Supprimer une catégorie** : Utilisez la fenêtre modale de gestion des catégories pour supprimer une catégorie.

#### Exportation des données :

- **Exporter en excel** : Cliquez sur l'icône d'exportation pour télécharger la liste des articles en format Excel.

### Interaction et navigation :

- **Filtrage et recherche** : Utilisez les champs de recherche et les menus déroulants pour affiner la liste des articles affichés.
- **Ajouter un article** : Cliquez sur le bouton "Ajouter un article" pour ouvrir la fenêtre modale d'ajout d'article.
- **Modifier un article** : Cliquez sur un article pour ouvrir la fenêtre modale de modification d'article.
- **Supprimer un article** : Cliquez sur l'icône de suppression pour retirer un article de la liste.
- **Ajouter ou soustraire une quantité** : Cliquez sur les icônes d'ajout ou de soustraction pour ouvrir la fenêtre modale de modification de quantité.
- **Impression de QR code** : Cliquez sur l'icône d'impression pour imprimer le QR code d'un article.
- **Gérer les catégories** : Cliquez sur l'icône de gestion des catégories pour ouvrir la fenêtre modale de gestion des catégories.
- **Exporter en Excel** : Cliquez sur l'icône d'exportation pour télécharger la liste des articles en format Excel.

`,
  },
  {
    title: 'Gestion des articles médicaux',
    description: `

    Le composant Gestion des articles médicaux de DRIVESOFT permet de gérer les articles médicaux de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage des articles médicaux :

- **Liste des articles médicaux** : Affiche une liste complète des articles médicaux avec les informations suivantes :
  - Photo
  - Nom
  - Numéro de série
  - Date de révision
  - Date d'achat
  - Prix d'achat (€ HT)
  - Valeur (€ HT)
  - Société
  - Catégorie
  - Localisation
  - Actions (Modifier, Supprimer)

#### Filtrage et recherche :

- **Recherche par nom** : Utilisez la barre de recherche pour trouver un article par son nom.
- **Filtrage par société** : Utilisez le menu déroulant pour filtrer les articles par société.
- **Filtrage par catégorie** : Utilisez le menu déroulant pour filtrer les articles par catégorie.

#### Gestion des articles médicaux :

- **Ajouter un article médical** : Cliquez sur l'icône d'ajout pour ouvrir une fenêtre modale permettant d'ajouter un nouvel article médical.
- **Modifier un article médical** : Cliquez sur l'icône de modification à côté de l'article que vous souhaitez modifier pour ouvrir une fenêtre modale.
- **Supprimer un article médical** : Cliquez sur l'icône de suppression à côté de l'article que vous souhaitez supprimer.

#### Exportation des données :

- **Exporter en Excel** : Cliquez sur le bouton pour exporter la liste des articles médicaux au format Excel.

### Interaction et navigation :

- **Rechercher un article médical** :
  - Utilisez la barre de recherche pour entrer le nom de l'article.
  - Utilisez les menus déroulants pour filtrer par société ou catégorie.

- **Ajouter un article médical** :
  - Cliquez sur l'icône d'ajout.
  - Remplissez les informations dans la fenêtre modale et cliquez sur "Créer".

- **Modifier un article médical** :
  - Cliquez sur l'icône de modification à côté de l'article.
  - Modifiez les informations dans la fenêtre modale et cliquez sur "Mettre à jour".

- **Supprimer un article médical** :
  - Cliquez sur l'icône de suppression à côté de l'article.
  - Confirmez la suppression dans la fenêtre de dialogue.

- **Exporter en Excel** :
  - Cliquez sur le bouton "Exporter en Excel" pour télécharger la liste des articles médicaux au format Excel.

`
  },
  {
    title: 'Gestion des procédures',
    description: `

    Le composant Gestion des procédures de DRIVESOFT permet de gérer les procédures de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage des procédures :
- **Liste des procédures** : Affiche une liste complète des procédures avec les informations suivantes :
  - Nom de la procédure
  - Fréquence
  - Échéance
  - Véhicules en attente de réponse (pour les rôles autorisés)
  - Actions (Compléter, Historique des réponses, Supprimer)

#### Filtrage et recherche :
- **Recherche par nom** : Utilisez la barre de recherche pour trouver une procédure par son nom.
- **Filtrage par société** : Utilisez le menu déroulant pour filtrer les procédures par société.
- **Filtrage par catégorie** : Utilisez le menu déroulant pour filtrer les procédures par catégorie.

#### Gestion des procédures :
- **Ajouter une procédure** : Cliquez sur l'icône d'ajout pour ouvrir une fenêtre modale permettant d'ajouter une nouvelle procédure.
- **Modifier une procédure** : Cliquez sur une procédure pour ouvrir une fenêtre modale permettant de modifier ses détails.
- **Supprimer une procédure** : Cliquez sur l'icône de suppression à côté de la procédure que vous souhaitez supprimer.

#### Affichage des réponses :
- **Voir les réponses** : Cliquez sur l'icône de visualisation pour voir l'historique des réponses d'une procédure.
- **Compléter une procédure** : Cliquez sur l'icône de complétion pour remplir une procédure.

### Interaction et navigation :

#### Rechercher une procédure :
- Utilisez la barre de recherche pour entrer le nom de la procédure.
- Utilisez les menus déroulants pour filtrer par société ou catégorie.

#### Ajouter une procédure :
- Cliquez sur l'icône d'ajout.
- Remplissez les informations dans la fenêtre modale et cliquez sur "Créer".

#### Modifier une procédure :
- Cliquez sur une procédure pour ouvrir la fenêtre modale.
- Modifiez les informations dans la fenêtre modale et cliquez sur "Mettre à jour".

#### Supprimer une procédure :
- Cliquez sur l'icône de suppression à côté de la procédure.
- Confirmez la suppression dans la fenêtre de dialogue.

#### Voir les réponses :
- Cliquez sur l'icône de visualisation pour voir l'historique des réponses d'une procédure.

#### Compléter une procédure :
- Cliquez sur l'icône de complétion pour remplir une procédure.
- Remplissez les champs nécessaires dans la fenêtre modale et soumettez la procédure.

### Instructions détaillées pour les formulaires de procédure :

#### Ajouter/Modifier une procédure :
- **Nom de la procédure** : Entrez le nom de la procédure.
- **Description** : Entrez une description pour la procédure.
- **Fréquence** : Sélectionnez la fréquence de la procédure (Ponctuelle, Quotidienne, Hebdomadaire, Mensuelle, Annuelle).
- **Champs** :
  - **Ajouter un champ** : Cliquez sur le bouton "Ajouter un champ" pour ajouter un nouveau champ à la procédure.
  - **Modifier un champ** : Cliquez sur un champ pour ouvrir la fenêtre modale et modifier ses détails.
  - **Supprimer un champ** : Cliquez sur l'icône de suppression à côté du champ pour le supprimer.
  - **Types de champs disponibles** :
    - Tête de chapitre
    - Informations
    - Texte
    - Nombre
    - Date
    - Date limite
    - Date et heure
    - Photo/Fichier
    - Texte multi-lignes
    - Choix unique
    - Choix multiple
    - OK/KO

#### Remplir une procédure :
- **Champs** : Remplissez les champs de la procédure en fonction de leur type.
  - **Texte** : Entrez une valeur textuelle.
  - **Nombre** : Entrez une valeur numérique.
  - **Date** : Sélectionnez une date.
  - **Date et heure** : Sélectionnez une date et une heure.
  - **Photo/Fichier** : Téléchargez un fichier ou une photo.
  - **Texte multi-lignes** : Entrez un texte multi-lignes.
  - **Choix unique** : Sélectionnez une option.
  - **Choix multiple** : Sélectionnez une ou plusieurs options.
  - **OK/KO** : Sélectionnez "OK" ou "KO".
- **Soumettre la procédure** : Cliquez sur le bouton "Soumettre la Procédure" pour soumettre la procédure complétée.

### Astuces :
- Utilisez les icônes et les boutons pour naviguer facilement entre les procédures et gérer leurs informations.
- Les champs obligatoires sont marqués d'un astérisque rouge.
- Les champs de type "radio" et "checkbox" nécessitent au moins deux options.
- Pour les champs de type "date", une date approchant de sa limite sera signalée.
`,
  },
  {
    title: 'Gestion des locaux',
    description: `

    Le composant Gestion des locaux de DRIVESOFT permet de gérer les locaux de votre entreprise. Voici une présentation de ses principales fonctionnalités :

### Fonctionnalités principales :

#### Affichage des locaux :
- **Liste des locaux** : Affiche une liste complète des locaux avec les informations suivantes :
  - Nom
  - Adresse
  - Dates de vérification (électrique, extincteur, éléments de sécurité, entretien extincteur, porte sectionnelle)
  - Actions (Éditer, Supprimer)

#### Gestion des locaux :
- **Ajouter un local** : Cliquez sur l'icône d'ajout pour ouvrir une fenêtre modale permettant d'ajouter un nouveau local.
- **Modifier un local** : Cliquez sur l'icône d'édition à côté d'un local pour ouvrir une fenêtre modale permettant de modifier ses détails.
- **Supprimer un local** : Cliquez sur l'icône de suppression à côté du local que vous souhaitez supprimer.

### Interaction et navigation :

### Instructions détaillées pour les formulaires de gestion des locaux :

#### Ajouter/Modifier un local :
- **Nom** : Entrez le nom du local.
- **Adresse** : Entrez l'adresse du local.
- **Date de vérification électrique** : Sélectionnez la date de la dernière vérification électrique.
- **Date de vérification extincteur** : Sélectionnez la date de la dernière vérification extincteur.
- **Date de vérification des éléments de sécurité** : Sélectionnez la date de la dernière vérification des éléments de sécurité.
- **Date d'entretien extincteur** : Sélectionnez la date du dernier entretien extincteur.
- **Date de vérification porte** : Sélectionnez la date de la dernière vérification de la porte sectionnelle.
- **Loyer** : Entrez le montant du loyer.
- **Documents associés** : Téléchargez ou visualisez les documents associés (vérification électrique, vérification extincteur, etc.).
  - Cliquez sur l'icône de visualisation pour voir le document.
  - Cliquez sur l'icône de téléchargement pour télécharger le document.

  #### Statistiques et alertes :

- **Alertes de contrôle technique** : Le système vérifie les dates de contrôle et affiche des alertes si des dates d'expiration sont proches ou dépassées.
- **Création automatique de tâches** : Des tâches sont automatiquement créées pour les dates de contrôle approchant leur date d'expiration.

### Astuces :
- Utilisez les icônes et les boutons pour naviguer facilement entre les locaux et gérer leurs informations.
- Les champs obligatoires sont marqués d'un astérisque rouge.
- Les fichiers doivent être au format PNG, JPEG ou PDF et ne pas dépasser 2 Mo.
`,
  },
];

const regulationsFeatures = [
  {
    title: 'Gestion de la régulation',
    description: `

    Le composant Gestion de la régulation de DRIVESOFT permet de gérer les transports de votre entreprise. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales :

### Gestion des transports :
- **Ajout de transport** : Ajoutez de nouvelles missions de transport en cliquant sur le bouton "Ajouter une mission".
- **Modification de transport** : Cliquez sur une mission existante pour ouvrir le modal de modification.
- **Suppression de transport** : Supprimez une mission en cliquant sur l'icône de la poubelle à côté de la mission.
- **Filtrage des transports** : Filtrez les missions par type de transport, véhicule ou effectuez une recherche par nom, lieu de départ ou d'arrivée.

### Visualisation des missions :
- **Vue par heure de départ** : Les missions sont triées par heure de départ et peuvent être développées ou réduites par heure.
- **Chiffre d'affaires du Jour** : Visualisez le chiffre d'affaires généré par les missions terminées ou annulées facturées pour le jour sélectionné.
- **Statut des missions** : Les missions sont colorées selon leur statut (En cours, Terminé, Annulé).

### Gestion des incidents :
- **Ajout d'incidents** : Ajoutez des incidents spécifiques à une mission (par exemple, annuler sur place, patient non prêt).
- **Description des incidents** : Fournissez des descriptions détaillées des incidents pour un meilleur suivi.

### Calcul des devis :
- **Devis rapide** : Établissez rapidement un devis pour une mission en utilisant les tarifs applicables et les détails du transport.

### Notifications :
- **Envoi de SMS** : Envoyez des notifications par SMS aux véhicules assignés pour les informer des détails de la mission.

### Gestion des véhicules et équipages :
- **Affectation des véhicules** : Assignez des véhicules et des chauffeurs spécifiques aux missions.
- **Visualisation des équipages Actuels** : Affichez les équipages disponibles pour le jour sélectionné et assignez-les aux missions.

### Historique et recherche avancée :
- **Historique des missions** : Consultez l'historique des missions par date et statut.
- **Recherche avancée** : Recherchez des missions en utilisant des critères avancés comme le type de transport, les noms des patients ou les lieux de départ et d'arrivée.

## Interaction et navigation :

- **Navigation dans l'application** : Utilisez la barre de navigation en haut pour accéder aux différentes sections (ajout de mission, devis, etc.).
- **Sélection de la date** : Utilisez les flèches pour naviguer entre les jours ou sélectionnez une date spécifique pour voir les missions de ce jour.
- **Ajout et modification** : Utilisez les boutons et les formulaires pour ajouter ou modifier les missions. Les champs requis sont indiqués et des validations sont effectuées pour assurer la complétude des informations.

## Instructions détaillées pour les formulaires de gestion des transports :

### Ajouter/Modifier un transport :
- **Date** : Sélectionnez la date de la mission.
- **Heure de rendez-vous** : Entrez l'heure de rendez-vous.
- **Heure de départ** : Entrez l'heure de départ.
- **Mode de transport** : Sélectionnez le type de transport (Ambulance, VSL, etc.).
- **Nom** : Entrez le nom du patient.
- **Prénom** : Entrez le prénom du patient.
- **Numéro de téléphone** : Entrez le numéro de téléphone du patient.
- **Adresse de départ** : Entrez l'adresse de départ.
- **Adresse d'arrivée** : Entrez l'adresse d'arrivée.
- **Motif** : Sélectionnez le motif de la mission (Consultation, Transfert, etc.).
- **Retour** : Indiquez si un retour est prévu.
- **Commentaires** : Ajoutez des commentaires supplémentaires si nécessaire.
- **Chauffeur** : Assignez un chauffeur à la mission.
- **Véhicule** : Assignez un véhicule à la mission.
- **Statut** : Mettez à jour le statut de la mission (Non affecté, En cours, Terminé, Annulé).

## Statistiques et alertes :

- **Alertes de retard** : Les retards sont indiqués et colorés selon le degré de retard.
- **Chiffre d'affaires** : Le chiffre d'affaires est calculé et affiché pour chaque type de transport (Ambulance, VSL, etc.).
`,
  }
];

const billingFeatures = [
  {
    title: 'Gestion des assurés',
    description: `

  Le composant Gestion des Assurés de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des informations des assurés. Voici une présentation de ses principales fonctionnalités :
  
  ## Fonctionnalités principales
  
  ### Vue d'ensemble des assurés
  - **Affichage des assurés** : Affiche une liste complète des assurés avec des détails tels que le nom, le prénom, la date de naissance, le téléphone, l'email et la date de fin des droits.
  - **Actions sur les assurés** : Permet d'ajouter, de modifier et de supprimer des assurés.
  
  ### Formulaire d'ajout/modification des assurés
  - **Informations personnelles** : Saisissez les informations personnelles de l'assuré telles que la civilité, le nom, le prénom, la date de naissance, l'adresse, le téléphone, l'email, etc.
  - **Informations d'assurance** : Saisissez les détails de l'assurance tels que le type, l'exonération, le taux, la nature de l'assurance, la date de fin des droits, etc.
  - **Détails de la caisse et de la mutuelle** : Sélectionnez et affichez les détails de la caisse et de la mutuelle associées à l'assuré.
  
  ### Gestion des bénéficiaires
  - **Affichage des bénéficiaires** : Affiche une liste des bénéficiaires associés à un assuré.
  - **Ajout et modification des bénéficiaires** : Permet d'ajouter et de modifier les informations des bénéficiaires.
  - **Suppression des bénéficiaires** : Permet de supprimer des bénéficiaires.
  
  ## Interface utilisateur
  
  ### Liste des assurés
  Les assurés sont affichés dans un tableau avec les colonnes suivantes :
  - **Civilité**
  - **Nom**
  - **Nom d'usage**
  - **Prénom**
  - **Date de naissance**
  - **Téléphone**
  - **Email**
  - **Date de fin des droits**
  - **Commentaire**
  - **Actions** : Boutons pour éditer et supprimer les assurés.
  
  ### Formulaire d'ajout/modification des assurés
  Les utilisateurs peuvent ajouter ou modifier les informations des assurés via un formulaire contenant les champs suivants :
  - **Civilité** : Sélectionnez la civilité (Mr, Mme).
  - **Nom** : Saisissez le nom de l'assuré.
  - **Nom d'usage** : Saisissez le nom d'usage de l'assuré.
  - **Prénom** : Saisissez le prénom de l'assuré.
  - **Date de naissance** : Sélectionnez la date de naissance de l'assuré.
  - **Adresse** : Saisissez l'adresse complète de l'assuré (adresse, code postal, ville, bâtiment, étage, porte).
  - **Téléphone** : Saisissez et formatez le numéro de téléphone de l'assuré.
  - **Numéro de sécurité sociale** : Saisissez et formatez le numéro de sécurité sociale de l'assuré.
  - **Email** : Saisissez l'email de l'assuré.
  - **Commentaire** : Saisissez un commentaire éventuel.
  
  ### Détails de l'assurance
  Les utilisateurs peuvent saisir les détails de l'assurance de l'assuré :
  - **Type d'assuré** : Saisissez le type d'assuré.
  - **Exonération** : Sélectionnez une exonération parmi les options disponibles.
  - **Taux** : Saisissez le taux d'exonération.
  - **Nature de l'assurance** : Saisissez la nature de l'assurance.
  - **Date de fin des droits** : Sélectionnez la date de fin des droits.
  
  ### Détails de la caisse et de la mutuelle
  Les utilisateurs peuvent sélectionner et afficher les détails de la caisse et de la mutuelle associées à l'assuré :
  - **Caisse** : Sélectionnez une caisse parmi les options disponibles. Les détails de la caisse sont affichés automatiquement.
  - **Mutuelle** : Sélectionnez une mutuelle parmi les options disponibles. Les détails de la mutuelle sont affichés automatiquement.
  
  ### Gestion des bénéficiaires
  Les utilisateurs peuvent gérer les bénéficiaires associés à un assuré :
  - **Ajouter bénéficiaire** : Permet d'ajouter un nouveau bénéficiaire avec les informations suivantes : nom, prénom, date de naissance, lien, rang.
  - **Modifier bénéficiaire** : Permet de modifier les informations d'un bénéficiaire existant.
  - **Supprimer bénéficiaire** : Permet de supprimer un bénéficiaire de la liste.
  
  ## Utilisation
  Le composant Gestion des Assurés est conçu pour fournir une solution centralisée et accessible pour la gestion des informations des assurés et de leurs bénéficiaires, aidant ainsi à maintenir une base de données organisée et à jour.
  
  ---
  
  Avec le composant Gestion des Assurés, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles des assurés et de leurs bénéficiaires, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

  `,
  },

  {
    title: 'Gestion des caisses',
    description: `

  Le composant Gestion des Caisses de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des informations des caisses. Voici une présentation de ses principales fonctionnalités :
  
  ## Fonctionnalités principales
  
  ### Vue d'ensemble des caisses
  - **Affichage des caisses** : Affiche une liste complète des caisses avec des détails tels que le code régime, la caisse gestionnaire, le centre gestionnaire, le libellé, le régime, l'organisme destinataire, le code centre informatique, les adresses, la commune, le code postal, le cedex, le téléphone et le fax.
  - **Actions sur les caisses** : Permet d'ajouter, de modifier et de supprimer des caisses.
  
  ### Formulaire d'ajout/modification des caisses
  - **Informations sur la caisse** : Saisissez les informations détaillées de la caisse telles que le code régime, la caisse gestionnaire, le centre gestionnaire, le libellé, le régime, l'organisme destinataire, le code centre informatique, les adresses, la commune, le code postal, le cedex, le téléphone et le fax.
  
  ### Importation de fichiers
  - **Importer des fichiers** : Permet d'importer des fichiers Excel (.xlsx) contenant les informations des caisses pour une mise à jour en masse.
  
  ## Interface utilisateur
  
  ### Liste des caisses
  Les caisses sont affichées dans un tableau avec les colonnes suivantes :
  - **Code Régime**
  - **Caisse Gestionnaire**
  - **Centre Gestionnaire**
  - **Libellé**
  - **Régime**
  - **Organisme Destinataire**
  - **Code Centre Informatique**
  - **Adresse 1**
  - **Adresse 2**
  - **Commune**
  - **Code Postal**
  - **Cedex**
  - **Téléphone**
  - **Fax**
  - **Actions** : Boutons pour éditer et supprimer les caisses.
  
  ### Formulaire d'ajout/modification des caisses
  Les utilisateurs peuvent ajouter ou modifier les informations des caisses via un formulaire contenant les champs suivants :
  - **Code Régime** : Saisissez le code régime de la caisse.
  - **Caisse Gestionnaire** : Saisissez la caisse gestionnaire.
  - **Centre Gestionnaire** : Saisissez le centre gestionnaire.
  - **Libellé** : Saisissez le libellé de la caisse.
  - **Régime** : Saisissez le régime de la caisse.
  - **Organisme Destinataire** : Saisissez l'organisme destinataire.
  - **Code Centre Informatique** : Saisissez le code centre informatique.
  - **Adresse 1** : Saisissez la première ligne de l'adresse.
  - **Adresse 2** : Saisissez la deuxième ligne de l'adresse (facultatif).
  - **Commune** : Saisissez la commune.
  - **Code Postal** : Saisissez le code postal.
  - **Cedex** : Saisissez le cedex.
  - **Téléphone** : Saisissez le numéro de téléphone.
  - **Fax** : Saisissez le numéro de fax (facultatif).
  
  ### Importation de fichiers
  Les utilisateurs peuvent importer des fichiers Excel contenant les informations des caisses :
  - **Importer un fichier** : Sélectionnez un fichier Excel à importer. Les informations contenues dans le fichier seront utilisées pour mettre à jour la liste des caisses.
  
  ## Utilisation
  Le composant Gestion des Caisses est conçu pour fournir une solution centralisée et accessible pour la gestion des informations des caisses, aidant ainsi à maintenir une base de données organisée et à jour.
  
  ---
  
  Avec le composant Gestion des Caisses, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles des caisses, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.
  `,
  },
  {
    title: 'Gestion des marchés',
    description: `

Le composant Gestion des Marchés de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des informations des marchés. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des marchés
- **Affichage des marchés** : Affiche une liste complète des marchés avec des détails tels que la société, le numéro de marché, le libellé, la date de début et la date de fin.
- **Actions sur les marchés** : Permet d'ajouter, de modifier et de supprimer des marchés.

### Formulaire d'ajout/modification des marchés
- **Informations sur le marché** : Saisissez les informations détaillées du marché telles que la société, le numéro de marché, le libellé, la date de début et la date de fin.

## Interface utilisateur

### Liste des marchés
Les marchés sont affichés dans un tableau avec les colonnes suivantes :
- **Société** : Affiche la société associée au marché.
- **Numéro de marché** : Affiche le numéro de marché.
- **Libellé** : Affiche le libellé du marché.
- **Début** : Affiche la date de début du marché.
- **Fin** : Affiche la date de fin du marché.
- **Actions** : Boutons pour éditer et supprimer les marchés.

### Formulaire d'ajout/modification des marchés
Les utilisateurs peuvent ajouter ou modifier les informations des marchés via un formulaire contenant les champs suivants :
- **Société** : Sélectionnez la société associée au marché.
- **Numéro de marché** : Saisissez le numéro de marché.
- **Libellé** : Saisissez le libellé du marché.
- **Début** : Saisissez la date de début du marché.
- **Fin** : Saisissez la date de fin du marché.

## Utilisation
Le composant Gestion des Marchés est conçu pour fournir une solution centralisée et accessible pour la gestion des informations des marchés, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des Marchés, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles des marchés, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

    `,
  },
  {
    title: 'Gestion des mutuelles',
    description: `

Le composant Gestion des Mutuelles de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des informations des mutuelles. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des mutuelles
- **Affichage des mutuelles** : Affiche une liste complète des mutuelles avec des détails tels que le code, le nom, le numéro de contrat, le type de contrat, le CMU, l'AME, le risque, l'exo, le taux, l'adresse, le complément, le code postal, la ville, le téléphone, l'email et le numéro de compte.
- **Actions sur les mutuelles** : Permet d'ajouter, de modifier et de supprimer des mutuelles.

### Formulaire d'ajout/modification des mutuelles
- **Informations sur la mutuelle** : Saisissez les informations détaillées de la mutuelle telles que le code, le nom, le numéro de contrat, le type de contrat, le CMU, l'AME, le risque, l'exo, le taux, l'adresse, le complément, le code postal, la ville, le téléphone, l'email et le numéro de compte.

## Interface utilisateur

### Liste des mutuelles
Les mutuelles sont affichées dans un tableau avec les colonnes suivantes :
- **Code** : Affiche le code de la mutuelle.
- **Nom** : Affiche le nom de la mutuelle.
- **Numéro de contrat** : Affiche le numéro de contrat de la mutuelle.
- **Type de contrat** : Affiche le type de contrat de la mutuelle.
- **CMU** : Indique si la mutuelle couvre le CMU.
- **AME** : Indique si la mutuelle couvre l'AME.
- **Risque** : Affiche le risque associé à la mutuelle.
- **Exo** : Affiche l'exonération associée à la mutuelle.
- **Taux** : Affiche le taux de la mutuelle.
- **Adresse** : Affiche l'adresse de la mutuelle.
- **Complément** : Affiche le complément d'adresse de la mutuelle.
- **Code postal** : Affiche le code postal de la mutuelle.
- **Ville** : Affiche la ville de la mutuelle.
- **Téléphone** : Affiche le numéro de téléphone de la mutuelle.
- **Email** : Affiche l'email de la mutuelle.
- **Numéro de compte** : Affiche le numéro de compte de la mutuelle.
- **Actions** : Boutons pour éditer et supprimer les mutuelles.

### Formulaire d'ajout/modification des mutuelles
Les utilisateurs peuvent ajouter ou modifier les informations des mutuelles via un formulaire contenant les champs suivants :
- **Code** : Saisissez le code de la mutuelle.
- **Nom** : Saisissez le nom de la mutuelle.
- **Numéro de contrat** : Saisissez le numéro de contrat de la mutuelle.
- **Type de contrat** : Saisissez le type de contrat de la mutuelle.
- **CMU** : Cochez cette case si la mutuelle couvre le CMU.
- **AME** : Cochez cette case si la mutuelle couvre l'AME.
- **Risque** : Saisissez le risque associé à la mutuelle.
- **Exo** : Saisissez l'exonération associée à la mutuelle.
- **Taux** : Saisissez le taux de la mutuelle.
- **Adresse** : Saisissez l'adresse de la mutuelle.
- **Complément** : Saisissez le complément d'adresse de la mutuelle.
- **Code postal** : Saisissez le code postal de la mutuelle.
- **Ville** : Saisissez la ville de la mutuelle.
- **Téléphone** : Saisissez le numéro de téléphone de la mutuelle.
- **Email** : Saisissez l'email de la mutuelle.
- **Numéro de compte** : Saisissez le numéro de compte de la mutuelle.

## Utilisation
Le composant Gestion des Mutuelles est conçu pour fournir une solution centralisée et accessible pour la gestion des informations des mutuelles, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des Mutuelles, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles des mutuelles, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

`,
  },
  {
    title: 'Gestion des prescripteurs', description: `

Le composant Gestion des Prescripteurs de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des informations des prescripteurs. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des prescripteurs
- **Affichage des prescripteurs** : Affiche une liste complète des prescripteurs avec des détails tels que le code, l'identifiant, le nom, le prénom, l'adresse, le code postal, la ville, le téléphone et la spécialité.
- **Actions sur les prescripteurs** : Permet d'ajouter, de modifier et de supprimer des prescripteurs.

### Formulaire d'ajout/modification des prescripteurs
- **Informations sur le prescripteur** : Saisissez les informations détaillées du prescripteur telles que le code, l'identifiant, le nom, le prénom, l'adresse, le code postal, la ville, le téléphone et la spécialité.

## Interface utilisateur

### Liste des prescripteurs
Les prescripteurs sont affichés dans un tableau avec les colonnes suivantes :
- **Code** : Affiche le code du prescripteur.
- **Identifiant** : Affiche l'identifiant du prescripteur.
- **Nom** : Affiche le nom du prescripteur.
- **Prénom** : Affiche le prénom du prescripteur.
- **Adresse** : Affiche l'adresse du prescripteur.
- **Code Postal** : Affiche le code postal du prescripteur.
- **Ville** : Affiche la ville du prescripteur.
- **Téléphone** : Affiche le numéro de téléphone du prescripteur.
- **Spécialité** : Affiche la spécialité du prescripteur.
- **Actions** : Boutons pour éditer et supprimer les prescripteurs.

### Formulaire d'ajout/modification des prescripteurs
Les utilisateurs peuvent ajouter ou modifier les informations des prescripteurs via un formulaire contenant les champs suivants :
- **Code** : Saisissez le code du prescripteur.
- **Identifiant** : Saisissez l'identifiant du prescripteur.
- **Nom** : Saisissez le nom du prescripteur.
- **Prénom** : Saisissez le prénom du prescripteur.
- **Adresse** : Saisissez l'adresse du prescripteur.
- **Code Postal** : Saisissez le code postal du prescripteur.
- **Ville** : Saisissez la ville du prescripteur.
- **Téléphone** : Saisissez le numéro de téléphone du prescripteur.
- **Spécialité** : Saisissez la spécialité du prescripteur.

## Utilisation
Le composant Gestion des Prescripteurs est conçu pour fournir une solution centralisée et accessible pour la gestion des informations des prescripteurs, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des Prescripteurs, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles des prescripteurs, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

  `,
  },
  {
    title: 'Gestion des risques / exonérations',
    description: `

Le composant Gestion des Risques / Exonérations de DRIVESOFT permet une gestion efficace des risques et exonérations associés aux assurés. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des risques / Exonérations
- **Affichage des risques/exonérations** : Affiche une liste complète des risques et exonérations avec des détails tels que le code risque, le code exonération, le taux et le commentaire.
- **Actions sur les risques/exonérations** : Permet d'ajouter, de modifier et de supprimer des entrées de risques/exonérations.

### Formulaire d'ajout/modification des risques/exonérations
- **Informations sur le risque/exonération** : Saisissez les informations détaillées telles que le code risque, le code exonération, le taux et le commentaire.

## Interface utilisateur

### Liste des Risques / Exonérations
Les risques et exonérations sont affichés dans un tableau avec les colonnes suivantes :
- **Code risque** : Affiche le code du risque.
- **Code exonération** : Affiche le code de l'exonération.
- **Taux** : Affiche le taux associé.
- **Commentaire** : Affiche le commentaire associé.
- **Actions** : Boutons pour éditer et supprimer les entrées de risques/exonérations.

### Formulaire d'ajout/modification des Risques / Exonérations
Les utilisateurs peuvent ajouter ou modifier les informations des risques/exonérations via un formulaire contenant les champs suivants :
- **Code risque** : Saisissez le code du risque.
- **Code exonération** : Saisissez le code de l'exonération.
- **Taux** : Saisissez le taux associé.
- **Commentaire** : Saisissez un commentaire associé.

## Utilisation
Le composant Gestion des risques / Exonérations est conçu pour fournir une solution centralisée et accessible pour la gestion des risques et exonérations associés aux assurés, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des risques / Exonérations, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles sur les risques et exonérations, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

    `,
  },

  {
    title: 'Gestion des tarifs', description: `

Le composant Gestion des Tarifs de DRIVESOFT permet une gestion complète et efficace des tarifs. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des tarifs
- **Affichage des tarifs** : Affiche une liste complète des tarifs regroupés par type.
- **Actions sur les tarifs** : Permet d'ajouter, de modifier et de supprimer des tarifs.
- **Ajout et gestion des types de tarifs** : Possibilité d'ajouter de nouveaux types de tarifs.

### Formulaire d'ajout/modification des tarifs
- **Informations sur le tarif** : Saisissez les informations détaillées telles que le nom, la prise en charge, la réduction pour deux et trois personnes, la TVA, les majorations, les conditions kilométriques, etc.
- **Types de tarifs** : Sélectionnez le type de tarif parmi les types disponibles.

## Interface utilisateur

### Liste des tarifs
Les tarifs sont affichés dans un tableau avec les colonnes suivantes :
- **Nom** : Affiche le nom du tarif.
- **Catégorie** : Affiche la catégorie du tarif.
- **Pris en charge** : Affiche le montant de prise en charge.
- **Remise 2 pers.** : Affiche la remise pour deux personnes en pourcentage.
- **Remise 3 pers.** : Affiche la remise pour trois personnes en pourcentage.
- **TVA** : Affiche le taux de TVA en pourcentage.
- **Tarif kilométrique** : Affiche le tarif kilométrique.
- **Majorations** : Affiche les majorations applicables.
- **Tarifs court trajet** : Affiche les tarifs pour les courts trajets.
- **Actions** : Boutons pour éditer et supprimer les tarifs.

### Formulaire d'ajout/modification des Tarifs
Les utilisateurs peuvent ajouter ou modifier les informations des tarifs via un formulaire contenant les champs suivants :
- **Nom du tarif** : Saisissez le nom du tarif.
- **Type de tarif** : Sélectionnez le type de tarif parmi les types disponibles.
- **Prise en charge (€)** : Saisissez le montant de prise en charge.
- **Tarif kilométrique (€)** : Saisissez le tarif kilométrique.
- **Catégorie** : Sélectionnez la catégorie du tarif.
- **Réduction pour deux personnes (%)** : Saisissez la réduction pour deux personnes en pourcentage.
- **Réduction pour trois personnes (%)** : Saisissez la réduction pour trois personnes en pourcentage.
- **TVA (10%)** : Saisissez le taux de TVA.
- **Majorations** : Saisissez les majorations applicables.
- **Conditions kilométriques** : Saisissez les conditions kilométriques et les tarifs correspondants.

### Gestion des types de tarifs
Les utilisateurs peuvent ajouter de nouveaux types de tarifs via un formulaire dédié.

## Utilisation
Le composant Gestion des Tarifs est conçu pour fournir une solution centralisée et accessible pour la gestion des tarifs, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des Tarifs, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles sur les tarifs, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

    `,
  },

  {
    title: 'Gestion des factures', description: `

Le composant Gestion des Factures de DRIVESOFT permet une gestion complète des factures. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des factures
- **Affichage des factures** : Affiche une liste complète des factures avec des informations essentielles telles que le numéro de facture, la date de la facture, le type de transport, et le nom de l'assuré.
- **Actions sur les factures** : Permet de créer, modifier et supprimer des factures.

### Formulaire d'ajout/modification des Factures
- **Informations sur la facture** : Saisissez les informations détaillées telles que le numéro de télétransmission, le numéro de relevé, le type de transport, le numéro de facture, etc.
- **Renseignements sur l'assuré** : Saisissez les informations de l'assuré, telles que le nom, le prénom, le numéro de sécurité sociale, l'adresse, etc.
- **Informations du transport** : Saisissez les détails du transport, tels que le motif, l'origine, le numéro de prescripteur, la date et l'heure de départ et d'arrivée, la distance, le nombre de personnes, etc.
- **Modalité de règlement** : Saisissez les détails du paiement, tels que la dispense d'avance de frais, le paiement direct, la signature et le lieu de paiement, la date de paiement, etc.
- **Attestation sur l’honneur** : Saisissez les informations de l'attestation sur l’honneur, telles que le lieu, la date et la signature de l'attestation.
- **Règlements** : Saisissez les montants payés par l'assuré, la caisse, la complémentaire, et les divers paiements.
- **Tarif** : Saisissez les informations tarifaires, telles que le nom du tarif, le type de tarif, le forfait, le tarif taxi, etc.
- **Renseignements société** : Saisissez les informations de la société, telles que le nom, le SIRET, l'adresse, le code postal, la ville, et le numéro d'identification de la société.

## Interface utilisateur

### Liste des factures
Les factures sont affichées dans un tableau avec les colonnes suivantes :
- **Numéro de Facture** : Affiche le numéro de la facture.
- **Date de Facture** : Affiche la date de la facture.
- **Type de Transport** : Affiche le type de transport.
- **Nom de l'Assuré** : Affiche le nom de l'assuré.
- **Actions** : Boutons pour éditer et supprimer les factures.

### Formulaire d'ajout/modification des Factures
Les utilisateurs peuvent ajouter ou modifier les informations des factures via un formulaire détaillé contenant plusieurs sections pour les différentes catégories d'informations nécessaires à la création d'une facture.

### Gestion des types de tarifs
Les utilisateurs peuvent sélectionner le type de tarif et les options tarifaires à appliquer à la facture, avec des calculs automatiques pour les différentes majorations et remises.

## Utilisation
Le composant Gestion des factures est conçu pour fournir une solution centralisée et accessible pour la gestion des factures, aidant ainsi à maintenir une base de données organisée et à jour.

---

Avec le composant Gestion des Factures, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux informations essentielles sur les factures, facilitant ainsi le travail des administrateurs et des utilisateurs autorisés.

    `,
  },

];

const dashboardFeatures = [
  {
    title: 'Tableau de bord',
    description: `

Le composant Tableau de bord offre une interface utilisateur centralisée pour visualiser les performances et les données clés de votre entreprise. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble et chiffres détaillés
- Les utilisateurs peuvent basculer entre une vue d'ensemble et des chiffres détaillés en cliquant sur un bouton. 
  - **Vue d'ensemble** : Affiche un résumé des chiffres clés, comme le chiffre d'affaires global et le nombre de missions.
  - **Chiffres détaillés** : Affiche des détails spécifiques sur les transports, les types de missions et les performances des chauffeurs et véhicules.

### Filtrage par année et société
- **Sélection de l'année** : Les utilisateurs peuvent sélectionner l'année pour laquelle ils souhaitent voir les données.
- **Filtrage par société** : Les utilisateurs peuvent filtrer les données par société spécifique.

#### Vue d'ensemble
- **Chiffre d'affaires** : Affiche le chiffre d'affaires global, le nombre de missions, et le chiffre d'affaires du mois dernier.
- **Graphiques** : Affiche des graphiques interactifs montrant l'évolution du chiffre d'affaires par mois et par type de mission (Ambulance, Paramédical, VSL, Samu, Taxi).

#### Division des ventes
- **Filtrage par période** : Les utilisateurs peuvent sélectionner une période spécifique à l'aide d'un sélecteur de date pour visualiser les données pertinentes.
- **Types de Missions** : Affiche des statistiques détaillées sur chaque type de mission, y compris le chiffre d'affaires et le nombre de missions pour Ambulance, Paramédical, VSL, Samu et Taxi.
- **Grilles de Données** : Offre deux vues différentes :
  - **Chauffeurs** : Affiche des informations détaillées sur les chauffeurs, y compris leurs heures travaillées, le nombre de missions, et leur chiffre d'affaires.
  - **Véhicules** : Affiche des informations détaillées sur les véhicules, y compris leur immatriculation, la moyenne de prix par mission, et le chiffre d'affaires généré.

## Avantages
- **Interface utilisateur intuitive** : Le tableau de bord est conçu pour être facile à utiliser et naviguer.
- **Visualisation des données** : Les graphiques interactifs et les tableaux permettent une analyse rapide et efficace des données.
- **Personnalisation** : Les filtres par année et société permettent aux utilisateurs de personnaliser les données affichées selon leurs besoins.

---

Avec ce tableau de bord, DRIVESOFT fournit un outil puissant pour la gestion et l'analyse des données de transport, permettant aux utilisateurs de suivre facilement les performances et de prendre des décisions éclairées.
    `,
  },

];

const dataFeatures = [
  {
    title: 'Données d\'exploitation',
    description: `

Le composant Données d'exploitation de DRIVESOFT offre une interface complète pour la gestion et l'analyse des données d'exploitation de votre entreprise. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Vue d'ensemble des données d'exploitation
- **Gestion des rôles** : Le tableau de bord est accessible uniquement aux utilisateurs autorisés.
- **Filtrage par période** : Les utilisateurs peuvent sélectionner une période spécifique à l'aide d'un sélecteur de date pour visualiser les données pertinentes.

### Données ressources humaines
- **Taux de présence et d'absentéisme** : Affiche le taux de présence et d'absentéisme des employés.
- **Taux de turn-over** : Indique le taux de départ des employés pendant la période sélectionnée.
- **Temps de formation** : Affiche le pourcentage de temps consacré à la formation des employés.
- **Indices de maladies et d'accidents** : Montre les indices de fréquence des arrêts maladie et des accidents du travail.
- **Balance homme/femme et moyenne d'âge** : Affiche la répartition par genre et l'âge moyen des employés.
- **Distribution par âge** : Graphique en barres montrant la distribution par tranche d'âge du personnel.

### Incidents établissements
- **Filtrage par établissement** : Permet de filtrer les incidents par établissement.
- **Liste des incidents** : Affiche une liste détaillée des incidents avec des informations telles que la date, le nom du patient, l'établissement, le service, la raison de la mission, le type et la description de l'incident.
- **Graphique en donut** : Montre la répartition des types d'incidents par établissement.

### Incidents personnel
- **Filtrage par chauffeur** : Permet de filtrer les incidents par chauffeur.
- **Liste des incidents** : Affiche une liste détaillée des incidents impliquant le personnel avec des informations telles que la date, le type et la description de l'incident.
- **Graphique en donut** : Montre la répartition des types d'incidents par chauffeur.

### Données de gestion de stock
- **Total des articles sortis** : Affiche le total en euros des articles sortis.
- **Répartition par raison** : Affiche une répartition des articles sortis par raison, avec le nombre d'articles et le total en euros.
- **Graphiques en donut** : Montre les réapprovisionnements par véhicule et les retraits par raison en euros.

### Données de gestion de véhicules
- **Filtrage par société et véhicule** : Permet de filtrer les données par société et par véhicule.
- **Liste des factures de véhicules** : Affiche une liste détaillée des factures associées aux véhicules, y compris le code, l'immatriculation, la marque, le type, les kilomètres, la date, le type de facture, le sous-type de facture et le total HT en euros.
- **Graphiques en donut** : Montre le coût total par type de réparation et par véhicule.

### Données de gestion des procédures
- **Statistiques des procédures** : Affiche des statistiques sur les procédures par employé et par véhicule.
- **Graphiques en barres et en donut** : Montre le nombre de procédures par employé, par véhicule, et les balances des retours des procédures.

## Avantages
- **Interface utilisateur intuitive** : Conçu pour être facile à utiliser et naviguer.
- **Visualisation des données** : Les graphiques interactifs et les tableaux permettent une analyse rapide et efficace des données.
- **Personnalisation** : Les filtres par période, société et véhicule permettent aux utilisateurs de personnaliser les données affichées selon leurs besoins.

## Utilisation
Le tableau de bord des Données d'exploitation est accessible à tous les utilisateurs autorisés et offre une vue centralisée des performances de l'entreprise, aidant ainsi à la prise de décision et à l'optimisation des opérations.

---

Avec ce tableau de bord, DRIVESOFT fournit un outil puissant pour la gestion et l'analyse des données d'exploitation, permettant aux utilisateurs de suivre facilement les performances et de prendre des décisions éclairées.
    `,
  },

];

const documentHolderFeatures = [
  {
    title: 'Porte document',
    description: `

Le composant Porte Document de DRIVESOFT offre une interface utilisateur intuitive pour la gestion et la visualisation des documents d'entreprise. Voici une présentation de ses principales fonctionnalités :

## Fonctionnalités principales

### Gestion des documents
- **Ajout de documents** : Les utilisateurs autorisés peuvent ajouter de nouveaux documents en téléchargeant des fichiers et en spécifiant des catégories, des sociétés et des titres pour chaque document.
- **Visualisation des documents** : Les documents peuvent être visualisés directement dans une nouvelle fenêtre ou un nouvel onglet.
- **Téléchargement des documents** : Les utilisateurs peuvent télécharger les documents.
- **Suppression des documents** : Les utilisateurs autorisés peuvent supprimer les documents.

### Catégories de documents
- **Livret d'accueil**
- **Règlement intérieur**
- **Note de service**
- **Protocoles**
- **Fiches de données de sécurité**
- **Réunions**
- **Autre**

## Interface utilisateur

### Formulaire d'ajout de document
Les utilisateurs autorisés peuvent ajouter de nouveaux documents en remplissant un formulaire contenant les champs suivants :
- **Choisir un fichier** : Sélectionnez un fichier à télécharger depuis votre ordinateur.
- **Titre du document** : Saisissez le titre du document.
- **Catégorie** : Sélectionnez la catégorie du document parmi les options disponibles.
- **Sociétés** : Sélectionnez une ou plusieurs sociétés pour lesquelles le document est pertinent.

### Liste des documents
Les documents sont affichés par catégorie dans des accordéons. Chaque accordéon peut être développé pour afficher les documents de la catégorie correspondante. Pour chaque document, les actions suivantes sont disponibles :
- **Visualiser** : Ouvre le document dans une nouvelle fenêtre ou un nouvel onglet.
- **Télécharger** : Télécharge le document sur l'ordinateur de l'utilisateur.
- **Supprimer** : Supprime le document de la base de données (uniquement pour les utilisateurs autorisés).

## Utilisation
Le Porte Document est conçu pour fournir une solution centralisée et accessible pour la gestion des documents d'entreprise, permettant aux utilisateurs de visualiser, télécharger et gérer les documents importants en toute simplicité.

---

Avec le Porte Document, DRIVESOFT fournit un outil efficace pour la gestion et l'accès aux documents essentiels, aidant ainsi à maintenir l'organisation et la conformité de l'entreprise.

    `,
  },

];

const mobileFeatures = [
  {
    title: 'Planning',
    description: `
  
Optimisez l'organisation du temps de travail et assurez une communication fluide des plannings grâce à notre application. Voici comment nous facilitons la gestion des plannings :

### Gestion centralisée des plannings

La direction utilise notre outil de gestion pour remplir et ajuster les plannings des employés. Ce processus centralisé permet de planifier efficacement le travail en tenant compte des besoins opérationnels et des disponibilités des employés.

### Accès en temps réel

Une fois les plannings établis, ils sont immédiatement disponibles sur l'application mobile. Les employés peuvent accéder à leurs plannings en temps réel, leur permettant de voir les modifications et les mises à jour dès qu'elles se produisent.

### Amélioration de la Satisfaction des Employés

Ce système de gestion des plannings non seulement rationalise les opérations mais améliore également la satisfaction des employés en leur donnant une visibilité accrue sur leur temps de travail.

---

Disponible sur **iOS** et **Android**
  
    `,
  },
  {
    title: 'Porte document',
    description: `

Centralisez et sécurisez l'accès aux documents pour une meilleure gestion de l'information. Voici le fonctionnement détaillé de cette fonctionnalité :

### Mise à jour des documents importants

La direction met à jour les documents importants de la société, tels que :
- Notes de service
- Livret d'accueil
- Protocoles

Ces documents sont accessibles à tous les employés via l’application.

### Accès aux documents spécifiques aux véhicules

Les documents spécifiques au véhicule attribué pour la journée, tels que :
- Assurance
- Carte grise
- UTAC 

sont également disponibles sur l'application.

### Gestion des documents personnels

Si un ambulancier le souhaite, la direction peut également mettre en ligne ses documents personnels, tels que :
- Permis de conduire
- Carte d'identité
- Diplômes

Ces documents sont strictement privés et accessibles uniquement par l'employé concerné.

### Simplification de la gestion Documentaire

Cette fonctionnalité de porte-document simplifie la gestion documentaire et améliore la communication interne, tout en assurant une haute sécurité et confidentialité des informations.

---

Disponible sur **iOS** et **Android**

    `,
  },

  {
    title: 'Procédures qualité',
    description: `

Cette fonctionnalité permet une collecte et un traitement efficaces des données pour améliorer continuellement nos services et répondre efficacement aux besoins des employés. Voici le processus :

### Accès aux procédures

Les employés ont accès à une variété de procédures via l'application. Ces procédures doivent être complétées à une fréquence spécifique, garantissant ainsi le respect constant des standards de qualité et des réglementations en vigueur.

### Soumission des procédures

Après avoir rempli les procédures, les enquêtes de satisfaction ou les demandes ponctuelles, les employés soumettent leurs réponses via l'application. Ces données sont ensuite envoyées automatiquement à notre système de gestion.

### Génération automatique de tâches

En fonction des réponses, des tâches spécifiques sont générées automatiquement. Par exemple, si une procédure révèle un problème de conformité, une tâche de correction est immédiatement assignée.

### Avantages de cette approche

Cette approche assure non seulement la conformité aux exigences de qualité, mais elle engage également les employés dans le processus d’amélioration, renforçant ainsi leur implication et leur satisfaction au travail.

---

Disponible sur **iOS** et **Android**

    `,
  },

  {
    title: 'Pointage des heures', description: `


Cette fonctionnalité clé de l'application assure une gestion précise et transparente des heures de travail. Voici comment nous procédons :

### Enregistrement et transmission des heures

Chaque employé enregistre ses heures de travail et ses pauses via l'application. Ces données sont ensuite transmises de manière sécurisée à notre système de gestion.

### Vérification et validation

Les heures soumises sont analysées pour vérifier leur exactitude. Une fois les heures validées, un document est généré de façon hebdomadaire. Ce document sert de confirmation pour les employés et peut être utilisé pour des besoins administratifs ou légaux.

### Gestion des incohérences

Si des incohérences sont détectées, une alerte est envoyée à l'employé concerné avec une demande de révision des heures enregistrées. L'employé peut alors répondre à cette incohérence, la corriger ou confirmer ses heures directement dans l’application.

### Avantages de cette approche

Ce processus non seulement garantit l'exactitude des données de temps de travail mais offre aussi une transparence totale et soutient la conformité réglementaire et interne.

---

Disponible sur **iOS** et **Android**

    `,
  },

  {
    title: 'Gestion des stocks',
    description: `

Assurez une gestion précise et efficace de vos fournitures et équipements avec notre application. Voici comment fonctionne cette fonctionnalité :

### Suivi des stocks

- **Mise à jour des stocks** : Les responsables peuvent ajouter, modifier ou supprimer des articles dans l'inventaire via l'application. Chaque article est accompagné de détails tels que la quantité, la date d'expiration et les spécifications.
- **QR code** : Les responsables peuvent générer un QR code pour chaque article, permettant ainsi une accessibilité plus facile et une meilleure prise en charge des stocks.
- **Notifications de réapprovisionnement** : Des alertes automatiques sont envoyées lorsque les niveaux de stock d'un article tombent en dessous d'un seuil prédéfini. Cela permet de réapprovisionner les stocks de manière proactive.
- **Historique des mouvements** : Un historique détaillé des ajouts, retraits et transferts de stocks est disponible, offrant une traçabilité complète.

### Visualisation et accessibilité

- **Consultation en temps réel** : Les employés attribué à la gestion des stocks peuvent consulter l'état des stocks en temps réel depuis leur mobile, via le QR code. Cela inclut la disponibilité des fournitureset les quantités restantes.
- **Recherche et filtrage** : L'application permet de rechercher et de filtrer les articles par catégorie, nom, date d'ajout ou autres critères pertinents.

### Gestion des équipements

- **Assignation des équipements** : Les responsables peuvent assigner des équipements spécifiques à des employés ou des véhicules pour une gestion efficace et transparente.

Cette fonctionnalité garantit une gestion optimisée des stocks, réduisant les pertes et assurant que les équipes disposent toujours des fournitures nécessaires pour travailler efficacement.

--- 

Disponible sur **iOS** et **Android**

    `,
  },

  {
    title: 'Signature électronique',
    description: `

Simplifiez et sécurisez le processus de signature des documents avec notre fonctionnalité de signature électronique. Voici comment cela fonctionne :

### Collecte de signatures

- **Saisie de la signature** : Lors de la première connexion, les employés peuvent enregistrer leurs signatures directement depuis leur appareil mobile. L'interface est intuitive et permet de capturer une signature précise.
- **Effacer et recommencer** : Si nécessaire, la signature peut être effacée et reprise jusqu'à ce qu'elle soit satisfaisante.

### Sécurité et conformité

- **Confirmation de la signature** : Une fois la signature capturée, une confirmation est requise pour s'assurer que la signature est valide et conforme.
- **RGPD** : Le processus de confirmation comprend une étape de validation pour garantir la conformité aux réglementations de protection des données.

### Gestion des signatures

- **Téléchargement sécurisé** : Les signatures capturées sont transmises de manière sécurisée à notre serveur pour stockage.
- **Mise à jour des dossiers** : Les documents signés sont automatiquement associés aux dossiers des employés correspondants, assurant une gestion fluide et intégrée des contrats et autres documents nécessitant une signature.

Cette fonctionnalité garantit une gestion efficace et sécurisée des signatures, réduisant les délais administratifs et assurant la conformité aux normes de sécurité.

---

Disponible sur **iOS** et **Android**

    `,
  },

  {
    title: 'Gestion des tâches',
    description: `

Assurez un suivi précis et efficace de toutes les tâches avec notre fonctionnalité de gestion des tâches intégrée. Voici comment cette fonctionnalité optimise votre workflow :

### Création et attribution des tâches

- **Ajout de tâches** : Les utilisateurs autorisés peuvent créer de nouvelles tâches directement via l'application, en spécifiant le nom de la tâche, le commentaire, l'auteur, et le véhicule associé.
- **Attribution des rôles** : Les tâches peuvent être attribuées à des rôles spécifiques, permettant une répartition claire des responsabilités.

### Suivi et mise à jour des tâches

- **Colonnes de statut** : Les tâches sont organisées en colonnes selon leur statut : "À faire", "En cours", "Terminé", et "Archivé". Cela permet une vue d'ensemble rapide de l'état des tâches.
- **Mouvement des tâches** : Les tâches peuvent être déplacées entre les colonnes pour refléter leur progression. Par exemple, une tâche peut être déplacée de "À faire" à "En cours" puis à "Terminé".
- **Mise à jour en temps réel** : Toute modification du statut d'une tâche est mise à jour en temps réel, assurant que tous les utilisateurs voient les informations les plus récentes.

### Gestion des véhicules

- **Filtrage par véhicule** : Les utilisateurs peuvent filtrer les tâches par véhicule, permettant une vue ciblée des tâches associées à un véhicule spécifique.

Cette fonctionnalité de gestion des tâches vous aide à suivre efficacement les tâches, à assurer la responsabilité, et à maintenir une organisation fluide et productive.

     `,
  },

  {
    title: 'Entetiens des véhicules',
    description: `


Maintenez vos véhicules en parfait état avec notre fonctionnalité d'entretiens des véhicules. Voici comment cela fonctionne :

### Suivi des véhicules

- **QR Code** : Scannez le QR Code du véhicule pour accéder rapidement à ses informations et historiques d'entretiens.
- **Détails du véhicule** : Consultez les détails complets de chaque véhicule, incluant le modèle, l'immatriculation, le code, et le kilométrage actuel.

### Gestion des factures d'entretiens

- **Ajout de factures** : Enregistrez les factures d'entretiens avec des détails comme la date, le type d'entretien, le prix, le kilométrage, les commentaires et le sous-type (par exemple, Vidange/Révision, Pneus, Plaquettes).
- **Modification des factures** : Mettez à jour les informations des factures existantes pour maintenir un historique précis.
- **Filtres** : Filtrez les factures par type et sous-type d'entretien pour une recherche plus facile.
- **Alertes** : Affichez uniquement les factures avec des alertes basées sur des seuils kilométriques prédéfinis.

### Création de tâches automatisées

- **Tâches d'entretien** : Des tâches d'entretien sont automatiquement créées lorsque le kilométrage d'un véhicule atteint certains seuils par rapport aux derniers entretiens effectués.
- **Alerte de couleur** : Les factures sont colorées en fonction de leur état d'alerte (par exemple, rouge pour les alertes critiques, orange pour les alertes de précaution).

Cette fonctionnalité garantit que les entretiens des véhicules sont bien suivis et gérés, améliorant ainsi la durée de vie et la performance de votre flotte.

 `,
  },

];

const allFeatures = [
  { category: 'Régulation', features: regulationsFeatures },
  { category: 'Gestion d\'activités', features: managementFeatures },
  { category: 'Facturation', features: billingFeatures },
  { category: 'Tableau de bord', features: dashboardFeatures },
  { category: 'Données d\'exploitation', features: dataFeatures },
  { category: 'Porte document', features: documentHolderFeatures },
  { category: 'Application mobile', features: mobileFeatures }
];

// 3. Composant pour afficher les documentations
function DocumentationSystem() {
  const [selectedFeature, setSelectedFeature] = useState(allFeatures[0].features[0]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState({});

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleCategoryClick = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" sx={{ boxShadow: 3 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Guide d'utilisation
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {allFeatures.map((category, index) => (
              <React.Fragment key={index}>
                <ListItemButton onClick={() => handleCategoryClick(category.category)}>
                  <ListItemText primary={category.category} />
                  {openCategories[category.category] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCategories[category.category]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.features.map((feature, subIndex) => (
                      <ListItem key={subIndex} disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => setSelectedFeature(feature)}>
                          <ListItemText primary={feature.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box sx={{ mt: 2 }}>
        <FeatureComponent
          title={selectedFeature.title}
          description={selectedFeature.description}
        />
      </Box>
    </Box>
  );
}

export default React.memo(DocumentationSystem);
