/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import './salesDivisionStyles.scss';
import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import axios from 'axios';
import fr from 'date-fns/locale/fr';
import { BASE_URL } from '../../../utils/api';
import 'react-datepicker/dist/react-datepicker.css';
import UserGrid from '../userGrid/userGrid';
import VehiculeGrid from '../vehiculeGrid/vehiculeGrid';

registerLocale('fr', fr);
setDefaultLocale('fr');

function SalesDivision({ selectedSociety, setSelectedSociety, userToken }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedGrid, setSelectedGrid] = useState('chauffeurs');
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  });
  const [transports, setTransports] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchTransports = async () => {
    try {
      const res = await axios.get(`${BASE_URL}transports`, { headers });
      setTransports(res.data.transports);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTransports();
    fetchCompanies();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (dates) => {
    const [start, end] = dates;

    if (end) {
      // Set start date time to 00:00:00
      start.setHours(0, 0, 0, 0);
      // Set end date time to 23:59:59
      end.setHours(23, 59, 59, 999);
    }
    else {
      // Only start date selected, set it to 00:00:00
      start.setHours(0, 0, 0, 0);
    }
    setSelectionRange({ startDate: start, endDate: end, key: 'selection' });
  };
  const handleGridButtonClick = () => {
    // Toggle between 'chauffeurs' and 'vehicule' grid
    setSelectedGrid(selectedGrid === 'chauffeurs' ? 'vehicule' : 'chauffeurs');
  };

  function getTotalTypeFacture(startDate, endDate, idSociete, typeTransport) {
    const filteredInvoices = transports.filter((facture) => {
      const dateFacture = Date.parse(facture.date);
      const isStatusValid = facture.status === 'Terminé' || facture.status === 'Annuler facturé';
      return dateFacture >= startDate && dateFacture <= endDate && (idSociete ? facture.societe === idSociete : true) && facture.tarification_type === typeTransport && isStatusValid;
    });

    return Math.round(filteredInvoices.reduce((acc, facture) => acc + parseFloat(facture.price || 0), 0)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  function getTotalFacture(startDate, endDate, idSociete) {
    const filteredInvoices = transports.filter((facture) => {
      const dateFacture = Date.parse(facture.date);
      const isStatusValid = facture.status === 'Terminé' || facture.status === 'Annuler facturé';
      return dateFacture >= startDate && dateFacture <= endDate && (idSociete ? facture.societe === idSociete : true) && isStatusValid;
    });

    return Math.round(filteredInvoices.reduce((acc, facture) => acc + parseFloat(facture.price || 0), 0)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  const { startDate, endDate } = selectionRange;

  const CaSelectionAmbulance = getTotalTypeFacture(startDate, endDate, selectedSociety, 'Ambulance').toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  const CaSelectionPara = getTotalTypeFacture(startDate, endDate, selectedSociety, 'Paramédical').toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  const CaSelectionVsl = getTotalTypeFacture(startDate, endDate, selectedSociety, 'Vsl').toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  const CaSelectionSamu = getTotalTypeFacture(startDate, endDate, selectedSociety, 'Samu').toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  const CaSelectionTaxi = getTotalTypeFacture(startDate, endDate, selectedSociety, 'Taxi').toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  const CaSelection = getTotalFacture(startDate, endDate, selectedSociety);

  function getTypeCount(StartDate, EndDate, tarifType, idSociete) {
    const filteredAller = transports.filter((facture) => {
      const datealler = Date.parse(facture.date);
      return datealler >= StartDate && datealler <= EndDate && (facture.tarification_type === tarifType) && (idSociete ? facture.societe === idSociete : true);
    });

    const countAller = filteredAller.length;

    return countAller;
  }

  const countSelectionAmbulance = getTypeCount(startDate, endDate, 'Ambulance', selectedSociety);
  const countSelectionPara = getTypeCount(startDate, endDate, 'Paramédical', selectedSociety);
  const countSelectionVsl = getTypeCount(startDate, endDate, 'Vsl', selectedSociety);
  const countSelectionSamu = getTypeCount(startDate, endDate, 'Samu', selectedSociety);
  const countSelectionTaxi = getTypeCount(startDate, endDate, 'Taxi', selectedSociety);

  const countSelectionTotal = countSelectionAmbulance + countSelectionPara + countSelectionVsl + countSelectionSamu + countSelectionTaxi;

  if (isLoading) {
    return <div className="loading">Chargement...</div>;
  }

  return (
    <>
      <div className="sales-division">
        <div className="search-bar">
          <select value={selectedSociety} onChange={(event) => setSelectedSociety(event.target.value)}>
            <option value="">Toutes les sociétés</option>
            {companies.map((company) => (
              <option key={company.id} value={company.code}>
                {company.code}
              </option>
            ))}
          </select>
        </div>
        <div className="date-picker-section">
          <h2>Veuillez selectionner une période.</h2>
          <DatePicker
            selected={selectionRange.startDate}
            onChange={handleChange}
            startDate={selectionRange.startDate}
            endDate={selectionRange.endDate}
            selectsRange
            inline
            monthsShown={isMobile ? 1 : 2}
          />
        </div>
        <div className="article-division">
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title total">Total</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelection} <br /> {countSelectionTotal} Missions</h2>
            </div>
          </div>
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title amb">Ambulance</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelectionAmbulance} <br /> {countSelectionAmbulance} Missions</h2>
            </div>
          </div>
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title para">Paramédical</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelectionPara} <br /> {countSelectionPara} Missions</h2>
            </div>
          </div>
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title vsl">Vsl</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelectionVsl} <br /> {countSelectionVsl} Missions</h2>
            </div>
          </div>
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title samu">Samu</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelectionSamu} <br /> {countSelectionSamu} Missions</h2>
            </div>
          </div>
          <div className="App-article-division-preview">
            <div className="App-article-division-preview-title-container">
              <h1 className="App-article-division-preview-title taxi">Taxi</h1>
            </div>
            <div className="App-article-division-text">
              <h2>{CaSelectionTaxi} <br /> {countSelectionTaxi} Missions</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-button displayListButton">
        <div
          className="toggle-btn"
          onClick={handleGridButtonClick}
        >
          <div>{selectedGrid === 'Véhicules' ? 'Chauffeurs' : 'Véhicules'}</div>
          <div>{selectedGrid === 'Véhicules' ? '⟳' : '⟲'}</div>
        </div>
      </div>
      {selectedGrid === 'chauffeurs' && <UserGrid userToken={userToken} dateRange={selectionRange} />}
      {selectedGrid === 'vehicule' && <VehiculeGrid userToken={userToken} dateRange={selectionRange} />}

    </>
  );
}

export default React.memo(SalesDivision);
