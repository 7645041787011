/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, Select, FormControl, InputLabel, Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Tooltip, TablePagination
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import ArticleImage from '../stockManagement/Articles/articleImage/articleImage';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import { BASE_URL } from '../../../utils/api';
import MedicalStockForm from './medicalStockForm';
import ImageUploadField from '../../hooks/imageUploadField';

function MedicalStockManagement({ userToken, userRole, userEmail }) {
  usePageTitle('DRIVESOFT | GESTION DU MATERIEL MEDICAL');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState('');
  const [selectedRevisionUrl, setSelectedRevisionUrl] = useState('');
  const [medicalItems, setMedicalItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [factureDialogOpen, setFactureDialogOpen] = useState(false);
  const [currentMedicalItem, setCurrentMedicalItem] = useState(null);
  const [factures, setFactures] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [categories, setCategories] = useState(['Néonatologie', 'Brancardage', 'Bariatrique', 'Réanimation']);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    photo: null,
    serial_number: '',
    revision_date: null,
    purchase_date: null,
    purchase_price: 0,
    value: 0,
    company: '',
    category: '',
    ownership: '',
    img_revision: '',
  });

  const [filterCompany, setFilterCompany] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [searchName, setSearchName] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation', 'Gestionnaire de stock'];
    return authorizedRoles.includes(userRole);
  };

  const fetchMedicalItems = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}medical-items`, { headers });
      setMedicalItems(response.data.medicalItems);
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error fetching medical items:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchMedicalItems();
  }, []);

  const fetchFacturesByMedicalItem = async (medicalItemId) => {
    try {
      const response = await axios.get(`${BASE_URL}medical-items/${medicalItemId}/factures`, { headers });

      // Tri des factures par date décroissante (plus récentes en premier)
      const sortedFactures = response.data.factures.sort((a, b) => new Date(b.date) - new Date(a.date));

      setFactures(sortedFactures || []);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
    }
    catch (error) {
      console.error(error);
    }
  };

  const downloadFile = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      saveAs(blob, filename || 'download');
    }
    catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Impossible de télécharger le fichier.',
      });
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}medical-items/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l\'URL signée pour visualisation:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Impossible de visualiser le document.',
      });
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}medical-items/get-signed-url`, { key }, { headers });
      downloadFile(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Erreur lors de la récupération de l\'URL signée pour téléchargement:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Impossible de télécharger le document.',
      });
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
      setViewImageUrl('');
    }
  }, [viewImageUrl]);

  const fetchVehicules = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });
      const sortedVehicles = response.data.vehicule.sort((a, b) => a.code.localeCompare(b.code));
      setVehicules(sortedVehicles);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des véhicules', error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchVehicules();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false);
      }
    });
  };

  const handleOpen = (item) => {
    if (item) {
      setIsEditing(true);
      setFormData({
        ...item,
        photo: item.photo || '', // Conserver l'image de l'article en modification
        img_revision: item.img_revision || '', // Conserver l'image de révision en modification
        revision_date: item.revision_date ? formatDateForInput(item.revision_date) : null,
        purchase_date: formatDateForInput(item.purchase_date),
      });
    }
    else {
      setIsEditing(false);
      setFormData({
        id: null,
        name: '',
        photo: '',
        serial_number: '',
        revision_date: null,
        purchase_date: '',
        purchase_price: '',
        value: '',
        company: '',
        category: '',
        ownership: '',
        img_revision: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      confirmCloseModal();
    }
  };

  const handleFileChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    // Créer un aperçu d'image spécifique pour chaque champ
    const reader = new FileReader();
    reader.onload = (event) => {
      if (fieldName === 'photo') {
        setSelectedPhotoUrl(event.target.result);
      }
      else if (fieldName === 'img_revision') {
        setSelectedRevisionUrl(event.target.result);
      }
    };
    reader.readAsDataURL(file);

    // Vérifiez la taille et le type du fichier
    const maxFileSize = 2 * 1024 * 1024; // Limite de 2 Mo
    if (file.size > maxFileSize) {
      console.error('Le fichier est trop volumineux. Veuillez télécharger un fichier inférieur à 2 Mo.');
      Swal.fire({
        icon: 'error',
        title: 'Fichier Trop Volumineux',
        text: 'Veuillez télécharger un fichier inférieur à 2 Mo.',
      });
      return;
    }

    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Type de fichier invalide. Veuillez télécharger un fichier PNG, JPEG ou PDF.');
      Swal.fire({
        icon: 'error',
        title: 'Type de Fichier Invalide',
        text: 'Veuillez télécharger un fichier PNG, JPEG ou PDF.',
      });
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}medical-items/presigned`, { name: file.name, type: file.type }, { headers });
      const { url, fields } = response.data;

      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const uploadedFileUrl = `${fields.key}`;
      setFormData((prevState) => ({ ...prevState, [fieldName]: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur de Téléchargement',
        text: 'Impossible de télécharger le fichier. Veuillez réessayer.',
      });
    }
  };

  const calculateDepreciatedValue = (purchasePrice, purchaseDate) => {
    if (!purchasePrice || !purchaseDate) return purchasePrice;

    const purchaseYear = new Date(purchaseDate).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearsSincePurchase = currentYear - purchaseYear;

    const depreciationFactor = 0.9 ** yearsSincePurchase;
    const depreciatedValue = purchasePrice * depreciationFactor;

    return depreciatedValue.toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedValue = calculateDepreciatedValue(formData.purchase_price, formData.purchase_date);

    // Si la date de révision est vide ou contient seulement des espaces, la définir comme null
    const revisionDate = formData.revision_date && formData.revision_date.trim() !== '' ? formData.revision_date : null;

    const updatedFormData = {
      ...formData,
      value: updatedValue,
      revision_date: revisionDate
    };

    try {
      if (updatedFormData.id) {
        await axios.put(`${BASE_URL}medical-items/${updatedFormData.id}`, updatedFormData, { headers });
        Swal.fire({
          icon: 'success',
          title: 'Mis à jour!',
          text: 'L\'article médical a été mis à jour avec succès.'
        });
      }
      else {
        await axios.post(`${BASE_URL}medical-items`, updatedFormData, { headers });
        Swal.fire({
          icon: 'success',
          title: 'Ajouté!',
          text: 'L\'article médical a été ajouté avec succès.'
        });
      }
      fetchMedicalItems();
      handleClose();
    }
    catch (error) {
      console.error('Error creating/updating medical item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur s\'est produite lors de la sauvegarde de l\'article médical.'
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas annuler cela!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!'
      });

      if (result.isConfirmed) {
        await axios.delete(`${BASE_URL}medical-items/${id}`, { headers });
        fetchMedicalItems();
        Swal.fire(
          'Supprimé!',
          'L\'article médical a été supprimé.',
          'success'
        );
      }
    }
    catch (error) {
      console.error('Error deleting medical item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur s\'est produite lors de la suppression de l\'article médical.'
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString.trim() === '') return 'sans date';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const getRevisionColor = (revisionDate) => {
    if (!revisionDate) return 'inherit';
    const currentDate = new Date();
    const revision = new Date(revisionDate);

    // Calculez la date d'expiration en ajoutant un an
    const expirationDate = new Date(revision);
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    // La date d'avertissement est 3 mois avant la date d'expiration
    const warningDate = new Date(expirationDate);
    warningDate.setMonth(expirationDate.getMonth() - 3);

    if (currentDate >= expirationDate) {
      return '#ffcccc';
    }
    if (currentDate >= warningDate) {
      return '#ffebcc';
    }
    return 'inherit';
  };

  const filteredMedicalItems = medicalItems.filter((item) => (
    (!filterCompany || item.company === filterCompany)
      && (!filterCategory || item.category === filterCategory)
      && (!searchName || item.name.toLowerCase().includes(searchName.toLowerCase()))
  ));

  const totalStockValue = filteredMedicalItems
    .reduce((total, item) => total + (parseFloat(item.value) || 0), 0)
    .toLocaleString('fr-FR', { minimumFractionDigits: 0 });

  // Ensuite, utilisez le Typography pour l'affichage formaté

  const handleExportExcel = () => {
    const data = filteredMedicalItems.map((item) => ({
      Nom: item.name,
      'Numéro de série': item.serial_number,
      'Date de révision': formatDate(item.revision_date),
      "Date d'achat": formatDate(item.purchase_date),
      "Prix d'achat (€ HT)": item.purchase_price,
      'Valeur (€ HT)': item.value,
      Société: item.company,
      Catégorie: item.category,
      Localisation: item.ownership
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Médical');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(dataBlob, 'stock_medical.xlsx');
  };

  const groupFacturesByMonth = (factures) => factures.reduce((acc, facture) => {
    const date = new Date(facture.date);
    const monthYear = date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });

    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(facture);

    return acc;
  }, {});

  const handleOpenFactureDialog = (medicalItem) => {
    setCurrentMedicalItem(medicalItem);
    fetchFacturesByMedicalItem(medicalItem.id);
    setFactureDialogOpen(true);
  };

  const handleCloseFactureDialog = () => {
    setFactureDialogOpen(false);
    setCurrentMedicalItem(null);
  };

  const handleAddFacture = async (factureData) => {
    try {
      await axios.post(`${BASE_URL}medical-items/${currentMedicalItem.id}/factures`, factureData, { headers });
      Swal.fire({
        title: 'Facture ajoutée !',
        icon: 'success',
      });
      fetchFacturesByMedicalItem(currentMedicalItem.id);
    }
    catch (error) {
      Swal.fire({
        title: 'Erreur',
        text: 'Erreur lors de l\'ajout de la facture.',
        icon: 'error',
      });
    }
  };

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}medical-items/${currentMedicalItem.id}/factures`, {
          headers: { Authorization: `Bearer ${userToken}` },
          data: { factureId },
        });

        Swal.fire({
          title: 'Supprimée!',
          text: 'La facture a été supprimée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        // Re-fetch factures after deletion
        fetchFacturesByMedicalItem(currentMedicalItem.id);
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la facture :', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de la facture.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const checkIfTaskExists = async (name, serialNumber, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;

      // Vérifie si une tâche existe avec le nom et le numéro de série
      return tasks.some((task) => task.field_name === `${type} - Matériel ${name} - ${serialNumber}`);
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const createTask = async (medicalItem, type, expirationDate) => {
    const fieldName = `${type} - Matériel ${medicalItem.name} - ${medicalItem.serial_number}`; // Utilisation du nom + numéro de série
    const comment = `${type} pour le matériel ${medicalItem.name} (numéro de série ${medicalItem.serial_number}) expire le ${format(new Date(expirationDate), 'dd-MM-yyyy')}`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      medical_item_id: medicalItem.id,
      role_name: 'Administrateur',
      comment,
    };

    const taskExists = await checkIfTaskExists(medicalItem.name, medicalItem.serial_number, type);

    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  useEffect(() => {
    const checkMedicalItemRevisionDates = async () => {
      const currentDate = new Date();
      const alertMessages = [];

      for (const medicalItem of medicalItems) {
        if (medicalItem.revision_date) {
          const revisionDate = new Date(medicalItem.revision_date);

          // Ajoutez un an à la date de révision pour obtenir la date d'expiration
          const expirationDate = new Date(revisionDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);

          // Calculez le nombre de jours jusqu'à l'expiration
          const daysUntilExpiration = differenceInDays(expirationDate, currentDate);

          if (daysUntilExpiration <= 30) {
            // Créer une tâche si la révision du matériel expire dans moins de 30 jours
            await createTask(medicalItem, 'Révision', expirationDate);
          }

          if (daysUntilExpiration <= 0) {
            // Ajouter un message d'alerte si la révision est expirée
            alertMessages.push(
              `Le matériel ${medicalItem.name} (numéro de série ${medicalItem.serial_number}) a une révision expirée.`
            );
          }
        }
      }

      if (alertMessages.length > 0) {
        Swal.fire({
          title: 'Alertes de Révision du Matériel Médical',
          html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    };

    if (medicalItems.length > 0) {
      checkMedicalItemRevisionDates();
    }
  }, [medicalItems]);

  const facturesGroupedByMonth = groupFacturesByMonth(factures);

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <Grid container style={{ paddingTop: '16px', justifyContent: 'center' }}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Rechercher par Nom"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-company-label">Société</InputLabel>
            <Select
              labelId="filter-company-label"
              label="Société"
              value={filterCompany}
              onChange={(e) => setFilterCompany(e.target.value)}
            >
              <MenuItem value="">Toutes les sociétés</MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.code} value={company.code}>
                  {company.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="filter-category-label">Catégorie</InputLabel>
            <Select
              labelId="filter-category-label"
              label="Catégorie"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <MenuItem value="">Toutes les catégories</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Button variant="contained" color="grey" onClick={handleExportExcel} style={{ margin: '16px' }}>
          Exporter en Excel
        </Button>
      </Grid>
      {isAuthorizedRole() && (
        <Tooltip title="Ajouter un article médical">
          <IconButton color="black" onClick={() => handleOpen(null)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ margin: 'auto' }}>{isEditing ? 'Modifier un article médical' : 'Créer un nouvel article médical'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box mb={2} display="flex" justifyContent="center" alignItems="center">
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={(e) => handleFileChange(e, 'photo')}
              />
              <label htmlFor="contained-button-file">
                <ArticleImage imageKey={formData.photo} selectedImageUrl={selectedPhotoUrl} width={200} height={200} />
              </label>
            </Box>

            <TextField
              label="Nom"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Numéro de série"
              name="serial_number"
              value={formData.serial_number}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Date de révision"
              name="revision_date"
              type="date"
              value={formData.revision_date || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <ImageUploadField
              uploadField={{
                id: 'img_revision-input',
                label: 'Procès verbal révision',
                fieldName: 'img_revision',
              }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />

            <TextField
              label="Date d'achat"
              name="purchase_date"
              type="date"
              value={formData.purchase_date || ''}
              onChange={(e) => {
                handleInputChange(e);
                const newDate = e.target.value;
                const updatedValue = calculateDepreciatedValue(formData.purchase_price, newDate);
                setFormData({
                  ...formData,
                  purchase_date: newDate,
                  value: updatedValue,
                });
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Prix d'achat (€ HT)"
              name="purchase_price"
              type="number"
              value={formData.purchase_price}
              onChange={(e) => {
                handleInputChange(e);
                const newPrice = e.target.value;
                const updatedValue = calculateDepreciatedValue(newPrice, formData.purchase_date);
                setFormData({
                  ...formData,
                  purchase_price: newPrice,
                  value: updatedValue,
                });
              }}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Valeur (€ HT)"
              name="value"
              value={formData.value || calculateDepreciatedValue(formData.purchase_price, formData.purchase_date)}
              fullWidth
              disabled
              margin="normal"
              InputProps={{ readOnly: true }}
            />

            <FormControl fullWidth required>
              <InputLabel id="company">Société</InputLabel>
              <Select
                required
                label="Société"
                name="company"
                id="company"
                value={formData.company}
                onChange={handleInputChange}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.code}>
                    {company.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <InputLabel id="category-label">Catégorie</InputLabel>
              <Select
                labelId="category-label"
                label="Catégorie"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <InputLabel id="ownership-label">Localisation</InputLabel>
              <Select
                labelId="ownership-label"
                label="Appartenance"
                name="ownership"
                value={formData.ownership}
                onChange={handleInputChange}
              >
                <MenuItem value="Stock">Stock</MenuItem>
                <MenuItem value="En entretien">En entretien</MenuItem>
                {vehicules.map((vehicle) => (
                  <MenuItem key={vehicle.id} value={vehicle.code}>
                    {vehicle.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DialogActions>
              <Button onClick={confirmCloseModal} color="primary">
                Annuler
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {isEditing ? 'Mettre à jour' : 'Créer'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Photo</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Nom</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Numéro de série</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Date de révision</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Date d'achat</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Prix d'achat (€ HT)</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Valeur (€ HT)</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Société</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Catégorie</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Localisation</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMedicalItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ textAlign: 'center' }}>
                    <ArticleImage userToken={userToken} imageKey={item?.photo} width={50} height={50} />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.name}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.serial_number}</TableCell>
                  <TableCell style={{ textAlign: 'center', backgroundColor: getRevisionColor(item.revision_date) }}>
                    {formatDate(item.revision_date)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{formatDate(item.purchase_date)}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.purchase_price}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {calculateDepreciatedValue(item.purchase_price, item.purchase_date)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.company}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.category}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{item.ownership}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {isAuthorizedRole() && (
                    <>
                      <Tooltip title="Modifier l'article médical">
                        <IconButton onClick={() => handleOpen(item)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer l'article médical">
                        <IconButton onClick={() => handleDelete(item.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ajouter/Consulter les factures">
                        <IconButton onClick={() => handleOpenFactureDialog(item)} style={{ color: 'grey' }}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

        </Table>

        <Box display="flex" justifyContent="flex-end" p={2}>
          <TablePagination
            rowsPerPageOptions={[8, 16, 24]}
            component="div"
            count={filteredMedicalItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Résultats par page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`}
          />
        </Box>
      </TableContainer>

      <Typography
        variant="h6"
        component="div"
        style={{
          color: 'grey', fontSize: '16px', textAlign: 'right', paddingRight: '10px'
        }}
      >
        Valeur du matériel médical: {totalStockValue} € HT
      </Typography>

      {/* Modale de gestion des factures */}
      <Dialog open={factureDialogOpen} onClose={handleCloseFactureDialog} fullWidth maxWidth="md">
        <DialogTitle>Gestion des factures pour {currentMedicalItem?.name}</DialogTitle>
        <DialogContent>
          <MedicalStockForm onSaveFacture={handleAddFacture} />
          <h3 className="addModalTitle">Charges existantes</h3>

          {Object.keys(facturesGroupedByMonth)
            .sort((a, b) => new Date(b) - new Date(a))
            .map((monthYear) => (
              <Accordion key={monthYear}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{monthYear}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Details</TableCell>
                          <TableCell>Commentaires</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {facturesGroupedByMonth[monthYear].map((facture, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {new Date(facture.date).toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </TableCell>
                            <TableCell>{facture.type}</TableCell>
                            <TableCell>
                              {Object.keys(facture.details).map((key) => (
                                facture.details[key] && (
                                <div key={key}>
                                  <strong>{key}:</strong> {facture.details[key]}
                                </div>
                                )
                              ))}
                            </TableCell>
                            <TableCell>{facture.commentaires}</TableCell>
                            <TableCell>
                              <Tooltip title="Supprimer la facture">
                                <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFactureDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(MedicalStockManagement);
