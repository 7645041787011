/* eslint-disable react/prop-types */
import React from 'react';

import './mapStyles.scss';

function Map() {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <div style={{ height: '40vh', width: '100%' }}>
      <div className="map-container">
        <iframe
          title="Localisation de le la société DriveSoft"
          className="google-map"
          allowFullScreen=""
          style={{ border: 0 }}
          aria-hidden="false"
          src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=DriveSoft+ 3 rue Marlène Dietrich, Herblay-sur-Seine, Francezoom=10`}
        />

      </div>
    </div>
  );
}

export default React.memo(Map);
