/* eslint-disable react/prop-types */
// RessourcesHumainesData.jsx
import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Autocomplete,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  People as PeopleIcon,
  NotInterested as NotInterestedIcon,
  Refresh as RefreshIcon,
  School as SchoolIcon,
  Sick as SickIcon,
  ChildFriendly as ChildFriendlyIcon,
  Wc as WcIcon,
  DateRange as DateRangeIcon,
} from '@mui/icons-material'; // Remplacez par les icônes appropriées
import { Doughnut, Bar } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';

// Style personnalisé pour les Card
const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  padding: theme.spacing(1),
}));

function RessourcesHumainesData({
  companies,
  handleFilterCompanyChange,
  tauxPresence,
  tauxAbsenteeisme,
  tauxTurnOver,
  tempsFormation,
  indiceArretMaladies,
  indiceCongerParental,
  indiceFrequenceAccidents,
  genderBalance,
  averageAge,
  ageDistributionBarData,
  doughnutData,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Barre de Filtrage */}
      <Box sx={{ marginBottom: 4 }}>
        <Autocomplete
          options={companies}
          getOptionLabel={(option) => option.code}
          value={companies.find((company) => company.code === (companies.selectedCompany || '')) || null}
          onChange={(event, newValue) => handleFilterCompanyChange(newValue ? newValue.code : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtrer par société"
              variant="outlined"
              fullWidth
            />
          )}
          clearOnEscape
        />
      </Box>

      {/* Cartes de Statistiques */}
      <Grid container spacing={3} sx={{ marginBottom: 4 }}>
        {/* Exemple de Carte de Statistique */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <PeopleIcon color="primary" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Taux de présence
              </Typography>
              <Typography
                variant="h5"
                color={tauxPresence >= 75 ? 'success.main' : 'error.main'}
              >
                {Number.isNaN(tauxPresence) ? 'N/A' : `${tauxPresence.toFixed(2)}%`}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Répétez les Grid items pour les autres statistiques avec des icônes appropriées */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <NotInterestedIcon color="error" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Taux d'absentéisme
              </Typography>
              <Typography
                variant="h5"
                color={tauxAbsenteeisme >= 5 ? 'error.main' : 'success.main'}
              >
                {tauxAbsenteeisme.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <RefreshIcon color="warning" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Taux de turn-over
              </Typography>
              <Typography
                variant="h5"
                color={tauxTurnOver >= 10 ? 'error.main' : 'success.main'}
              >
                {tauxTurnOver.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <SchoolIcon color="secondary" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Temps de formation
              </Typography>
              <Typography variant="h5" color="text.primary">
                {tempsFormation.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <SickIcon color="error" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Indice d'arrêt maladies
              </Typography>
              <Typography variant="h5" color="text.primary">
                {indiceArretMaladies.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <SickIcon color="warning" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Fréquence des accidents du travail
              </Typography>
              <Typography variant="h5" color="text.primary">
                {indiceFrequenceAccidents.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <ChildFriendlyIcon color="success" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Indice de congé parental
              </Typography>
              <Typography variant="h5" color="text.primary">
                {indiceCongerParental.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Balance Homme/Femme */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <WcIcon color="info" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Balance Homme/Femme
              </Typography>
              <Typography variant="body1" color="text.primary">
                Homme: {genderBalance.homme.toFixed(2)}%
              </Typography>
              <Typography variant="body1" color="text.primary">
                Femme: {genderBalance.femme.toFixed(2)}%
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Moyenne d'âge */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <IconWrapper>
              <DateRangeIcon color="secondary" />
            </IconWrapper>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Moyenne d'âge
              </Typography>
              <Typography variant="h5" color="text.primary">
                {averageAge.toFixed(2)} ans
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Graphiques */}
      <Grid container spacing={4}>
        {/* Distribution par Tranche d'âge */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Distribution par Tranche d'âge" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 300 }}>
                <Bar
                  data={ageDistributionBarData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: true,
                          borderDash: [5, 5],
                          color: muiTheme.palette.divider,
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.dataset.label || '';
                            const value = context.parsed.y;
                            return `${label}: ${value}%`;
                          },
                        },
                        backgroundColor: muiTheme.palette.background.paper,
                        titleColor: muiTheme.palette.text.primary,
                        bodyColor: muiTheme.palette.text.primary,
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Statistiques RH (Doughnut Chart) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Statistiques RH" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 300 }}>
                <Doughnut
                  data={doughnutData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const label = context.label || '';
                            const value = context.parsed;
                            return `${label}: ${value}%`;
                          },
                        },
                        backgroundColor: muiTheme.palette.background.paper,
                        titleColor: muiTheme.palette.text.primary,
                        bodyColor: muiTheme.palette.text.primary,
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(RessourcesHumainesData);
