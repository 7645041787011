/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography, Checkbox, FormControlLabel
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Worker } from '@react-pdf-viewer/core';
import { BASE_URL } from '../../../utils/api';
import VehiculeImages from '../vehiculeManagement/vehiculeImages/vehiculeImages';
import LocalFactureForm from './locauxFacturesForm';
import usePageTitle from '../../hooks/usePageTitle';

const useStyles = {
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
  localLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#333',
  },
  localButton: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: '#f5f5f5',
    padding: '16px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  textField: {
    marginBottom: '20px', // Adjusted margin for more spacing
  },
  checkboxLabel: {
    marginLeft: '8px',
  },
  gridItem: {
    padding: '12px', // Increased padding for better separation
  },
};

function StyledUploadField({
  uploadField, formData, handleFileChange, userToken, fetchSignedUrlForViewing, fetchSignedUrlForDownloading
}) {
  const [isHovered, setIsHovered] = useState(false);
  const styles = useStyles;

  const fileKey = formData[uploadField.fieldName];
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  return (
    <Grid item xs={12} sm={6} md={4} style={styles.gridItem} key={uploadField.id}>
      <input
        accept="image/*, application/pdf"
        style={styles.input}
        id={uploadField.id}
        type="file"
        onChange={(e) => handleFileChange(e, uploadField.fieldName)}
      />
      <label
        htmlFor={uploadField.id}
        style={{ ...styles.label, ...(isHovered ? { backgroundColor: '#e0e0e0' } : {}) }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="LocalCard"
      >
        <p style={styles.localLabel} className="localLabel">{uploadField.label}</p>
        {fileKey && (fileExtension === 'pdf' ? (
          <PictureAsPdfIcon style={{ fontSize: 40 }} />
        ) : (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={fileKey}
              key={fileKey}
              userToken={userToken}
            />
          </Worker>
        ))}
        <div style={styles.localButton} className="localButton">
          {fileKey && (
            <Tooltip title="Visualiser le document">
              <IconButton type="button" onClick={() => fetchSignedUrlForViewing(fileKey)}>
                <ImageSearchIcon size="small" />
              </IconButton>
            </Tooltip>
          )}
          {fileKey && (
            <Tooltip title="Télécharger le document">
              <IconButton type="button" onClick={() => fetchSignedUrlForDownloading(fileKey)}>
                <CloudDownloadIcon size="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </label>
    </Grid>
  );
}

function LocauxManagement({ userToken, userEmail, userRole }) {
  usePageTitle('Gestion des locaux');
  const [viewImageUrl, setViewImageUrl] = useState('');
  const [locaux, setLocaux] = useState([]);
  const [factures, setFactures] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentLocal, setCurrentLocal] = useState(null);
  const [factureDialogOpen, setFactureDialogOpen] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [hasVerificationElementSecurite, setHasVerificationElementSecurite] = useState(false);
  const [hasVerificationPorteSectionnelle, setHasVerificationPorteSectionnelle] = useState(false);
  const [formData, setFormData] = useState({
    nom: '',
    adresse: '',
    date_verification_electrique: '',
    date_verification_extincteur: '',
    date_verification_elementsecurite: '',
    date_entretien_extincteur: '',
    date_verification_porte: '',
    img_verif_electique: '',
    img_verif_ext: '',
    img_verif_element: '',
    img_entretien_extincteur: '',
    img_verif_porte: '',
    img_q18: '',
    observations: '',
  });
  const [errors, setErrors] = useState({
    nom: '',
    adresse: '',
    date_verification_electrique: '',
    date_verification_extincteur: '',
    date_verification_elementsecurite: '',
    date_entretien_extincteur: '',
    date_verification_porte: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.nom.trim()) {
      newErrors.nom = 'Le nom est requis';
      isValid = false;
    }

    if (!formData.adresse.trim()) {
      newErrors.adresse = 'L\'adresse est requise';
      isValid = false;
    }

    if (!formData.date_verification_electrique.trim()) {
      newErrors.date_verification_electrique = 'La date de vérification électrique est requise';
      isValid = false;
    }

    if (!formData.date_verification_extincteur.trim()) {
      newErrors.date_verification_extincteur = 'La date de vérification extincteur est requise';
      isValid = false;
    }

    if (!formData.date_verification_elementsecurite.trim()) {
      newErrors.date_verification_elementsecurite = 'La date de vérification des éléments de sécurité est requise';
      isValid = false;
    }

    if (!formData.date_entretien_extincteur.trim()) {
      newErrors.date_entretien_extincteur = 'La date d\'entretien extincteur est requise';
      isValid = false;
    }

    if (!formData.date_verification_porte.trim()) {
      newErrors.date_verification_porte = 'La date de vérification de la porte est requise';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchLocaux = async () => {
    try {
      const response = await axios.get(`${BASE_URL}locaux`, { headers });
      setLocaux(response.data.locaux);
    }
    catch (error) {
      console.error('Failed to fetch locaux', error);
    }
  };

  const fetchFacturesByLocal = async (localId) => {
    try {
      const response = await axios.get(`${BASE_URL}locaux/${localId}/factures`, { headers });
      setFactures(response.data.factures || []);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  };

  const groupFacturesByMonth = (factures) => factures.reduce((acc, facture) => {
    const date = new Date(facture.date);
    const monthYear = date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
    if (!acc[monthYear]) acc[monthYear] = [];
    acc[monthYear].push(facture);
    return acc;
  }, {});

  const facturesGroupedByMonth = groupFacturesByMonth(factures);

  useEffect(() => {
    fetchLocaux();
  }, []);

  const handleOpenFactureDialog = (local) => {
    setCurrentLocal(local);
    fetchFacturesByLocal(local.id);
    setFactureDialogOpen(true);
  };

  const handleCloseFactureDialog = () => {
    setFactureDialogOpen(false);
    setCurrentLocal(null);
  };

  const handleAddFacture = async (factureData) => {
    try {
      await axios.post(`${BASE_URL}locaux/${currentLocal.id}/factures`, factureData, { headers });
      Swal.fire({
        title: 'Facture ajoutée !',
        icon: 'success',
      });
      fetchFacturesByLocal(currentLocal.id);
    }
    catch (error) {
      Swal.fire({
        title: 'Erreur',
        text: 'Erreur lors de l\'ajout de la facture.',
        icon: 'error',
      });
    }
  };

  const handleDeleteFacture = async (factureId) => {
    const result = await Swal.fire({
      title: 'Confirmer la suppression',
      text: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}locaux/${currentLocal.id}/factures`, {
          headers: { Authorization: `Bearer ${userToken}` },
          data: { factureId }, // Pass the factureId here
        });

        Swal.fire({
          title: 'Supprimée!',
          text: 'La facture a été supprimée avec succès.',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        // Re-fetch factures after deletion
        fetchFacturesByLocal(currentLocal.id);
      }
      catch (error) {
        console.error('Erreur lors de la suppression de la facture :', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de la facture.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const isoDateToShortDate = (isoDate) => (isoDate ? isoDate.split('T')[0] : '');

  const handleOpen = (local = null) => {
    setIsEditing(!!local);
    setCurrentLocal(local);

    if (local) {
      setFormData({
        ...local,
        date_verification_electrique: isoDateToShortDate(local.date_verification_electrique),
        date_verification_extincteur: isoDateToShortDate(local.date_verification_extincteur),
        date_verification_elementsecurite: isoDateToShortDate(local.date_verification_elementsecurite),
        date_entretien_extincteur: isoDateToShortDate(local.date_entretien_extincteur),
        date_verification_porte: isoDateToShortDate(local.date_verification_porte),
      });

      // Initialiser les cases à cocher
      setHasVerificationElementSecurite(!!local.date_verification_elementsecurite);
      setHasVerificationPorteSectionnelle(!!local.date_verification_porte);
    }
    else {
      setFormData({
        nom: '',
        adresse: '',
        date_verification_electrique: '',
        date_verification_extincteur: '',
        date_verification_elementsecurite: '',
        date_entretien_extincteur: '',
        date_verification_porte: '',
        img_verif_electique: '',
        img_verif_ext: '',
        img_verif_element: '',
        img_entretien_extincteur: '',
        img_verif_porte: '',
        img_q18: '',
        observations: '',
      });
      setHasVerificationElementSecurite(false);
      setHasVerificationPorteSectionnelle(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentLocal(null);
  };

  const handleSave = async () => {
    const dataToSave = {
      ...formData,
      date_verification_elementsecurite: hasVerificationElementSecurite ? formData.date_verification_elementsecurite : null,
      date_verification_porte: hasVerificationPorteSectionnelle ? formData.date_verification_porte : null,
    };

    // Enregistrez dataToSave dans la base de données via l'API
    try {
      if (isEditing) {
        await axios.put(`${BASE_URL}locaux/${currentLocal.id}`, dataToSave, { headers });
        Swal.fire({
          title: 'Local modifié !',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      else {
        await axios.post(`${BASE_URL}locaux`, dataToSave, { headers });
        Swal.fire({
          title: 'Local ajouté !',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      fetchLocaux();
      handleClose();
    }
    catch (error) {
      console.error('Erreur lors de la sauvegarde', error);
      Swal.fire({
        title: 'Erreur',
        text: 'Une erreur est survenue lors de la sauvegarde.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce local ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}locaux/${id}`, { headers });
        fetchLocaux();
        Swal.fire({
          title: 'Local supprimé !',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
      catch (error) {
        console.error('Failed to delete local', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression du local.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      Swal.fire({
        title: 'Êtes-vous sûr?',
        text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, quitter!',
        cancelButtonText: 'Non, rester',
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
        }
      });
    }
  };

  const handleFileChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxFileSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF.');
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}locaux/presigned`, { name: file.name, type: file.type }, { headers });
      const { url, fields } = response.data;

      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', file);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const uploadedFileUrl = `${fields.key}`;
      setFormData((prevState) => ({ ...prevState, [fieldName]: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const fetchSignedUrlForViewing = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}locaux/get-signed-url`, { key }, { headers });
      setViewImageUrl(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const downloadFile = async (signedUrl, filename) => {
    try {
      const response = await axios.get(signedUrl, { responseType: 'blob' });
      const contentType = response.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([response.data], { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
    catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const fetchSignedUrlForDownloading = async (key) => {
    try {
      const response = await axios.post(`${BASE_URL}locaux/get-signed-url`, { key }, { headers });
      downloadFile(response.data.url, key.split('/').pop());
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (viewImageUrl) {
      window.open(viewImageUrl, '_blank');
    }
  }, [viewImageUrl]);

  const calculateControlColor = (controlDate) => {
    const currentDate = moment();
    const expirationDate = moment(controlDate).add(1, 'years');
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

    if (daysUntilExpiration < 0) {
      return 'red';
    }
    if (daysUntilExpiration <= 30) {
      return 'red';
    }
    if (daysUntilExpiration <= 90) {
      return 'orange';
    }
    return 'inherit';
  };

  const checkIfTaskExists = async (localName, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;

      // Vérifier l'existence de la tâche pour le contrôle spécifique
      const taskExists = tasks.some((task) => task.field_name === `${type} - ${localName}`);

      return taskExists;
    }
    catch (error) {
      console.error('Erreur lors de la vérification des tâches:', error);
      return false;
    }
  };

  const createTask = async (local, type, expirationDate, customComment) => {
    const fieldName = `${type} - ${local.nom}`;
    const comment = customComment || `${type} du local ${local.nom} expire dans moins d'un mois.`;

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      vehicule_code: local.nom,
      role_name: 'Administrateur',
      comment,
    };

    const taskExists = await checkIfTaskExists(local.nom, type);

    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Erreur lors de la création de la tâche:', error.response?.data || error.message);
        Swal.fire({
          title: 'Erreur',
          text: `Erreur lors de la création de la tâche: ${error.response?.data?.message || error.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  useEffect(() => {
    const checkControlDates = async () => {
      const alertMessages = [];

      for (const local of locaux) {
        const checks = [
          { date: local.date_verification_electrique, type: 'Vérification électrique' },
          { date: local.date_verification_extincteur, type: 'Vérification extincteur' },
          { date: local.date_verification_elementsecurite, type: 'Vérification éléments sécurité' },
          { date: local.date_entretien_extincteur, type: 'Entretien extincteur' },
          { date: local.date_verification_porte, type: 'Vérification porte' },
        ];

        for (const check of checks) {
          if (check.date && moment(check.date, moment.ISO_8601, true).isValid()) {
            const controlDate = moment(check.date).add(1, 'years');
            const daysUntilExpiration = controlDate.diff(moment(), 'days');

            if (daysUntilExpiration < 0) {
              alertMessages.push(`${check.type} du local ${local.nom} est expiré.`);
              await createTask(
                local,
                check.type,
                controlDate.toDate(),
                `${check.type} du local ${local.nom} est expiré.`
              );
            }
            else if (daysUntilExpiration <= 30) {
              alertMessages.push(`${check.type} du local ${local.nom} expire dans moins d'un mois.`);
              await createTask(
                local,
                check.type,
                controlDate.toDate(),
                `${check.type} du local ${local.nom} expire dans moins d'un mois.`
              );
            }
          }
          else {
            console.warn(`Date invalide ou absente pour ${check.type} du local ${local.nom}: ${check.date}`);
          }
        }
      }

      if (alertMessages.length > 0 && !alertShown) {
        Swal.fire({
          title: 'Alertes Contrôles Locaux',
          html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        setAlertShown(true);
      }
    };

    if (locaux.length > 0 && !alertShown) {
      checkControlDates();
    }
  }, [locaux, alertShown]);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const styles = useStyles;

  return (
    <div>
      {isAuthorizedRole() && (
      <Tooltip title="Ajouter un local">
        <IconButton color="black" onClick={() => handleOpen(null)}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      )}
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle style={styles.dialogTitle}>{isEditing ? 'Modifier Local' : 'Ajouter Local'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ paddingTop: '10px' }}>
            {[
              { name: 'nom', label: 'Nom' },
              { name: 'adresse', label: 'Adresse' },
              { name: 'observations', label: 'Observations' },
              { name: 'date_verification_electrique', label: 'Date vérif. électrique', type: 'date' },
              { name: 'date_verification_extincteur', label: 'Date vérif. extincteur', type: 'date' },
              { name: 'date_entretien_extincteur', label: 'Date entretien extincteur', type: 'date' }
            ].map((field) => (
              <Grid item xs={12} sm={6} key={field.name}>
                <TextField
                  type={field.type || 'text'}
                  name={field.name}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={handleChange}
                  InputLabelProps={field.type === 'date' ? { shrink: true } : undefined}
                  fullWidth
                  style={styles.textField}
                  error={Boolean(errors[field.name])}
                />
              </Grid>
            ))}

            {/* Case à cocher pour Vérification des éléments de sécurité */}
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={hasVerificationElementSecurite}
                    onChange={() => setHasVerificationElementSecurite(!hasVerificationElementSecurite)}
                  />
          )}
                label="Soumis à une vérification des éléments de sécurité"
              />
            </Grid>

            {/* Afficher l'input et l'image si la case est cochée */}
            {hasVerificationElementSecurite && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  name="date_verification_elementsecurite"
                  label="Date vérif. éléments sécurité"
                  value={formData.date_verification_elementsecurite}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  style={styles.textField}
                  error={errors.date_verification_elementsecurite}
                />
              </Grid>
              <StyledUploadField
                uploadField={{ id: 'verification-elementsecurite-input', label: 'Vérification éléments sécurité', fieldName: 'img_verif_element' }}
                formData={formData}
                handleFileChange={handleFileChange}
                userToken={userToken}
                fetchSignedUrlForViewing={fetchSignedUrlForViewing}
                fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
              />
            </>
            )}

            {/* Case à cocher pour Porte sectionnelle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={hasVerificationPorteSectionnelle}
                    onChange={() => setHasVerificationPorteSectionnelle(!hasVerificationPorteSectionnelle)}
                  />
          )}
                label="Soumis à une vérification de la porte sectionnelle"
              />
            </Grid>

            {/* Afficher l'input et l'image si la case est cochée */}
            {hasVerificationPorteSectionnelle && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  name="date_verification_porte"
                  label="Date vérif. porte sectionnelle"
                  value={formData.date_verification_porte}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  style={styles.textField}
                  error={errors.date_verification_porte}
                />
              </Grid>
              <StyledUploadField
                uploadField={{ id: 'verification-porte-input', label: 'Vérification porte sectionnelle', fieldName: 'img_verif_porte' }}
                formData={formData}
                handleFileChange={handleFileChange}
                userToken={userToken}
                fetchSignedUrlForViewing={fetchSignedUrlForViewing}
                fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
              />
            </>
            )}

            {/* Images pour les champs non conditionnels */}
            <StyledUploadField
              uploadField={{ id: 'verification-electrique-input', label: 'Vérification électrique', fieldName: 'img_verif_electique' }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{ id: 'verification-extincteur-input', label: 'Vérification extincteur', fieldName: 'img_verif_ext' }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{ id: 'entretien-extincteur-input', label: 'Entretien extincteur', fieldName: 'img_entretien_extincteur' }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
            <StyledUploadField
              uploadField={{ id: 'q18_input', label: 'Q18', fieldName: 'img_q18' }}
              formData={formData}
              handleFileChange={handleFileChange}
              userToken={userToken}
              fetchSignedUrlForViewing={fetchSignedUrlForViewing}
              fetchSignedUrlForDownloading={fetchSignedUrlForDownloading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Annuler</Button>
          <Button onClick={handleSave} color="primary">Enregistrer</Button>
        </DialogActions>
      </Dialog>

      {/* Table des locaux */}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                'Nom', 'Adresse', 'Observations', 'Date vérif. electrique', 'Date vérif. extincteur', 'Date vérif. éléments sécurité', 'Date entretien extincteur', 'Date vérif. porte', 'Actions',
              ].map((header) => (
                <TableCell key={header} style={{ textAlign: 'center' }}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {locaux.map((local) => (
              <TableRow key={local.id}>
                <TableCell style={{ textAlign: 'center' }}>{local.nom}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.adresse}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.observations}</TableCell>
                <TableCell style={{ textAlign: 'center', color: calculateControlColor(local.date_verification_electrique) }}>
                  {formatDate(local.date_verification_electrique)}
                </TableCell>
                <TableCell style={{ textAlign: 'center', color: calculateControlColor(local.date_verification_extincteur) }}>
                  {formatDate(local.date_verification_extincteur)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {local.date_verification_elementsecurite ? formatDate(local.date_verification_elementsecurite) : 'Pas de date'}
                </TableCell>
                <TableCell style={{ textAlign: 'center', color: calculateControlColor(local.date_entretien_extincteur) }}>
                  {formatDate(local.date_entretien_extincteur)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {local.date_verification_porte ? formatDate(local.date_verification_porte) : 'Pas de date'}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {isAuthorizedRole() && (
                  <>
                    <Tooltip title="Modifier le local">
                      <IconButton onClick={() => handleOpen(local)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer le local">
                      <IconButton onClick={() => handleDelete(local.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ajouter/Consulter une facture">
                      <IconButton onClick={() => handleOpenFactureDialog(local)} style={{ color: 'grey' }}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modale de gestion des factures */}
      <Dialog open={factureDialogOpen} onClose={handleCloseFactureDialog} fullWidth maxWidth="md">
        <DialogTitle>Gestion des factures pour {currentLocal?.nom}</DialogTitle>
        <DialogContent>
          <LocalFactureForm onSaveFacture={handleAddFacture} />

          {/* Titre placé en dehors du tableau */}
          <h3 className="addModalTitle">Charges existantes</h3>

          {Object.keys(facturesGroupedByMonth).map((monthYear) => (
            <Accordion key={monthYear}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{monthYear}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Details</TableCell>
                        <TableCell>Commentaires</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {facturesGroupedByMonth[monthYear].map((facture, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {new Date(facture.date).toLocaleDateString('fr-FR', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                          </TableCell>
                          <TableCell>{facture.type}</TableCell>
                          <TableCell>
                            {Object.keys(facture.details).map((key) => (
                              facture.details[key] && (
                                <div key={key}>
                                  <strong>{key}:</strong> {facture.details[key]}
                                </div>
                              )
                            ))}
                          </TableCell>
                          <TableCell>{facture.commentaires}</TableCell>
                          <TableCell>
                            <Tooltip title="Supprimer la facture">
                              <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFactureDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(LocauxManagement);
