/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, Select, MenuItem,
  Checkbox, FormControlLabel,
} from '@mui/material';
import { BASE_URL } from '../../../utils/api';
import calculateInvoice from '../../hooks/calculateInvoice';

function FactureManager({ userToken }) {
  const [factures, setFactures] = useState([]);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [tarifs, setTarifs] = useState([]);
  const [tarifTypes, setTarifTypes] = useState([]);
  const [tarifTaxiOptions, setTarifTaxiOptions] = useState([]);
  const [assures, setAssures] = useState([]);
  const [mutuelles, setMutuelles] = useState([]);
  const [caisses, setCaisses] = useState([]);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  }

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    axios.get(`${BASE_URL}invoices`, { headers })
      .then((response) => {
        setFactures(response.data.factures);
      })
      .catch((error) => {
        console.error('Failed to fetch invoices', error);
      });

    axios.get(`${BASE_URL}companies`, { headers })
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((error) => {
        console.error('Failed to fetch companies', error);
      });

    axios.get(`${BASE_URL}tarifs`, { headers })
      .then((response) => {
        setTarifs(response.data.tarifs);
      })
      .catch((error) => {
        console.error('Failed to fetch tarifs', error);
      });

    axios.get(`${BASE_URL}types-de-tarif`, { headers })
      .then((response) => {
        setTarifTypes(response.data.typesDeTarif);
      })
      .catch((error) => {
        console.error('Failed to fetch types de tarif', error);
      });

    axios.get(`${BASE_URL}assures`, { headers })
      .then((response) => {
        setAssures(response.data.assures);
      })
      .catch((error) => {
        console.error('Failed to fetch assures', error);
      });

    axios.get(`${BASE_URL}mutuelles`, { headers })
      .then((response) => {
        setMutuelles(response.data.mutuelles);
      })
      .catch((error) => {
        console.error('Failed to fetch mutuelles', error);
      });

    axios.get(`${BASE_URL}caisses`, { headers })
      .then((response) => {
        setCaisses(response.data.caisses);
      })
      .catch((error) => {
        console.error('Failed to fetch caisses', error);
      });
  }, []);

  const handleSelectFacture = (facture) => {
    const matchingCompany = companies.find((company) => company.code === facture.societe_nom);
    if (matchingCompany) {
      facture.societe_siret = matchingCompany.siret;
      facture.societe_adresse = matchingCompany.address;
      facture.societe_code_postal = matchingCompany.address.split(' ').slice(-2, -1)[0];
      facture.societe_ville = matchingCompany.address.split(' ').slice(-1)[0];
      facture.societe_numero_identification = matchingCompany.numeroidentification;
    }

    const matchingTarif = tarifs.find((tarif) => tarif.categorie === facture.tarif_nom);
    if (matchingTarif) {
      const matchingTarifType = tarifTypes.find((type) => type.id === matchingTarif.typedetarifid);
      facture.tarif_type = matchingTarifType ? matchingTarifType.nom : '';
      facture.tarif_nom = matchingTarif.nom;
      facture.tarif_forfait = matchingTarif.base;

      setTarifTaxiOptions([
        { label: `A - ${matchingTarif.basekma}`, value: matchingTarif.basekma },
        { label: `B - ${matchingTarif.basekmb}`, value: matchingTarif.basekmb },
        { label: `C - ${matchingTarif.basekmc}`, value: matchingTarif.basekmc },
        { label: `D - ${matchingTarif.basekmd}`, value: matchingTarif.basekmd }
      ]);

      const transportDetails = {
        selectedTransportType: matchingTarif.typedetarifid,
        distance: facture.distance,
        waitingTime: facture.attente,
        roundTrip: facture.retour,
        departureTime: facture.heure_depart,
        arrivalTime: facture.heure_arrivee,
        dayOfWeek: new Date(facture.date_transport).getDay(),
        aeroport: facture.aeroport,
        premature: facture.premature,
        tpmr: facture.tpmr,
        numberOfPeople: facture.nombre_personnes,
        cancelled: facture.annule,
        urgent: facture.urgent,
        tarifTaxi: facture.tarif_taxi,
      };

      const {
        totalWithTVA,
        distanceCharge,
        nightRate,
        holidayRate,
        waitingCost,
        valorisationTrajetCourtCharge,
        remiseSimultane,
        aeroportMajoration,
        prematureMajoration,
        tpmrMajoration,
        cancelledBill,
        urgentMajoration,
        majorationTranche25km
      } = calculateInvoice(matchingTarif, transportDetails);

      facture.tarif_total = totalWithTVA;
      facture.tarif_total_facture = totalWithTVA;
      facture.tarif_distance = distanceCharge;
      facture.tarif_nuit = nightRate;
      facture.tarif_dimanche_ferie = holidayRate;
      facture.majoration_trajet_court = valorisationTrajetCourtCharge;
      facture.tarif_remise_simultane = remiseSimultane;
      facture.tarif_peage = aeroportMajoration;
      facture.tarif_part_organisme = prematureMajoration;
      facture.tarif_part_assure = tpmrMajoration;
      facture.tarif_supplement_non_remboursable = cancelledBill;
      facture.tarif_somme_payee_assure = urgentMajoration;
      facture.cout_temps_attente = waitingCost;
      facture.cout_par_tranche_25km = majorationTranche25km;
    }

    const matchingAssure = assures.find((assure) => assure.nom === facture.assure_nom && assure.prenom === facture.assure_prenom);

    if (matchingAssure) {
      facture.assure_numero_securite_sociale = matchingAssure.numero_securite_sociale;
      facture.assure_adresse = matchingAssure.adresse;
      facture.assure_code_postal = `${matchingAssure.code_postal} ${matchingAssure.ville}`;
      facture.patient_risque = matchingAssure.nature_assurance;
      facture.patient_exo = matchingAssure.exoneration;
      facture.patient_taux = matchingAssure.taux;
      facture.patient_date_fin_droits = matchingAssure.date_fin_droits;

      const matchingMutuelle = mutuelles.find((mutuelle) => mutuelle.id === matchingAssure.mutuelle_id);
      if (matchingMutuelle) {
        facture.assure_mutuelle_nom = matchingMutuelle.nom;
        facture.assure_type_contrat = matchingMutuelle.numero_contrat;
      }

      const matchingCaisse = caisses.find((caisse) => caisse.id === matchingAssure.caisse_id);
      if (matchingCaisse) {
        facture.assure_organisme_nom = matchingCaisse.libelle;
        facture.assure_organisme_code = `${matchingCaisse.code_regime} ${matchingCaisse.caisse_gestionnaire} ${matchingCaisse.centre_gestionnaire}`;
      }
    }

    setSelectedFacture(facture);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFacture(null);
  };

  const handleSaveFacture = () => {
    if (selectedFacture.id) {
      axios.put(`${BASE_URL}invoices/${selectedFacture.id}`, selectedFacture, { headers })
        .then((response) => {
          setFactures(factures.map((fact) => (fact.id === selectedFacture.id ? response.data.facture : fact)));
        })
        .catch((error) => {
          console.error('Failed to update invoice', error);
        });
    }
    else {
      axios.post(`${BASE_URL}invoices`, selectedFacture, { headers })
        .then((response) => {
          setFactures([...factures, response.data.facture]);
        })
        .catch((error) => {
          console.error('Failed to create invoice', error);
        });
    }
    handleClose();
  };

  const handleDeleteFacture = (id) => {
    axios.delete(`${BASE_URL}invoices/${id}`, { headers })
      .then(() => {
        setFactures(factures.filter((fact) => fact.id !== id));
      })
      .catch((error) => {
        console.error('Failed to delete invoice', error);
      });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFacture({ ...selectedFacture, [name]: checked });
  };

  const handleCompanyChange = (e) => {
    const companyCode = e.target.value;
    const selectedCompany = companies.find((company) => company.code === companyCode);

    if (selectedCompany) {
      setSelectedFacture({
        ...selectedFacture,
        societe_nom: selectedCompany.code,
        societe_siret: selectedCompany.siret,
        societe_adresse: selectedCompany.address,
        societe_code_postal: selectedCompany.address.split(' ').slice(-2, -1)[0],
        societe_ville: selectedCompany.address.split(' ').slice(-1)[0],
        societe_numero_identification: selectedCompany.numeroidentification,
      });
    }
  };

  const handleTarifChange = (e) => {
    const tarifNom = e.target.value;
    const selectedTarif = tarifs.find((tarif) => tarif.nom === tarifNom);

    if (selectedTarif) {
      const matchingTarifType = tarifTypes.find((type) => type.id === selectedTarif.typedetarifid);
      setSelectedFacture({
        ...selectedFacture,
        tarif_nom: selectedTarif.nom,
        tarif_type: matchingTarifType ? matchingTarifType.nom : '',
        tarif_forfait: selectedTarif.base, // Assuming forfait is based on basekma, update if necessary
        tarif_taxi: '', // Resetting to an empty string to handle the comparison below
      });

      // Mise à jour des options du sélecteur de tarif_taxi
      setTarifTaxiOptions([
        { label: `A - ${selectedTarif.basekma}`, value: selectedTarif.basekma },
        { label: `B - ${selectedTarif.basekmb}`, value: selectedTarif.basekmb },
        { label: `C - ${selectedTarif.basekmc}`, value: selectedTarif.basekmc },
        { label: `D - ${selectedTarif.basekmd}`, value: selectedTarif.basekmd }
      ]);

      // Calculer les autres valeurs de la facture
      const transportDetails = {
        selectedTransportType: selectedTarif.typedetarifid,
        distance: selectedFacture.distance,
        waitingTime: selectedFacture.attente,
        roundTrip: selectedFacture.retour,
        departureTime: selectedFacture.heure_depart,
        arrivalTime: selectedFacture.heure_arrivee,
        dayOfWeek: new Date(selectedFacture.date_transport).getDay(),
        aeroport: selectedFacture.aeroport,
        premature: selectedFacture.premature,
        tpmr: selectedFacture.tpmr,
        numberOfPeople: selectedFacture.nombre_personnes,
        cancelled: selectedFacture.annule,
        urgent: selectedFacture.urgent,
        tarifTaxi: selectedFacture.tarif_taxi, // Use the numeric value directly
      };

      const {
        totalWithTVA,
        distanceCharge,
        nightRate,
        holidayRate,
        waitingCost,
        valorisationTrajetCourtCharge,
        remiseSimultane,
        aeroportMajoration,
        prematureMajoration,
        tpmrMajoration,
        cancelledBill,
        urgentMajoration,
        majorationTranche25km
      } = calculateInvoice(selectedTarif, transportDetails);

      setSelectedFacture((prevFacture) => ({
        ...prevFacture,
        tarif_total: totalWithTVA,
        tarif_total_facture: totalWithTVA,
        tarif_distance: distanceCharge,
        tarif_nuit: nightRate,
        tarif_dimanche_ferie: holidayRate,
        majoration_trajet_court: valorisationTrajetCourtCharge,
        tarif_remise_simultane: remiseSimultane,
        tarif_peage: aeroportMajoration,
        tarif_part_organisme: prematureMajoration,
        tarif_part_assure: tpmrMajoration,
        tarif_supplement_non_remboursable: cancelledBill,
        tarif_somme_payee_assure: urgentMajoration,
        cout_temps_attente: waitingCost,
        cout_par_tranche_25km: majorationTranche25km
      }));
    }
  };

  const handleTarifTaxiChange = (e) => {
    setSelectedFacture({ ...selectedFacture, tarif_taxi: e.target.value });
  };

  const handleAssureSearch = (nom, prenom) => {
    if (nom && prenom) {
      axios.get(`${BASE_URL}assures/search?nom=${nom}&prenom=${prenom}`, { headers })
        .then((response) => {
          const matchingAssure = response.data.assure;
          if (matchingAssure) {
            setSelectedFacture({
              ...selectedFacture,
              assure_numero_securite_sociale: matchingAssure.numero_securite_sociale,
              assure_adresse: matchingAssure.adresse,
              assure_code_postal: `${matchingAssure.code_postal} ${matchingAssure.ville}`,
              patient_risque: matchingAssure.nature_assurance,
              patient_exo: matchingAssure.exoneration,
              patient_taux: matchingAssure.taux,
              patient_date_fin_droits: matchingAssure.date_fin_droits,
              assure_mutuelle_nom: matchingAssure.mutuelle ? matchingAssure.mutuelle.nom : '',
              assure_type_contrat: matchingAssure.mutuelle ? matchingAssure.mutuelle.numero_contrat : '',
              assure_organisme_nom: matchingAssure.caisse ? matchingAssure.caisse.libelle : '',
              assure_organisme_code: matchingAssure.caisse ? `${matchingAssure.caisse.code_regime} ${matchingAssure.caisse.caisse_gestionnaire} ${matchingAssure.caisse.centre_gestionnaire}` : '',
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching assure:', error);
          // Vous pouvez ajouter un message d'erreur pour l'utilisateur ici si nécessaire
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFacture({ ...selectedFacture, [name]: value });

    if (name === 'assure_nom' || name === 'assure_prenom') {
      handleAssureSearch(selectedFacture.assure_nom, selectedFacture.assure_prenom);
    }
  };

  return (
    <div>
      <h1>Gestion des Factures</h1>
      <Button variant="outlined" color="primary" onClick={() => handleSelectFacture({})}>Créer Facture</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Numéro de Facture</TableCell>
              <TableCell>Date de Facture</TableCell>
              <TableCell>Type de Transport</TableCell>
              <TableCell>Nom de l'Assuré</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {factures.map((facture) => (
              <TableRow key={facture.id}>
                <TableCell>{facture.numero_facture}</TableCell>
                <TableCell>{formatDate(facture.date_facture)}</TableCell>
                <TableCell>{facture.type_transport}</TableCell>
                <TableCell>{facture.assure_nom}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => handleSelectFacture(facture)}>Modifier</Button>
                  <Button variant="outlined" color="secondary" onClick={() => handleDeleteFacture(facture.id)}>Supprimer</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{selectedFacture?.id ? 'Modifier Facture' : 'Créer Facture'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Informations de la facture</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_teletransmission"
                label="Numéro de Télétransmission"
                type="text"
                fullWidth
                value={selectedFacture?.numero_teletransmission || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_releve"
                label="Numéro de Relevé"
                type="text"
                fullWidth
                value={selectedFacture?.numero_releve || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="type_transport"
                label="Type de Transport"
                type="text"
                fullWidth
                value={selectedFacture?.type_transport || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_facture"
                label="Numéro de Facture"
                type="text"
                fullWidth
                value={selectedFacture?.numero_facture || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_facture"
                label="Date de Facture"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_facture) || ''}
                onChange={handleChange}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.accident || false}
                    onChange={handleCheckboxChange}
                    name="accident"
                    color="primary"
                  />
                )}
                label="Accident de travail"
              />
            </Grid>
            {selectedFacture?.accident && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  name="date_accident"
                  label="Date de l'Accident"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formatDateForInput(selectedFacture?.date_accident) || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  name="numero_at"
                  label="Numéro AT"
                  type="text"
                  fullWidth
                  value={selectedFacture?.numero_at || ''}
                  onChange={handleChange}
                />
              </Grid>
            </>
            )}
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.article115 || false}
                    onChange={handleCheckboxChange}
                    name="article115"
                    color="primary"
                  />
                )}
                label="Article 115"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.ald || false}
                    onChange={handleCheckboxChange}
                    name="ald"
                    color="primary"
                  />
                )}
                label="ALD"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.atmp || false}
                    onChange={handleCheckboxChange}
                    name="atmp"
                    color="primary"
                  />
                )}
                label="ATMP"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.cent_pourcent || false}
                    onChange={handleCheckboxChange}
                    name="cent_pourcent"
                    color="primary"
                  />
                )}
                label="100%"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Renseignements assuré</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_nom"
                label="Nom"
                type="text"
                fullWidth
                value={selectedFacture?.assure_nom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_prenom"
                label="Prénom"
                type="text"
                fullWidth
                value={selectedFacture?.assure_prenom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_numero_securite_sociale"
                label="Numéro de Sécurité Sociale"
                type="text"
                fullWidth
                value={selectedFacture?.assure_numero_securite_sociale || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_adresse"
                label="Adresse"
                type="text"
                fullWidth
                value={selectedFacture?.assure_adresse || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_code_postal"
                label="Code Postal"
                type="text"
                fullWidth
                value={selectedFacture?.assure_code_postal || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_risque"
                color="primary"
                type="text"
                label="Risque"
                fullWidth
                value={selectedFacture?.patient_risque || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_exo"
                color="primary"
                type="text"
                label="Exonération"
                fullWidth
                value={selectedFacture?.patient_exo || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_taux"
                label="Taux"
                type="number"
                fullWidth
                value={selectedFacture?.patient_taux || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_date_fin_droits"
                label="Date de Fin des Droits"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.patient_date_fin_droits) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_organisme_nom"
                label="Nom de l'Organisme"
                type="text"
                fullWidth
                value={selectedFacture?.assure_organisme_nom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_organisme_code"
                label="Code de l'Organisme"
                type="text"
                fullWidth
                value={selectedFacture?.assure_organisme_code || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_mutuelle_nom"
                label="Nom de la Mutuelle"
                type="text"
                fullWidth
                value={selectedFacture?.assure_mutuelle_nom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="assure_type_contrat"
                label="Numéro de Contrat"
                type="text"
                fullWidth
                value={selectedFacture?.assure_type_contrat || ''}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography variant="h6">Renseignements complémentaires sur le patient</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_nom"
                label="Nom"
                type="text"
                fullWidth
                value={selectedFacture?.patient_nom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_prenom"
                label="Prénom"
                type="text"
                fullWidth
                value={selectedFacture?.patient_prenom || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_date_naissance"
                label="Date de Naissance"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.patient_date_naissance) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_numero_securite_sociale"
                label="Numéro de Sécurité Sociale"
                type="text"
                fullWidth
                value={selectedFacture?.patient_numero_securite_sociale || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="patient_lien"
                label="Lien"
                type="text"
                fullWidth
                value={selectedFacture?.patient_lien || ''}
                onChange={handleChange}
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography variant="h6">Informations du transport</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="motif"
                label="Motif"
                type="text"
                fullWidth
                value={selectedFacture?.motif || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="origine"
                label="Origine"
                type="text"
                fullWidth
                value={selectedFacture?.origine || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_prescripteur"
                label="Numéro de Prescripteur"
                type="text"
                fullWidth
                value={selectedFacture?.numero_prescripteur || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_structure"
                label="Numéro de Structure"
                type="text"
                fullWidth
                value={selectedFacture?.numero_structure || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_prescription"
                label="Date de Prescription"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_prescription) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="numero_marche"
                label="Numéro de Marché"
                type="text"
                fullWidth
                value={selectedFacture?.numero_marche || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_transport"
                label="Date du Transport"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_transport) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="heure_depart"
                label="Heure de Départ"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.heure_depart || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="heure_arrivee"
                label="Heure d'Arrivée"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.heure_arrivee || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="vehicule"
                label="Véhicule"
                type="text"
                fullWidth
                value={selectedFacture?.vehicule || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="chauffeur1"
                label="Chauffeur 1"
                type="text"
                fullWidth
                value={selectedFacture?.chauffeur1 || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="chauffeur2"
                label="Chauffeur 2"
                type="text"
                fullWidth
                value={selectedFacture?.chauffeur2 || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="adresse_depart"
                label="Adresse de Départ"
                type="text"
                fullWidth
                value={selectedFacture?.adresse_depart || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="adresse_arrivee"
                label="Adresse d'Arrivée"
                type="text"
                fullWidth
                value={selectedFacture?.adresse_arrivee || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="distance"
                label="Distance"
                type="number"
                fullWidth
                value={selectedFacture?.distance || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="nombre_personnes"
                label="Nombre de Personnes"
                type="number"
                fullWidth
                value={selectedFacture?.nombre_personnes || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="attente"
                label="Attente"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.attente || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="remise"
                label="Remise"
                type="number"
                fullWidth
                value={selectedFacture?.remise || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="supplement"
                label="Supplément"
                type="number"
                fullWidth
                value={selectedFacture?.supplement || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.retour || false}
                    onChange={handleCheckboxChange}
                    name="retour"
                    color="primary"
                  />
                )}
                label="Retour"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_retour"
                label="Date de Retour"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_retour) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="heure_depart_retour"
                label="Heure de Départ du Retour"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.heure_depart_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="heure_arrivee_retour"
                label="Heure d'Arrivée du Retour"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.heure_arrivee_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="vehicule_retour"
                label="Véhicule du Retour"
                type="text"
                fullWidth
                value={selectedFacture?.vehicule_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="chauffeur1_retour"
                label="Chauffeur 1 du Retour"
                type="text"
                fullWidth
                value={selectedFacture?.chauffeur1_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="chauffeur2_retour"
                label="Chauffeur 2 du Retour"
                type="text"
                fullWidth
                value={selectedFacture?.chauffeur2_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="adresse_depart_retour"
                label="Adresse de Départ du Retour"
                type="text"
                fullWidth
                value={selectedFacture?.adresse_depart_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="adresse_arrivee_retour"
                label="Adresse d'Arrivée du Retour"
                type="text"
                fullWidth
                value={selectedFacture?.adresse_arrivee_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="distance_retour"
                label="Distance du Retour"
                type="number"
                fullWidth
                value={selectedFacture?.distance_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="nombre_personnes_retour"
                label="Nombre de Personnes du Retour"
                type="number"
                fullWidth
                value={selectedFacture?.nombre_personnes_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="attente_retour"
                label="Attente du Retour"
                type="time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedFacture?.attente_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="remise_retour"
                label="Remise du Retour"
                type="number"
                fullWidth
                value={selectedFacture?.remise_retour || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="supplement_retour"
                label="Supplément du Retour"
                type="number"
                fullWidth
                value={selectedFacture?.supplement_retour || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Modalité de règlement</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.paiement_avance || false}
                    onChange={handleCheckboxChange}
                    name="paiement_avance"
                    color="primary"
                  />
                )}
                label="Dispense d'avance de frais"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedFacture?.paiement_direct || false}
                    onChange={handleCheckboxChange}
                    name="paiement_direct"
                    color="primary"
                  />
                )}
                label="Paiement Direct"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="signature_paiement"
                label="Signature Paiement"
                type="text"
                fullWidth
                value={selectedFacture?.signature_paiement || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="lieu_paiement"
                label="Lieu de Paiement"
                type="text"
                fullWidth
                value={selectedFacture?.lieu_paiement || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_paiement"
                label="Date de Paiement"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_paiement) || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Attestation sur l’honneur</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="lieu_attestation"
                label="Lieu d'Attestation"
                type="text"
                fullWidth
                value={selectedFacture?.lieu_attestation || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="date_attestation"
                label="Date d'Attestation"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formatDateForInput(selectedFacture?.date_attestation) || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="signature_attestation"
                label="Signature Attestation"
                type="text"
                fullWidth
                value={selectedFacture?.signature_attestation || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Règlements</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="paiement_assure"
                label="Paiement Assuré"
                type="number"
                fullWidth
                value={selectedFacture?.paiement_assure || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="paiement_caisse"
                label="Paiement Caisse"
                type="number"
                fullWidth
                value={selectedFacture?.paiement_caisse || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="paiement_complementaire"
                label="Paiement Complémentaire"
                type="number"
                fullWidth
                value={selectedFacture?.paiement_complementaire || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="paiement_divers"
                label="Paiement Divers"
                type="number"
                fullWidth
                value={selectedFacture?.paiement_divers || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Tarif</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                margin="dense"
                name="tarif_nom"
                label="Nom"
                fullWidth
                value={selectedFacture?.tarif_nom || ''}
                onChange={handleTarifChange}
              >
                {tarifs.map((tarif) => (
                  <MenuItem key={tarif.nom} value={tarif.nom}>
                    {tarif.nom}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_type"
                label="Type de Tarif"
                type="text"
                fullWidth
                value={selectedFacture?.tarif_type || ''}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_forfait"
                label="Forfait"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_forfait || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                margin="dense"
                name="tarif_taxi"
                label="Tarif Taxi"
                fullWidth
                value={selectedFacture?.tarif_taxi || ''}
                onChange={handleTarifTaxiChange}
              >
                {tarifTaxiOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="tarif_dimanche_ferie"
                color="primary"
                type="number"
                label="Tarif Dimanche Férié"
                fullWidth
                value={selectedFacture?.tarif_dimanche_ferie || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="tarif_nuit"
                color="primary"
                type="number"
                label="Tarif Nuit"
                fullWidth
                value={selectedFacture?.tarif_nuit || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_distance"
                label="Tarif Distance"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_distance || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="majoration_trajet_court"
                label="Majoration Trajet Court"
                type="number"
                fullWidth
                value={selectedFacture?.majoration_trajet_court || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="cout_temps_attente"
                label="Coût Temps d'Attente"
                type="number"
                fullWidth
                value={selectedFacture?.cout_temps_attente || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="cout_par_tranche_25km"
                label="Coût par Tranche de 25 km"
                type="number"
                fullWidth
                value={selectedFacture?.cout_par_tranche_25km || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_total"
                label="Tarif Total"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_total || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_nombre_personnes"
                label="Nombre de Personnes"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_nombre_personnes || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_remise_simultane"
                label="Remise Simultanée"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_remise_simultane || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_peage"
                label="Tarif Péage"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_peage || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_part_organisme"
                label="Part de l'Organisme"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_part_organisme || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_part_assure"
                label="Part de l'Assuré"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_part_assure || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_supplement_non_remboursable"
                label="Supplément Non Remboursable"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_supplement_non_remboursable || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_somme_payee_assure"
                label="Somme Payée par l'Assuré"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_somme_payee_assure || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="tarif_total_facture"
                label="Total de la Facture"
                type="number"
                fullWidth
                value={selectedFacture?.tarif_total_facture || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Renseignements société</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                margin="dense"
                name="societe_nom"
                label="Nom de la Société"
                fullWidth
                value={selectedFacture?.societe_nom || ''}
                onChange={handleCompanyChange}
              >
                {companies.map((company) => (
                  <MenuItem key={company.code} value={company.code}>
                    {company.companyname}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="societe_siret"
                label="SIRET de la Société"
                type="text"
                fullWidth
                value={selectedFacture?.societe_siret || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="societe_adresse"
                label="Adresse de la Société"
                type="text"
                fullWidth
                value={selectedFacture?.societe_adresse || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="societe_code_postal"
                label="Code Postal de la Société"
                type="text"
                fullWidth
                value={selectedFacture?.societe_code_postal || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="societe_ville"
                label="Ville de la Société"
                type="text"
                fullWidth
                value={selectedFacture?.societe_ville || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="societe_numero_identification"
                label="Numéro d'Identification de la Société"
                type="text"
                fullWidth
                value={selectedFacture?.societe_numero_identification || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveFacture} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(FactureManager);
