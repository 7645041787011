/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { TextField, MenuItem, Button } from '@mui/material';

function LocalFactureForm({ onSaveFacture }) {
  const [factureType, setFactureType] = useState('');
  const [details, setDetails] = useState({});
  const [commentaires, setCommentaires] = useState('');
  const [factureDate, setFactureDate] = useState(new Date().toISOString().slice(0, 7)); // Format 'YYYY-MM'

  const categories = {
    Energies: ['Gaz', 'Electricité'],
    Impôts: ['Taxe foncière'],
    'Entretiens annuels': [
      'Porte sectionnelle',
      'Vérification électrique',
      'Vérification incendie',
      'Vérification élément de sécurité incendie'
    ],
    Loyer: ['Coût'],
    'Intervention autre': ['Coût']
  };

  const handleInputChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const handleSaveFacture = () => {
    const newFacture = {
      date: `${factureDate}-01`, // Use only month and year, adding day 01 to avoid full date formatting issues
      type: factureType,
      details,
      commentaires
    };
    onSaveFacture(newFacture); // Fonction pour enregistrer la facture
  };

  return (
    <div>
      <TextField
        label="Mois et année de la facture"
        type="month"
        value={factureDate}
        onChange={(e) => setFactureDate(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        select
        label="Catégorie de facture"
        value={factureType}
        onChange={(e) => setFactureType(e.target.value)}
        fullWidth
        margin="normal"
        required
      >
        {Object.keys(categories).map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>

      {/* Affiche dynamiquement les champs en fonction du type de facture sélectionné */}
      {factureType && categories[factureType].map((field) => (
        <TextField
          key={field}
          label={field}
          value={details[field] || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          fullWidth
          margin="normal"
        />
      ))}

      <TextField
        label="Commentaires"
        value={commentaires}
        onChange={(e) => setCommentaires(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />

      <Button
        onClick={handleSaveFacture}
        sx={{ margin: 'auto', backgroundColor: '#505154' }}
        variant="contained"
        fullWidth
      >
        Enregistrer la facture
      </Button>
    </div>
  );
}

export default React.memo(LocalFactureForm);
