/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';

function SalaryDataTab({
  companies,
  handleFilterCompanyChange,
  filteredStaff,
  selectedCompany,
  selectedEmployee,
  setSelectedEmployee,
  currentStaffFacturesPage,
  paginateSalary,
  currentSalaryPage,
  totalPages,
  pagesToShow,
  totalSalaryCost,
  totalSalaryCostPerEmployee,
  salaryCostsByType,
  handleChartTypeClick,
  salaryBreakdownData,
  setSalaryBreakdownData,
  barData,
  pastelColors,
}) {
  const doughnutTypeRef = useRef(null);
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Barre de recherche */}
      <Box sx={{ marginBottom: 4, display: 'flex', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="company-select-label">Société</InputLabel>
          <Select
            labelId="company-select-label"
            label="Société"
            onChange={(event) => handleFilterCompanyChange(event.target.value)}
            value={selectedCompany || ''}
          >
            <MenuItem value="">
              <em>Toutes les sociétés</em>
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="employee-select-label">Employé</InputLabel>
          <Select
            labelId="employee-select-label"
            label="Employé"
            value={selectedEmployee || ''}
            onChange={(event) => setSelectedEmployee(event.target.value)}
          >
            <MenuItem value="">
              <em>Tous les employés</em>
            </MenuItem>
            {filteredStaff.map((employee) => (
              <MenuItem key={employee.id} value={`${employee.name} ${employee.last_name}`}>
                {employee.name} {employee.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Tableau des factures */}
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom de l'employé</TableCell>
              <TableCell>Date de la facture</TableCell>
              <TableCell>Type de facture</TableCell>
              <TableCell>Total (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStaffFacturesPage.map((facture, index) => (
              <TableRow key={index}>
                <TableCell>{facture.staffName}</TableCell>
                <TableCell>{facture.date}</TableCell>
                <TableCell>{facture.type}</TableCell>
                <TableCell>
                  {facture.totalCost
                    ? `${facture.totalCost.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €`
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
        <Pagination
          count={totalPages}
          page={currentSalaryPage}
          onChange={(event, page) => paginateSalary(page)}
          color="primary"
          siblingCount={pagesToShow}
        />
      </Box>

      {/* Coût total et par type */}
      <Grid container spacing={3} sx={{ marginBottom: 4 }} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Coût total des charges
              </Typography>
              <Typography variant="h5" color="textPrimary">
                {selectedEmployee ? (
                  `${totalSalaryCostPerEmployee.toLocaleString('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} € (pour ${selectedEmployee})`
                ) : (
                  `${totalSalaryCost.toLocaleString('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} €`
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Graphiques */}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Coût par type de charge" />
            <CardContent>
              <Box sx={{ height: isSmDown ? 250 : 300 }}>
                <Doughnut
                  ref={doughnutTypeRef}
                  data={{
                    labels: Object.keys(salaryCostsByType),
                    datasets: [
                      {
                        data: Object.values(salaryCostsByType),
                        backgroundColor: pastelColors,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                    onClick: (event, elements, chart) => handleChartTypeClick(event, elements, chart),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {salaryBreakdownData && (
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Détail du Salaire" />
              <CardContent>
                <Box sx={{ height: isSmDown ? 250 : 300 }}>
                  <Doughnut
                    data={{
                      labels: Object.keys(salaryBreakdownData),
                      datasets: [
                        {
                          data: Object.values(salaryBreakdownData),
                          backgroundColor: pastelColors,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                          labels: {
                            color: muiTheme.palette.text.primary,
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                  <Typography
                    variant="button"
                    color="primary"
                    onClick={() => setSalaryBreakdownData(null)}
                    sx={{ cursor: 'pointer' }}
                  >
                    Fermer
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Coût total par employé" />
            <CardContent>
              <Box sx={{ height: 500 }}>
                <Bar
                  data={barData}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(SalaryDataTab);
