/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import usePageTitle from '../../hooks/usePageTitle';
import { BASE_URL } from '../../../utils/api';
import './seriesManagementStyles.scss';
import UpdateSeriesModal from './updateSerieModal/updateSerieModal';

function SeriesList({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES SÉRIES');
  const [series, setSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialSeriesData, setInitialSeriesData] = useState(null);
  const [searchLastName, setSearchLastName] = useState('');
  const [searchTarificationType, setSearchTarificationType] = useState('');
  const [searchReason, setSearchReason] = useState('');

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  function fetchSeries() {
    axios.get(`${BASE_URL}transports`, { headers })
      .then((response) => {
        const allSeries = response.data.transports.filter((transport) => transport.is_series);

        // Create an object to store series transports by their series_id
        const seriesMap = {};
        allSeries.sort((a, b) => new Date(`1970-01-01T${a.departure_hour}Z`) - new Date(`1970-01-01T${b.departure_hour}Z`)).forEach((transport) => {
          if (!seriesMap[transport.series_id]) {
            seriesMap[transport.series_id] = {
              ...transport,
              departure_hour_aller: null,
              departure_hour_retour: null,
            };
          }

          // Check the time of the current transport and assign it to aller or retour
          if (seriesMap[transport.series_id].departure_hour_aller === null || transport.departure_hour < seriesMap[transport.series_id].departure_hour_aller) {
            seriesMap[transport.series_id].departure_hour_aller = transport.departure_hour;
          }
          else {
            seriesMap[transport.series_id].departure_hour_retour = transport.departure_hour;
          }
        });

        // Convert the object back to an array of unique series transports
        const uniqueSeries = Object.values(seriesMap);
        setSeries(uniqueSeries);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    fetchSeries();
  }, []);

  function getFilteredSeries() {
    return series
      .filter((serie) => serie.last_name.toLowerCase().includes(searchLastName.toLowerCase()))
      .filter((serie) => serie.tarification_type.toLowerCase().includes(searchTarificationType.toLowerCase()))
      .filter((serie) => serie.reason.toLowerCase().includes(searchReason.toLowerCase()));
  }

  function formatDate(inputDate) {
    return moment(inputDate).format('DD-MM-YYYY');
  }

  function translateDays(daysString) {
    if (!daysString) return '';
    const daysMap = {
      Monday: 'Lundi',
      Tuesday: 'Mardi',
      Wednesday: 'Mercredi',
      Thursday: 'Jeudi',
      Friday: 'Vendredi',
      Saturday: 'Samedi',
      Sunday: 'Dimanche',
    };

    return daysString.split(',').map((day) => daysMap[day]).join(', ');
  }

  function calculateRemainingTransports(startDate, endDate, daysString) {
    const daysMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 0,
    };
    const currentDate = moment();
    const end = moment(endDate);
    const start = moment(startDate);

    if (currentDate.isAfter(end)) {
      return 0;
    }

    const selectedDays = daysString.split(',').map((day) => daysMap[day]);
    let remainingTransports = 0;

    for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
      if (selectedDays.includes(m.day()) && m.isSameOrAfter(currentDate)) {
        remainingTransports += 1;
      }
    }

    return remainingTransports;
  }

  const handleDelete = async (seriesId) => {
    try {
      const response = await axios.delete(`${BASE_URL}transports/series/${seriesId}`, { headers });

      if (response.data.message) {
        console.log(response.data.message);
      }

      // Refresh the data or make necessary UI updates here...
      fetchSeries();
    }
    catch (error) {
      console.error('Error deleting the transport series:', error);
      // Handle the error, maybe show a notification or alert to the user
    }
  };

  function handleEdit(id) {
    const serieToEdit = series.find((serie) => serie.series_id === id);
    if (serieToEdit) {
      setInitialSeriesData(serieToEdit);
      setIsModalOpen(true);
    }
    else {
      console.warn('Series with the provided ID was not found.');
    }
  }

  const handleSeriesUpdate = (updatedSeriesData) => {
    axios.put(`${BASE_URL}transports/series/${updatedSeriesData.series_id}`, updatedSeriesData, { headers })
      .then(() => {
        if (updatedSeriesData.unselectedDays && updatedSeriesData.unselectedDays.length > 0) {
          return axios.delete(`${BASE_URL}transports/series/unselectedDays/${updatedSeriesData.series_id}`, {
            headers,
            data: { unselectedDays: updatedSeriesData.unselectedDays }
          });
        }
      })
      .then(() => {
        fetchSeries(); // Actualiser la liste des séries
      })
      .catch((error) => {
        console.error('Error updating or deleting in the series:', error);
      });
  };

  return (
    <div className="seriesTab">
      <h1 className="seriesTab__title">Liste des séries</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Nom"
          value={searchLastName}
          onChange={(e) => setSearchLastName(e.target.value)}
        />
        <select
          value={searchTarificationType}
          onChange={(e) => setSearchTarificationType(e.target.value)}
        >
          <option value="">Tous les types</option>
          <option value="AMB">AMB</option>
          <option value="VSL">VSL</option>
          <option value="PARA">PARA</option>
          <option value="BARIA">BARIA</option>
          <option value="PSY">PSY</option>
          <option value="TAXI">TAXI</option>
        </select>
        <select
          value={searchReason}
          onChange={(e) => setSearchReason(e.target.value)}
        >
          <option value="">Toutes les raisons</option>
          <option value="CONSULTATION">Consultation</option>
          <option value="TRANSFERT">Transfert</option>
          <option value="URGENCE">Urgence</option>
          <option value="DIALYSE">Dialyse</option>
          <option value="RADIO">Radio</option>
          <option value="IRM">IRM</option>
          <option value="MEDECINE NUCLEAIRE">Médecine nucléaire</option>
          <option value="CHIMIO">Chimio</option>
          <option value="RADIOTHERAPIE">Radiotherapie</option>
          <option value="REEDUCATION">Rééducation</option>
          <option value="PSYCHIATRIE">Psychiatrie</option>
          <option value="DENTISTE">Dentiste</option>
          <option value="OPHTALMOLOGIE">Ophtalmologie</option>
          <option value="KINE">Kiné</option>
          <option value="AUTRE">Autre</option>
        </select>
      </div>
      <table className="seriesTab__table">
        <thead className="seriesTab__thead">
          <tr className="seriesTab__tr">
            <th className="seriesTab__th">Nom</th>
            <th className="seriesTab__th">Date de début de série</th>
            <th className="seriesTab__th">Jours de la série</th>
            <th className="seriesTab__th">Date de fin de la série</th>
            <th className="seriesTab__th">Type de tarification</th>
            <th className="seriesTab__th">Raison</th>
            <th className="seriesTab__th">Transports restants</th>
            <th className="seriesTab__th">Actions</th>
          </tr>
        </thead>
        <tbody className="seriesTab__tbody">
          {getFilteredSeries().map((serie) => (
            <tr key={serie.id} className="seriesTab__tr">
              <td className="seriesTab__td">{serie.last_name}</td>
              <td className="seriesTab__td">{formatDate(serie.date)}</td>
              <td className="seriesTab__td">{translateDays(serie.series_days)}</td>
              <td className="seriesTab__td">{formatDate(serie.series_end_date)}</td>
              <td className="seriesTab__td">{serie.tarification_type}</td>
              <td className="seriesTab__td">{serie.reason}</td>
              <td className="seriesTab__td">{calculateRemainingTransports(serie.date, serie.series_end_date, serie.series_days)}</td>
              <td className="seriesTab__td">
                <Tooltip title="Modifier la série">
                  <IconButton type="button" onClick={() => handleEdit(serie.series_id)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer la série">
                  <IconButton type="button" onClick={() => handleDelete(serie.series_id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {initialSeriesData && (
      <UpdateSeriesModal
        onConfirm={handleSeriesUpdate}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialSeriesData={initialSeriesData}
        userToken={userToken}
      />
      )}
    </div>
  );
}
export default React.memo(SeriesList);
