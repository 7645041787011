/* eslint-disable no-inner-declarations */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import { BASE_URL } from '../../../../../utils/api';
import AmbysoftLoader from '../../../../AmbysoftLoader/AmbysoftLoader';

const urlCache = {};

function ArticleImage({
  imageKey, selectedImageUrl, userToken, width = 50, height = 50
}) {
  const [imgSrc, setImgSrc] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchSignedUrl = async () => {
    if (urlCache[imageKey]) {
      setImgSrc(urlCache[imageKey]);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}articles/get-signed-url`, { key: imageKey }, { headers });
      urlCache[imageKey] = response.data.url; // Cache the URL
      setImgSrc(response.data.url);
    }
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  useEffect(() => {
    if (selectedImageUrl) {
      setImgSrc(selectedImageUrl);
    }
    else if (imageKey) {
      fetchSignedUrl();
    }
  }, [imageKey, selectedImageUrl]);

  const handleImageLoad = () => {
    setIsUploading(false);
  };

  return (
    <div>
      {isUploading && <AmbysoftLoader />}
      <Avatar
        onLoad={handleImageLoad}
        alt="articleImg"
        variant="square"
        style={{
          cursor: 'pointer', width: width, height: height, margin: '5px'
        }}
        src={imgSrc}
      />
    </div>
  );
}

export default React.memo(ArticleImage);
