/* eslint-disable react/prop-types */
import React from 'react';
import './ambysoftLoader.scss';
import logoLight from '../../images/logoDrive.png';
import logoDark from '../../images/logoDark.png';

function AmbysoftLoader({ theme }) {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="ambysoft-loader-container">
      <img
        src={theme === 'dark' ? logoDark : logoLight}
        alt="Ambysoft Logo"
        className="ambysoft-logo"
        style={{ width: isMobile ? '80vw' : '75vw', height: isMobile ? '50px' : '200px', marginBottom: '40px' }}
      />

      <div className="ambysoft-loader">
        <div className="ambysoft-loader-circle" />
        <div className="ambysoft-loader-circle" />
        <div className="ambysoft-loader-circle" />
      </div>
    </div>
  );
}

export default React.memo(AmbysoftLoader);
