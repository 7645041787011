/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Button, FormControlLabel, Checkbox,
} from '@mui/material';

Modal.setAppElement('#root');

function UpdateSeriesModal({
  isOpen, onClose, onConfirm, initialSeriesData, theme
}) {
  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  function daysStringToObject(daysString) {
    const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const activeDays = daysString ? daysString.split(',') : [];

    const daysObject = {};
    for (const day of daysArray) {
      daysObject[day.toLowerCase()] = activeDays.includes(day);
    }
    return daysObject;
  }

  const [seriesData, setSeriesData] = useState({
    ...initialSeriesData,
    days: daysStringToObject(initialSeriesData.series_days),
    date: formatDateForInput(initialSeriesData.date),
    series_end_date: formatDateForInput(initialSeriesData.series_end_date)
  });

  const [originalDays, setOriginalDays] = useState(seriesData.days);

  useEffect(() => {
    setOriginalDays(daysStringToObject(initialSeriesData.series_days));
  }, [initialSeriesData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      if (['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].includes(name)) {
        setSeriesData((prev) => ({
          ...prev,
          days: {
            ...prev.days,
            [name]: !prev.days[name]
          }
        }));
      }
      else {
        setSeriesData((prev) => ({ ...prev, [name]: !prev[name] }));
      }
    }
    else {
      setSeriesData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleUpdate = () => {
    const unselectedDays = [];
    for (const day in originalDays) {
      if (originalDays[day] && !seriesData.days[day]) {
        unselectedDays.push(day.charAt(0).toUpperCase() + day.slice(1));
      }
    }
    const selectedDays = [];
    for (const [day, isChecked] of Object.entries(seriesData.days)) {
      if (isChecked) {
        selectedDays.push(day.charAt(0).toUpperCase() + day.slice(1));
      }
    }
    const updatedSeriesData = {
      ...seriesData,
      series_days: selectedDays.join(','),
      unselectedDays: unselectedDays
    };
    onConfirm(updatedSeriesData);
    onClose();
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '30%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
      overflowY: 'hidden',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modifier les jours de la série"
      style={customStyles}
    >
      <div className="modal-content">
        <h2 className="addModalTitle">Modifier la série</h2>

        <div className="firstLine">
          <FormControlLabel
            control={<Checkbox name="monday" onChange={handleChange} checked={seriesData.days.monday} />}
            label="Lundi"
          />
          <FormControlLabel
            control={<Checkbox name="tuesday" onChange={handleChange} checked={seriesData.days.tuesday} />}
            label="Mardi"
          />
          <FormControlLabel
            control={<Checkbox name="wednesday" onChange={handleChange} checked={seriesData.days.wednesday} />}
            label="Mercredi"
          />
          <FormControlLabel
            control={<Checkbox name="thursday" onChange={handleChange} checked={seriesData.days.thursday} />}
            label="Jeudi"
          />
          <FormControlLabel
            control={<Checkbox name="friday" onChange={handleChange} checked={seriesData.days.friday} />}
            label="Vendredi"
          />
          <FormControlLabel
            control={<Checkbox name="saturday" onChange={handleChange} checked={seriesData.days.saturday} />}
            label="Samedi"
          />
          <FormControlLabel
            control={<Checkbox name="sunday" onChange={handleChange} checked={seriesData.days.sunday} />}
            label="Dimanche"
          />
        </div>
        <div className="secondLine">
          <Button sx={{ margin: 'auto' }} onClick={handleUpdate}>Mettre à jour la série</Button>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(UpdateSeriesModal);
