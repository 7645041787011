/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { permissions } from './permissions';

function ProtectedRoute({
  element: Component, userRole, theme, userToken, userEmail, userId
}) {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (!permissions[userRole] || !permissions[userRole].includes(path)) {
      Swal.fire({
        icon: 'error',
        title: 'Accès refusé',
        text: 'Vous n\'avez pas la permission d\'accéder à cette page.',
      }).then(() => {
        window.history.back();
      });
    }
  }, [userRole, path]);

  return permissions[userRole] && permissions[userRole].includes(path) ? (
    <Component userRole={userRole} theme={theme} userToken={userToken} userEmail={userEmail} userId={userId} />
  ) : null;
}

export default ProtectedRoute;
