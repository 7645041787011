/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddTransportModalStyles.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Autocomplete, Grid
} from '@mui/material';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api';
import fetchAddresses from '../../../utils/fetchAddresses';
import AddSeriesModal from '../AddSerieModal/AddSerieModal';

function AddTransportModal({ onAddTransport, theme, userToken }) {
  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const formatDate = (date) => date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const [formData, setFormData] = useState({
    date: getCurrentDate(),
    appointment_hour: '00:00',
    departure_hour: '00:00',
    arrival_hour: '00:00',
    tarification_type: '',
    name: '',
    last_name: '',
    phone_number: '',
    departure_address: '',
    departure_label: '',
    arrival_address: '',
    arrival_label: '',
    price: 0,
    samu_number: '',
    comment: '',
    reason: '',
    round_trip: false,
    aller_id: '',
    uh: null,
    service: '',
    uh_arrival: null,
    service_arrival: '',
    is_series: false,
    series_days: '',
    series_end_date: null,
    round_trip_series: false,
    tarif_applicable: '',
    distance: null,
    aeroport: false,
    premature: false,
    tpmr: false,
    urgent: false,
  });

  const [returnFormData, setReturnFormData] = useState({
    return_date: formatDate(new Date()),
    return_hour: null,
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [etablissementsData, setEtablissementsData] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [arrivalAddressSuggestions, setArrivalAddressSuggestions] = useState([]);
  const [isSeries, setIsSeries] = useState(false);
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);
  const [tarifs, setTarifs] = useState([]);
  const [typesDeTarif, setTypesDeTarif] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [assures, setAssures] = useState([]);

  const formatPhoneNumber = (phoneNumber) => {
    const formattedNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, '$1 ');
    return formattedNumber;
  };

  const fetchTypeDeTarif = async () => {
    try {
      const res = await axios.get(`${BASE_URL}types-de-tarif`, { headers });
      setTypesDeTarif(res.data.typesDeTarif);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchTarifs = async () => {
    try {
      const res = await axios.get(`${BASE_URL}tarifs`, { headers });
      setTarifs(res.data.tarifs);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchAssures = async () => {
    try {
      const res = await axios.get(`${BASE_URL}assures`, { headers });
      setAssures(res.data.assures);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTarifs();
    fetchTypeDeTarif();
    fetchAssures();
  }, []);

  const selectedTypeId = typesDeTarif.find((type) => type.nom === selectedType)?.id;

  // Filtrez les catégories basées sur le type de tarif sélectionné
  const filteredCategories = tarifs
    .filter((tarif) => tarif.typedetarifid === selectedTypeId)
    .map((tarif) => tarif.categorie)
    .filter((value, index, self) => self.indexOf(value) === index);

  // Créez une correspondance entre les catégories filtrées et les noms de tarif pour l'affichage
  const categoryToTarifNameMapping = filteredCategories.reduce((acc, category) => {
    const tarifName = tarifs.find((tarif) => tarif.categorie === category)?.nom;
    if (tarifName) {
      acc[category] = tarifName;
    }
    return acc;
  }, {});

  const handleChange = (e) => {
    const {
      name, type, value, checked
    } = e.target;
    let formattedValue = type === 'checkbox' ? checked : value;

    if (name === 'phone_number') {
      formattedValue = formatPhoneNumber(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? !prevFormData[name] : formattedValue,
    }));

    if (name === 'uh') {
      const service = servicesData.find((s) => s.uh === value);
      if (service) {
        const etablissement = etablissementsData.find(
          (etab) => etab.id === service.etablissement_id
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          service: service.name,
          departure_address: etablissement.address,
          departure_label: etablissement.name,
          phone_number: service.phone,
        }));
      }
    }
    if (name === 'uh_arrival') {
      const service_arrival = servicesData.find((s) => s.uh === value);
      if (service_arrival) {
        const etablissementArrival = etablissementsData.find(
          (etab) => etab.id === service_arrival.etablissement_id
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          service_arrival: service_arrival.name,
          arrival_address: etablissementArrival.address,
          arrival_label: etablissementArrival.name,
        }));
      }
    }
    if (name === 'is_series') {
      setIsSeries(formattedValue); // formattedValue devrait être un booléen si 'is_series' est une case à cocher
    }
  };

  const handleInputChange = (event, value) => {
    if (event && event.target) {
      const {
        name, type, value: inputValue, checked
      } = event.target;
      let formattedValue = type === 'checkbox' ? checked : inputValue;

      if (name === 'phone_number') {
        formattedValue = formatPhoneNumber(inputValue);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedValue,
      }));
    }
    else {
      // Handling the input change when user types a new name in the Autocomplete
      setFormData((prevFormData) => ({
        ...prevFormData,
        last_name: value, // Directly update the last_name field with typed value
      }));
    }
  };

  const handleTransportTypeChange = (e) => {
    const { value } = e.target;
    setSelectedType(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      tarification_type: value,
    }));
  };

  const handleTarifApplicableChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      tarif_applicable: value,
    }));
  };

  const handleUhChange = (event, value) => {
    if (!value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh: '',
        service: '',
        departure_address: '',
        departure_label: '',
        phone_number: '',
      }));
      return;
    }

    const service = servicesData.find((s) => s.uh === value);
    if (service) {
      const etablissement = etablissementsData.find(
        (e) => e.id === service.etablissement_id
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh: value,
        service: service.name,
        departure_address: etablissement.address,
        departure_label: etablissement.name,
        phone_number: service.phone,
      }));
    }
  };
  const handleUhArrivalChange = (event, value) => {
    if (!value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh_arrival: '',
        service_arrival: '',
        arrival_address: '',
        arrival_label: '',
      }));
      return;
    }

    const service_arrival = servicesData.find((s) => s.uh === value);
    if (service_arrival) {
      const etablissementArrival = etablissementsData.find(
        (e) => e.id === service_arrival.etablissement_id
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        uh_arrival: value,
        service_arrival: service_arrival.name,
        arrival_address: etablissementArrival.address,
        arrival_label: etablissementArrival.name,
      }));
    }
  };

  const handleReturnDataChange = (e) => {
    const { name, value } = e.target;
    setReturnFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.date) {
      newErrors.date = 'La date est requise';
    }
    if (!formData.appointment_hour) {
      newErrors.appointment_hour = 'L\'heure de rendez-vous est requise';
    }
    if (!formData.departure_hour) {
      newErrors.departure_hour = 'L\'heure de départ est requise';
    }
    if (!formData.tarification_type) {
      newErrors.tarification_type = 'Le mode de transport est requis';
    }
    if (!formData.last_name) {
      newErrors.last_name = 'Le nom est requis';
    }
    if (!formData.phone_number) {
      newErrors.phone_number = 'Le numéro de téléphone est requis';
    }
    if (!formData.departure_address) {
      newErrors.departure_address = 'L\'adresse de départ est requise';
    }
    if (!formData.arrival_address) {
      newErrors.arrival_address = 'L\'adresse d\'arrivée est requise';
    }
    if (!formData.reason) {
      newErrors.reason = 'La raison est requise';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleSeriesConfirm = (seriesData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      series_days: seriesData.series_days,
      series_end_date: seriesData.series_end_date,
      round_trip_series: seriesData.round_trip_series,
      return_departure_hour: seriesData.return_departure_hour,
    }));
    Swal.fire({
      icon: 'success',
      title: 'Informations de série enregistrées',
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      date: formData.date,
      appointmentHour: formData.appointment_hour,
      departureHour: formData.departure_hour,
      arrivalHour: formData.arrival_hour,
      tarificationType: formData.tarification_type,
      name: formData.name,
      lastName: formData.last_name,
      phoneNumber: formData.phone_number,
      price: formData.price ? parseFloat(formData.price) : null,
      departureAddress: formData.departure_address,
      departureLabel: formData.departure_label,
      arrivalAddress: formData.arrival_address,
      arrivalLabel: formData.arrival_label,
      comment: formData.comment,
      reason: formData.reason,
      roundTrip: formData.round_trip,
      uh: formData.uh,
      samuNumber: formData.samu_number,
      service: formData.service,
      uh_arrival: formData.uh_arrival,
      service_arrival: formData.service_arrival,
      return_date: returnFormData.return_date,
      return_departure_hour: formData.is_series ? formData.return_departure_hour : returnFormData.return_departure_hour,
      is_series: formData.is_series,
      series_days: formData.series_days,
      series_end_date: formData.series_end_date,
      round_trip_series: formData.round_trip_series,
      tarif_applicable: formData.tarif_applicable,
      distance: formData.distance,
      aeroport: formData.aeroport,
      premature: formData.premature,
      tpmr: formData.tpmr,
      urgent: formData.urgent,
    };
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}transports`, data, { headers });
      onAddTransport(res.data);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch services data from the API or set it from a static file
    axios.get(`${BASE_URL}services`, { headers })
      .then((response) => {
        setServicesData(response.data.services);
      })
      .catch((error) => {
        console.error(error);
        // Handle the error
      });

    // Fetch etablissements data from the API or set it from a static file
    axios.get(`${BASE_URL}etablissements`, { headers })
      .then((response) => {
        setEtablissementsData(response.data.etablissements);
      })
      .catch((error) => {
        console.error(error);
        // Handle the error
      });
  }, []);

  const handleAddressChange = async (query) => {
    if (query) {
      const results = await fetchAddresses(query);
      if (results && results.features) {
        setAddressSuggestions(results.features);
      }
    }
    else {
      setAddressSuggestions([]);
    }
  };

  const handleArrivalAddressChange = async (query) => {
    if (query) {
      const results = await fetchAddresses(query);
      if (results && results.features) {
        setArrivalAddressSuggestions(results.features);
      }
    }
    else {
      setArrivalAddressSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      departure_address: suggestion.properties.label,
    }));
    setAddressSuggestions([]);
  };

  const handleSuggestionClickArrival = (suggestion) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      arrival_address: suggestion.properties.label,
    }));
    setArrivalAddressSuggestions([]);
  };

  const handleAutocompleteChange = (event, value) => {
    const selectedClient = assures.find((assure) => assure.nom === value);
    if (selectedClient) {
      // Populate fields if a matching client is found
      const departureAddress = `${selectedClient.adresse}, ${selectedClient.code_postal} ${selectedClient.ville}`;

      const serviceParts = [];
      if (selectedClient.batiment) serviceParts.push(`BAT ${selectedClient.batiment}`);
      if (selectedClient.etage) serviceParts.push(`etage ${selectedClient.etage}`);
      if (selectedClient.porte) serviceParts.push(`porte ${selectedClient.porte}`);
      const service = serviceParts.join(', ');

      setFormData({
        ...formData,
        last_name: selectedClient.nom,
        name: selectedClient.prenom,
        phone_number: selectedClient.telephone,
        departure_address: departureAddress,
        service: service,
        departure_label: '', // Clear departure label
      });
    }
    else {
      // Handle manual input: if there's no match, only set the last_name
      setFormData((prevFormData) => ({
        ...prevFormData,
        last_name: value, // Keep the manually typed value
        name: '', // Reset other fields
        phone_number: '',
        departure_address: '',
        service: '',
        departure_label: '', // Reset the departure label
      }));
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <h2 className="addModalTitle">
        {isSeries ? 'Ajouter une mission en série' : 'Ajouter une mission'}
      </h2>
      <div className="addTransportModal">
        <FormControlLabel
          control={(
            <Checkbox
              name="is_series"
              checked={formData.is_series}
              onChange={(e) => {
                handleChange(e);
                setIsSeriesModalOpen(e.target.checked);
              }}
              color="primary"
            />
          )}
          label="Série"
        />
        {isSeries && (
        <AddSeriesModal
          isOpen={isSeriesModalOpen}
          onClose={() => setIsSeriesModalOpen(false)}
          onConfirm={handleSeriesConfirm}
          transportTime={formData.departure_hour}
          theme={theme}
        />
        )}
        <div className="firstLine">
          <TextField
            type="date"
            sx={{ width: '30%' }}
            name="date"
            label={isSeries ? 'Date de début de la série' : 'Date'}
            InputLabelProps={{
              shrink: true,
            }}
            id="date"
            value={formData.date}
            onChange={handleChange}
            error={!!errors.date}
            helperText={errors.date}
          />
          <TextField
            type="time"
            sx={{ width: '30%' }}
            name="appointment_hour"
            id="appointment_hour"
            label="Heure de rendez-vous"
            value={formData.appointment_hour}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
              list: 'time-list',
              pattern: '[0-9]{2}:[0-9]{2}',
            }}
            error={!!errors.appointment_hour}
            helperText={errors.appointment_hour}
          />
          <TextField
            type="time"
            sx={{ width: '30%' }}
            name="departure_hour"
            id="departure_hour"
            label="Heure de départ"
            value={formData.departure_hour}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            error={!!errors.departure_hour}
            helperText={errors.departure_hour}
          />
        </div>
        <Grid container spacing={2} className="secondLine">
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
            <FormControl sx={{ width: '30%' }}>
              <TextField
                select
                required
                error={!!errors.tarification_type}
                helperText={errors.tarification_type}
                label="Mode de transport"
                name="tarification_type"
                value={formData.tarification_type}
                onChange={handleTransportTypeChange}
              >
                {typesDeTarif.map((type) => (
                  <MenuItem key={type.id} value={type.nom}>
                    {type.nom}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            {filteredCategories.length > 0 && (
            <FormControl sx={{ width: '30%' }}>

              <InputLabel id="category-label">Tarification</InputLabel>
              <Select
                labelId="category-label"
                label="Tarification"
                id="category"
                value={formData.tarif_applicable}
                onChange={handleTarifApplicableChange}
              >
                {Object.entries(categoryToTarifNameMapping).map(([category, tarifName]) => (
                  <MenuItem key={category} value={category}>
                    {tarifName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            )}
          </Grid>
        </Grid>
        <div className="secondLine">
          <Autocomplete
            size="small"
            sx={{ width: '30%' }}
            required
            id="last_name"
            options={assures.map((assure) => assure.nom)}
            value={formData.last_name}
            freeSolo
            onChange={handleAutocompleteChange}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nom"
                variant="outlined"
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
            )}
          />

          <TextField
            size="small"
            sx={{ width: '30%' }}
            id="name"
            label="Prénom"
            variant="outlined"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            size="small"
            sx={{ width: '30%' }}
            id="phone_number"
            label="Numéro de téléphone"
            variant="outlined"
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="transport-details">
          <div className="departure">
            <Autocomplete
              sx={{ width: '70%', marginBottom: '10px' }}
              id="uh"
              options={servicesData.map((service) => service.uh)}
              value={formData.uh}
              onChange={handleUhChange}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="UH"
                  error={!!errors.uh}
                  helperText={errors.uh}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            <TextField
              size="small"
              sx={{ width: '90%' }}
              id="service"
              label="Service"
              variant="outlined"
              type="text"
              name="service"
              value={formData.service}
              onChange={handleChange}
              error={!!errors.service}
              helperText={errors.service}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
            />

            <span className="span_adress">
              <TextField
                size="small"
                sx={{ width: '90%' }}
                required
                id="departure_address"
                label="Adresse de départ"
                variant="outlined"
                type="text"
                name="departure_address"
                value={formData.departure_address}
                onChange={(e) => {
                  handleChange(e);
                  handleAddressChange(e.target.value);
                }}
                error={!!errors.departure_address}
                helperText={errors.departure_address}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {addressSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {addressSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="suggestions-list-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
              )}
            </span>
            <TextField
              size="small"
              sx={{ width: '90%' }}
              id="departure_label"
              label="Etablissement de départ"
              variant="outlined"
              type="text"
              name="departure_label"
              value={formData.departure_label}
              onChange={handleChange}
              error={!!errors.departure_label}
              helperText={errors.departure_label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="arrival">
            <Autocomplete
              sx={{ width: '70%', marginBottom: '10px' }}
              id="uh_arrival"
              options={servicesData.map((service) => service.uh)}
              value={formData.uh_arrival}
              onChange={handleUhArrivalChange}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="UH d'arrivée"
                  error={!!errors.uh_arrival}
                  helperText={errors.uh_arrival}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            <TextField
              size="small"
              sx={{ width: '90%' }}
              id="service_arrival"
              label="Service d'arrivée"
              variant="outlined"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              name="service_arrival"
              value={formData.service_arrival}
              onChange={handleChange}
              error={!!errors.service_arrival}
              helperText={errors.service_arrival}
            />
            <span className="span_adress">
              <TextField
                size="small"
                sx={{ width: '90%' }}
                required
                id="arrival_address"
                label="Adresse d'arrivée"
                variant="outlined"
                type="text"
                name="arrival_address"
                value={formData.arrival_address}
                onChange={(e) => {
                  handleChange(e);
                  handleArrivalAddressChange(e.target.value);
                }}
                error={!!errors.arrival_address}
                helperText={errors.arrival_address}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {arrivalAddressSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {arrivalAddressSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="suggestions-list-item"
                    onClick={() => handleSuggestionClickArrival(suggestion)}
                  >
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
              )}
            </span>
            <TextField
              size="small"
              sx={{ width: '90%' }}
              id="arrival_label"
              label="Etablissement d'arrivée"
              variant="outlined"
              type="text"
              name="arrival_label"
              value={formData.arrival_label}
              onChange={handleChange}
              error={!!errors.arrival_label}
              helperText={errors.arrival_label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="fourthLine">
          <FormControl sx={{ width: '30%' }}>
            <InputLabel id="reason">Motif</InputLabel>
            <Select
              name="reason"
              id="reason"
              value={formData.reason}
              onChange={handleChange}
              label="Motif"
              error={!!errors.reason}
              helperText={errors.reason}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="CONSULTATION">Consultation</MenuItem>
              <MenuItem value="TRANSFERT">Transfert</MenuItem>
              <MenuItem value="URGENCE">Urgence</MenuItem>
              <MenuItem value="DIALYSE">Dialyse</MenuItem>
              <MenuItem value="RADIO">Radio</MenuItem>
              <MenuItem value="IRM">IRM</MenuItem>
              <MenuItem value="MEDECINE NUCLEAIRE">Médecine nucléaire</MenuItem>
              <MenuItem value="CHIMIO">Chimio</MenuItem>
              <MenuItem value="RADIOTHERAPIE">Radiotherapie</MenuItem>
              <MenuItem value="REEDUCATION">Rééducation</MenuItem>
              <MenuItem value="PSYCHIATRIE">Psychiatrie</MenuItem>
              <MenuItem value="DENTISTE">Dentiste</MenuItem>
              <MenuItem value="OPHTALMOLOGIE">Ophtalmologie</MenuItem>
              <MenuItem value="KINE">Kiné</MenuItem>
              <MenuItem value="AUTRE">Autre</MenuItem>
            </Select>
          </FormControl>
          {!isSeries && (
            <>
              {selectedType === 'Samu' && (
              <TextField
                size="small"
                sx={{ width: '30%' }}
                id="samu_number"
                label="Numéro de SAMU"
                variant="outlined"
                type="text"
                name="samu_number"
                value={formData.samu_number}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              )}

              <FormControl component="fieldset">
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="round_trip"
                      checked={formData.round_trip}
                      onChange={handleChange}
                      color="primary"
                    />
                 )}
                  label="Retour"
                />
                {formData.round_trip && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Date de Retour"
                      type="date"
                      sx={{ width: '110%' }}
                      name="return_date"
                      value={returnFormData.return_date}
                      onChange={handleReturnDataChange}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Heure de Retour"
                      type="time"
                      sx={{ width: '110%' }}
                      name="return_departure_hour"
                      value={returnFormData.return_departure_hour}
                      onChange={handleReturnDataChange}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                )}
              </FormControl>
            </>
          )}
        </div>
        <div className="fifthLine">
          <TextField
            fullWidth
            multiline
            id="comment"
            placeholder="Commentaires"
            variant="outlined"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
          />
        </div>
        <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit}>
          {isSeries ? 'Ajouter la série' : 'Ajouter'}
        </Button>
      </div>

    </Box>
  );
}

export default React.memo(AddTransportModal);
