/* eslint-disable max-len */
import React from 'react';
import './privacyStyles.scss';

function Privacy() {
  return (
    <div className="privacy-policy-container">
      <h1>Politique de Confidentialité de DRIVESOFT</h1>
      <p>Dernière mise à jour : 12/03/2024</p>

      <p>DRIVESOFT, s'engage à protéger la vie privée de ses utilisateurs. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons, et sauvegardons vos informations lorsque vous utilisez notre application DRIVESOFT.</p>

      <h2>Collecte d'informations</h2>
      <p>Nous collectons des informations vous concernant lorsque vous utilisez notre Application. Les informations collectées incluent :</p>
      <ul>
        <li>Votre nom et adresse e-mail lors de l'inscription à notre service.</li>
        <li>Les données d'utilisation pour améliorer l'expérience utilisateur.</li>
        <li>Les informations sur votre appareil, telles que la version du système d'exploitation et l'identifiant unique de l'appareil.</li>
      </ul>

      <h2>Utilisation des informations</h2>
      <p>Les informations que nous collectons sont utilisées pour :</p>
      <ul>
        <li>Fournir, opérer et maintenir notre Application.</li>
        <li>Améliorer, personnaliser et étendre notre Application.</li>
        <li>Comprendre et analyser comment vous utilisez notre Application.</li>
        <li>Développer de nouveaux produits, services, fonctionnalités et fonctionnalités.</li>
        <li>Communiquer avec vous, directement ou par l'un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d'autres informations relatives à l'Application, et à des fins de marketing et de promotion.</li>
      </ul>

      <h2>Partage d'informations</h2>
      <p>Votre information n'est partagée avec des tiers que dans les cas suivants :</p>
      <ul>
        <li>Si cela est nécessaire pour fournir, opérer ou améliorer les fonctionnalités de l'Application.</li>
        <li>En réponse à une demande légale si nous croyons de bonne foi qu'il est nécessaire de se conformer à une loi en vigueur, à une procédure judiciaire, ou à une demande du gouvernement.</li>
        <li>Si notre entreprise est impliquée dans une fusion, une acquisition, ou une vente d'actifs, vos informations peuvent être transférées comme partie de cette transaction.</li>
      </ul>

      <h2>Sécurité</h2>
      <p>La sécurité de vos informations est importante pour nous. Nous prenons des mesures pour protéger vos informations contre l'accès non autorisé, l'utilisation, la modification et la divulgation. Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100%. Par conséquent, bien que nous nous efforcions de protéger vos informations personnelles, nous ne pouvons garantir leur sécurité absolue.</p>

      <h2>Modifications de cette politique de confidentialité</h2>
      <p>Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous notifierons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page. Vous êtes conseillé de revoir cette Politique de Confidentialité périodiquement pour tout changement.</p>

      <h2>Contactez-nous</h2>
      <p>Si vous avez des questions sur cette Politique de Confidentialité, vous pouvez nous contacter :</p>
      <ul>
        <li>Par email : contact@drivesoft.fr</li>
        <li>Par téléphone : (+33)652807736</li>
      </ul>
    </div>
  );
}

export default React.memo(Privacy);
