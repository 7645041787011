/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BASE_URL } from '../../../../utils/api';
import VehiculeImages from '../vehiculeImages/vehiculeImages';
import usePdfToImageConverter from '../../../hooks/usePdfToImageConverter';

function AddVehiculeModal({ onAddVehicule, userToken }) {
  const convertPdfToImage = usePdfToImageConverter();
  const [companies, setCompanies] = useState([]);
  const [selectedImagePreview, setSelectedImagePreview] = useState({}); // initialisé comme un objet
  const [errors, setErrors] = useState({});
  const [responsables, setResponsables] = useState([]);
  const [vehiculeForm, setVehiculeForm] = useState({
    societe: '',
    code: '',
    immat: '',
    marque: '',
    type: '',
    categorie: '',
    entry_date: '',
    exit_date: null,
    technical_control_date: '',
    pneu_dim: '',
    pneu_secour: false,
    second_key: false,
    km: '',
    assurance: '',
    utac: '',
    responsable_id: '',
    carte_grise: null,
    contrat_location: null,
    ams: null,
    observation: '',
    imei: '',
    carte_carburant: null,
    telepeage: null,
    pinpuk: null,
    code_carburant: null,
    technical_control: '',
    phone_number: '',
    location_date: null,
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchResponsables = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      setResponsables(response.data.staff);
    }
    catch (error) {
      console.error(error);
    }
  };
  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchResponsables();
    fetchCompanies();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!vehiculeForm.societe) {
      newErrors.societe = 'La société est requise';
    }
    if (!vehiculeForm.code) {
      newErrors.code = 'Le code est requis';
    }
    if (!vehiculeForm.immat) {
      newErrors.immat = "L'immatriculation est requise";
    }
    if (!vehiculeForm.responsable_id) {
      newErrors.responsable_id = 'Le responsable est requis';
    }
    if (!vehiculeForm.marque) {
      newErrors.marque = 'La marque est requise';
    }
    if (!vehiculeForm.km) {
      newErrors.km = 'Le kilométrage est requis';
    }
    if (!vehiculeForm.type) {
      newErrors.type = 'Le type est requis';
    }
    if (!vehiculeForm.categorie) {
      newErrors.categorie = 'La catégorie est requise';
    }
    if (!vehiculeForm.entry_date) {
      newErrors.entry_date = "La date d'entrée est requise";
    }
    if (!vehiculeForm.technical_control_date) {
      newErrors.technical_control_date = 'La date de contrôle technique est requise';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const generatedQRCode = uuidv4(); // Utiliser la librairie uuid pour générer un ID unique

    const formData = {
      societe: vehiculeForm.societe,
      code: vehiculeForm.code,
      immat: vehiculeForm.immat,
      marque: vehiculeForm.marque,
      type: vehiculeForm.type,
      categorie: vehiculeForm.categorie,
      entry_date: vehiculeForm.entry_date,
      exit_date: vehiculeForm.exit_date,
      technical_control_date: vehiculeForm.technical_control_date,
      pneu_dim: vehiculeForm.pneu_dim,
      pneu_secour: vehiculeForm.pneu_secour,
      second_key: vehiculeForm.second_key,
      km: vehiculeForm.km,
      assurance: vehiculeForm.assurance,
      utac: vehiculeForm.utac,
      responsable_id: vehiculeForm.responsable_id,
      carte_grise: vehiculeForm.carte_grise,
      contrat_location: vehiculeForm.contrat_location,
      ams: vehiculeForm.ams,
      observation: vehiculeForm.observation,
      imei: vehiculeForm.imei,
      carte_carburant: vehiculeForm.carte_carburant,
      telepeage: vehiculeForm.telepeage,
      pinpuk: vehiculeForm.pinpuk,
      code_carburant: vehiculeForm.code_carburant,
      technical_control: vehiculeForm.technical_control,
      phone_number: vehiculeForm.phone_number,
      qr_code: generatedQRCode,
      location_date: vehiculeForm.location_date,
    };

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}vehicules`, formData, { headers });
      onAddVehicule(response.data);
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleVehiculeFileChange = async (e, imageField) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImagePreview((prevState) => ({
        ...prevState,
        [imageField]: event.target.result
      }));
    };
    reader.readAsDataURL(file);

    // Check file size
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxFileSize) {
      console.error('File is too large. Please upload a file smaller than 2MB.');
      return;
    }

    // Check file type
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      console.error('Invalid file type. Please upload a PNG, JPEG, or PDF');
      return;
    }

    try {
      // Convertir le PDF en image si c'est un PDF
      let fileToUpload = file;
      if (file.type === 'application/pdf') {
        const imageBlob = await convertPdfToImage(file);
        fileToUpload = new File([imageBlob], `${file.name.split('.').slice(0, -1).join('.')}.jpg`, { type: 'image/jpeg' });
      }
      // Request a presigned URL from the backend
      const response = await axios.post(`${BASE_URL}vehicules/presigned`, { name: fileToUpload.name, type: fileToUpload.type }, { headers });
      const { url, fields } = response.data;

      // Upload the file to S3
      const uploadData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        uploadData.append(key, value);
      });
      uploadData.append('file', fileToUpload);

      await axios.post(url, uploadData, {
        headers: {
          'Content-Type': file.type
        }
      });

      // Set the picture field to the URL of the uploaded file
      const uploadedFileUrl = `${fields.key}`;
      setVehiculeForm((prevState) => ({ ...prevState, [imageField]: uploadedFileUrl }));
    }
    catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const formatImmatriculation = (value) => {
    // Retire tout caractère non-alphabétique et non-numérique et met en majuscules
    const cleaned = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    if (cleaned.length <= 2) return cleaned;
    if (cleaned.length <= 5) return `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`;

    // Format final : deux lettres, trois chiffres, deux lettres (ex: EK-130-BZ)
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 5)}-${cleaned.slice(5, 7)}`;
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  };

  const handleFormChange = (event) => {
    const {
      name, value, checked, type
    } = event.target;
    let formattedValue = type === 'checkbox' ? checked : value;

    if (name === 'phone_number') {
      formattedValue = formatPhoneNumber(value);
    }
    else if (name === 'immat') {
      formattedValue = formatImmatriculation(value);
    }

    setVehiculeForm((prevForm) => ({
      ...prevForm,
      [name]: formattedValue,
    }));
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <h2 className="addModalTitle">Ajouter un nouveau véhicule</h2>
      <form className="addTransportModal">
        <div className="firstLine">
          <TextField
            required
            id="code"
            name="code"
            label="Code"
            variant="outlined"
            value={vehiculeForm.code}
            onChange={handleFormChange}
            error={!!errors.code}
            helperText={errors.code}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            required
            id="immat"
            name="immat"
            label="Immatriculation"
            variant="outlined"
            value={vehiculeForm.immat}
            onChange={handleFormChange}
            error={!!errors.immat}
            helperText={errors.immat}
            style={{ width: '20%', marginRight: '10px' }}

          />
          <TextField
            required
            id="marque"
            name="marque"
            label="Marque"
            variant="outlined"
            value={vehiculeForm.marque}
            onChange={handleFormChange}
            error={!!errors.marque}
            helperText={errors.marque}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            required
            id="km"
            name="km"
            label="Kilométrage"
            variant="outlined"
            value={vehiculeForm.km}
            onChange={handleFormChange}
            error={!!errors.km}
            helperText={errors.km}
            style={{ width: '20%', marginRight: '10px' }}

          />
        </div>
        <FormControl sx={{ width: '20%', padding: '10px' }}>
          <InputLabel id="responsable_id">Responsable</InputLabel>
          <Select
            required
            id="responsable_id"
            name="responsable_id"
            label="Responsable"
            variant="outlined"
            value={vehiculeForm.responsable_id || ''}
            onChange={handleFormChange}
            error={!!errors.responsable_id}
            helperText={errors.responsable_id}
          >
            {responsables.map((responsable) => (
              <MenuItem key={responsable.id} value={responsable.id}>
                {responsable.name} {responsable.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '20%', padding: '10px' }}>
          <InputLabel id="type">Type</InputLabel>
          <Select
            required
            name="type"
            id="type"
            value={vehiculeForm.type}
            onChange={handleFormChange}
            error={!!errors.type}
            helperText={errors.type}
          >
            <MenuItem value="AMB">AMB</MenuItem>
            <MenuItem value="PARA">PARA</MenuItem>
            <MenuItem value="TAXI">TAXI</MenuItem>
            <MenuItem value="VSL">VSL</MenuItem>
            <MenuItem value="BARIA">BARIA</MenuItem>
            <MenuItem value="PSY">PSY</MenuItem>
            <MenuItem value="VL">VL</MenuItem>
          </Select>
          {errors.type && <span className="error">{errors.type}</span>}
        </FormControl>
        <FormControl sx={{ width: '20%', padding: '10px' }}>
          <InputLabel id="categorie">Categorie</InputLabel>
          <Select
            required
            name="categorie"
            id="categorie"
            value={vehiculeForm.categorie}
            onChange={handleFormChange}
            error={!!errors.categorie}
            helperText={errors.categorie}
          >
            <MenuItem value="C TYPE A">C type A</MenuItem>
            <MenuItem value="A TYPE B">A type B</MenuItem>
            <MenuItem value="A TYPE C">A type C</MenuItem>
            <MenuItem value="VL">VL</MenuItem>
          </Select>
          {errors.categorie && <span className="error">{errors.categorie}</span>}
        </FormControl>
        <FormControl sx={{ width: '15%', padding: '10px' }}>
          <InputLabel id="societe">Société</InputLabel>
          <Select
            required
            name="societe"
            id="societe"
            value={vehiculeForm.societe}
            onChange={handleFormChange}
          >
            {errors.company && <span className="error">{errors.societe}</span>}
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          id="phone_number"
          name="phone_number"
          label="Numéro de téléphone"
          variant="outlined"
          value={vehiculeForm.phone_number}
          onChange={handleFormChange}
          error={!!errors.phone_number}
          helperText={errors.phone_number}
        />
        <div className="secondLine">
          <TextField
            id="pinpuk"
            name="pinpuk"
            label="Pinpuk"
            variant="outlined"
            value={vehiculeForm.pinpuk}
            onChange={handleFormChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="imei"
            name="imei"
            label="Numéro imei"
            variant="outlined"
            value={vehiculeForm.imei}
            onChange={handleFormChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="carte_carburant"
            name="carte_carburant"
            label="Carte carburant"
            variant="outlined"
            value={vehiculeForm.carte_carburant}
            onChange={handleFormChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="code_carburant"
            name="code_carburant"
            label="Code carburant"
            variant="outlined"
            value={vehiculeForm.code_carburant}
            onChange={handleFormChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
          <TextField
            id="telepeage"
            name="telepeage"
            label="Télépéage"
            variant="outlined"
            value={vehiculeForm.telepeage}
            onChange={handleFormChange}
            style={{ width: '20%', marginRight: '10px' }}
          />
        </div>
        <div className="thirdLineVehicule">
          <FormControlLabel
            label="Double de clef"
            control={
              <Checkbox name="second_key" checked={vehiculeForm.second_key} onChange={handleFormChange} />
                }
          />
          <TextField
            required
            id="pneu_dim"
            name="pneu_dim"
            label="Dimension des pneus"
            variant="outlined"
            value={vehiculeForm.pneu_dim}
            onChange={handleFormChange}
          />
          <FormControlLabel
            label="Roue de secours"
            control={
              <Checkbox name="pneu_secour" checked={vehiculeForm.pneu_secour} onChange={handleFormChange} />
        }
          />
        </div>
        <div className="fourthLine" />
        <div className="fifthLine">
          <TextField
            required
            type="date"
            id="entry_date"
            name="entry_date"
            label="Date d'entrée"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={vehiculeForm.entry_date}
            onChange={handleFormChange}
            error={!!errors.entry_date}
            helperText={errors.entry_date}
          />
          <TextField
            required
            type="date"
            id="technical_control_date"
            name="technical_control_date"
            label="Date de contrôle technique"
            variant="outlined"
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
            value={vehiculeForm.technical_control_date}
            onChange={handleFormChange}
            error={!!errors.technical_control_date}
            helperText={errors.technical_control_date}
          />
          <TextField
            type="date"
            id="location_date"
            name="location_date"
            label="Date contrat de location"
            variant="outlined"
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
            value={vehiculeForm.location_date}
            onChange={handleFormChange}
            error={!!errors.location_date}
            helperText={errors.location_date}
          />
          <TextField
            id="observation"
            name="observation"
            label="Observation"
            variant="outlined"
            multiline
            rows={4}
            maxRows={8}
            value={vehiculeForm.observation}
            onChange={handleFormChange}
          />
        </div>
        <Box mb={2}>
          <TextField
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="carte_grise-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'carte_grise')}
          />
          <label htmlFor="carte_grise-input">
            <p className="vehiculeLabel">Carte grise</p>
            <VehiculeImages
              imageKey={vehiculeForm.carte_grise}
              selectedImageUrl={selectedImagePreview.carte_grise}
            />
          </label>
        </Box>

        <Box mb={2}>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="utac-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'utac')}
          />
          <label htmlFor="utac-input">
            <p className="vehiculeLabel">UTAC</p>
            <VehiculeImages
              imageKey={vehiculeForm.utac}
              selectedImageUrl={selectedImagePreview.utac}
            />
          </label>
        </Box>

        <Box mb={2}>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="assurance-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'assurance')}
          />
          <label htmlFor="assurance-input">
            <p className="vehiculeLabel">Assurance</p>
            <VehiculeImages
              imageKey={vehiculeForm.assurance}
              selectedImageUrl={selectedImagePreview.assurance}
            />
          </label>
        </Box>

        <Box mb={2}>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="technical_control-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'technical_control')}
          />
          <label htmlFor="technical_control-input">
            <p className="vehiculeLabel">Contrôle technique</p>
            <VehiculeImages
              imageKey={vehiculeForm.technical_control}
              selectedImageUrl={selectedImagePreview.technical_control}
            />
          </label>
        </Box>
        <Box mb={2}>
          <input
            accept="image/*, application/pdf"
            style={{ display: 'none' }}
            id="contrat_location-input"
            type="file"
            onChange={(e) => handleVehiculeFileChange(e, 'contrat_location')}
          />
          <label htmlFor="contrat_location-input">
            <p className="vehiculeLabel">Contrat de location</p>
            <VehiculeImages
              imageKey={vehiculeForm.contrat_location}
              selectedImageUrl={selectedImagePreview.contrat_location}
            />
          </label>
        </Box>
        <div className="sevenLine">
          <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '20%' }} variant="contained" type="submit" onClick={handleSubmit}>
            Ajouter le véhicule
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default React.memo(AddVehiculeModal);
