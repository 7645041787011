/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';

function VehicleGrid(props) {
  const [vehicles, setVehicles] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [transports, setTransports] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('ASJ');
  const [averagePerVehicle, setAveragePerVehicle] = useState({});
  const [globalAverage, setGlobalAverage] = useState(0);
  const { dateRange } = props;
  const { userToken } = props;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const selectedCompanyRef = useRef(selectedCompany);

  const fetchTransports = async () => {
    try {
      const res = await axios.get(`${BASE_URL}transports`, { headers });
      setTransports(res.data.transports);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchVehicules = async () => {
    try {
      const res = await axios.get(`${BASE_URL}vehicules`, { headers });
      setVehicles(res.data.vehicule);
      setAllVehicles(res.data.vehicule);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVehicules();
    fetchTransports();
  }, []);

  useEffect(() => {
    let companyVehicles = allVehicles;
    selectedCompanyRef.current = selectedCompany;
    if (selectedCompany) {
      companyVehicles = allVehicles.filter((vehicle) => vehicle.societe === selectedCompany);
    }

    // Filtrer les factures par date ET par statut ("Terminé" ou "Annulé facturé")
    const filteredFactures = transports.filter((facture) => {
      const date = new Date(facture.date);
      const isValidStatus = facture.status === 'Terminé' || facture.status === 'Annuler facturé';
      return date >= dateRange.startDate && date <= dateRange.endDate && isValidStatus;
    });

    const totalFactureAmountForAllVehicles = {};
    let totalFacturesForAllVehicles = 0;

    companyVehicles.forEach((vehicle) => {
      const facturesForThisVehicle = filteredFactures.filter(
        (facture) => facture.vehicule === vehicle.code
      );

      if (facturesForThisVehicle.length > 0) {
        let totalFactureAmountForThisVehicle = 0;

        facturesForThisVehicle.forEach((facture) => {
          totalFactureAmountForThisVehicle += parseFloat(facture.price || 0);
        });
        vehicle.averageFacturePrice = totalFactureAmountForThisVehicle / facturesForThisVehicle.length;
        totalFactureAmountForAllVehicles[vehicle.code] = totalFactureAmountForThisVehicle;
        totalFacturesForAllVehicles += facturesForThisVehicle.length;
      }
      else {
        vehicle.averageFacturePrice = 0;
      }
    });

    const totalFactureAmount = Object.values(totalFactureAmountForAllVehicles).reduce((a, b) => a + b, 0);
    const globalAverageFacturePrice = totalFacturesForAllVehicles > 0 ? totalFactureAmount / totalFacturesForAllVehicles : 0;

    setAveragePerVehicle(totalFactureAmountForAllVehicles);
    setGlobalAverage(globalAverageFacturePrice);

    companyVehicles.sort((a, b) => a.code - b.code);
    setVehicles(companyVehicles);
  }, [selectedCompany, dateRange, allVehicles]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredVehicles = vehicles.filter(
    (vehicle) => vehicle.code.toLowerCase().includes(search.toLowerCase())
      || vehicle.immat.toLowerCase().includes(search.toLowerCase())
  );

  if (loading) return <div><AmbysoftLoader /></div>;

  return (
    <div className="userGrid">
      <div className="articles-search">
        <form>
          <input
            type="text"
            className="articles-input"
            placeholder="Rechercher un véhicule"
            onChange={handleChange}
          />
        </form>
      </div>

      <div className="select-wrapper">
        <div>
          <select
            className="select_user_grid"
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="ASJ">ASJ</option>
            <option value="APAP">APAP</option>
            <option value="A2L">A2L</option>
            <option value="SNAS">SNAS</option>
            <option value="SNAM">SNAM</option>
          </select>
        </div>
      </div>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="fl-table">
            <thead>
              <tr>
                <th>Numéro Véhicule</th>
                <th>Immatriculation</th>
                <th>Moyenne de prix d'une mission</th>
                <th>Chiffre d'affaire</th>
              </tr>
            </thead>
            <tbody>
              {filteredVehicles.map((vehicle) => (
                <tr key={vehicle.id} className="userGrid-body">
                  <td>{vehicle.code}</td>
                  <td>{vehicle.immat}</td>
                  <td>
                    {vehicle.averageFacturePrice
                      ? vehicle.averageFacturePrice.toLocaleString('fr-FR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : 'N/A'}
                    €
                  </td>
                  <td>
                    {averagePerVehicle[vehicle.code]
                      ? averagePerVehicle[vehicle.code].toLocaleString('fr-FR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : 'N/A'}
                    €
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="userGrid-footer">
        <p>
          Moyenne de prix d'une mission total:{' '}
          {globalAverage
            ? globalAverage.toLocaleString('fr-FR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            : 'N/A'}
          €
        </p>
      </div>
    </div>
  );
}

export default React.memo(VehicleGrid);
