/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// PorteDocument.js
import React, { useState } from 'react';
import GeneralDocuments from './generalDocument/generalDocument';
import './documentHolderStyles.scss';

function PorteDocument({
  userRole, theme, userToken, userEmail
}) {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <div className="porte-document">
      <div className="tabs">
        <button type="button" onClick={() => setActiveTab('general')} className={activeTab === 'general' ? 'active' : ''}>Général</button>
      </div>

      <div className="content">
        {activeTab === 'general' && <GeneralDocuments userRole={userRole} theme={theme} userToken={userToken} userEmail={userEmail} />}
      </div>
    </div>
  );
}

export default React.memo(PorteDocument);
