import axios from 'axios';

const fetchAddresses = async (query, options = {}) => {
  try {
    const {
      limit, autocomplete, lat, lon, postcode, type, citycode
    } = options;
    let url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}`;

    if (limit) url += `&limit=${limit}`;
    if (autocomplete !== undefined) url += `&autocomplete=${autocomplete}`;
    if (lat && lon) url += `&lat=${lat}&lon=${lon}`;
    if (postcode) url += `&postcode=${postcode}`;
    if (type) url += `&type=${type}`;
    if (citycode) url += `&citycode=${citycode}`;

    const response = await axios.get(url);
    return response.data;
  }
  catch (error) {
    console.error('Error fetching addresses:', error);
    return null;
  }
};

export default fetchAddresses;
