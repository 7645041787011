/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SignUpForm from '../signUp/signUp';
import './adminDashboard.scss';
import usePageTitle from '../hooks/usePageTitle';
import { BASE_URL } from '../../utils/api';

function AdminDashboard({ userToken }) {
  usePageTitle('DRIVESOFT | ADMINISTRATEUR');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newPasswords, setNewPasswords] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmPasswords, setConfirmPasswords] = useState({});
  const [passwordVisible, setPasswordVisible] = useState({});
  const [searchTerm, setSearchTerm] = useState(''); // Nouvel état pour la barre de recherche
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\w\W])[A-Za-z\d\w\W]{8,}$/;

  const togglePasswordVisibility = (userId) => {
    setPasswordVisible((prevVisible) => ({
      ...prevVisible,
      [userId]: !prevVisible[userId]
    }));
  };
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}users`, { headers });
      setUsers(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}roles`, { headers });
      setRoles(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des rôles:', error);
    }
  };

  const updateUserRole = async (userId, roleName) => {
    try {
      await axios.put(`${BASE_URL}users/${userId}/role`, { roleName }, { headers });
      fetchUsers();
      swal.fire('Succès', 'Rôle de l\'utilisateur mis à jour avec succès.', 'success');
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour du rôle de l\'utilisateur:', error);
      swal.fire('Erreur', 'Erreur lors de la mise à jour du rôle de l\'utilisateur.', 'error');
    }
  };

  const updatePassword = async (userId, newPassword) => {
    try {
      await axios.put(`${BASE_URL}users/${userId}/password`, { newPassword }, { headers });
      fetchUsers();
      swal.fire('Succès', 'Mot de passe mis à jour avec succès.', 'success');
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour du mot de passe de l\'utilisateur:', error);
      swal.fire('Erreur', 'Erreur lors de la mise à jour du mot de passe.', 'error');
    }
  };

  const isValidPassword = (password) => passwordRegex.test(password);

  const handlePasswordUpdate = (userId) => {
    const newPassword = newPasswords[userId];
    if (!isValidPassword(newPassword)) {
      swal.fire('Erreur', 'Le mot de passe doit contenir au moins 8 caractères, dont une lettre et un chiffre.', 'error');
      return;
    }
    updatePassword(userId, newPassword);
  };

  const deleteUser = async (userId) => {
    swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas revenir en arrière!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimez-le!',
      cancelButtonText: 'Non, annulez!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}users/${userId}`, { headers });
          fetchUsers();
          swal.fire('Supprimé!', 'L\'utilisateur a été supprimé.', 'success');
        }
        catch (error) {
          console.error('Erreur lors de la suppression de l\'utilisateur:', error);
          swal.fire('Erreur', 'Erreur lors de la suppression de l\'utilisateur.', 'error');
        }
      }
    });
  };

  const confirmCloseModal = (setModalState) => {
    swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        setModalState(false);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  // Filtrer les utilisateurs en fonction de la recherche
  // Filtrer les utilisateurs en fonction de la recherche
  const filteredUsers = users.filter(
    (user) => (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase()))
    || (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="admin_dashboard">
      <button type="button" className="AddTransport" onClick={() => setIsModalOpen(true)}>Ajouter un utilisateur</button>
      <input
        type="text"
        placeholder="Rechercher par nom d'utilisateur ou email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="searchInput"
      />
      <Modal
        isOpen={isModalOpen}
        style={{
          content: {
            width: '90%', height: '90vh', margin: 'auto', overflow: 'hidden',
          }
        }}
        onRequestClose={() => confirmCloseModal(setIsModalOpen)}
        contentLabel="Inscription Utilisateur"
      >
        <SignUpForm />
      </Modal>
      <table className="table">
        <thead>
          <tr className="table-row">
            <th className="table__header">Rôle</th>
            <th className="table__header">Nom d'utilisateur</th>
            <th className="table__header">Email</th>
            <th className="table__header table__header--hide-on-mobile">Mot de passe</th>
            <th className="table__header">Modification rôle</th>
            <th className="table__header delete_row table__header--hide-on-mobile">Suppression</th>
          </tr>
        </thead>
        <tbody className="transportBody">
          {filteredUsers.map((user) => (
            <tr className="table-row" key={user.id}>
              <td className="table__cell">{user.role_name}</td>
              <td className="table__cell">{user.username}</td>
              <td className="table__cell">{user.email}</td>
              <td className="table__header--hide-on-mobile">
                <div className="password-update">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const newPassword = newPasswords[user.id];
                      const confirmPassword = confirmPasswords[user.id];
                      if (newPassword !== confirmPassword) {
                        swal.fire('Erreur', 'Les mots de passe ne correspondent pas.', 'error');
                        return;
                      }
                      handlePasswordUpdate(user.id);
                    }}
                  >
                    <input
                      className="updatePassword table__header--hide-on-mobile"
                      type={passwordVisible[user.id] ? 'text' : 'password'}
                      placeholder="Nouveau mot de passe"
                      value={newPasswords[user.id] || ''}
                      onChange={(e) => setNewPasswords({ ...newPasswords, [user.id]: e.target.value })}
                    />
                    <button
                      type="button"
                      onClick={() => togglePasswordVisibility(user.id)}
                      style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
                    >
                      {passwordVisible[user.id] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </button>
                    <input
                      className="updatePassword table__header--hide-on-mobile"
                      type={passwordVisible[user.id] ? 'text' : 'password'}
                      placeholder="Confirmer le nouveau mot de passe"
                      value={confirmPasswords[user.id] || ''}
                      onChange={(e) => setConfirmPasswords({ ...confirmPasswords, [user.id]: e.target.value })}
                    />
                    <button className="buttonUpdate table__header--hide-on-mobile" type="submit">Mettre à jour</button>
                  </form>
                </div>
              </td>
              <td className="table__cell">
                <select
                  className="row__select"
                  value={user.role_name}
                  onChange={(e) => updateUserRole(user.id, e.target.value)}
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </td>
              <td className="table__cell table__header--hide-on-mobile">
                <button type="button" onClick={() => deleteUser(user.id)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(AdminDashboard);
