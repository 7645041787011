/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// ProceduresManagementData.js
import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Rating,
  Fade,
  Button,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

function ProceduresManagementData({
  theme,
  procedureTypes,
  setSelectedProcedureType,
  selectedProcedureType,
  procedures,
  showOnlyQualiteProcedures,
  setShowOnlyQualiteProcedures,
  setSelectedProcedure,
  selectedProcedure,
  questionAverages,
  dataForEmployeesChart,
  dataForVehiclesChart,
  dataForProceduresChart,
  positiveResponses,
  negativeResponses,
  radioResponses, // Recevoir les réponses radio
  textComments,
}) {
  // Options pour les graphiques Bar
  const chartOptionsBar = (themes) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
          color: themes === 'dark' ? '#feba00' : '#000',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
          // beginAtZero: true, // Supprimé pour permettre les valeurs négatives
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: themes === 'dark' ? '#feba00' : '#000',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
        backgroundColor: themes === 'dark' ? '#333' : '#fff',
        titleColor: themes === 'dark' ? '#feba00' : '#000',
        bodyColor: themes === 'dark' ? '#feba00' : '#000',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: themes === 'dark' ? '#feba00' : '#000',
        font: {
          size: 12,
        },
        formatter: function (value, context) {
          return Math.abs(Math.round(value));
        },
      },
    },
  });

  const chartOptionsEmployees = (themes) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: themes === 'dark' ? '#feba00' : '#000',
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Désactiver la légende
      },
      tooltip: {
        color: themes === 'dark' ? '#feba00' : '#000',
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y.toFixed(2);
            label += '%';
            return label;
          },
        },
      },
    },
  });

  const chartOptionsVehicles = (themes) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Désactiver la légende
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y}`;
          },
        },
      },
    },
  });

  const procedureHasType = (procedure, typeName) => procedure.types.some((type) => type.name === typeName);

  // Fonction pour générer des couleurs pastel
  const generateColors = (num) => {
    const colors = [];
    const baseColors = [
      'rgba(255, 99, 132, 0.6)', // Rouge
      'rgba(54, 162, 235, 0.6)', // Bleu
      'rgba(255, 206, 86, 0.6)', // Jaune
      'rgba(75, 192, 192, 0.6)', // Vert
      'rgba(153, 102, 255, 0.6)', // Violet
      'rgba(255, 159, 64, 0.6)', // Orange
    ];
    for (let i = 0; i < num; i++) {
      colors.push(baseColors[i % baseColors.length]);
    }
    return colors;
  };

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: '#f4f6f8',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Titre Principal */}
      <Typography variant="h4" align="center" gutterBottom>
        Statistiques des procédures
      </Typography>

      {/* Barre de Filtrage */}
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ marginBottom: 4 }}
      >
        {/* Sélecteur de Type de Procédure */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="procedure-type-label">Type de procédure</InputLabel>
            <Select
              labelId="procedure-type-label"
              id="procedure-type-select"
              value={selectedProcedureType}
              label="Type de Procédure"
              onChange={(e) => setSelectedProcedureType(e.target.value)}
            >
              <MenuItem value="">
                <em>Tous les types de procédures</em>
              </MenuItem>
              {procedureTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Sélecteur de Procédure */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="procedure-select-label">Procédure</InputLabel>
            <Select
              labelId="procedure-select-label"
              id="procedure-select"
              value={selectedProcedure}
              label="Procédure"
              onChange={(e) => setSelectedProcedure(e.target.value)}
            >
              <MenuItem value="">
                <em>Toutes les procédures</em>
              </MenuItem>
              {procedures
                .filter((procedure) => {
                  if (showOnlyQualiteProcedures) {
                    return procedureHasType(procedure, 'QUALITE');
                  }
                  return true;
                })
                .map((procedure) => (
                  <MenuItem key={procedure.id} value={procedure.id}>
                    {procedure.name}
                  </MenuItem>
                ))}
            </Select>

          </FormControl>
        </Grid>

        {/* Bouton de Basculement */}
        <Grid item xs={12} sm={12} md={3} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color={showOnlyQualiteProcedures ? 'secondary' : 'primary'}
            startIcon={
              showOnlyQualiteProcedures ? <BarChartIcon /> : <PieChartIcon />
            }
            onClick={() => setShowOnlyQualiteProcedures(!showOnlyQualiteProcedures)}
            sx={{
              paddingX: 4,
              paddingY: 1.5,
              minWidth: '220px',
              transition: 'background-color 0.3s ease',
            }}
          >
            {showOnlyQualiteProcedures
              ? 'Afficher les Graphiques'
              : 'Afficher les Procédures QUALITE'}
          </Button>
        </Grid>
      </Grid>

      {/* Contenu Principal */}
      {showOnlyQualiteProcedures ? (
        // Vue Résumé pour les procédures "QUALITE"
        <Fade in={showOnlyQualiteProcedures} timeout={500}>
          <Box>
            {procedures.filter((proc) => procedureHasType(proc, 'QUALITE'))
              .length > 0 ? (
                procedures
                  .filter((proc) => procedureHasType(proc, 'QUALITE'))
                  .map((procedure) => {
                    const averages = questionAverages[procedure.id] || {};
                    const radioResp = radioResponses[procedure.id] || {};
                    const comments = textComments[procedure.id] || [];

                    return (
                      <Paper
                        key={procedure.id}
                        elevation={3}
                        sx={{ padding: 3, marginBottom: 3 }}
                      >
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ color: 'primary.main' }}
                        >
                          {procedure.name}
                        </Typography>

                        {/* Moyennes des champs de satisfaction */}
                        {Object.keys(averages).length > 0 && (
                        <Box sx={{ marginTop: 2 }}>
                          <Grid container spacing={2}>
                            {Object.entries(averages).map(
                              ([fieldName, data]) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={fieldName}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: 'bold',
                                        width: '60%',
                                      }}
                                    >
                                      {fieldName}:
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Rating
                                        name={`rating-${fieldName}`}
                                        value={data.total / data.count}
                                        precision={0.1}
                                        readOnly
                                        sx={{ marginRight: 1 }}
                                      />
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        {(data.total / data.count).toFixed(2)}/5
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Box>
                        )}

                        {/* Distribution des champs de type Radio avec Bar Charts */}
                        {Object.keys(radioResp).length > 0 && (
                        <Box sx={{ marginTop: 4 }}>
                          <Typography variant="h6">Distribution</Typography>
                          {Object.entries(radioResp).map(
                            ([fieldName, options]) => {
                              const labels = Object.keys(options);
                              const counts = Object.values(options);
                              const data = {
                                labels,
                                datasets: [
                                  {
                                    label: 'Nombre de réponses',
                                    data: counts,
                                    backgroundColor: generateColors(
                                      labels.length
                                    ),
                                  },
                                ],
                              };

                              return (
                                <Box
                                  key={fieldName}
                                  sx={{
                                    marginTop: 2,
                                    maxWidth: 500,
                                    margin: '0 auto',
                                  }}
                                >
                                  {/* Suppression du label au-dessus du graphique */}
                                  {/* <Typography variant="subtitle1" gutterBottom>
                                    {fieldName}
                                  </Typography> */}
                                  <Bar
                                    data={data}
                                    options={chartOptionsBar(theme)}
                                    height={200}
                                  />
                                </Box>
                              );
                            }
                          )}
                        </Box>
                        )}

                        {/* Liste des commentaires */}
                        {comments.length > 0 && (
                        <Box sx={{ marginTop: 4 }}>
                          <Typography variant="h6">Commentaires</Typography>
                          {comments.map((comment, index) => (
                            <Paper
                              key={index}
                              elevation={1}
                              sx={{ padding: 2, marginTop: 1 }}
                            >
                              <Typography variant="body2">
                                {comment}
                              </Typography>
                            </Paper>
                          ))}
                        </Box>
                        )}
                      </Paper>
                    );
                  })
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  color="text.secondary"
                  sx={{ marginTop: 4 }}
                >
                  Aucune donnée disponible pour les procédures "QUALITE".
                </Typography>
              )}
          </Box>
        </Fade>
      ) : (
        // Vue Graphiques pour les procédures globales
        <Fade in={!showOnlyQualiteProcedures} timeout={500}>
          <Grid container spacing={4}>
            {/* Nombre par employé */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <BarChartIcon />
                  Nombre par employé
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Bar
                    data={dataForEmployeesChart}
                    options={chartOptionsEmployees(theme)}
                  />
                </Box>
              </Paper>
            </Grid>

            {/* Nombre par véhicule */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <PieChartIcon />
                  Nombre par véhicule
                </Typography>
                <Box sx={{ height: 300 }}>
                  <Bar
                    data={dataForVehiclesChart}
                    options={chartOptionsVehicles(theme)}
                  />
                </Box>
              </Paper>
            </Grid>

            {/* Balances retours des procédures */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <BarChartIcon />
                  Balances retours des procédures
                </Typography>
                <Box sx={{ height: 400 }}>
                  <Bar
                    data={dataForProceduresChart}
                    options={chartOptionsBar(theme)}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Box>
  );
}

export default React.memo(ProceduresManagementData);
