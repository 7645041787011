/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import './dashboardStyles.scss';
import usePageTitle from '../hooks/usePageTitle';
import SalesDivision from './SalesDivision/salesDivision';
import Overview from './OverView/overView'; //

function Dashboard({ userToken }) {
  usePageTitle('DRIVESOFT | TABLEAU DE BORD');
  const userRole = localStorage.getItem('userRole');

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedSociety, setSelectedSociety] = useState('');
  const [showOverview, setShowOverview] = useState(true);

  const toggleOverviewAndSales = () => {
    setShowOverview(!showOverview);
  };

  return (
    <div className="dashboard">
      {userRole === 'invité' ? (
        <p className="bad_role_message">Vous êtes sur un compte "invité", veuillez demander les permissions à l'administrateur.</p>
      ) : (
        <>
          <h2 className="dashboard-title">Tableau de bord</h2>
          <div className="grid-button">
            <button className="toggle-btn" type="button" onClick={toggleOverviewAndSales}>
              {showOverview ? 'Chiffres détaillés ⟳' : 'Vue densemble ⟳'}
            </button>
          </div>
          {showOverview
            ? (
              <Overview
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                selectedSociety={selectedSociety}
                setSelectedSociety={setSelectedSociety}
                userToken={userToken}
              />
            )
            : (
              <SalesDivision
                selectedSociety={selectedSociety}
                setSelectedSociety={setSelectedSociety}
                userToken={userToken}
              />
            )}
        </>
      )}
    </div>
  );
}

export default React.memo(Dashboard);
