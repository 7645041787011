/* eslint-disable max-len */
import React from 'react';
import './aboutStyles.scss';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  FaRegClock,
  FaFileInvoice,
  FaWarehouse,
  FaCogs,
  FaChartLine,
  FaCar,
  FaDatabase,
  FaLocationArrow,
  FaMobileAlt,
  FaClipboardCheck,
} from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';

function About() {
  usePageTitle('DRIVESOFT - SOLUTION');

  return (
    <div className="aboutPage">
      {/* Section Introduction */}
      <section className="introSection">
        <h1>Découvrez Drivesoft</h1>
        <p>
          Drivesoft est la solution ultime pour gérer vos sociétés de transport sanitaire. Nous vous offrons un
          logiciel complet englobant la régulation, la facturation, la gestion du temps,
          l'inventaire, le tableau de bord, les données d'exploitation et la localisation.
          Compatible avec divers dispositifs, notre solution vous accompagne globalement,
          nécessitant uniquement une connexion Internet.
          <br />
          <br />
          Drivesoft propose également une application mobile disponible sur iOS et Android, vous
          offrant une flexibilité totale pour gérer vos opérations en déplacement. Avec notre
          application, accédez au pointage des heures, à la gestion des stocks, des procédures, du
          planning, et à un porte-document, le tout optimisé pour une utilisation mobile.
          <br />
          <br />
          Notre ambition est de fournir aux entreprises et professionnels un outil de pilotage
          intégré, évolutif et entièrement personnalisable. Reconnaissant la diversité des opérations
          commerciales, Drivesoft s'engage à adapter ses services pour répondre efficacement à vos
          exigences spécifiques.
        </p>
      </section>

      {/* Section des fonctionnalités */}
      <section className="featuresSection">
        <h2>Nos fonctionnalités clés</h2>
        <div className="featuresGrid">
          {/* Fonctionnalité 1 */}
          <div className="featureItem">
            <FaCar className="featureIcon" />
            <h3>Régulation</h3>
            <p>
              Optimisez la gestion de vos missions et améliorez votre efficience.
            </p>
          </div>
          {/* Fonctionnalité 2 */}
          <div className="featureItem">
            <FaRegClock className="featureIcon" />
            <h3>Gestion du temps</h3>
            <p>
              Optimisez la gestion du temps de vos employés et améliorez la productivité.
            </p>
          </div>
          {/* Fonctionnalité 3 */}
          <div className="featureItem">
            <FaFileInvoice className="featureIcon" />
            <h3>Facturation</h3>
            <p>
              Gérez facilement la facturation et assurez-vous de la conformité financière.
            </p>
          </div>
          {/* Fonctionnalité 4 */}
          <div className="featureItem">
            <FaWarehouse className="featureIcon" />
            <h3>Gestion de stocks</h3>
            <p>
              Surveillez vos stocks en temps réel et évitez les ruptures de stock.
            </p>
          </div>
          {/* Fonctionnalité 5 */}
          <div className="featureItem">
            <FaChartLine className="featureIcon" />
            <h3>Tableau de bord</h3>
            <p>
              Visualisez toutes vos données en temps réel et prenez les meilleures décisions.
            </p>
          </div>
          {/* Fonctionnalité 6 */}
          <div className="featureItem">
            <FaDatabase className="featureIcon" />
            <h3>Données d'exploitation</h3>
            <p>
              Consultez vos données d'exploitation et améliorez votre efficience.
            </p>
          </div>
          {/* Fonctionnalité 7 */}
          <div className="featureItem">
            <FaLocationArrow className="featureIcon" />
            <h3>Localisation</h3>
            <p>
              Localisez vos véhicules en temps réel et optimisez vos trajets.
            </p>
          </div>
          {/* Fonctionnalité 8 */}
          <div className="featureItem">
            <FaCogs className="featureIcon" />
            <h3>Personnalisation</h3>
            <p>
              Adaptez notre solution à vos besoins pour une expérience optimale.
            </p>
          </div>
          {/* Fonctionnalité 9 */}
          <div className="featureItem">
            <FaMobileAlt className="featureIcon" />
            <h3>Application mobile</h3>
            <p>
              Accédez et gérez vos opérations via notre application sur iOS et Android, où que vous
              soyez.
            </p>
          </div>
          {/* Fonctionnalité 10 */}
          <div className="featureItem">
            <FaClipboardCheck className="featureIcon" />
            <h3>Procédures qualité</h3>
            <p>
              Maintenez des standards élevés avec des procédures qualité intégrées, assurant une
              performance constante.
            </p>
          </div>
        </div>
      </section>

      {/* Section Appel à l'action */}
      <section className="ctaSection">
        <h2>Prêt à transformer la gestion de votre flotte ?</h2>
        <Link to="/contact">
          <Button variant="contained" color="primary" className="ctaButton">
            Contactez-nous dès maintenant
          </Button>
        </Link>
      </section>

      {/* Pied de page */}
      <footer className="aboutFooter">
        <div className="footerContent">
          <div className="legalLinks">
            <Link to="/privacy">Politique de confidentialité</Link>
            <Link to="/terms">Conditions générales d'utilisation</Link>
            <Link to="/legal-mentions">Mentions légales</Link>
            <p>
              Contact : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a> | Tél : (+33)6 52 80 77 36
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default React.memo(About);
