/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  IconButton,
  Box,
  Chip,
} from '@mui/material';
import { ArrowBack, ArrowForward, Close as CloseIcon } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';

function Crews({ userRole, userToken }) {
  usePageTitle('DRIVESOFT | ÉQUIPAGES');
  const [crews, setCrews] = useState([]);
  const [initialCrewData, setInitialCrewData] = useState(null);
  const [staff, setStaff] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [crewTipe, setCrewTipe] = useState('');
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentCrew, setCurrentCrew] = useState(null);
  const [showChauffeur2Input, setShowChauffeur2Input] = useState(false);
  const [updatedStaffList, setUpdatedStaffList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Nouvel état pour le filtre de société
  const [selectedCompany, setSelectedCompany] = useState('Tous');

  const crewTypes = ['AMB', 'PARA', 'SAMU', 'VSL', 'TAXI', 'BARIA', 'PSY'];

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchCrews = async () => {
    setDataLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}crews`, { headers });
      setCrews(response.data.crews);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setDataLoading(false);
    }
  };

  const fetchStaff = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      const filteredStaff = response.data.staff.filter(
        (member) => !member.exitDate && member.graduation !== 'ADMINISTRATIF'
      );
      filteredStaff.sort((a, b) => a.name.localeCompare(b.name));
      setStaff(filteredStaff);
    }
    catch (error) {
      console.error('Failed to fetch staff:', error);
    }
  };

  const fetchVehicules = async () => {
    try {
      const response = await axios.get(`${BASE_URL}vehicules`, { headers });
      const sortedVehicules = response.data.vehicule.sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true }));
      setVehicules(sortedVehicules);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchPlanning = async () => {
    try {
      const response = await axios.get(`${BASE_URL}planning`, { headers });
      setPlanning(response.data.plannings);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchStaffCompanies = async (staffId) => {
    try {
      const response = await axios.get(`${BASE_URL}staffCompanies/${staffId}`, { headers });
      return response.data.companies;
    }
    catch (error) {
      console.error('Error fetching staff companies:', error);
      return [];
    }
  };

  useEffect(() => {
    setShowChauffeur2Input(crewTipe !== 'VSL' && crewTipe !== 'TAXI');
  }, [crewTipe]);

  useEffect(() => {
    fetchCrews();
    fetchStaff();
    fetchVehicules();
    fetchPlanning();
  }, []);

  const getEmployeeName = (staffId) => {
    const employee = staff.find((employees) => employees.id === staffId);
    if (employee) {
      return `${employee.name} ${employee.last_name}`;
    }
    return '';
  };

  const getVehiculeCode = (vehiculeId) => {
    const vehicule = vehicules.find((vehicul) => vehicul.id === vehiculeId);
    if (vehicule) {
      return `${vehicule.code}`;
    }
    return '';
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const isStaffCompanyValid = (staffId, vehicleCompany, staffCompanies) => {
    const staffMember = staff.find((member) => member.id === parseInt(staffId, 10));
    if (staffMember.company === vehicleCompany) {
      return true;
    }
    return staffCompanies.some((company) => company.code === vehicleCompany);
  };

  const formatDate = (dateString) => dayjs(dateString).format('YYYY-MM-DD');

  const staffIdsWorkingOnSelectedDate = planning
    .filter(
      (entry) => entry.date.slice(0, 10) === selectedDate.format('YYYY-MM-DD') && entry.status === 'T'
    )
    .map((entry) => entry.staff_id);

  const staffIdsInCrewsOnSelectedDate = crews
    .filter((crew) => formatDate(crew.date) === selectedDate.format('YYYY-MM-DD'))
    .flatMap((crew) => [crew.staff_id_1, crew.staff_id_2]);

  const staffWorkingOnSelectedDate = staff
    .filter((member) => staffIdsWorkingOnSelectedDate.includes(member.id))
    .filter((member) => !staffIdsInCrewsOnSelectedDate.includes(member.id));

  const storeVehicleIdByDate = (vehicleId, date) => {
    const vehicleIdsByDate = JSON.parse(localStorage.getItem('vehicleIdsByDate') || '{}');
    if (vehicleIdsByDate[date]) {
      if (!vehicleIdsByDate[date].includes(vehicleId)) {
        vehicleIdsByDate[date].push(vehicleId);
      }
    }
    else {
      vehicleIdsByDate[date] = [vehicleId];
    }
    localStorage.setItem('vehicleIdsByDate', JSON.stringify(vehicleIdsByDate));
  };

  const removeVehicleIdForDate = (vehicleId, date) => {
    const vehicleIdsByDate = JSON.parse(localStorage.getItem('vehicleIdsByDate') || '{}');
    const vehicleIdNb = String(vehicleId);

    if (vehicleIdsByDate[date]) {
      vehicleIdsByDate[date] = vehicleIdsByDate[date].filter((id) => id !== vehicleIdNb);

      if (vehicleIdsByDate[date].length === 0) {
        delete vehicleIdsByDate[date];
      }
    }

    localStorage.setItem('vehicleIdsByDate', JSON.stringify(vehicleIdsByDate));
  };

  const openAddModal = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'avez pas la permission de créer un équipage",
      });
      return;
    }
    setCrewTipe('');
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => setIsAddModalOpen(false);

  const openUpdateModal = async (crew) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'avez pas la permission de modifier un équipage",
      });
      return;
    }
    setCurrentCrew(crew);
    setInitialCrewData({
      date: dayjs(crew.date).format('YYYY-MM-DD'),
      staff_id_1: crew.staff_id_1,
      staff_id_2: crew.staff_id_2,
      vehicule_id: crew.vehicule_id,
      crew_type: crew.crew_type,
    });

    const staffWorkingOnDate = staff.filter((member) => staffIdsWorkingOnSelectedDate.includes(member.id));
    const crewMembers = [crew.staff_id_1, crew.staff_id_2]
      .map((id) => staff.find((member) => member.id === id))
      .filter(Boolean);
    const combinedStaff = [...new Set([...staffWorkingOnDate, ...crewMembers])];

    setUpdatedStaffList(combinedStaff);
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => setIsUpdateModalOpen(false);

  const getTimeClockEntries = async () => {
    try {
      const response = await axios.get(`${BASE_URL}timeClock`, { headers });
      return response.data.timeClockEntries;
    }
    catch (error) {
      console.error('Failed to fetch time clock entries', error);
      throw error;
    }
  };

  const createTimeClockEntryIfNeeded = async (staffId, date) => {
    try {
      const timeClockEntriesResponse = await getTimeClockEntries();
      const dateString = dayjs(date).format('YYYY-MM-DD');

      const existingEntry = timeClockEntriesResponse.find(
        (entry) => entry.staff_id === staffId && entry.date === dateString
      );

      if (!existingEntry) {
        const baseEntry = {
          staff_id: staffId,
          clock_in_time: '00:00',
          clock_out_time: '00:00',
          break_time: null,
          lunch_break_time: null,
          break_time2: null,
          break_time3: null,
          break_time4: null,
          date: dateString,
        };
        await axios.post(`${BASE_URL}timeClock`, baseEntry, { headers });
      }
    }
    catch (error) {
      console.error('Failed to create time clock entry', error);
    }
  };

  const handleCreateCrew = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const date = selectedDate.format('YYYY-MM-DD'); // Utilisez selectedDate
    const staff_id_1 = formData.get('staff_id_1');
    const staff_id_2 = formData.get('staff_id_2');
    const vehicule_id = formData.get('vehicule_id');
    const crew_type = formData.get('crew_type');

    const existingCrewForVehicle = crews.filter(
      (crew) => crew.date.startsWith(selectedDate.format('YYYY-MM-DD'))
        && crew.vehicule_id === parseInt(vehicule_id, 10)
    );

    if (existingCrewForVehicle.length > 0) {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text:
          "Ce véhicule a déjà un enregistrement pour la date sélectionnée. Voulez-vous quand même créer l'équipage?",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    const existingCrewForStaff = crews.filter(
      (crew) => crew.date.startsWith(selectedDate.format('YYYY-MM-DD'))
        && (crew.staff_id_1 === parseInt(staff_id_1, 10) || crew.staff_id_2 === parseInt(staff_id_1, 10))
    );

    if (existingCrewForStaff.length > 0) {
      const existingStaffMember = staff.find((employee) => employee.id === parseInt(staff_id_1, 10));
      const staffMemberName = existingStaffMember
        ? `${existingStaffMember.name} ${existingStaffMember.last_name}`
        : 'Ce membre du personnel';

      const result = await Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text: `${staffMemberName} est déjà utilisé dans un équipage existant pour la date sélectionnée. Voulez-vous quand même créer l'équipage?`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    if (crew_type === 'VSL' || crew_type === 'TAXI') {
      try {
        await axios.post(
          `${BASE_URL}crews`,
          {
            date,
            staff_id_1,
            vehicule_id,
            crew_type,
          },
          { headers }
        );
        storeVehicleIdByDate(vehicule_id, date);
        fetchCrews();
        showSnackbar('Équipage créé avec succès', 'success');
      }
      catch (error) {
        console.error(error);
        showSnackbar("Erreur lors de la création de l'équipage", 'error');
      }
    }
    else {
      const selectedVehicle = vehicules.find((v) => v.id === parseInt(vehicule_id, 10));
      const firstDriver = staff.find((s) => s.id === parseInt(staff_id_1, 10));
      const secondDriver = staff.find((s) => s.id === parseInt(staff_id_2, 10));
      const staff1Companies = await fetchStaffCompanies(staff_id_1);
      const staff2Companies = await fetchStaffCompanies(staff_id_2);

      const isStaff1Valid = isStaffCompanyValid(staff_id_1, selectedVehicle.societe, staff1Companies);
      const isStaff2Valid = isStaffCompanyValid(staff_id_2, selectedVehicle.societe, staff2Companies);

      if (!isStaff1Valid || !isStaff2Valid) {
        await Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Un des chauffeurs n'appartient pas à la même société que le véhicule.",
          confirmButtonText: 'OK',
        });
        return;
      }

      if (
        !(
          firstDriver
          && (firstDriver.graduation === 'CCA' || firstDriver.graduation === 'DEA')
        )
        && !(
          secondDriver
          && (secondDriver.graduation === 'CCA' || secondDriver.graduation === 'DEA')
        )
      ) {
        await Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Au moins un des chauffeurs doit avoir le diplôme CCA ou DEA pour ce type d'équipage.",
          confirmButtonText: 'OK',
        });
        return;
      }

      try {
        await axios.post(
          `${BASE_URL}crews`,
          {
            date,
            staff_id_1,
            staff_id_2,
            vehicule_id,
            crew_type,
          },
          { headers }
        );
        storeVehicleIdByDate(vehicule_id, date);
        fetchCrews();
        showSnackbar('Équipage créé avec succès', 'success');
      }
      catch (error) {
        console.error(error);
        showSnackbar("Erreur lors de la création de l'équipage", 'error');
      }
    }

    await createTimeClockEntryIfNeeded(staff_id_1, date);
    if (crew_type !== 'VSL' && crew_type !== 'TAXI') {
      await createTimeClockEntryIfNeeded(staff_id_2, date);
    }

    closeAddModal();
  };

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setModalState(false);
      }
    });
  };

  const deleteTimeClockEntriesForCrew = async (crew) => {
    try {
      const timeClockEntriesResponse = await getTimeClockEntries();
      const isoDate = dayjs(crew.date).toISOString();

      const entriesToDelete = timeClockEntriesResponse.filter(
        (entry) => (entry.staff_id === crew.staff_id_1 || entry.staff_id === crew.staff_id_2) && entry.date === isoDate
      );

      await Promise.all(
        entriesToDelete.map((entry) => axios.delete(`${BASE_URL}timeClock/${entry.staff_id}/${entry.date}`, { headers }))
      );
    }
    catch (error) {
      console.error('Failed to delete time clock entries', error);
    }
  };

  const handleDeleteCrew = async (crewId) => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Vous n'avez pas la permission de supprimer un équipage",
      });
      return;
    }

    const result = await Swal.fire({
      icon: 'warning',
      title: 'Attention',
      text: 'Voulez-vous vraiment supprimer cet équipage?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    });

    if (!result.isConfirmed) {
      return;
    }

    try {
      const crewToDelete = crews.find((crew) => crew.id === crewId);
      if (crewToDelete) {
        await deleteTimeClockEntriesForCrew(crewToDelete);
        removeVehicleIdForDate(crewToDelete.vehicule_id, formatDate(crewToDelete.date));
        await axios.delete(`${BASE_URL}crews/${crewId}`, { headers });
        fetchCrews();
        showSnackbar('Équipage supprimé avec succès', 'success');
      }
    }
    catch (error) {
      console.error(error);
      showSnackbar("Erreur lors de la suppression de l'équipage", 'error');
    }
  };

  const handleUpdateCrew = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { date } = initialCrewData; // Utilisez initialCrewData.date
    const staff_id_1 = formData.get('staff_id_1');
    const staff_id_2 = formData.get('staff_id_2');
    const vehicule_id = formData.get('vehicule_id');
    const crew_type = formData.get('crew_type');

    const currentCrewData = crews.find((crew) => crew.id === currentCrew.id);
    const oldVehicleId = currentCrewData ? currentCrewData.vehicule_id : null;

    const existingCrewForVehicle = crews.filter(
      (crew) => crew.date.startsWith(selectedDate.format('YYYY-MM-DD'))
        && crew.vehicule_id === parseInt(vehicule_id, 10)
        && crew.id !== currentCrew.id // Exclure l'équipage actuel
    );

    if (existingCrewForVehicle.length > 0) {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text:
          "Ce véhicule a déjà un enregistrement pour la date sélectionnée. Voulez-vous quand même créer l'équipage?",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    const existingCrewForStaff = crews.filter(
      (crew) => crew.date.startsWith(selectedDate.format('YYYY-MM-DD'))
        && (crew.staff_id_1 === parseInt(staff_id_1, 10) || crew.staff_id_2 === parseInt(staff_id_1, 10))
        && crew.id !== currentCrew.id // Exclure l'équipage actuel
    );

    if (existingCrewForStaff.length > 0) {
      const existingStaffMember = staff.find((employee) => employee.id === parseInt(staff_id_1, 10));
      const staffMemberName = existingStaffMember
        ? `${existingStaffMember.name} ${existingStaffMember.last_name}`
        : 'Ce membre du personnel';

      const result = await Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text: `${staffMemberName} est déjà utilisé dans un équipage existant pour la date sélectionnée. Voulez-vous quand même créer l'équipage?`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    if (crew_type === 'VSL' || crew_type === 'TAXI') {
      try {
        await axios.put(
          `${BASE_URL}crews/${currentCrew.id}`,
          {
            date,
            staff_id_1,
            vehicule_id,
            crew_type,
          },
          { headers }
        );
        storeVehicleIdByDate(vehicule_id, date);
        fetchCrews();
        showSnackbar('Équipage mis à jour avec succès', 'success');
      }
      catch (error) {
        console.error(error);
        showSnackbar("Erreur lors de la mise à jour de l'équipage", 'error');
      }
    }
    else {
      if (staff_id_1 === staff_id_2) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Vous ne pouvez pas sélectionner le même membre du personnel pour les deux membres de l'équipage",
        });
        return;
      }

      const selectedVehicle = vehicules.find((v) => v.id === parseInt(vehicule_id, 10));
      const firstDriver = staff.find((s) => s.id === parseInt(staff_id_1, 10));
      const secondDriver = staff.find((s) => s.id === parseInt(staff_id_2, 10));

      const staff1Companies = await fetchStaffCompanies(staff_id_1);
      const staff2Companies = await fetchStaffCompanies(staff_id_2);

      const isStaff1Valid = isStaffCompanyValid(staff_id_1, selectedVehicle.societe, staff1Companies);
      const isStaff2Valid = isStaffCompanyValid(staff_id_2, selectedVehicle.societe, staff2Companies);

      if (!isStaff1Valid || !isStaff2Valid) {
        await Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Un des chauffeurs n'appartient pas à la même société que le véhicule.",
          confirmButtonText: 'OK',
        });
        return;
      }

      if (
        !(
          firstDriver
          && (firstDriver.graduation === 'CCA' || firstDriver.graduation === 'DEA')
        )
        && !(
          secondDriver
          && (secondDriver.graduation === 'CCA' || secondDriver.graduation === 'DEA')
        )
      ) {
        await Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Au moins un des chauffeurs doit avoir le diplôme CCA ou DEA pour ce type d'équipage.",
          confirmButtonText: 'OK',
        });
        return;
      }

      try {
        await axios.put(
          `${BASE_URL}crews/${currentCrew.id}`,
          {
            date,
            staff_id_1,
            staff_id_2,
            vehicule_id,
            crew_type,
          },
          { headers }
        );

        if (oldVehicleId && oldVehicleId !== vehicule_id) {
          removeVehicleIdForDate(String(oldVehicleId), date);
        }
        storeVehicleIdByDate(vehicule_id, date);
        fetchCrews();
        showSnackbar('Équipage mis à jour avec succès', 'success');
      }
      catch (error) {
        console.error(error);
        showSnackbar("Erreur lors de la mise à jour de l'équipage", 'error');
      }
    }

    await createTimeClockEntryIfNeeded(staff_id_1, date);
    if (crew_type !== 'VSL' && crew_type !== 'TAXI') {
      await createTimeClockEntryIfNeeded(staff_id_2, date);
    }

    closeUpdateModal();
  };

  const getSocieteName = (vehiculeId) => {
    const vehicule = vehicules.find((vehicul) => vehicul.id === vehiculeId);
    if (vehicule) {
      return `${vehicule.societe}`;
    }
    return '';
  };

  const handleCrewTipeChange = (event) => {
    setCrewTipe(event.target.value);
  };

  // Nouvel état pour gérer les sociétés disponibles
  const companyList = ['Tous', ...Array.from(new Set(vehicules.map((v) => v.societe)))];

  // Filtrer les équipages en fonction de la société sélectionnée
  const filteredCrews = selectedCompany === 'Tous'
    ? crews
    : crews.filter((crew) => {
      const vehicule = vehicules.find((v) => v.id === crew.vehicule_id);
      return vehicule && vehicule.societe === selectedCompany;
    });

  const groupedCrews = filteredCrews
    .filter((crew) => (selectedDate ? formatDate(crew.date) === selectedDate.format('YYYY-MM-DD') : true))
    .reduce((acc, crew) => {
      const societe = getSocieteName(crew.vehicule_id);
      const crewType = crew.crew_type;
      const key = `${societe} - ${crewType}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(crew);
      return acc;
    }, {});

  // Trier les clés des groupes par société puis par type d'équipage
  const sortedGroupKeys = Object.keys(groupedCrews).sort((a, b) => {
    const [societeA, crewTypeA] = a.split(' - ');
    const [societeB, crewTypeB] = b.split(' - ');
    if (societeA < societeB) return -1;
    if (societeA > societeB) return 1;
    if (crewTypeA < crewTypeB) return -1;
    if (crewTypeA > crewTypeB) return 1;
    return 0;
  });

  // Trier les équipages dans chaque groupe par code de véhicule croissant
  sortedGroupKeys.forEach((key) => {
    groupedCrews[key].sort((a, b) => {
      const vehiculeA = vehicules.find((v) => v.id === a.vehicule_id);
      const vehiculeB = vehicules.find((v) => v.id === b.vehicule_id);
      if (vehiculeA && vehiculeB) {
        return vehiculeA.code.localeCompare(vehiculeB.code, 'en', { numeric: true });
      }
      return 0;
    });
  });

  const handleInputChange = (field, value) => {
    setInitialCrewData((prevData) => ({
      ...prevData,
      [field]: field === 'crew_type' ? value : parseInt(value, 10),
    }));
  };

  if (dataLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Équipages du {selectedDate.locale('fr').format('dddd D MMMM YYYY')}
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
        <Grid item>
          <IconButton onClick={() => setSelectedDate(selectedDate.subtract(1, 'day'))} color="primary">
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Sélectionnez une date"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              format="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </LocalizationProvider>

        </Grid>
        <Grid item>
          <IconButton onClick={() => setSelectedDate(selectedDate.add(1, 'day'))} color="primary">
            <ArrowForward />
          </IconButton>
        </Grid>
      </Grid>

      {/* Ajout du filtre par société */}
      <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filtrer par société</InputLabel>
          <Select
            value={selectedCompany}
            label="Filtrer par société"
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            {companyList.map((company) => (
              <MenuItem key={company} value={company}>
                {company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
        <Button variant="contained" color="primary" onClick={openAddModal}>
          Ajouter un équipage
        </Button>
      </Box>
      <Grid container spacing={2}>
        {sortedGroupKeys.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Aucun équipage trouvé pour la société sélectionnée et la date choisie.
            </Typography>
          </Grid>
        ) : (
          sortedGroupKeys.map((key) => (
            <Grid item xs={12} key={key}>
              <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                {key}
              </Typography>
              <Grid container spacing={2}>
                {groupedCrews[key].map((crew) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={crew.id}>
                    <Card
                      onClick={() => openUpdateModal(crew)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          boxShadow: 6,
                        },
                        borderRadius: 2,
                        minHeight: 150,
                      }}
                    >
                      <CardContent sx={{ padding: 2 }}>
                        <Chip
                          label={crew.crew_type}
                          color="primary"
                          sx={{ marginBottom: 1, fontWeight: 'bold' }}
                        />
                        <Typography variant="h6" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                          {getVehiculeCode(crew.vehicule_id)}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                          {getEmployeeName(crew.staff_id_1)}
                        </Typography>
                        {crew.staff_id_2 && (
                          <Typography variant="body2">{getEmployeeName(crew.staff_id_2)}</Typography>
                        )}
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end', padding: 1 }}>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCrew(crew.id);
                          }}
                        >
                          Supprimer
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      {/* Modale d'ajout */}
      <Dialog
        open={isAddModalOpen}
        onClose={() => confirmCloseModal(setIsAddModalOpen)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Ajouter un équipage</DialogTitle>
        <form onSubmit={handleCreateCrew}>
          <DialogContent dividers>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField {...params} fullWidth required margin="normal" />
                )}
              />
            </LocalizationProvider>

            <FormControl fullWidth required margin="normal">
              <InputLabel>Type d'équipage</InputLabel>
              <Select label="Type d'équipage" name="crew_type" value={crewTipe} onChange={handleCrewTipeChange}>
                {crewTypes.map((crewType) => (
                  <MenuItem key={crewType} value={crewType}>
                    {crewType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Véhicule</InputLabel>
              <Select label="Véhicule" name="vehicule_id">
                {vehicules.map((vehicule) => (
                  <MenuItem key={vehicule.id} value={vehicule.id}>
                    {vehicule.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Chauffeur 1</InputLabel>
              <Select label="Chauffeur 1" name="staff_id_1">
                {staffWorkingOnSelectedDate.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showChauffeur2Input && (
              <FormControl fullWidth required margin="normal">
                <InputLabel>Chauffeur 2</InputLabel>
                <Select label="Chauffeur 2" name="staff_id_2">
                  {staffWorkingOnSelectedDate.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name} {employee.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => confirmCloseModal(setIsAddModalOpen)} color="secondary">
              Annuler
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Créer
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Modale de mise à jour */}
      <Dialog
        open={isUpdateModalOpen}
        onClose={() => confirmCloseModal(setIsUpdateModalOpen)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Mettre à jour l'équipage</DialogTitle>
        <form onSubmit={handleUpdateCrew}>
          <DialogContent dividers>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField {...params} fullWidth required margin="normal" />
                )}
              />
            </LocalizationProvider>

            <FormControl fullWidth required margin="normal">
              <InputLabel>Véhicule</InputLabel>
              <Select
                name="vehicule_id"
                label="Véhicule"
                value={initialCrewData?.vehicule_id || ''}
                onChange={(e) => handleInputChange('vehicule_id', e.target.value)}
              >
                {vehicules.map((vehicule) => (
                  <MenuItem key={vehicule.id} value={vehicule.id}>
                    {vehicule.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Type d'équipage</InputLabel>
              <Select
                name="crew_type"
                label="Type d'équipage"
                value={initialCrewData?.crew_type || ''}
                onChange={(e) => handleInputChange('crew_type', e.target.value)}
              >
                {crewTypes.map((crewType) => (
                  <MenuItem key={crewType} value={crewType}>
                    {crewType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth required margin="normal">
              <InputLabel>Chauffeur 1</InputLabel>
              <Select
                name="staff_id_1"
                label="Chauffeur 1"
                value={initialCrewData?.staff_id_1 || ''}
                onChange={(e) => handleInputChange('staff_id_1', e.target.value)}
              >
                {updatedStaffList.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentCrew?.crew_type !== 'VSL' && currentCrew?.crew_type !== 'TAXI' && (
              <FormControl fullWidth required margin="normal">
                <InputLabel>Chauffeur 2</InputLabel>
                <Select
                  name="staff_id_2"
                  label="Chauffeur 2"
                  value={initialCrewData?.staff_id_2 || ''}
                  onChange={(e) => handleInputChange('staff_id_2', e.target.value)}
                >
                  {updatedStaffList.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name} {employee.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => confirmCloseModal(setIsUpdateModalOpen)} color="secondary">
              Annuler
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Mettre à jour
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Snackbar pour les notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          action={(
            <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default React.memo(Crews);
