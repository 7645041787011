/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Box
} from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../../../utils/api';

function UpdateTarif({
  onUpdateTarif, currentTarif, typesDeTarif, userToken
}) {
  const [tarifData, setTarifData] = useState({
    typeDeTarifId: '',
    nom: '',
    reductionDeuxPersonnes: 0,
    reductionTroisPersonnes: 0,
    categorie: '',
    tva: 0,
    base: 0,
    baseKm: 0,
    basekma: 0,
    basekmb: 0,
    basekmc: 0,
    basekmd: 0,
    majorations: [],
    tarifsCourtTrajet: [],
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    // Vérifiez que currentTarif est défini avant d'essayer de l'utiliser
    if (currentTarif) {
      setTarifData({
        id: currentTarif.id,
        typeDeTarifId: currentTarif.typedetarifid,
        nom: currentTarif.nom,
        reductionDeuxPersonnes: parseFloat(currentTarif.reductiondeuxpersonnes),
        reductionTroisPersonnes: parseFloat(currentTarif.reductiontroispersonnes),
        categorie: currentTarif.categorie,
        tva: parseFloat(currentTarif.tva),
        base: currentTarif.base,
        baseKm: currentTarif.basekm,
        basekma: currentTarif.basekma,
        basekmb: currentTarif.basekmb,
        basekmc: currentTarif.basekmc,
        basekmd: currentTarif.basekmd,
        majorations: currentTarif.majorations || [],
        tarifsCourtTrajet: currentTarif.tarifscourttrajet || [],
      });
    }
  }, [currentTarif]);

  const handleBaseChange = (e) => {
    const { name, value } = e.target;
    setTarifData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleJSONChange = (section, index, e) => {
    const updatedItems = [...tarifData[section]]; // Copie de la section pour éviter les mutations directes
    updatedItems[index] = { ...updatedItems[index], [e.target.name]: e.target.value }; // Mise à jour de l'item spécifique
    setTarifData({ ...tarifData, [section]: updatedItems }); // Mise à jour de l'état avec la nouvelle liste d'items
  };

  const addJSONItem = (section) => {
    const newItem = section === 'majorations' ? { nom: '', type: '', valeur: '' } : { km: '', tarif: '' };
    setTarifData({ ...tarifData, [section]: [...tarifData[section], newItem] });
  };

  const removeJSONItem = (section, index) => {
    const updatedItems = [...tarifData[section]];
    updatedItems.splice(index, 1);
    setTarifData({ ...tarifData, [section]: updatedItems });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Structure correcte de l'objet data pour l'envoi
    const data = {
      id: tarifData.id,
      typeDeTarifId: parseInt(tarifData.typeDeTarifId, 10),
      nom: tarifData.nom,
      reductionDeuxPersonnes: parseFloat(tarifData.reductionDeuxPersonnes),
      reductionTroisPersonnes: parseFloat(tarifData.reductionTroisPersonnes),
      categorie: tarifData.categorie,
      tva: parseFloat(tarifData.tva),
      base: tarifData.base,
      baseKm: tarifData.baseKm,
      basekma: tarifData.basekma,
      basekmb: tarifData.basekmb,
      basekmc: tarifData.basekmc,
      basekmd: tarifData.basekmd,
      majorations: tarifData.majorations || [],
      tarifsCourtTrajet: tarifData.tarifsCourtTrajet || [],
    };

    try {
      await axios.put(`${BASE_URL}tarifs/${tarifData.id}`, data, { headers });
      onUpdateTarif(data); // Assurez-vous que cette fonction fait ce qu'elle est censée faire
    }
    catch (error) {
      console.error('Erreur lors de la mise à jour du tarif:', error);
    }
  };

  const isTaxi = currentTarif.typeDeTarifId === 4;

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ margin: '10px auto', textAlign: 'center' }} gutterBottom>Mettre à jour le tarif</Typography>
      <Box sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, '& .MuiButton-root': { m: 1 } }}>
        <TextField
          label="Nom du tarif"
          id="nom"
          name="nom"
          value={tarifData.nom}
          onChange={handleBaseChange}
          fullWidth
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Type de tarif</InputLabel>
          <Select
            label="Type de tarif"
            name="typeDeTarifId"
            value={tarifData.typeDeTarifId}
            onChange={handleBaseChange}
            required
          >
            <MenuItem value="">
              <em>Sélectionner un type de tarif</em>
            </MenuItem>
            {typesDeTarif.map((type) => (
              <MenuItem key={type.id} value={type.id}>{type.nom}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Prise en charge (€)"
          type="number"
          id="base"
          name="base"
          value={tarifData.base}
          onChange={handleBaseChange}
          margin="normal"
        />

        {!isTaxi && (
        <TextField
          label="Tarif kilométrique (€)"
          type="number"
          id="baseKm"
          name="baseKm"
          value={tarifData.baseKm}
          onChange={handleBaseChange}
          margin="normal"
        />
        )}

        {isTaxi && (
        <>
          <TextField
            label="Tarif kilométrique A (€)"
            type="number"
            id="basekma"
            name="basekma"
            value={tarifData.basekma}
            onChange={handleBaseChange}
            margin="normal"
          />
          <TextField
            label="Tarif kilométrique B (€)"
            type="number"
            id="basekmb"
            name="basekmb"
            value={tarifData.basekmb}
            onChange={handleBaseChange}
            margin="normal"
          />
          <TextField
            label="Tarif kilométrique C (€)"
            type="number"
            id="basekmc"
            name="basekmc"
            value={tarifData.basekmc}
            onChange={handleBaseChange}
            margin="normal"
          />
          <TextField
            label="Tarif kilométrique D (€)"
            type="number"
            id="basekmd"
            name="basekmd"
            value={tarifData.basekmd}
            onChange={handleBaseChange}
            margin="normal"
          />
        </>
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel>Catégorie</InputLabel>
          <Select
            label="Catégorie"
            id="categorie"
            name="categorie"
            value={tarifData.categorie}
            onChange={handleBaseChange}
          >
            <MenuItem value=""><em>Sélectionner une catégorie</em></MenuItem>
            <MenuItem value="Agglomération">Forfait agglomération</MenuItem>
            <MenuItem value="Départemental">Forfait départemental</MenuItem>
            <MenuItem value="PEC">Forfait prise en charge</MenuItem>
            <MenuItem value="UPH">Forfait UPH</MenuItem>
            <MenuItem value="APHP150">Forfait APHP 150%</MenuItem>
            <MenuItem value="APHP100">Forfait APHP 100%</MenuItem>
            <MenuItem value="APHP5">Forfait APHP 5%</MenuItem>
            <MenuItem value="PARAHC">Forfait PARA hors contrat</MenuItem>
            <MenuItem value="PARAC">Forfait PARA contrat</MenuItem>
            <MenuItem value="VSL">Vsl</MenuItem>
            <MenuItem value="TAXI">Taxi</MenuItem>
            <MenuItem value="TAXI-AM">Taxi - AM</MenuItem>
            <MenuItem value="TAXI-AMREM">Taxi- AM REMISE</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Réduction pour deux personnes (%)"
          type="number"
          id="reductionDeuxPersonnes"
          name="reductionDeuxPersonnes"
          value={tarifData.reductionDeuxPersonnes}
          onChange={handleBaseChange}
          margin="normal"
        />

        <TextField
          label="Réduction pour trois personnes (%)"
          type="number"
          id="reductionTroisPersonnes"
          name="reductionTroisPersonnes"
          value={tarifData.reductionTroisPersonnes}
          onChange={handleBaseChange}
          margin="normal"
        />

        <TextField
          label="TVA (10%)"
          type="number"
          id="tva"
          name="tva"
          value={tarifData.tva}
          onChange={handleBaseChange}
          margin="normal"
        />

        <Typography variant="subtitle1" gutterBottom>Majorations</Typography>
        {(tarifData.majorations?.length > 0 ? tarifData.majorations : [{}]).map((maj, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2
            }}
          >
            <TextField
              label="Nom"
              name="nom"
              value={maj.nom || ''}
              onChange={(e) => handleJSONChange('majorations', index, e)}
              placeholder="Nom de la majoration"
              variant="outlined"
            />
            <TextField
              label="Valeur"
              type="number"
              name="valeur"
              value={maj.valeur || ''}
              onChange={(e) => handleJSONChange('majorations', index, e)}
              placeholder="Valeur"
              variant="outlined"
            />
            <FormControl variant="outlined" sx={{ width: 150 }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={maj.type || ''}
                onChange={(e) => handleJSONChange('majorations', index, e)}
                label="Type"
              >
                <MenuItem value="%">%</MenuItem>
                <MenuItem value="€">€</MenuItem>
              </Select>
            </FormControl>
            <Button variant="outlined" sx={{ width: 250 }} color="error" onClick={() => removeJSONItem('majorations', index)}>Supprimer</Button>
          </Box>
        ))}
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '5%' }} onClick={() => addJSONItem('majorations')}>+</Button>

        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 20 }}>Conditions kilométriques</Typography>
        {(tarifData.tarifsCourtTrajet?.length > 0 ? tarifData.tarifsCourtTrajet : [{}]).map((conditionTarif, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2
            }}
          >
            <TextField
              label="Condition (km)"
              name="condition"
              value={conditionTarif.condition || ''}
              onChange={(e) => handleJSONChange('tarifsCourtTrajet', index, e)}
              placeholder="Condition"
              variant="outlined"
            />
            <TextField
              label="Tarif (€)"
              type="number"
              name="tarif"
              value={conditionTarif.tarif || ''}
              onChange={(e) => handleJSONChange('tarifsCourtTrajet', index, e)}
              placeholder="Tarif"
              variant="outlined"
            />
            <Button variant="outlined" sx={{ width: 250 }} color="error" onClick={() => removeJSONItem('tarifsCourtTrajet', index)}>Supprimer</Button>
          </Box>
        ))}
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '5%' }} onClick={() => addJSONItem('tarifsCourtTrajet', { condition: '', tarif: 0 })}>+</Button>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button type="submit" variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '40%' }}>Mettre à jour le tarif complet</Button>
        </Box>
      </Box>
    </form>
  );
}

export default React.memo(UpdateTarif);
