/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import {
  Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Box
} from '@mui/material';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../../utils/api';

function CreateTarifForm({ typesDeTarif, onAddTarif, userToken }) {
  const [tarif, setTarif] = useState({
    typeDeTarifId: '',
    nom: '',
    reductionDeuxPersonnes: 0,
    reductionTroisPersonnes: 0,
    categorie: '',
    tva: 0,
    base: 0,
    basekma: 0,
    basekmb: 0,
    basekmc: 0,
    basekmd: 0,
    baseKm: 0,
    majorations: [],
    tarifsCourtTrajet: [],
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTarif((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleJSONChange = (section, index, e) => {
    const updatedItems = [...tarif[section] || []]; // S'assurer que la section n'est jamais null
    updatedItems[index] = { ...updatedItems[index], [e.target.name]: e.target.value };
    setTarif({ ...tarif, [section]: updatedItems });
  };

  const addJSONItem = (section) => {
    const newItem = section === 'majorations' ? { nom: '', type: '', valeur: '' } : { km: '', tarif: '' };
    setTarif({ ...tarif, [section]: [...tarif[section] || [], newItem] }); // Utiliser un tableau vide si la section est null
  };

  const removeJSONItem = (section, index) => {
    const updatedItems = [...tarif[section] || []]; // S'assurer que la section n'est jamais null
    updatedItems.splice(index, 1);
    setTarif({ ...tarif, [section]: updatedItems });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assurez-vous que majorations et tarifsCourtTrajet ne soient jamais soumis comme null
      const submissionData = {
        ...tarif,
        majorations: tarif.majorations || [],
        tarifsCourtTrajet: tarif.tarifsCourtTrajet || [],
      };
      const response = await axios.post(`${BASE_URL}tarifs`, submissionData, { headers });
      onAddTarif(response.data);
    }
    catch (error) {
      console.error('Erreur lors de la création du tarif:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur s\'est produite lors de la création du tarif. Veuillez réessayer.',
      });
    }
  };

  const isTaxi = tarif.typeDeTarifId === 4;

  return (
    (
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom sx={{ margin: '10px auto', textAlign: 'center' }}>Créer un nouveau tarif</Typography>
        <Box sx={{ '& .MuiTextField-root': { margin: 1, width: '100%' }, '& .MuiButton-root': { margin: 1 } }}>
          <TextField
            label="Nom du tarif"
            variant="outlined"
            id="nom"
            name="nom"
            value={tarif.nom}
            onChange={handleChange}
          />

          <FormControl fullWidth margin="auto">
            <InputLabel id="type-de-tarif-label">Type de tarif</InputLabel>
            <Select
              labelId="type-de-tarif-label"
              id="typeDeTarifId"
              label="Type de tarif"
              name="typeDeTarifId"
              value={tarif.typeDeTarifId}
              onChange={handleChange}
              required
            >
              <MenuItem value="">
                <em>Sélectionner un type de tarif</em>
              </MenuItem>
              {typesDeTarif.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.nom}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Prise en charge (€)"
            type="number"
            id="base"
            name="base"
            value={tarif.base}
            onChange={handleChange}
          />

          {!isTaxi && (
          <TextField
            label="Tarif kilométrique (€)"
            type="number"
            id="baseKm"
            name="baseKm"
            value={tarif.baseKm}
            onChange={handleChange}
          />
          )}

          {isTaxi && (
          <>
            <TextField
              label="Tarif kilométrique A (€)"
              type="number"
              name="basekma"
              value={tarif.basekma}
              onChange={handleChange}
            />
            <TextField
              label="Tarif kilométrique B (€)"
              type="number"
              name="basekmb"
              value={tarif.basekmb}
              onChange={handleChange}
            />
            <TextField
              label="Tarif kilométrique C (€)"
              type="number"
              name="basekmc"
              value={tarif.basekmc}
              onChange={handleChange}
            />
            <TextField
              label="Tarif kilométrique D (€)"
              type="number"
              name="basekmd"
              value={tarif.basekmd}
              onChange={handleChange}
            />
          </>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel id="categorie-label">Catégorie</InputLabel>
            <Select
              labelId="categorie-label"
              label="Catégorie"
              id="categorie"
              name="categorie"
              value={tarif.categorie}
              onChange={handleChange}
            >
              <MenuItem value="Agglomération">Forfait agglomération</MenuItem>
              <MenuItem value="Départemental">Forfait départemental</MenuItem>
              <MenuItem value="PEC">Forfait prise en charge</MenuItem>
              <MenuItem value="UPH">Forfait UPH</MenuItem>
              <MenuItem value="APHP150">Forfait APHP 150%</MenuItem>
              <MenuItem value="APHP100">Forfait APHP 100%</MenuItem>
              <MenuItem value="APHP5">Forfait APHP 5%</MenuItem>
              <MenuItem value="PARAHC">Forfait PARA hors contrat</MenuItem>
              <MenuItem value="PARAC">Forfait PARA contrat</MenuItem>
              <MenuItem value="VSL">Vsl</MenuItem>
              <MenuItem value="TAXI">Taxi</MenuItem>
              <MenuItem value="TAXI-AM">Taxi - AM</MenuItem>
              <MenuItem value="TAXI-AMREM">Taxi- AM REMISE</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Réduction pour deux personnes (%)"
            type="number"
            id="reductionDeuxPersonnes"
            name="reductionDeuxPersonnes"
            value={tarif.reductionDeuxPersonnes}
            onChange={handleChange}
          />

          <TextField
            label="Réduction pour trois personnes (%)"
            type="number"
            id="reductionTroisPersonnes"
            name="reductionTroisPersonnes"
            value={tarif.reductionTroisPersonnes}
            onChange={handleChange}
          />

          <TextField
            label="TVA (%)"
            type="number"
            id="tva"
            name="tva"
            value={tarif.tva}
            onChange={handleChange}
          />
        </Box>

        <Typography variant="h6" gutterBottom>Majorations</Typography>
        {tarif.majorations.map((maj, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2
            }}
          >
            <TextField
              label="Nom"
              variant="outlined"
              name="nom"
              value={maj.nom}
              placeholder="Nom de la majoration"
              onChange={(e) => handleJSONChange('majorations', index, e)}
            />
            <TextField
              label="Valeur"
              type="number"
              name="valeur"
              value={maj.valeur}
              onChange={(e) => handleJSONChange('majorations', index, e)}
              placeholder="Valeur"
              variant="outlined"
            />
            <FormControl variant="outlined" sx={{ width: 150 }}>

              <InputLabel>Type</InputLabel>

              <Select
                labelId={`majoration-type-label-${index}`}
                id={`majoration-type-${index}`}
                name="type"
                label="Type"
                value={maj.type}
                onChange={(e) => handleJSONChange('majorations', index, e)}
              >
                <MenuItem value=""><em>Selectionner une valeur</em></MenuItem>
                <MenuItem value="%">%</MenuItem>
                <MenuItem value="€">€</MenuItem>
              </Select>
            </FormControl>
            <Button variant="outlined" sx={{ width: 250 }} color="error" onClick={() => removeJSONItem('majorations', index)}>Supprimer</Button>
          </Box>
        ))}
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '5%' }} onClick={() => addJSONItem('majorations')}>+</Button>

        <Typography variant="h6" gutterBottom>Conditions kilométriques</Typography>
        {tarif.tarifsCourtTrajet.map((conditionTarif, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2
            }}
          >
            <TextField
              label="Condition (km)"
              variant="outlined"
              name="condition"
              value={conditionTarif.condition}
              onChange={(e) => handleJSONChange('tarifsCourtTrajet', index, e)}
            />
            <TextField
              label="Tarif (€)"
              type="number"
              name="tarif"
              value={conditionTarif.tarif}
              onChange={(e) => handleJSONChange('tarifsCourtTrajet', index, e)}
            />
            <Button variant="outlined" sx={{ width: 250 }} color="error" onClick={() => removeJSONItem('tarifsCourtTrajet', index)}>Supprimer</Button>
          </Box>
        ))}
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '5%' }} onClick={() => addJSONItem('tarifsCourtTrajet', { condition: '', tarif: 0 })}>+</Button>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button type="submit" variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '40%' }}>Soumettre le tarif complet</Button>
        </Box>
      </form>
    )
  );
}

export default React.memo(CreateTarifForm);
