/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
// Import additional libraries if needed
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import './vehiculeManagementStyles.scss';
import moment from 'moment';
import * as XLSX from 'xlsx';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Tooltip from '@mui/material/Tooltip';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddVehiculeModal from './addVehiculeModal/addVehiculeModal';
import UpdateVehiculeModal from './updateVehiculeModal/updateVehiculeModal';
import usePageTitle from '../../hooks/usePageTitle';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import { BASE_URL } from '../../../utils/api';
import PrintQRCodes from './printQRCodes';

function vehiculeManagement({
  theme, userRole, userToken, userEmail
}) {
  usePageTitle('Gestion des véhicules');
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [vehiculeList, setVehiculeList] = useState([]);
  const [showExitedVehicule, setShowExitedSVehicule] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVehicule, setCurrentVehicule] = useState(null);
  const [filterSociete, setFilterSociete] = useState('');
  const [alertShown, setAlertShown] = useState(false);

  const modalVehiculeRef = useRef();
  const UpdateVehiculeModalRef = useRef();

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const exportToExcel = () => {
    Swal.fire({
      title: 'Exportation en Excel',
      text: 'Voulez-vous exporter la liste des véhicules en Excel ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        const vehiculesForExcel = vehiculeList.map((vehicule) => ({
          Société: vehicule.societe,
          Code: vehicule.code,
          Immatriculation: vehicule.immat,
          Marque: vehicule.marque,
          Type: vehicule.type,
          Catégorie: vehicule.categorie,
          "Date d'entrée": vehicule.entry_date ? new Date(vehicule.entry_date).toLocaleDateString() : '',
          'Date de sortie': vehicule.exit_date ? new Date(vehicule.exit_date).toLocaleDateString() : '',
          'Date du contrôle technique': vehicule.technical_control_date ? new Date(vehicule.technical_control_date).toLocaleDateString() : '',
          'Date du contrat de location': vehicule.location_date ? new Date(vehicule.location_date).toLocaleDateString() : '',
          'Dimension des pneus': vehicule.pneu_dim,
          'Pneu de secours': vehicule.pneu_secour ? 'Oui' : 'Non',
          'Seconde clé': vehicule.second_key ? 'Oui' : 'Non',
          Kilométrage: vehicule.km,
          'Numéro de téléphone': vehicule.phone_number,
          Observation: vehicule.observation ? vehicule.observation : ''
        }));

        const ws = XLSX.utils.json_to_sheet(vehiculesForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Liste des Véhicules');

        // Exporter le fichier
        XLSX.writeFile(wb, 'export_vehicules.xlsx');
      }
    });
  };

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        // Fermer la modal si l'utilisateur confirme
        setModalState(false);
      }
    });
  };

  const isAuthorizedRole = () => {
    const authorizedRoles = ['Administrateur', 'Régulation', 'Direction', 'Responsable exploitation'];
    return authorizedRoles.includes(userRole);
  };

  const handleUnauthorizedAccess = () => {
    if (!isAuthorizedRole(userRole)) {
      Swal.fire({
        title: 'Accès refusé',
        text: 'Vous n\'avez pas les droits pour modifier les informations des véhicules.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleAddButtonClick = () => {
    if (isAuthorizedRole(userRole)) {
      setShowModal(true);
    }
    else {
      handleUnauthorizedAccess();
    }
  };

  const handleClick = (e) => {
    if (modalVehiculeRef.current && !modalVehiculeRef.current.contains(e.target)) {
      setShowModal(false);
    }
    if (UpdateVehiculeModalRef.current && !UpdateVehiculeModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };
  const fetchResponsables = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      return response.data.staff;
    }
    catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchAllVehicule = async (fetchAll = false) => {
    try {
      const [vehiculesResponse, responsables] = await Promise.all([
        axios.get(`${BASE_URL}vehicules`, { params: { all: fetchAll }, headers }),
        fetchResponsables()
      ]);

      const responsablesMap = new Map(responsables.map((responsable) => [responsable.id, responsable]));

      const vehiculesWithResponsables = vehiculesResponse.data.vehicule.map((vehicule) => {
        const responsable = responsablesMap.get(vehicule.responsable_id);
        return { ...vehicule, responsable, task_created: vehicule.task_created };
      });

      const sortedVehicules = vehiculesWithResponsables.sort((a, b) => a.code - b.code);
      setVehiculeList(sortedVehicules);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllVehicule(true);
    fetchCompanies();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    fetchAllVehicule(true);
    fetchCompanies();
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [currentVehicule]);

  const handleClickModal = (vehicule) => {
    if (isAuthorizedRole(userRole)) {
      setCurrentVehicule(vehicule);
      setShowUpdateModal(true);
    }
    else {
      handleUnauthorizedAccess();
    }
  };
  const handleAddVehicule = (vehicule) => {
    setVehiculeList([...vehiculeList, vehicule]);
    setShowModal(false);
    fetchAllVehicule(true);
  };

  const handleFilterSocieteChange = (societe) => {
    setFilterSociete(societe);
  };

  // Handle updating the vehicule
  const handleUpdateVehicule = async (updatedVehicule) => {
    // Show a confirmation before updating the vehicule
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier ce vehicule ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, mettre à jour ',
      cancelButtonText: 'Non',
    });
    if (confirmation.value) {
      const index = vehiculeList.findIndex((vehicule) => vehicule.id === currentVehicule.id);
      const newList = [...vehiculeList.slice(0, index), updatedVehicule, ...vehiculeList.slice(index + 1)];
      setVehiculeList(newList);
      setShowUpdateModal(false);
      Swal.fire({
        title: 'Vehicule modifié !',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      fetchAllVehicule(true);
    }
  };

  const checkIfTaskExists = async (vehiculeCode, type) => {
    try {
      const response = await axios.get(`${BASE_URL}tasks`, { headers });
      const { tasks } = response.data;
      return tasks.some((task) => task.field_name === `${type} - ${vehiculeCode}`);
    }
    catch (error) {
      console.error('Error checking tasks:', error);
      return false;
    }
  };

  const calculateEcheanceColor = (locationDate) => {
    if (!locationDate) return 'inherit'; // No color if no location date

    const echeanceDate = moment(locationDate).add(4, 'years');
    const daysUntilEcheance = echeanceDate.diff(moment(), 'days');

    if (daysUntilEcheance < 0) {
      return 'red'; // Expired
    } if (daysUntilEcheance <= 30) {
      return 'red'; // Within 30 days
    } if (daysUntilEcheance <= 90) {
      return 'orange'; // Within 90 days
    }
    return 'inherit'; // No color if more than 90 days
  };

  const createTask = async (vehicule, type, expirationDate) => {
    let fieldName;
    let comment;

    if (type === 'Échéance') {
      fieldName = `Échéance - ${vehicule.code}`;
      comment = `L'échéance du véhicule ${vehicule.code} est prévue pour le ${moment(expirationDate).format('DD-MM-YYYY')}.`;
    }
    else if (type === 'Contre visite') {
      fieldName = `Contre visite - ${vehicule.code}`;
      comment = `Contre visite à réaliser avant le ${moment(expirationDate).format('DD-MM-YYYY')}`;
    }
    else {
      fieldName = `${type} - ${vehicule.code}`;
      comment = `${type} du véhicule ${vehicule.code} expire dans moins d'un mois.`;
    }

    const taskData = {
      procedure_id: 0,
      status: 'à faire',
      author_name: userEmail,
      field_name: fieldName,
      vehicule_code: vehicule.code,
      role_name: 'Administrateur',
      comment: comment,
    };

    const taskExists = await checkIfTaskExists(vehicule.code, type);

    if (!taskExists) {
      try {
        await axios.post(`${BASE_URL}tasks`, taskData, { headers });
      }
      catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  const checkEcheance = async () => {
    let alertMessage = '';

    for (const vehicule of vehiculeList) {
      // Exclure les véhicules avec exit_date
      if (vehicule.exit_date) {
        continue;
      }

      if (vehicule.location_date) {
        const echeanceDate = moment(vehicule.location_date).add(4, 'years');
        const daysUntilEcheance = echeanceDate.diff(moment(), 'days');

        if (daysUntilEcheance < 0) {
          alertMessage += `L'échéance du véhicule ${vehicule.code} est expirée.\n`;
        }
        else if (daysUntilEcheance <= 30) {
          alertMessage += `L'échéance du véhicule ${vehicule.code} approche (dans moins de 30 jours).\n`;
          await createTask(vehicule, 'Échéance', echeanceDate.toDate());
        }
      }
    }

    if (alertMessage) {
      Swal.fire({
        title: 'Alerte Échéance',
        text: alertMessage,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  useEffect(() => {
    if (vehiculeList.length > 0 && !alertShown) {
      checkEcheance();
      setAlertShown(true);
    }
  }, [vehiculeList, alertShown]);

  useEffect(() => {
    const checkControlDates = async () => {
      const alertMessages = [];

      for (const vehicule of vehiculeList) {
        // Exclure les véhicules avec exit_date
        if (vehicule.exit_date) {
          continue;
        }

        // Vérification du contrôle technique
        if (vehicule.technical_control_date) {
          const controlDate = moment(vehicule.technical_control_date).add(1, 'years');
          const daysUntilExpiration = controlDate.diff(moment(), 'days');

          if (daysUntilExpiration < 0) {
            alertMessages.push(`Le contrôle technique du véhicule ${vehicule.code} est expiré.`);
          }
          else if (daysUntilExpiration <= 30) {
            alertMessages.push(`Le contrôle technique du véhicule ${vehicule.code} expire dans moins d'un mois.`);
            await createTask(vehicule, 'Contrôle technique');
          }
        }

        // Nouvelle logique pour la contre-visite
        if (vehicule.counter_visit_date) {
          const counterVisitExpiration = moment(vehicule.counter_visit_date).add(2, 'months');

          // Créer une tâche pour la contre-visite, quelle que soit la date d'expiration
          await createTask(vehicule, 'Contre visite', counterVisitExpiration.toDate());

          // Ajouter un message d'alerte si la date d'expiration est proche ou dépassée
          const daysUntilCounterVisitExpiration = counterVisitExpiration.diff(moment(), 'days');
          if (daysUntilCounterVisitExpiration <= 0) {
            alertMessages.push(`La contre-visite du véhicule ${vehicule.code} est expirée.`);
          }
          else if (daysUntilCounterVisitExpiration <= 30) {
            alertMessages.push(`La contre-visite du véhicule ${vehicule.code} expire dans moins d'un mois.`);
          }
        }
      }

      if (alertMessages.length > 0) {
        Swal.fire({
          title: 'Alertes contrôle technique et contre-visite',
          html: `<ul>${alertMessages.map((msg) => `<li>${msg}</li>`).join('')}</ul>`,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    };

    if (vehiculeList.length > 0 && !alertShown) {
      checkControlDates();
      setAlertShown(true);
    }
  }, [vehiculeList, alertShown]);

  const formatNumberWithUnit = (number, unit) => `${number?.toLocaleString('fr-FR')}${unit}`;

  const calculateControlColor = (technicalControlDate) => {
    const currentDate = moment();
    const expirationDate = moment(technicalControlDate).add(1, 'years');
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

    let color;
    if (daysUntilExpiration < 0) {
      color = 'red';
    }
    else if (daysUntilExpiration <= 30) {
      color = 'red';
    }
    else if (daysUntilExpiration <= 90) {
      color = 'orange';
    }
    else {
      color = 'inherit';
    }

    return color;
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  if (isLoading) {
    return <AmbysoftLoader />;
  }

  return (
    <div className="vehiculeManagement">
      <div className="regulation__header__button">
        {isAuthorizedRole(userRole) && (
        <button type="button" className="AddTransport" onClick={handleAddButtonClick}>Ajouter un vehicule</button>
        )}
      </div>
      <button className="hide-on-mobile" type="button" onClick={exportToExcel}>Exporter en Excel</button>
      <div className="print-qr-container">
        <PrintQRCodes vehiculeList={vehiculeList} />
        <QrCodeIcon onClick={() => document.querySelector('.btn-print-qr').click()} style={{ cursor: 'pointer', fontSize: 40 }} />
      </div>
      <div className="search-bar hide-on-mobile">
        <button type="button" onClick={() => setShowExitedSVehicule(!showExitedVehicule)}>
          {showExitedVehicule ? 'Cacher les vehicules sortis' : 'Afficher les vehicules sortis'}
        </button>
        <select onChange={(event) => handleFilterSocieteChange(event.target.value)}>
          <option value="">Toutes les sociétés</option>
          {companies.map((company) => (
            <option key={company.id} value={company.code}>
              {company.code}
            </option>
          ))}
        </select>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="table__header"> </th>
            <th className="table__header">Code</th>
            <th className="table__header">Immat</th>
            <th className="table__header">Modèle</th>
            <th className="table__header">Type</th>
            <th className="table__header">Kilometrage</th>
            <th className="table__header">Date Contrôle technique</th>
            <th className="table__header">Date contrat de location</th>
            <th className="table__header hide-on-mobile">Observation</th>
          </tr>
        </thead>
        <tbody className="transportBody">
          {vehiculeList.filter((vehicule) => (!filterSociete || vehicule.societe === filterSociete) && (showExitedVehicule ? vehicule.exit_date : !vehicule.exit_date)).map((vehicule) => (
            <tr
              key={vehicule.id}
              className="table-row listing"
              onClick={() => {
                if (!isMobile()) {
                  handleClickModal(vehicule);
                }
              }}
            >
              <td className="table__cell">
                {vehicule.counter_visit_date && (
                <Tooltip title={`Contre visite à réaliser avant le ${moment(vehicule.counter_visit_date).add(2, 'months').local().format('DD-MM-YYYY')}`}>
                  <ReportProblemIcon style={{
                    color: 'orange', cursor: 'pointer', marginLeft: '8px', alignSelf: 'center'
                  }}
                  />
                </Tooltip>
                )}
              </td>
              <td className="table__cell">
                {vehicule.code}
              </td>
              <td className="table__cell">{vehicule.immat}</td>
              <td className="table__cell">{vehicule.marque}</td>
              <td className="table__cell">{vehicule.type}</td>
              <td className="table__cell">{formatNumberWithUnit(vehicule.km, 'km')}</td>
              <td className="table__cell" style={{ color: calculateControlColor(vehicule.technical_control_date) }}>
                {moment(vehicule.technical_control_date).local().format('DD-MM-YYYY')}
              </td>
              <td className="table__cell" style={{ color: calculateEcheanceColor(vehicule.location_date) }}>
                {vehicule.location_date
                  ? moment(vehicule.location_date).local().format('DD-MM-YYYY')
                  : 'Pas de date'}
              </td>

              <td className="table__cell hide-on-mobile">{vehicule.observation}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <Modal
          isOpen={showModal}
          style={customStyles}
          ariaHideApp={false}
          onRequestClose={() => confirmCloseModal(setShowModal)}
          ref={(el) => {
            modalVehiculeRef.current = el && el.contentRef;
          }}
        >
          <AddVehiculeModal userToken={userToken} onAddVehicule={handleAddVehicule} />
        </Modal>
      )}

      <Modal
        isOpen={showUpdateModal}
        onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
        style={customStyles}
        ariaHideApp={false}
        ref={(el) => {
          UpdateVehiculeModalRef.current = el && el.contentRef;
        }}
      >
        <UpdateVehiculeModal
          onUpdateVehicule={handleUpdateVehicule}
          currentVehicule={currentVehicule}
          userToken={userToken}
          userEmail={userEmail}
        />
      </Modal>
    </div>
  );
}

export default React.memo(vehiculeManagement);
