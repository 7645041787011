/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, Button, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { BASE_URL } from '../../../../utils/api';
import fetchAddresses from '../../../../utils/fetchAddresses';

function AddEtablissementModal({ onAddEtablissement, userToken }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [etablissementForm, setEtablissementForm] = useState({
    code: '',
    name: '',
    address: '',
    zipcode: '',
    contact: '',
    phone: '',
    day_contract: '',
    company: '',
    attachment_fund: '',
    night_contract: '',
    day_tarif_amb: '',
    day_tarif_vsl: '',
    night_tarif_amb: '',
    night_tarif_vsl: '',
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const validateForm = () => {
    const newErrors = {};
    if (!etablissementForm.code) {
      newErrors.code = 'Code is required';
    }
    if (!etablissementForm.name) {
      newErrors.name = 'Name is required';
    }
    if (!etablissementForm.address) {
      newErrors.address = 'Address is required';
    }
    if (!etablissementForm.zipcode) {
      newErrors.zipcode = 'Zipcode is required';
    }
    if (!etablissementForm.contact) {
      newErrors.contact = 'Contact is required';
    }
    if (!etablissementForm.phone) {
      newErrors.phone = 'Phone is required';
    }
    if (!etablissementForm.company) {
      newErrors.company = 'Company is required';
    }
    if (!etablissementForm.attachment_fund) {
      newErrors.attachment_fund = 'Attachment fund is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}etablissements`, etablissementForm, { headers });
      onAddEtablissement(response.data);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleFormChange = (event) => {
    setEtablissementForm((prevEtablissementForm) => ({
      ...prevEtablissementForm,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddressChange = async (event) => {
    const query = event.target.value;
    setEtablissementForm((prevEtablissementForm) => ({
      ...prevEtablissementForm,
      address: query,
    }));

    if (query.length > 2) {
      const data = await fetchAddresses(query, { limit: 5 });
      if (data && data.features) {
        setAddressSuggestions(data.features);
      }
    }
    else {
      setAddressSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestionLabel) => {
    const selectedSuggestion = addressSuggestions.find((item) => item.properties.label === suggestionLabel);
    if (selectedSuggestion) {
      setEtablissementForm((prevEtablissementForm) => ({
        ...prevEtablissementForm,
        address: selectedSuggestion.properties.name || selectedSuggestion.properties.label,
        zipcode: `${selectedSuggestion.properties.postcode || ''} ${selectedSuggestion.properties.city || ''}`,
      }));
    }
    setAddressSuggestions([]);
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <h2 className="addModalTitle">Ajouter un nouvel établissement</h2>
      <form className="addEtablissementModal">
        <div className="firstLine">
          <TextField
            type="text"
            sx={{ width: '50%', padding: '10px' }}
            name="code"
            label="Code"
            InputLabelProps={{
              shrink: true,
            }}
            id="code"
            value={etablissementForm.code}
            onChange={handleFormChange}
          />
          {errors.code && <span className="error">{errors.code}</span>}
          <TextField
            type="text"
            sx={{ width: '50%', padding: '10px' }}
            name="name"
            label="Nom"
            InputLabelProps={{
              shrink: true,
            }}
            id="name"
            value={etablissementForm.name}
            onChange={handleFormChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
        <div className="secondLine">
          <TextField
            type="text"
            sx={{ width: '50%', padding: '10px' }}
            name="contact"
            label="Contact"
            InputLabelProps={{
              shrink: true,
            }}
            id="contact"
            value={etablissementForm.contact}
            onChange={handleFormChange}
          />
          {errors.contact && <span className="error">{errors.contact}</span>}
          <TextField
            type="text"
            sx={{ width: '50%', padding: '10px' }}
            name="phone"
            label="Téléphone"
            InputLabelProps={{
              shrink: true,
            }}
            id="phone"
            value={etablissementForm.phone}
            onChange={handleFormChange}
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>
        <div className="thirdLine">
          <TextField
            type="text"
            sx={{ width: '100%', padding: '10px' }}
            name="address"
            label="Adresse"
            InputLabelProps={{
              shrink: true,
            }}
            id="address"
            value={etablissementForm.address}
            onChange={handleAddressChange}
          />
          {addressSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {addressSuggestions.map((suggestion, index) => (
              <li
                key={index}
                className="suggestions-list-item"
                onClick={() => handleSuggestionClick(suggestion.properties.label)}
              >
                {suggestion.properties.label}
              </li>
            ))}
          </ul>
          )}

          {errors.address && <span className="error">{errors.address}</span>}
          <TextField
            type="text"
            sx={{ width: '70%', padding: '10px' }}
            name="zipcode"
            label="Code postal / Ville"
            InputLabelProps={{
              shrink: true,
            }}
            id="zipcode"
            value={etablissementForm.zipcode}
            onChange={handleFormChange}
          />
          {errors.zipcode && <span className="error">{errors.zipcode}</span>}
        </div>
        <div className="fourthLine">
          <FormControl sx={{ width: '50%', padding: '10px' }}>
            <InputLabel id="company">Société</InputLabel>
            <Select
              required
              name="company"
              id="company"
              value={etablissementForm.company}
              onChange={handleFormChange}
            >
              {errors.company && <p className="error">{errors.company}</p>}
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.code}>
                  {company.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.company && <span className="error">{errors.company}</span>}
          <TextField
            type="text"
            sx={{ width: '50%', padding: '10px' }}
            name="attachment_fund"
            label="Tarif attribué"
            InputLabelProps={{
              shrink: true,
            }}
            id="attachmentFund"
            value={etablissementForm.attachment_fund}
            onChange={handleFormChange}
          />
          {errors.attachment_fund && <span className="error">{errors.attachment_fund}</span>}
        </div>
        <div className="divButton">
          <Button sx={{ margin: 'auto', backgroundColor: '#505154', width: '40%' }} variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? 'Loading...' : 'Ajouter un établissement'}
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default React.memo(AddEtablissementModal);
