/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import usePageTitle from '../../hooks/usePageTitle';

function MarchesManager({ userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES MARCHÉS');
  const [loading, setLoading] = useState(true);
  const [marches, setMarches] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMarche, setSelectedMarche] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useState({
    societe: '',
    numero_marche: '',
    libelle: '',
    debut: '',
    fin: ''
  });

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  }

  const fetchMarches = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}marches`, { headers });
      setMarches(response.data.marches);
      setLoading(false);
    }
    catch (error) {
      console.error('Failed to fetch marches', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchMarches();
  }, []);

  const confirmCloseModal = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester',
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(false); // Ferme le dialogue seulement si l'utilisateur confirme
      }
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault(); // Empêche la fermeture immédiate du dialogue
      confirmCloseModal(); // Demande confirmation avant de fermer
    }
  };

  const handleOpen = (marche) => {
    if (marche) {
      setIsEditing(true);
      setSelectedMarche(marche);
      setFormData({ ...marche });
    }
    else {
      setIsEditing(false);
      setFormData({
        societe: '',
        numero_marche: '',
        libelle: '',
        debut: '',
        fin: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isEditing ? 'put' : 'post';
    const url = isEditing ? `${BASE_URL}marches/${selectedMarche.id}` : `${BASE_URL}marches`;

    try {
      await axios({
        method: method,
        url: url,
        data: formData,
        headers: headers // Ajoutez vos en-têtes ici
      });
      fetchMarches();
      handleClose();
    }
    catch (error) {
      console.error('Failed to submit marche', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}marches/${id}`, { headers });
      fetchMarches();
    }
    catch (error) {
      console.error('Failed to delete marche', error);
    }
  };

  if (loading) {
    return <AmbysoftLoader />;
  }

  return (
    <div>
      <IconButton color="black" onClick={() => handleOpen(null)}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{isEditing ? 'Modifier le marché' : 'Créer un nouveau marché'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="company">Société</InputLabel>
              <Select
                required
                name="company"
                label="Société"
                id="company"
                value={formData.societe}
                onChange={(event) => setFormData({ ...formData, societe: event.target.value })}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.code}>
                    {company.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="Numéro de marché"
              value={formData.numero_marche}
              onChange={(event) => setFormData({ ...formData, numero_marche: event.target.value })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Libellé"
              value={formData.libelle}
              onChange={(event) => setFormData({ ...formData, libelle: event.target.value })}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Début"
              type="date"
              value={formData.debut}
              onChange={(event) => setFormData({ ...formData, debut: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Fin"
              type="date"
              value={formData.fin}
              onChange={(event) => setFormData({ ...formData, fin: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Société</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Numéro de marché</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Libellé</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Début</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Fin</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marches.map((marche) => (
              <TableRow key={marche.id}>
                <TableCell style={{ textAlign: 'center' }}>{marche.societe}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{marche.numero_marche}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{marche.libelle}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{formatDate(marche.debut)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{formatDate(marche.fin)}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton color="black" onClick={() => handleOpen(marche)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="black" onClick={() => handleDelete(marche.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default React.memo(MarchesManager);
