/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// FacilityIncidents.jsx
import React, { useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';

function FacilityIncidents({
  establishments,
  selectedEstablishment,
  handleEstablishmentChange,
  currentIncidents,
  paginate,
  currentPage,
  totalPages,
  incidentsByEstablishment,
  establishmentIncidentData,
  incidentColors,
  pastelColors,
  handleChartClick,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const barChartRef = useRef(null);

  // Préparer les données pour le graphique en barres horizontal
  const incidentsPerEstablishment = Object.entries(incidentsByEstablishment)
    .sort((a, b) => b[1] - a[1]); // Tri décroissant

  const barChartDataEstablishments = {
    labels: incidentsPerEstablishment.map(([establishmentName]) => establishmentName),
    datasets: [
      {
        label: "Nombre d'incidents par établissement",
        data: incidentsPerEstablishment.map(([, count]) => count),
        backgroundColor: pastelColors.slice(0, incidentsPerEstablishment.length),
      },
    ],
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Sélecteur d'établissement */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="establishment-select-label">Sélectionner un établissement</InputLabel>
        <Select
          labelId="establishment-select-label"
          value={selectedEstablishment}
          label="Sélectionner un établissement"
          onChange={handleEstablishmentChange}
        >
          <MenuItem value="">
            <em>Tous les établissements</em>
          </MenuItem>
          {establishments.map((est) => (
            <MenuItem key={est.id} value={est.name}>
              {est.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Tableau des incidents */}
      <TableContainer component={Paper}>
        <Table aria-label="Incidents des établissements">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Nom du patient</TableCell>
              <TableCell>Heure de départ</TableCell>
              <TableCell>Établissement</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Raison mission</TableCell>
              <TableCell>Type d'incident</TableCell>
              <TableCell>Description de l'incident</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentIncidents.map((incident) => (
              <TableRow key={incident.id}>
                <TableCell>{new Date(incident.date).toLocaleDateString()}</TableCell>
                <TableCell>{incident.last_name}</TableCell>
                <TableCell>{incident.departure_hour}</TableCell>
                <TableCell>{incident.departure_label}</TableCell>
                <TableCell>{incident.service}</TableCell>
                <TableCell>{incident.reason}</TableCell>
                <TableCell>{incident.incident_type}</TableCell>
                <TableCell>{incident.incident}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => paginate(page)}
          color="primary"
        />
      </Box>

      {selectedEstablishment && establishmentIncidentData[selectedEstablishment] ? (
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Répartition des incidents pour {selectedEstablishment}
              </Typography>
              {/* Ajustez la hauteur du conteneur si nécessaire */}
              <Box sx={{ height: isSmDown ? 250 : 400 }}>
                <Doughnut
                  data={{
                    labels: Object.keys(establishmentIncidentData[selectedEstablishment]),
                    datasets: [{
                      data: Object.values(establishmentIncidentData[selectedEstablishment]),
                      backgroundColor: Object.keys(establishmentIncidentData[selectedEstablishment]).map(
                        (type) => incidentColors[type] || '#000000'
                      )
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false, // Ajoutez cette ligne
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          color: muiTheme.palette.text.primary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box sx={{ marginTop: 4 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Nombre d'incidents par établissement
              </Typography>
              <Box sx={{ height: isSmDown ? 500 : 600 }}>
                <Bar
                  ref={barChartRef}
                  data={barChartDataEstablishments}
                  options={{
                    indexAxis: 'y', // Barres horizontales
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        beginAtZero: true,
                      },
                      y: {
                        ticks: {
                          color: muiTheme.palette.text.primary,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    onClick: (event) => handleChartClick(event, barChartRef.current),
                  }}
                />

              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(FacilityIncidents);
