/* eslint-disable import/prefer-default-export */
// src/permissions.js
export const permissions = {
  Administrateur: [
    '/', '/dashboard', '/regulation', '/facturation', '/activityManagement', '/about',
    '/activityManagement/staffManagement', '/managementData',
    '/activityManagement/vehiculeManagement', '/activityManagement/planningManagement',
    '/activityManagement/etablissementsManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/seriesManagement', '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement',
    '/activityManagement/stockManagement/historique', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/porte-document', '/facturationManagement/tarifsManagement',
    '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement',
    '/facturationManagement/risquesExonerationManagement', '/facturationManagement/marchesManagement',
    '/facturationManagement/mutuellesManagement', '/facturationManagement/assuresManagement',
    '/facturationManagement/facturesManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/admin', '/activityManagement/locauxManagement', '/documentation', '/activityManagement/calendar', '/activityManagement/processManagement/historique'
  ],
  Direction: [
    '/', '/dashboard', '/regulation', '/facturation', '/activityManagement',
    '/activityManagement/staffManagement', '/managementData',
    '/activityManagement/vehiculeManagement', '/activityManagement/planningManagement',
    '/activityManagement/etablissementsManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/seriesManagement', '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement',
    '/activityManagement/stockManagement/historique', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/porte-document', '/facturationManagement/tarifsManagement',
    '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement',
    '/facturationManagement/risquesExonerationManagement', '/facturationManagement/marchesManagement',
    '/facturationManagement/mutuellesManagement', '/facturationManagement/assuresManagement',
    '/facturationManagement/facturesManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/activityManagement/locauxManagement', '/documentation', '/activityManagement/calendar', '/activityManagement/processManagement/historique'
  ],
  'Directeur financier': [
    '/', '/dashboard', '/facturation', '/facturationManagement/tarifsManagement',
    '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement',
    '/facturationManagement/risquesExonerationManagement', '/facturationManagement/marchesManagement', '/activityManagement/medicalStockManagement',
    '/facturationManagement/mutuellesManagement', '/facturationManagement/assuresManagement',
    '/facturationManagement/facturesManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/managementData', '/documentation', '/activityManagement/calendar'
  ],
  'Responsable exploitation': [
    '/', '/dashboard', '/regulation', '/facturation', '/activityManagement',
    '/activityManagement/staffManagement', '/managementData',
    '/activityManagement/vehiculeManagement', '/activityManagement/planningManagement',
    '/activityManagement/etablissementsManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/seriesManagement', '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement',
    '/activityManagement/stockManagement/historique', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/porte-document', '/facturationManagement/tarifsManagement',
    '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement',
    '/facturationManagement/risquesExonerationManagement', '/facturationManagement/marchesManagement',
    '/facturationManagement/mutuellesManagement', '/facturationManagement/assuresManagement',
    '/facturationManagement/facturesManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/activityManagement/locauxManagement', '/documentation', '/activityManagement/calendar', '/activityManagement/processManagement/historique'
  ],
  Régulation: [
    '/', '/dashboard', '/regulation', '/facturation', '/porte-document',
    '/managementData', '/activityManagement', '/activityManagement/planningManagement',
    '/activityManagement/vehiculeManagement', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement', '/contact', '/about', '/privacy',
    '/activityManagement/staffManagement', '/activityManagement/etablissementsManagement',
    '/activityManagement/seriesManagement', '/documentation', '/terms', '/legal-mentions', '/activityManagement/calendar'
  ],
  Facturation: [
    '/', '/dashboard', '/facturation', '/facturationManagement/facturesManagement', '/facturationManagement/assuresManagement', '/porte-document', '/facturationManagement/mutuellesManagement', '/facturationManagement/marchesManagement', '/facturationManagement/risquesExonerationManagement', '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement', '/facturationManagement/tarifsManagement', '/activityManagement',
    '/activityManagement/staffManagement', '/activityManagement/vehiculeManagement',
    '/activityManagement/companiesManagement', '/contact', '/about', '/privacy', '/terms', '/legal-mentions',
    '/activityManagement/seriesManagement', '/activityManagement/crews', '/documentation', '/activityManagement/calendar'
  ],
  'Ressources humaines': [
    '/', '/dashboard', '/porte-document', '/managementData', '/activityManagement',
    '/activityManagement/planningManagement', '/activityManagement/staffManagement',
    '/activityManagement/companiesManagement', '/activityManagement/timeClock', '/activityManagement/taskManagement',
    '/activityManagement/vehiculeManagement', '/contact', '/about', '/privacy', '/terms', '/legal-mentions', '/documentation', '/activityManagement/calendar'
  ],
  Comptabilité: [
    '/', '/dashboard', '/facturation', '/porte-document', '/activityManagement',
    '/activityManagement/staffManagement', '/activityManagement/stockManagement',
    '/managementData', '/activityManagement/vehiculeManagement',
    '/activityManagement/companiesManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/activityManagement/medicalStockManagement', '/documentation', '/activityManagement/calendar'
  ],
  Personnel: [
    '/', '/porte-document',
    '/contact', '/about', '/privacy', '/activityManagement/processManagement/historique'
  ],
  Garagiste: [
    '/', '/activityManagement', '/activityManagement/vehiculeManagement', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/documentation'
  ],
  Norme: [
    '/', '/managementData', '/activityManagement', '/activityManagement/planningManagement',
    '/activityManagement/staffManagement', '/activityManagement/companiesManagement',
    '/activityManagement/vehiculeManagement', '/activityManagement/crews',
    '/activityManagement/stockManagement', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/activityManagement/etablissementsManagement',
    '/activityManagement/seriesManagement', '/porte-document', '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/activityManagement/medicalStockManagement', '/documentation', '/activityManagement/calendar'
  ],
  'Appel offre': [
    '/', '/activityManagement', '/activityManagement/staffManagement',
    '/activityManagement/vehiculeManagement', '/activityManagement/planningManagement',
    '/activityManagement/etablissementsManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/seriesManagement', '/activityManagement/stockManagement',
    '/activityManagement/processManagement', '/activityManagement/taskManagement',
    '/porte-document', '/contact', '/about', '/privacy', '/activityManagement/medicalStockManagement', '/terms', '/legal-mentions', '/activityManagement/stockManagement', '/documentation', '/activityManagement/calendar'
  ],
  'Responsable opérationnel': [
    '/', '/dashboard', '/regulation', '/facturation', '/activityManagement',
    '/activityManagement/staffManagement', '/managementData',
    '/activityManagement/vehiculeManagement', '/activityManagement/planningManagement',
    '/activityManagement/etablissementsManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/seriesManagement', '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement',
    '/activityManagement/stockManagement/historique', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/porte-document', '/facturationManagement/tarifsManagement',
    '/facturationManagement/caissesManagement', '/facturationManagement/prescripteursManagement',
    '/facturationManagement/risquesExonerationManagement', '/facturationManagement/marchesManagement',
    '/facturationManagement/mutuellesManagement', '/facturationManagement/assuresManagement', '/facturationManagement/facturesManagement',
    '/contact', '/about', '/terms', '/legal-mentions', '/privacy', '/activityManagement/locauxManagement', '/documentation', '/activityManagement/calendar', '/activityManagement/processManagement/historique'
  ],
  'Gestionnaire de stock': [
    '/', '/dashboard', '/regulation', '/facturation', '/porte-document',
    '/managementData', '/activityManagement', '/activityManagement/planningManagement',
    '/activityManagement/vehiculeManagement', '/activityManagement/processManagement',
    '/activityManagement/taskManagement', '/activityManagement/timeClock',
    '/activityManagement/crews', '/activityManagement/companiesManagement',
    '/activityManagement/stockManagement', '/activityManagement/medicalStockManagement', '/contact', '/about', '/privacy',
    '/activityManagement/staffManagement', '/activityManagement/etablissementsManagement',
    '/activityManagement/seriesManagement', '/documentation', '/terms', '/legal-mentions', '/activityManagement/calendar'
  ],
};
