/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import {
  TextField, InputAdornment, Select, MenuItem, Button
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import './overViewStyles.scss';
import { BASE_URL } from '../../../utils/api';

function overView({
  selectedSociety,
  setSelectedSociety,
  userToken,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) {
  const [transports, setTransports] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalSalaryCost, setTotalSalaryCost] = useState(0);
  const [fetchedStaff, setFetchedStaff] = useState([]);
  const [caMode, setCaMode] = useState('Manuel'); // Pour gérer le mode CA (Automatique/Manuel)

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const fetchTransports = async () => {
    try {
      const res = await axios.get(`${BASE_URL}transports`, { headers });
      setTransports(res.data.transports);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${BASE_URL}companies`, { headers });
      setCompanies(res.data.companies);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  };

  const cleanAndParseValue = (value) => parseFloat(value.toString().replace(/\s/g, '').replace(',', '.')) || 0;

  const fetchStaffSalaryCost = async () => {
    try {
      const response = await axios.get(`${BASE_URL}staff`, { headers });
      const { staff } = response.data;

      const totalSalary = staff.reduce((acc, staffMember) => {
        if (staffMember.factures && Array.isArray(staffMember.factures)) {
          staffMember.factures.forEach((facture) => {
            const factureDate = new Date(facture.date);

            // Filtrage par société et par date
            const companyMatch = !selectedSociety || staffMember.company === selectedSociety;
            const dateMatch = factureDate >= startDate && factureDate <= endDate;

            if (companyMatch && dateMatch) {
              let totalFactureCost = 0;

              if (facture.type === 'Salaire') {
                const salaryFields = [
                  'salaire_brut',
                  'cotisations_patronales',
                  'indemnites_repas',
                  'indemnites_repas_2',
                  'prime_salissure',
                  'transport',
                  'taxe_salaire',
                ];

                totalFactureCost = salaryFields.reduce((sum, field) => {
                  const cleanedValue = cleanAndParseValue(
                    facture.details?.[field] || 0
                  );
                  return sum + cleanedValue;
                }, 0);
              }
              else {
                totalFactureCost = cleanAndParseValue(
                  facture.details?.cout || 0
                );
              }

              acc += totalFactureCost;
            }
          });
        }
        return acc;
      }, 0);

      setTotalSalaryCost(totalSalary);
      setFetchedStaff(staff);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  };

  useEffect(() => {
    fetchTransports();
    fetchCompanies();
    fetchStaffSalaryCost();
  }, [startDate, endDate, selectedSociety]);

  // Filtrer les factures pour CA manuel
  const filteredCaFactures = companies.reduce((acc, company) => {
    company.factures?.forEach((facture) => {
      const factureDate = new Date(facture.date);
      const dateMatch = factureDate >= startDate && factureDate <= endDate;

      if (
        facture.type === "Chiffre d'affaires"
        && dateMatch
        && (selectedSociety === '' || company.code === selectedSociety)
      ) {
        const caFields = [
          'Ambulance',
          'Paramedical',
          'Samu tableau A',
          'Samu tableau B',
          'Vsl',
          'Taxi',
        ];

        // Calcul du CA pour les champs standards
        const totalCa = caFields.reduce((sum, field) => {
          const fieldValue = cleanAndParseValue(facture.details?.[field] || 0);
          return sum + fieldValue;
        }, 0);

        // Calcul du CA pour les champs personnalisés
        const customFieldsCa = Object.keys(facture.details).reduce(
          (sum, field) => {
            if (!caFields.includes(field)) {
              // Si le champ n'est pas un des champs standards
              const fieldValue = cleanAndParseValue(
                facture.details?.[field] || 0
              );
              return sum + fieldValue;
            }
            return sum;
          },
          0
        );

        acc += totalCa + customFieldsCa; // Ajoute le CA standard et celui des champs personnalisés
      }
    });
    return acc;
  }, 0);

  const relevantInvoices = transports.filter(
    (facture) => facture.status === 'Terminé' || facture.status === 'Annuler facturé'
  );

  const CaGlobalAutomatiqueRaw = relevantInvoices
    .filter((facture) => {
      const factureDate = new Date(facture.date);
      return (
        factureDate >= startDate
        && factureDate <= endDate
        && (selectedSociety === '' || facture.societe === selectedSociety)
      );
    })
    .reduce((acc, facture) => acc + parseFloat(facture.price || 0), 0);

  // CA Automatique
  const CaGlobalAutomatique = CaGlobalAutomatiqueRaw.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  // CA Manuel
  const CaGlobalManuelRaw = Math.round(filteredCaFactures);

  const CaGlobalManuel = CaGlobalManuelRaw.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  const facturesdate = transports.filter((facture) => {
    const factureDate = new Date(facture.date);
    return (
      factureDate >= startDate
      && factureDate <= endDate
      && (selectedSociety === '' || facture.societe === selectedSociety)
    );
  });

  const facturesLength = facturesdate.length;

  // Basculement entre CA Automatique et Manuel
  function handleCaModeToggle() {
    setCaMode(caMode === 'Automatique' ? 'Manuel' : 'Automatique');
  }

  const displayedCa = caMode === 'Automatique' ? CaGlobalAutomatique : CaGlobalManuel;

  function generateData(startDate, endDate) {
    const data = [];
    const allCustomFields = new Set();

    const currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );

    while (currentDate <= endDate) {
      const month = currentDate.getMonth(); // 0-11
      const year = currentDate.getFullYear();

      const monthStart = new Date(year, month, 1);
      const monthEnd = new Date(year, month + 1, 0); // Last day of the month

      let Ca = 0;
      let CaAmbulance = 0;
      let CaPara = 0;
      let CaVsl = 0;
      let CaSamu = 0;
      let CaTaxi = 0;
      let totalMonthlySalary = 0;
      const customFieldsData = {};

      // Logique CA automatique ou manuel
      if (caMode === 'Automatique') {
        const filteredInvoices = transports.filter((facture) => {
          const factureDate = new Date(facture.date);
          return (
            factureDate >= monthStart
            && factureDate <= monthEnd
            && (!selectedSociety || facture.societe === selectedSociety)
          );
        });
        const relevantsInvoices = filteredInvoices.filter(
          (facture) => facture.status === 'Terminé' || facture.status === 'Annuler facturé'
        );

        Ca = Math.round(
          relevantsInvoices.reduce(
            (acc, facture) => acc + parseFloat(facture.price || 0),
            0
          )
        );
        CaAmbulance = Math.round(
          relevantsInvoices
            .filter((facture) => facture.tarification_type === 'Ambulance')
            .reduce(
              (acc, facture) => acc + parseFloat(facture.price || 0),
              0
            )
        );
        CaPara = Math.round(
          relevantsInvoices
            .filter((facture) => facture.tarification_type === 'Paramédical')
            .reduce(
              (acc, facture) => acc + parseFloat(facture.price || 0),
              0
            )
        );
        CaVsl = Math.round(
          relevantsInvoices
            .filter((facture) => facture.tarification_type === 'Vsl')
            .reduce(
              (acc, facture) => acc + parseFloat(facture.price || 0),
              0
            )
        );
        CaSamu = Math.round(
          relevantsInvoices
            .filter((facture) => facture.tarification_type === 'Samu')
            .reduce(
              (acc, facture) => acc + parseFloat(facture.price || 0),
              0
            )
        );
        CaTaxi = Math.round(
          relevantsInvoices
            .filter((facture) => facture.tarification_type === 'Taxi')
            .reduce(
              (acc, facture) => acc + parseFloat(facture.price || 0),
              0
            )
        );
      }
      else if (caMode === 'Manuel') {
        const caFields = [
          'Ambulance',
          'Paramedical',
          'Samu tableau A',
          'Samu tableau B',
          'Vsl',
          'Taxi',
        ];
        const filteredCompanies = companies.reduce(
          (acc, company) => {
            company.factures?.forEach((facture) => {
              const factureDate = new Date(facture.date);
              if (
                factureDate >= monthStart
                && factureDate <= monthEnd
                && (selectedSociety === '' || company.code === selectedSociety)
              ) {
                const totalCa = caFields.reduce((sum, field) => {
                  const fieldValue = cleanAndParseValue(
                    facture.details?.[field] || 0
                  );
                  return sum + fieldValue;
                }, 0);
                Object.keys(facture.details).forEach((field) => {
                  if (!caFields.includes(field)) {
                    const fieldValue = cleanAndParseValue(
                      facture.details[field]
                    );
                    customFieldsData[field] = (customFieldsData[field] || 0) + fieldValue; // Ajout des valeurs des champs personnalisés
                    allCustomFields.add(field); // Ajouter le champ au Set pour gestion dynamique
                  }
                });

                acc.totalCa += totalCa;
                acc.CaAmbulance += cleanAndParseValue(
                  facture.details?.Ambulance || 0
                );
                acc.CaPara += cleanAndParseValue(
                  facture.details?.Paramedical || 0
                );
                acc.CaVsl += cleanAndParseValue(facture.details?.Vsl || 0);
                acc.CaSamu
                  += cleanAndParseValue(
                    facture.details?.['Samu tableau A'] || 0
                  )
                  + cleanAndParseValue(facture.details?.['Samu tableau B'] || 0);
                acc.CaTaxi += cleanAndParseValue(facture.details?.Taxi || 0);
              }
            });
            return acc;
          },
          {
            totalCa: 0,
            CaAmbulance: 0,
            CaPara: 0,
            CaVsl: 0,
            CaSamu: 0,
            CaTaxi: 0,
          }
        );

        Ca = Math.round(filteredCompanies.totalCa);
        CaAmbulance = Math.round(filteredCompanies.CaAmbulance);
        CaPara = Math.round(filteredCompanies.CaPara);
        CaVsl = Math.round(filteredCompanies.CaVsl);
        CaSamu = Math.round(filteredCompanies.CaSamu);
        CaTaxi = Math.round(filteredCompanies.CaTaxi);
      }

      const customFieldsTotal = Object.values(customFieldsData).reduce(
        (sum, value) => sum + value,
        0
      );
      Ca += customFieldsTotal; // Ajoute la somme des champs personnalisés au total

      // Calcul indépendant de la masse salariale
      fetchedStaff.forEach((staffMember) => {
        staffMember.factures.forEach((facture) => {
          const factureDate = new Date(facture.date);
          const dateMatch = factureDate >= monthStart && factureDate <= monthEnd;
          const companyMatch = !selectedSociety || staffMember.company === selectedSociety;

          if (dateMatch && companyMatch) {
            let totalFactureCost = 0;

            if (facture.type === 'Salaire') {
              const salaryFields = [
                'salaire_brut',
                'cotisations_patronales',
                'indemnites_repas',
                'indemnites_repas_2',
                'prime_salissure',
                'transport',
                'taxe_salaire',
              ];
              totalFactureCost = salaryFields.reduce(
                (sum, field) => sum + cleanAndParseValue(facture.details?.[field] || 0),
                0
              );
            }
            else {
              totalFactureCost = cleanAndParseValue(
                facture.details?.cout || 0
              );
            }

            totalMonthlySalary += totalFactureCost;
          }
        });
      });

      // Ajout des données dans le tableau "data"
      data.push({
        date: `${month + 1}/${year}`,
        Total: Ca,
        Amb: CaAmbulance,
        Para: CaPara,
        Vsl: CaVsl,
        Samu: CaSamu,
        Taxi: CaTaxi,
        'Masse Salariale': parseFloat(totalMonthlySalary.toFixed(2)),
        ...customFieldsData, // Ajout dynamique des champs personnalisés dans les données mensuelles
      });

      // Passe au mois suivant
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return { data, allCustomFields };
  }

  const { data, allCustomFields } = generateData(startDate, endDate);

  const totalCa = caMode === 'Automatique' ? CaGlobalAutomatiqueRaw : CaGlobalManuelRaw;

  // Calcul du pourcentage de masse salariale par rapport au CA (éviter la division par zéro)
  const salaryPercentage = totalCa > 0 ? (totalSalaryCost / totalCa) * 100 : 0;

  if (isLoading) {
    return <div className="App">Chargement...</div>;
  }

  return (
    <div className="overView">
      <div className="search-bar">
        <Select
          value={selectedSociety}
          onChange={(event) => setSelectedSociety(event.target.value)}
          sx={{ width: 200 }}
          displayEmpty
          renderValue={(selected) => {
            if (selected === '') {
              return <em>Toutes les sociétés</em>;
            }
            return selected;
          }}
        >
          <MenuItem value="">
            <em>Toutes les sociétés</em>
          </MenuItem>
          {companies.map((company) => (
            <MenuItem key={company.id} value={company.code}>
              {company.code}
            </MenuItem>
          ))}
        </Select>
        {/* Bouton pour changer le mode de CA */}
        <Button variant="contained" onClick={handleCaModeToggle}>
          Mode CA: {caMode}
        </Button>
      </div>
      {/* Centrage des DatePickers */}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <div className="datePickerContainer">
          <DatePicker
            label="Date de début"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <DatePicker
            label="Date de fin"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            minDate={startDate}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      </LocalizationProvider>

      <div className="App-articles">
        <div className="App-article-preview">
          <div className="App-article-preview-title-text">
            <h1 className="App-article-preview-text">
              Chiffre d'affaire ({caMode})
            </h1>
          </div>
          <div className="App-article-preview-text">
            <h2 className="good">{displayedCa}</h2>
          </div>
        </div>
        <div className="App-article-preview">
          <div className="App-article-preview-title-text">
            <h1 className="App-article-preview-text">Nombre de missions</h1>
          </div>
          <div className="App-article-preview-text">
            <h2 className="good">{facturesLength}</h2>
          </div>
        </div>
        <div className="App-article-preview">
          <div className="App-article-preview-title-text">
            <h1 className="App-article-preview-text">Masse salariale</h1>
          </div>
          <div className="App-article-preview-text">
            <h2 className="good">
              {totalSalaryCost.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              €
            </h2>
          </div>
          <p style={{ fontSize: '14px', color: '#666' }}>
            {salaryPercentage.toFixed(2)}% du CA
          </p>
        </div>
      </div>
      <div className="Chart_container">
        <h3 className="chartTitle">
          Evolution du CA / Masse salariale mensuelle
        </h3>
        <ResponsiveContainer width="95%" height={400}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Total"
              stroke="#e35bf9"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="Amb" stroke="#cd0000" />
            <Line type="monotone" dataKey="Para" stroke="#008000" />
            <Line type="monotone" dataKey="Samu" stroke="#ffaa33" />
            <Line type="monotone" dataKey="Vsl" stroke="#0a47e0" />
            <Line type="monotone" dataKey="Taxi" stroke="#33f1ff9d" />
            {Array.from(allCustomFields).map((field, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={field}
                stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
              />
            ))}
            <Line
              type="monotone"
              dataKey="Masse Salariale"
              stroke="#ff6600"
            />
            {/* Ajoute dynamiquement les lignes pour les champs personnalisés */}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="Chart_container">
        <h3 className="chartTitle">
          Evolution de la masse salariale mensuelle
        </h3>
        <ResponsiveContainer width="95%" height={400}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="Masse Salariale"
              fill="grey"
              name="Masse Salariale"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default React.memo(overView);
