/* eslint-disable react/prop-types */
// src/components/StyledUploadField.js
import { useState } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Worker } from '@react-pdf-viewer/core';
import VehiculeImages from '../ActivityManagement/vehiculeManagement/vehiculeImages/vehiculeImages';

const useStyles = {
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
  localLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#333',
  },
  localButton: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
};

function StyledUploadField({
  uploadField,
  formData,
  handleFileChange,
  userToken,
  fetchSignedUrlForViewing,
  fetchSignedUrlForDownloading,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const styles = useStyles;

  const fileKey = formData[uploadField.fieldName];
  const filename = fileKey ? fileKey.split('/').pop() : '';
  const fileExtension = filename.split('.').pop().toLowerCase();

  return (
    <Grid item xs={12} sm={6} md={4} style={styles.gridItem} key={uploadField.id}>
      <input
        accept="image/*, application/pdf"
        style={styles.input}
        id={uploadField.id}
        type="file"
        onChange={(e) => handleFileChange(e, uploadField.fieldName)}
      />
      <label
        htmlFor={uploadField.id}
        style={{ ...styles.label, ...(isHovered ? { backgroundColor: '#e0e0e0' } : {}) }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="LocalCard"
      >
        <p style={styles.localLabel} className="localLabel">{uploadField.label}</p>
        {fileKey && (fileExtension === 'pdf' ? (
          <PictureAsPdfIcon style={{ fontSize: 40 }} />
        ) : (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <VehiculeImages
              imageKey={fileKey}
              key={fileKey}
              userToken={userToken}
            />
          </Worker>
        ))}
        <div style={styles.localButton} className="localButton">
          {fileKey && (
            <Tooltip title="Visualiser le document">
              <IconButton type="button" onClick={() => fetchSignedUrlForViewing(fileKey)}>
                <ImageSearchIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {fileKey && (
            <Tooltip title="Télécharger le document">
              <IconButton type="button" onClick={() => fetchSignedUrlForDownloading(fileKey)}>
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </label>
    </Grid>
  );
}

export default StyledUploadField;
