/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// VehicleManagementData.jsx
import React, { useRef } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Doughnut, Bar } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
  padding: theme.spacing(2),
}));

function VehicleManagementData({
  companies,
  handleFilterCompanyChange,
  handleVehiculeChange,
  vehicules,
  selectedVehicule,
  currentVehicules,
  paginateVehicles,
  pagesToShow,
  currentVehiclePage,
  totalVehiclePages,
  totalCost,
  repairsByType,
  barDataSelectedVehicle,
  barDataRepairsByVehicle,
  handleSelectVehicule,
  pastelColors,
}) {
  const muiTheme = useTheme();
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Référence pour le graphique
  const barChartRef = useRef(null);

  // Calcul de la hauteur dynamique du graphique
  const barChartHeight = Math.max(barDataRepairsByVehicle.labels.length * 30, 300);

  // Fonction pour gérer le clic sur le graphique
  const handleChartVehClick = (event, elements, chart) => {
    if (elements.length > 0) {
      const { index } = elements[0];
      const vehicleKey = chart.data.labels[index];
      handleSelectVehicule(vehicleKey.split(' - ')[0]);
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        backgroundColor: muiTheme.palette.background.default,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {/* Barre de Filtrage */}
      <Box sx={{ marginBottom: 4, display: 'flex', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="company-select-label">Société</InputLabel>
          <Select
            labelId="company-select-label"
            label="Société"
            onChange={(event) => handleFilterCompanyChange(event.target.value)}
          >
            <MenuItem value="">
              <em>Toutes les sociétés</em>
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.code}>
                {company.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="vehicle-select-label">Véhicule</InputLabel>
          <Select
            labelId="vehicle-select-label"
            label="Véhicule"
            value={selectedVehicule}
            onChange={handleVehiculeChange}
          >
            <MenuItem value="">
              <em>Tous les véhicules</em>
            </MenuItem>
            {vehicules.map((vehicule) => (
              <MenuItem key={vehicule.id} value={vehicule.code}>
                {vehicule.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Tableau des factures des véhicules */}
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Immatriculation</TableCell>
              <TableCell>Marque</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Kilométrage</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Type de Facture</TableCell>
              <TableCell>Sous-Type de Facture</TableCell>
              <TableCell>Total HT (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentVehicules.map((facture) => {
              const vehicule = vehicules.find((v) => v.code === facture.vehiculeCode);
              return (
                <TableRow key={facture.id}>
                  <TableCell>{vehicule.code}</TableCell>
                  <TableCell>{vehicule.immat}</TableCell>
                  <TableCell>{vehicule.marque}</TableCell>
                  <TableCell>{vehicule.type}</TableCell>
                  <TableCell>{facture.km}</TableCell>
                  <TableCell>{facture.date}</TableCell>
                  <TableCell>{facture.type}</TableCell>
                  <TableCell>{facture.sous_type}</TableCell>
                  <TableCell>{facture.Prices}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
        <Pagination
          count={totalVehiclePages}
          page={currentVehiclePage}
          onChange={(event, page) => paginateVehicles(page)}
          color="primary"
          siblingCount={pagesToShow}
        />
      </Box>

      {/* Cartes de Statistiques */}
      <Grid
        container
        spacing={3}
        sx={{ marginBottom: 4 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Coût total HT
              </Typography>
              <Typography variant="h5" color="textPrimary">
                {totalCost.toLocaleString('fr-FR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                €
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Graphiques */}
      <Grid container spacing={4} justifyContent="center">
        {selectedVehicule === '' ? (
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Coût par type" />
              <CardContent>
                <Box sx={{ height: isSmDown ? 250 : 300 }}>
                  <Doughnut
                    data={{
                      labels: Object.keys(repairsByType),
                      datasets: [
                        {
                          data: Object.values(repairsByType),
                          backgroundColor: pastelColors,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                          labels: {
                            color: muiTheme.palette.text.primary,
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}

        {selectedVehicule && selectedVehicule !== '' ? (
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`Détails des coûts pour ${selectedVehicule}`} />
              <CardContent>
                <Box sx={{ height: barChartHeight }}>
                  <Bar
                    lazy
                    data={barDataSelectedVehicle}
                    options={{
                      indexAxis: 'y',
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          ticks: {
                            color: muiTheme.palette.text.primary,
                          },
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          ticks: {
                            color: muiTheme.palette.text.primary,
                          },
                          grid: {
                            display: false,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Coût par véhicule" />
              <CardContent>
                <Box sx={{ height: barChartHeight }}>
                  <Bar
                    lazy
                    ref={barChartRef}
                    data={barDataRepairsByVehicle}
                    options={{
                      indexAxis: 'y',
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          ticks: {
                            color: muiTheme.palette.text.primary,
                          },
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          ticks: {
                            color: muiTheme.palette.text.primary,
                          },
                          grid: {
                            display: false,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      onClick: (event, elements, chart) => handleChartVehClick(event, elements, chart),
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default React.memo(VehicleManagementData);
