/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import AddTarifModal from './createTarif/createTarif';
import UpdateTarifModal from './updateTarif/updateTarif';
import AddTypeTarif from './addTypeTarif/addTypeTarif';
import usePageTitle from '../../hooks/usePageTitle';
import { BASE_URL } from '../../../utils/api';
import AmbysoftLoader from '../../AmbysoftLoader/AmbysoftLoader';
import './tarifsManagement.scss';

function TarifsManagement({ theme, userRole, userToken }) {
  usePageTitle('DRIVESOFT | GESTION DES TARIFS');
  const [tarifs, setTarifs] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddTypeModal, setShowAddTypeModal] = useState(false);
  const [typesDeTarif, setTypesDeTarif] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTarif, setCurrentTarif] = useState(null);

  const modalTarifRef = useRef(null);
  const updateTarifModalRef = useRef(null);
  const addTypeTarifModalRef = useRef(null);

  const isAuthorizedRole = () => {
    const authorizedRoles = ['administrateur', 'direction'];
    return authorizedRoles.includes(userRole);
  };

  const headers = {
    Authorization: `Bearer ${userToken}`,
  };

  const handleClick = (e) => {
    if (modalTarifRef.current && !modalTarifRef.current.contains(e.target)) {
      setShowAddModal(false);
    }
    if (addTypeTarifModalRef.current && !addTypeTarifModalRef.current.contains(e.target)) {
      setShowAddTypeModal(false);
    }
    if (updateTarifModalRef.current && !updateTarifModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
  };

  const handleAddClick = () => {
    if (!isAuthorizedRole()) {
      Swal.fire({
        icon: 'error',
        title: 'Vous n\'avez pas les droits pour ajouter une société',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setShowAddModal(true);
  };

  const handleDoubleClick = (tarif) => {
    setCurrentTarif(tarif);
    setShowUpdateModal(true);
  };

  const fetchTarifs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}tarifs`, { headers });
      setTarifs(response.data.tarifs);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des tarifs complets:', error);
    }
    setIsLoading(false);
  };

  // test

  useEffect(() => {
    fetchTarifs();
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const fetchTypesDeTarif = async () => {
    try {
      const response = await axios.get(`${BASE_URL}types-de-tarif`, { headers });
      setTypesDeTarif(response.data.typesDeTarif);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des types de tarif:', error);
    }
  };

  useEffect(() => {
    fetchTypesDeTarif();
  }, []);

  const handleAddTypeSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.elements.nom.value; // Assurez-vous que le champ du formulaire a l'attribut `name="nom"`

    try {
      await axios.post(`${BASE_URL}types-de-tarif`, { nom }, { headers });
      // Ajouter ici la logique pour mettre à jour l'état `typesDeTarif`
      setShowAddTypeModal(false);
      Swal.fire('Créé!', 'Le type de tarif a été ajouté avec succès.', 'success');
      fetchTypesDeTarif(); // Rafraîchir la liste des types de tarif
    }
    catch (error) {
      console.error('Erreur lors de la création du type de tarif:', error);
      Swal.fire('Erreur!', 'La création du type de tarif a échoué.', 'error');
    }
  };

  // Ajouter un tarif
  const handleAddTarif = async (tarif) => {
    setTarifs((prevTarifs) => [...prevTarifs, tarif]);
    Swal.fire({
      icon: 'success',
      title: 'Tarif ajouté avec succès',
      showConfirmButton: false,
      timer: 1500
    });
    setShowAddModal(false);
    fetchTarifs();
  };

  // Mettre à jour un tarif
  const handleUpdateTarif = async (tarif) => {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr de vouloir mettre à jour ce tarif?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, mettre à jour',
      cancelButtonText: 'Non',
    });
    if (confirmation.isConfirmed) {
      const index = tarifs.findIndex((t) => t.id === tarif.id);
      const updatedTarifs = [...tarifs.slice(0, index), tarif, ...tarifs.slice(index + 1)];
      setTarifs(updatedTarifs);
      Swal.fire({
        icon: 'success',
        title: 'Tarif mis à jour avec succès',
        showConfirmButton: false,
        timer: 1500
      });
      setShowUpdateModal(false);
      fetchTarifs();
    }
  };

  // Supprimer un tarif
  const handleDeleteTarif = async (tarifId) => {
    try {
      const confirmation = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimez-le!'
      });
      if (confirmation.isConfirmed) {
        await axios.delete(`${BASE_URL}/tarifs/${tarifId}`, { headers });
        Swal.fire(
          'Supprimé!',
          'Le tarif a été supprimé.',
          'success'
        );
        fetchTarifs(); // Rafraîchir la liste des tarifs
      }
    }
    catch (error) {
      console.error('Erreur lors de la suppression du tarif:', error);
      Swal.fire(
        'Erreur!',
        'La suppression du tarif a échoué.',
        'error'
      );
    }
  };

  const confirmCloseModal = (setModalState) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Êtes-vous sûr de vouloir quitter? Les modifications non enregistrées seront perdues.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, quitter!',
      cancelButtonText: 'Non, rester'
    }).then((result) => {
      if (result.isConfirmed) {
        // Fermer la modal si l'utilisateur confirme
        setModalState(false);
      }
    });
  };

  const groupedTarifsByType = () => typesDeTarif.map((type) => ({
    ...type,
    tarifs: tarifs.filter((tarif) => tarif.typedetarifid === type.id)
  }));

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
      margin: 'auto',
      width: '80%',
      marginLeft: '12%',
      height: '90%',
      padding: '30px',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
      overflow: 'scroll', // Vous pourriez vouloir ajuster cela pour être 'auto' si vous voulez que le défilement vertical apparaisse uniquement si nécessaire
      overflowX: 'hidden', // Cela cache la scrollbar horizontale
      backgroundColor: theme === 'dark' ? 'grey' : '#fff',
      color: theme === 'dark' ? 'black' : '#000',
    },
  };

  const darkThemeStyles = {
    backgroundColor: '#303030', // Fond sombre
    color: '#ffffff', // Texte clair
    borderColor: '#424242', // Bordures plus sombres
  };

  const lightThemeStyles = {
    backgroundColor: '#ffffff', // Fond clair
    color: '#000000', // Texte sombre
    borderColor: '#cccccc', // Bordures claires
  };

  const themeStyles = theme === 'dark' ? darkThemeStyles : lightThemeStyles;

  if (isLoading) return <AmbysoftLoader />;

  return (
    <div className="tarifsManagement" style={{ color: themeStyles.color, backgroundColor: themeStyles.backgroundColor }}>
      <div style={{
        marginBottom: '20px', justifyContent: 'center', display: 'flex', flexDirection: 'row'
      }}
      >
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '20%' }} onClick={() => setShowAddTypeModal(true)}>Types de tarif</Button>
        <Button variant="contained" sx={{ margin: '10px auto', backgroundColor: '#505154', width: '20%' }} onClick={handleAddClick} style={{ marginLeft: '10px' }}>Nouveau tarif</Button>
      </div>
      {groupedTarifsByType().map((type) => (
        <Accordion key={type.id} sx={{ backgroundColor: themeStyles.backgroundColor, color: themeStyles.color }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{type.nom}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow sx={{ borderColor: themeStyles.borderColor, backgroundColor: themeStyles.backgroundColor }}>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Nom</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Catégorie</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Pris en charge</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Remise 2 pers.</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Remise 3 pers.</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>TVA</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Tarif kilométrique</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Majorations</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Tarifs court trajet</TableCell>
                  <TableCell sx={{ color: themeStyles.color, borderColor: themeStyles.borderColor }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {type.tarifs.map((tarif) => (
                  <TableRow key={tarif.id} hover onClick={() => handleDoubleClick(tarif)} sx={{ '&:hover': { backgroundColor: theme === 'dark' ? '#424242' : '#eeeeee' } }}>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.nom}</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.categorie}</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.base}€</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.reductiondeuxpersonnes}%</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.reductiontroispersonnes}%</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.tva}%</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>{tarif.basekm}€</TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>
                      <ul>
                        {tarif.majorations && tarif.majorations.map((maj, majIndex) => (
                          <li key={majIndex}>{maj.nom}: {maj.valeur} {maj.type}</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell sx={{ color: themeStyles.color }}>
                      <ul>
                        {tarif.tarifscourttrajet && tarif.tarifscourttrajet.map((tct, tctIndex) => (
                          <li key={tctIndex}>{tct.condition}km: {tct.tarif}€</li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Supprimer">
                        <IconButton
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTarif(tarif.id);
                          }}
                          sx={{ color: themeStyles.color }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>

      ))}
      <Modal
        isOpen={showAddModal}
        ariaHideApp={false}
        onRequestClose={() => confirmCloseModal(setShowAddModal)}
        ref={(el) => {
          modalTarifRef.current = el && el.contentRef;
        }}
        style={customStyles}
      >
        <AddTarifModal userToken={userToken} typesDeTarif={typesDeTarif} onAddTarif={handleAddTarif} />
      </Modal>
      <Modal
        isOpen={showUpdateModal}
        ariaHideApp={false}
        onRequestClose={() => confirmCloseModal(setShowUpdateModal)}
        style={customStyles}
        ref={(el) => {
          updateTarifModalRef.current = el && el.contentRef;
        }}
      >
        <UpdateTarifModal userToken={userToken} onUpdateTarif={handleUpdateTarif} currentTarif={currentTarif} typesDeTarif={typesDeTarif} />
      </Modal>
      <Modal
        isOpen={showAddTypeModal}
        ariaHideApp={false}
        onRequestClose={() => confirmCloseModal(setShowAddTypeModal)}
        style={customStyles}
        ref={(el) => {
          addTypeTarifModalRef.current = el && el.contentRef;
        }}
      >
        <AddTypeTarif userToken={userToken} typesDeTarif={typesDeTarif} onAddTypeSubmit={handleAddTypeSubmit} />
      </Modal>
    </div>
  );
}

export default React.memo(TarifsManagement);
