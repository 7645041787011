/* eslint-disable max-len */
import React from 'react';
import './legalMentionsStyles.scss';

function LegalMentions() {
  return (
    <div className="legal-mentions-container">
      <h1>Mentions légales</h1>
      <p>Dernière mise à jour : 12/03/2024</p>

      <h2>Éditeur du site</h2>
      <p>
        <strong>DRIVESOFT SAS</strong><br />
        Capital social : 1 000€<br />
        RCS : <br />
        Siège social : 3 rue Marlène Dietrich, 95220 Herblay-Sur-Seine, France<br />
        N° TVA :<br />
        Téléphone : (+33)6 52 80 77 36<br />
        Email : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a>
      </p>

      <h2>Directeur de la publication</h2>
      <p>Florent Breux</p>

      <h2>Hébergeur</h2>
      <p>
        AWS<br />
        Site web : <a href="https://aws.amazon.com/fr/free/?gclid=Cj0KCQjw1Yy5BhD-ARIsAI0RbXY007lKpjwcNFAyatelj0Rk-WW9TUnWrJgL4d4tpyKOPBYY1w2Q4vEaAvoTEALw_wcB&trk=36ca612a-b750-4d2d-b0c1-7d0f0863c3d2&sc_channel=ps&ef_id=Cj0KCQjw1Yy5BhD-ARIsAI0RbXY007lKpjwcNFAyatelj0Rk-WW9TUnWrJgL4d4tpyKOPBYY1w2Q4vEaAvoTEALw_wcB:G:s&s_kwcid=AL!4422!3!563933958237!e!!g!!aws!15356572755!127983196977&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all">www.aws.amazon.com</a>
      </p>

      <h2>Propriété intellectuelle</h2>
      <p>
        Le site et chacun des éléments qui le composent, y compris les textes, images, logos, graphiques, logiciels, et autres contenus, sont la propriété exclusive de DRIVESOFT ou de ses partenaires. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite sans autorisation écrite préalable de DRIVESOFT.
      </p>

      <h2>Limitation de responsabilité</h2>
      <p>
        DRIVESOFT ne saurait être tenu responsable des dommages directs ou indirects causés au matériel de l'utilisateur lors de l'accès au site, résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées, soit de l'apparition d'un bug ou d'une incompatibilité.
      </p>

      <h2>Données personnelles</h2>
      <p>
        Les informations recueillies via les formulaires du site sont enregistrées dans un fichier informatisé par DRIVESOFT pour la gestion de sa clientèle. Elles sont conservées pendant 3 ans et sont destinées au service marketing et au service commercial établis au sein de l'Union européenne. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : <a href="mailto:contact@drivesoft.fr">contact@drivesoft.fr</a>
      </p>

      <h2>Cookies</h2>
      <p>
        La navigation sur le site est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Vous pouvez configurer votre navigateur pour refuser l'installation des cookies.
      </p>

      <h2>Loi applicable</h2>
      <p>
        Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
      </p>
    </div>
  );
}

export default React.memo(LegalMentions);
